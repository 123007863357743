import {Observable} from 'rxjs';
import {RestQueryParams} from '../base/queryParams';
import {marineHubAPI} from '../provider/marineHubAPI';

export function getDictionaryDataAPI(dictionaryName: string, authToken: string | null): Observable<any> {
    let headers = undefined;
    if (authToken) {
        headers = {
            Authorization: `Bearer ${authToken}`,
        };
    }

    return marineHubAPI.get(`api/dictionary/${dictionaryName}`, new RestQueryParams(), headers);
}
