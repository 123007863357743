import {IconSize, Marina, Translation} from 'marine-panel-common-web';
import {FC} from 'react';
import {Plus} from 'react-feather';
import {ModalBody, ModalHeader} from 'reactstrap';
import {setPhotoAsCover} from '../../../../store/reducers/marinaEditWizardSlice';
import styles from '../style.module.scss';

interface IChangePhotoModalProps {
    readonly setPhotoAsCover: typeof setPhotoAsCover;
    readonly marina: Marina | null;
    readonly toggleModalOpen: () => void;
}

const ChangePhotoModal: FC<IChangePhotoModalProps> = ({setPhotoAsCover, marina, toggleModalOpen}) => {
    return (
        <>
            <ModalHeader toggle={() => toggleModalOpen()}>
                <Translation text="modal.changePhoto.title" />
            </ModalHeader>
            <ModalBody className={styles.container}>
                <div className={styles.content}>
                    <ul>
                        {marina !== null ? (
                            marina?.photos.map((item: any) => {
                                return (
                                    <li key={item.file.id}>
                                        <img className={styles.galleryPhoto} alt="" src={item.file.fileUrls.original}></img>
                                        <button
                                            className={`btn btn-icon ${styles.changeCoverPhoto}`}
                                            onClick={() => setPhotoAsCover(item.file.id, marina?.id)}>
                                            <Plus id={item.file.id} size={IconSize.ChangePhotoModalButton} />
                                        </button>
                                    </li>
                                );
                            })
                        ) : (
                            <li key="no-data" className={styles.noData}>
                                <Translation text="modal.changePhoto.noPhotosUploaded" />
                            </li>
                        )}
                    </ul>
                </div>
            </ModalBody>
        </>
    );
};

export default ChangePhotoModal;
