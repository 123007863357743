import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {IArticle, IArticleFullDetails, IModelApiResponseViewObject} from 'marine-panel-common-web';
import {initApiCall} from '../../utils/reduxHelpers';
import {
    IBaseDictionaryState,
    IChangeIsInitialized,
    IChangeIsLoading,
    IChangePagePagination,
    IPaginationParams,
    ISetError,
    ISetPageMetadata,
} from './marinasSlice';
import {IAddArticlePayload} from '../../api/addArticle';

export interface IArticlesState extends IBaseDictionaryState {
    articles: IArticle[] | null;
    articleDetails: IArticleFullDetails | null;
    articlesMetadata: IModelApiResponseViewObject | null;
    pagination: IPaginationParams | null;
    isArticleActionComplete: boolean;
    isArticleActionProcessing: boolean;
}

export interface ISetArticles {
    readonly articles: IArticle[] | null;
}

export interface ISetArticleDetails {
    readonly article: IArticleFullDetails | null;
}

export interface IAddArticle {
    readonly articlePayload: IAddArticlePayload;
}

export interface IUpdateArticle {
    readonly articleId: string;
    readonly articlePayload: IAddArticlePayload;
}

export interface IDeleteArticle {
    readonly articleId: string;
}

export interface IBindArticle {
    readonly articleId: string;
    readonly marinaIds: string[];
}

export interface IChangeArticleActionComplete {
    readonly isComplete: boolean;
}

export interface IChangeArticleActionProcessing {
    readonly isProcessing: boolean;
}

export interface IFetchArticleDetails {
    readonly articleId: string;
}

export interface IChangeArticleStatus {
    readonly articleId: string;
    readonly isActive: boolean;
}

const initialState: IArticlesState = {
    articles: null,
    articleDetails: null,
    articlesMetadata: null,
    pagination: {
        page: 1,
        itemsPerPage: 10,
    },
    isLoading: false,
    isInitialized: false,
    error: null,
    isArticleActionComplete: false,
    isArticleActionProcessing: false,
};

const articlesSlice = createSlice({
    name: 'articles',
    initialState: initialState,
    reducers: {
        changeArticles: {
            reducer: (state: IArticlesState, action: PayloadAction<ISetArticles>) => {
                return {
                    ...state,
                    articles: action.payload.articles,
                };
            },
            prepare(articles: IArticle[] | null) {
                return {
                    payload: {articles: articles},
                };
            },
        },
        changeArticleDetails: {
            reducer: (state: IArticlesState, action: PayloadAction<ISetArticleDetails>) => {
                return {
                    ...state,
                    articleDetails: action.payload.article,
                };
            },
            prepare(article: IArticleFullDetails | null) {
                return {
                    payload: {article: article},
                };
            },
        },
        changeIsArticlesPageLoading: {
            reducer: (state: IArticlesState, action: PayloadAction<IChangeIsLoading>) => {
                return {
                    ...state,
                    isLoading: action.payload.isLoading,
                };
            },
            prepare(isLoading: boolean) {
                return {
                    payload: {isLoading: isLoading},
                };
            },
        },
        changeIsArticlesPageInitialized: {
            reducer: (state: IArticlesState, action: PayloadAction<IChangeIsInitialized>) => {
                return {
                    ...state,
                    isInitialized: action.payload.isInitialized,
                };
            },
            prepare(isInitialized: boolean) {
                return {
                    payload: {isInitialized: isInitialized},
                };
            },
        },
        setArticlesPageError: {
            reducer: (state: IArticlesState, action: PayloadAction<ISetError>) => {
                return {
                    ...state,
                    error: action.payload.error,
                };
            },
            prepare(error: string | null) {
                return {
                    payload: {error: error},
                };
            },
        },
        setArticlesPageMetadata: {
            reducer: (state: IArticlesState, action: PayloadAction<ISetPageMetadata>) => {
                return {
                    ...state,
                    articlesMetadata: action.payload.metadata,
                };
            },
            prepare(metadata: IModelApiResponseViewObject | null) {
                return {
                    payload: {
                        metadata: metadata,
                    },
                };
            },
        },
        changeArticlesPagePagination: {
            reducer: (state: IArticlesState, action: PayloadAction<IChangePagePagination>) => {
                return {
                    ...state,
                    isLoading: true,
                    pagination: action.payload.pagination,
                };
            },
            prepare(pagination: IPaginationParams) {
                return {
                    payload: {pagination: pagination},
                };
            },
        },
        changeIsArticlesActionComplete: {
            reducer: (state: IArticlesState, action: PayloadAction<IChangeArticleActionComplete>) => {
                return {
                    ...state,
                    isArticleActionComplete: action.payload.isComplete,
                };
            },
            prepare(isComplete: boolean) {
                return {
                    payload: {isComplete: isComplete},
                };
            },
        },
        changeIsArticlesActionProcessing: {
            reducer: (state: IArticlesState, action: PayloadAction<IChangeArticleActionProcessing>) => {
                return {
                    ...state,
                    isArticleActionProcessing: action.payload.isProcessing,
                };
            },
            prepare(isProcessing: boolean) {
                return {
                    payload: {isProcessing: isProcessing},
                };
            },
        },
        addArticle: {
            reducer: (state: IArticlesState) => {
                return {
                    ...state,
                    isArticleActionProcessing: true,
                };
            },
            prepare(articlePayload: IAddArticlePayload) {
                return {
                    payload: {articlePayload: articlePayload},
                };
            },
        },
        updateArticle: {
            reducer: (state: IArticlesState) => {
                return {
                    ...state,
                    isArticleActionProcessing: true,
                };
            },
            prepare(articleId: string, articlePayload: IAddArticlePayload) {
                return {
                    payload: {articleId: articleId, articlePayload: articlePayload},
                };
            },
        },
        deleteArticle: {
            reducer: (state: IArticlesState) => {
                return {
                    ...state,
                    isArticleActionProcessing: true,
                };
            },
            prepare(articleId: string) {
                return {
                    payload: {articleId: articleId},
                };
            },
        },
        bindArticleToMarinas: {
            reducer: (state: IArticlesState) => {
                return {
                    ...state,
                    isArticleActionProcessing: true,
                };
            },
            prepare(articleId: string, marinaIds: string[]) {
                return {
                    payload: {articleId: articleId, marinaIds: marinaIds},
                };
            },
        },
        unbindArticleFromMarinas: {
            reducer: (state: IArticlesState) => {
                return {
                    ...state,
                    isArticleActionProcessing: true,
                };
            },
            prepare(articleId: string, marinaIds: string[]) {
                return {
                    payload: {articleId: articleId, marinaIds: marinaIds},
                };
            },
        },
        changeArticleStatus: {
            reducer: (state: IArticlesState) => {
                return {
                    ...state,
                    isLoading: true,
                };
            },
            prepare(articleId: string, isActive: boolean) {
                return {
                    payload: {articleId: articleId, isActive: isActive},
                };
            },
        },
        fetchArticles: (state: IArticlesState) => initApiCall(state),
        fetchArticleDetails: {
            reducer: (state: IArticlesState) => {
                return {
                    ...state,
                    isArticleActionProcessing: true,
                };
            },
            prepare(articleId: string) {
                return {
                    payload: {articleId: articleId},
                };
            },
        },
        resetToInitialArticlesPageState: () => {
            return {
                ...initialState,
            };
        },
    },
});

export const {
    changeArticles,
    changeArticleDetails,
    changeIsArticlesPageLoading,
    changeIsArticlesPageInitialized,
    setArticlesPageError,
    setArticlesPageMetadata,
    changeArticlesPagePagination,
    changeIsArticlesActionComplete,
    changeIsArticlesActionProcessing,
    addArticle,
    updateArticle,
    deleteArticle,
    bindArticleToMarinas,
    unbindArticleFromMarinas,
    fetchArticles,
    fetchArticleDetails,
    changeArticleStatus,
    resetToInitialArticlesPageState,
} = articlesSlice.actions;

export default articlesSlice.reducer;
