import {IModelApiObject} from './base';

export enum DictionaryName {
    COUNTRY = 'countries',
    VESSEL_MODELS = 'vessel_models',
    VESSEL_TYPES = 'vessel_types',
}

export interface IModelDictionaryDatum extends IModelApiObject {
    readonly id: string;
    readonly name: string;
}

export interface IModelCity extends IModelDictionaryDatum {
    country: IModelDictionaryDatum;
}
