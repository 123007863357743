import React from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import {IFormConfig, UserRole} from '../../..';
import {IResetPasswordPayload} from '../../../api/auth/sendResetPasswordEmail';
import Form from '../../Form';
import Loader from '../../Loader';
import Translation from '../../Translation';
import {requestNewPassword} from '../../../store/reducers/authSlice';

interface IConnectedResetPasswordProps {
    readonly requestNewPassword: typeof requestNewPassword;
}

interface IExternalResetPasswordProps {
    readonly userRole: UserRole;
    readonly envData: any;
    readonly isLoading: boolean;
    readonly formConfig: IFormConfig;
}

interface IResetPasswordProps extends IConnectedResetPasswordProps, IExternalResetPasswordProps {}

interface IResetPasswordState {
    formConfig: IFormConfig;
    value: any;
}

class ResetPassword extends React.Component<IResetPasswordProps, IResetPasswordState> {
    constructor(props: IResetPasswordProps) {
        super(props);

        this.state = {
            formConfig: this.props.formConfig,
            value: null,
        };
    }

    render() {
        const isUserBoastman = this.props.userRole === UserRole.BOATSMAN,
            loginUrl = isUserBoastman ? '/boatsman/auth/login' : '/auth/login';

        return (
            <article className="auth-article">
                <header className="auth-form-header">
                    <h1 className="auth-title">
                        <Translation text="auth.resetPassword.title" />
                    </h1>
                    <div className="auth-subtitle-container">
                        <p className="auth-subtitle reset-subtitle">
                            <Translation text="auth.resetPassword.subtitle" />
                        </p>
                    </div>
                </header>
                <Loader showLoader={this.props.isLoading} />
                <Form config={this.state.formConfig} submitForm={this.resetPassword} controlName={'resetPasswordForm'} value={{}} />
                <p className="auth-subtitle redirect-link-container">
                    <Link className="auth-redirect-link" to={loginUrl}>
                        <Translation text="buttons.backToLogin" />
                    </Link>
                </p>
            </article>
        );
    }
    private resetPassword = (event: any, value: any, valid: boolean, touched: boolean) => {
        event.preventDefault();
        if (!valid || !touched) {
            return;
        }

        const baseUrl =
                this.props.userRole === UserRole.USER ? `${this.props.envData.REACT_APP_URL}` : `${this.props.envData.REACT_APP_URL}`,
            resetPasswordPayload: IResetPasswordPayload = {
                username: value.email,
                returnUrl: `${baseUrl}/auth/new-password`,
            };

        this.props.requestNewPassword(resetPasswordPayload);
    };
}

export default connect(() => ({}), {
    requestNewPassword,
})(ResetPassword);
