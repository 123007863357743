import React from 'react';
import ChatUsersListItem from './ChatUserListItem';
import {AllUserConnections, AvatarColor, AvatarStatus, ChatContact, ChatType} from '../../../types';
import Translation from '../../Translation';
import Avatar from '../../Avatar';
import {Input, InputGroup, InputGroupText} from 'reactstrap';
import {Coffee, Search} from 'react-feather';
import {IconSize} from '../../../constants/miscellaneous';

interface Props {
    connections: AllUserConnections;
    chatContactsList: ChatContact[];
    onChatSelection: (chat: string) => void;
    selectedChat: string;
    showOnlineIndicator?: boolean;
    isConnected: boolean;
}

interface State {
    searchedUser: string;
}

class ChatUsersList extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            searchedUser: '',
        };
    }

    render() {
        if (!this.props.chatContactsList || this.props.chatContactsList.length === 0) {
            return (
                <p>
                    <Translation text={'chat.noUserListProvided'} />
                </p>
            );
        }

        return (
            <div className="sidebar-left">
                <div className="sidebar">
                    <div className={'sidebar-content'}>
                        <div className="chat-fixed-search">
                            <div className="d-flex align-items-center w-100">
                                <div className="sidebar-profile-toggle">
                                    <Avatar
                                        //todo account
                                        img={undefined}
                                        icon={<Coffee size={IconSize.ChatUserListAvatar} />}
                                        className="avatar-border"
                                        color={AvatarColor.PRIMARY}
                                        imgHeight="42"
                                        imgWidth="42"
                                        status={this.props.isConnected ? AvatarStatus.ONLINE : AvatarStatus.OFFLINE}
                                        tag={'div'}
                                    />
                                </div>
                                <InputGroup className="input-group-merge ms-1 w-100 round" style={{border: '1px solid #d8d6de'}}>
                                    <InputGroupText className="round border-0">
                                        <Search className="text-muted" size={IconSize.ChatSearch} />
                                    </InputGroupText>
                                    <Input
                                        value={this.state.searchedUser}
                                        className="round"
                                        placeholder="Search or start a new chat"
                                        onChange={this.handleFilter}
                                    />
                                </InputGroup>
                            </div>
                        </div>
                        <div className="chat-user-list-wrapper list-group">
                            <h4 className="chat-list-title">Chats</h4>
                            <ul className={'chat-users-list chat-list media-list'}>
                                {this.sortUsers.map((chatContact: ChatContact) => (
                                    <React.Fragment key={chatContact.accountId}>
                                        <ChatUsersListItem
                                            chatContact={chatContact}
                                            showOnlineIndicator={
                                                this.props.showOnlineIndicator && this.props.connections?.[chatContact.roomId]?.peerIsOnline
                                            }
                                            unseenMessages={this.props.connections?.[chatContact.roomId]?.unseenMessages || 0}
                                            selectedChat={this.props.selectedChat}
                                            onChatSelection={this.props.onChatSelection}
                                        />
                                    </React.Fragment>
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    private handleFilter = (e) => this.setState({searchedUser: e.target.value});

    private get sortUsers() {
        const list = this.props.chatContactsList.filter(
            (contact) => `${contact.firstName} ${contact.lastName}`.toLowerCase().indexOf(this.state.searchedUser?.toLowerCase()) !== -1
        );
        // const list = deepCloneObject(this.props.chatContactsList)
        if (!list || list.length < 2) {
            return list;
        }
        const UNNAMED_LAST = 'ZZZZZ';
        const alphabeticalList = list.sort((a, b) => {
            const textA = a?.lastName?.toUpperCase() || UNNAMED_LAST;
            const textB = b?.lastName?.toUpperCase() || UNNAMED_LAST;

            return textA < textB ? -1 : textA > textB ? 1 : 0;
        });

        if (this.props.showOnlineIndicator) {
            alphabeticalList.sort((a: ChatContact, b: ChatContact) => {
                if (this.props.connections?.[a.roomId]?.unseenMessages && !this.props.connections?.[b.roomId]?.unseenMessages) {
                    return -1;
                } else if (this.props.connections?.[a.roomId]?.peerIsOnline && !this.props.connections?.[b.roomId]?.peerIsOnline) {
                    return -1;
                } else {
                    return 0;
                }
            });
        }

        return alphabeticalList;
    }
}

export default ChatUsersList;
