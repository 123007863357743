import {createSelector} from '@reduxjs/toolkit';
import {IAccountState} from '../reducers/accountSlice';
import {RootState} from '../reducers';
import {OperatorFullInfo, SkipperFullInfo} from '../../types';

export const selectAccountState = (state: RootState): IAccountState => {
    return state.account;
};

export const accountSelector = createSelector([selectAccountState], (state: IAccountState) => state.account);
export const accountIsInitialisedSelector = createSelector([selectAccountState], (state: IAccountState) => state.isInitialized);
export const accountIsLoadingSelector = createSelector([selectAccountState], (state: IAccountState) => state.isLoading);

export const basicAccountDataSelector = createSelector([selectAccountState], (state: IAccountState) => state.account);

export const basicAccountMiscSelector = createSelector([selectAccountState], (state: IAccountState) => state.misc);

export const accountIdSelector = createSelector([selectAccountState], (state: IAccountState) => {
    return state.account?.id;
});

export const skipperAccountIdSelector = createSelector([selectAccountState], (state: IAccountState) => {
    return (state.account as SkipperFullInfo)?.account.id;
});

export const operatorAccountIdSelector = createSelector([selectAccountState], (state: IAccountState) => {
    return (state.account as OperatorFullInfo)?.operator?.id;
});

export const accountUserIdSelector = createSelector(
    [selectAccountState],
    (state: IAccountState) => (state.account as OperatorFullInfo)?.user?.id
);
