import React, {Component} from 'react';
import * as Icon from 'react-feather';
import { UncontrolledDropdown, DropdownMenu, DropdownToggle, DropdownItem } from 'reactstrap';
import {Users} from "react-feather";
import {AvatarColor, AvatarSize, IUserDropdownProps, IUserMenuItem} from "../../../types";
import Avatar from "../../Avatar";
import Translation from '../../Translation';
import { isNotNullOrUndefined } from '../../../utils/runtimeUtils';
import {IconSize} from '../../../constants/miscellaneous';


type Props = IUserDropdownProps;

class UserDropdown extends Component<Props> {
    render() {

        return (
            this.props.isAuthenticated ? this.renderAuthenticatedUserMenu() : this.renderUnauthenticatedUserMenu()
        )
    }

    private renderAuthenticatedUserMenu = () => {
        const username = this.props.account && this.props.account.firstName ?
            `${this.props.account.firstName} ${this.props.account.lastName}` : 'Username',
            role = 'Admin',
            logo = this.props.account?.logo ? this.props.account.logo : undefined;

        return (
            <UncontrolledDropdown tag='li' className='dropdown-user nav-item'>
                <DropdownToggle href='/' tag='a' className='nav-link dropdown-user-link' onClick={e => e.preventDefault()}>
                    <div className='user-nav d-sm-flex d-none'>
                        <span className='user-name'>{username}</span>
                        <span className='user-status'>{role}</span>
                    </div>
                    <Avatar color={AvatarColor.LIGHT_PRIMARY}
                            size={AvatarSize.LG}
                            img={logo}
                            icon={logo === undefined ? <Users size={IconSize.UserNavAvatar}/> : undefined}
                            tag={'div'}/>
                </DropdownToggle>
                <DropdownMenu end>
                    {this.props.userMenu(this.handleLogout).map((item: IUserMenuItem) => this.renderDropdownItem(item))}
                </DropdownMenu>
            </UncontrolledDropdown>
        )
    };

    private renderUnauthenticatedUserMenu = () => {
        return (
            <UncontrolledDropdown tag='li' className='dropdown-user nav-item'>
                <DropdownToggle href='/' tag='a' className='nav-link dropdown-user-link' onClick={e => e.preventDefault()}>
                    <div className='user-nav d-sm-flex d-none'>
                        <span className='login-placeholder'><Translation text="buttons.login"/></span>
                    </div>
                    <Avatar color={AvatarColor.LIGHT_PRIMARY}
                            size={AvatarSize.LG}
                            icon={<Users size={IconSize.UserNavAvatar}/>}
                            tag={'div'}/>
                </DropdownToggle>
                <DropdownMenu end>
                    {this.props.unauthorizedUserMenu.map((item: IUserMenuItem) => this.renderDropdownItem(item))}
                </DropdownMenu>
            </UncontrolledDropdown>
        )
    };

    private renderDropdownItem = (item: IUserMenuItem) => {
        if (item.isDivider) {
            return <DropdownItem divider key='divider'/>
        }

        const IconTag = item.icon ? (Icon as any)[item.icon] : null,
            redirectUrl = this.props.isAuthenticated ? `${process.env.REACT_APP_CANDIDATE_URL}/${item.link}` : item.link,
            Tag = undefined !== item.action ?
            (
                <span className="" onClick={() => item.action ? item.action() : null}>
                    {isNotNullOrUndefined(IconTag) ? <IconTag size={IconSize.UserMenuItem} className='me-75' /> : null}
                    <span className='align-middle'><Translation text={item.title} /></span>
                </span>
            ) : (
                <a className="" href={redirectUrl} target="_blank">
                    {isNotNullOrUndefined(IconTag) ? <IconTag size={IconSize.UserMenuItem} className='me-75' /> : null}
                    <span className='align-middle'><Translation text={item.title} /></span>
                </a>
            );

        return (
            <DropdownItem key={item.id}>
                {Tag}
            </DropdownItem>
        )
    };

    private handleLogout = () => {
        console.log('logout');
    };
}

export default UserDropdown;
