import {FormButtonType, FormControlType, IFormConfig, InputType, ValidationRules} from 'marine-panel-common-web';

export const loginFormConfig = (): IFormConfig => ({
    controlType: 'form',
    class: 'auth-form admin-auth-form',
    dataAccessor: (data: any) => data,
    outputDataMapper: (data: any, previousStateSnapshot: any) => {
        Object.assign(previousStateSnapshot, data);
        return previousStateSnapshot;
    },
    controls: [
        {
            key: 'login_email',
            controlType: 'group',
            class: 'row',
            controls: {
                email: {
                    controlType: 'control',
                    defaultValue: '',
                    formControlType: FormControlType.INPUT,
                    validationRules: [
                        {name: ValidationRules.IS_REQUIRED, params: {}},
                        {name: ValidationRules.IS_EMAIL, params: {}},
                    ],
                    placeholder: 'auth.formControls.placeholders.email',
                    isLabelHidden: false,
                    label: 'auth.formControls.email',
                    type: InputType.EMAIL,
                    hostClass: 'col-xl-12 form-control',
                },
                password: {
                    controlType: 'control',
                    defaultValue: '',
                    formControlType: FormControlType.INPUT,
                    validationRules: [
                        {name: ValidationRules.IS_REQUIRED, params: {}},
                        {name: ValidationRules.MIN_LENGTH, params: {length: 6}},
                    ],
                    placeholder: 'auth.formControls.placeholders.password',
                    isLabelHidden: false,
                    label: 'auth.formControls.password',
                    type: InputType.PASSWORD,
                    hostClass: 'col-xl-12 form-control',
                },
            },
        },
        {
            controlType: 'group',
            key: 'login_submit',
            class: 'login-submit-button',
            controls: {
                submitButton: {
                    controlType: 'control',
                    formControlType: FormControlType.BUTTON,
                    buttonType: FormButtonType.SUBMIT,
                    inputStyles: 'btn btn-primary',
                    defaultContainerStyles: '',
                    // labelHidden: true,
                    hostClass: '',
                    containerStyles: 'button-container mt-2',
                    defaultValue: null,
                    btnText: 'buttons.login',
                },
            },
        },
    ],
});
