import {createSelector} from '@reduxjs/toolkit';
import {IVesselModelsState} from '../reducers/vesselModelsPageSlice';
import {RootState} from '../reducers';

export const selectVesselModelsPageSlice = (state: RootState): IVesselModelsState => {
    return state.vesselModels;
};

export const vesselModelsSelector = createSelector([selectVesselModelsPageSlice], (state: IVesselModelsState) => state.vesselModels);

export const isVesselModelsPagesLoadingSelector = createSelector(
    [selectVesselModelsPageSlice],
    (state: IVesselModelsState) => state.isLoading
);

export const isVesselModelsPagesInitializedSelector = createSelector(
    [selectVesselModelsPageSlice],
    (state: IVesselModelsState) => state.isInitialized
);

export const vesselModelsPagesErrorSelector = createSelector([selectVesselModelsPageSlice], (state: IVesselModelsState) => state.error);

export const isVesselModelActionCompleteSelector = createSelector(
    [selectVesselModelsPageSlice],
    (state: IVesselModelsState) => state.isActionComplete
);

export const isVesselModelActionProcessingSelector = createSelector(
    [selectVesselModelsPageSlice],
    (state: IVesselModelsState) => state.isActionProcessing
);
