import {RestQueryParams, marineHubAPI} from 'marine-panel-common-web';
import {Observable} from 'rxjs';
import {IChangeTagDescription} from '../../store/reducers/marinasTagsSlice';

export interface ITagDescriptionPayload {
    marinaTagId: string | null;
    description: string | null;
}

export function changeMarinaTagDescriptionAPI(authToken: string | null, payload: IChangeTagDescription): Observable<any> {
    const headers = {
        Authorization: `Bearer ${authToken}`,
    };

    return marineHubAPI.put(`api/marinas/${payload.marinaId}/change_tag_description`, payload.tagPayload, new RestQueryParams(), headers);
}
