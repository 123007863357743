import {Marina} from 'marine-panel-common-web';
import {useEffect} from 'react';
import {connect} from 'react-redux';
import {useParams} from 'react-router-dom';
import {fetchMarinaDetails, resetToInitialMarinaEditWizardState} from '../../../../../../store/reducers/marinaEditWizardSlice';
import {
    currentlyEditedMarina,
    isActionSuccessfulSelector,
    isMarinaEditWizardInitializedSelector,
    isMarinaEditWizardLoadingSelector,
} from '../../../../../../store/selectors/marinaEditWizardSelectors';
import {RootState} from '../../../../../../store/reducers';
import MarinaBasics from './MarinaBasics';
import MarinaContact from './MarinaContact';
import MarinaStatus from './MarinaStatus';
import MarinaLocation from './MarinaLocation';

export interface IMarinaDetailsComponentProps {
    readonly marina: Marina | null;
    readonly fetchMarinaDetails: typeof fetchMarinaDetails;
    readonly resetToInitialMarinaEditWizardState: typeof resetToInitialMarinaEditWizardState;
    readonly isLoading: boolean;
}

function MarinaDetailsComponent(props: IMarinaDetailsComponentProps) {
    const params = useParams(),
        marinaId = params.id;

    useEffect(() => {
        props.fetchMarinaDetails(marinaId ? marinaId : null);
    }, [marinaId]);

    const marina = props.marina;
    return (
        <div className="details-edit-section-wrapper" data-scroller>
            <MarinaBasics marina={marina} />
            <MarinaContact marina={marina} />
            <MarinaStatus marina={marina} />
            <MarinaLocation marina={marina} />
        </div>
    );
}

export default connect(
    (state: RootState) => ({
        marina: currentlyEditedMarina(state),
        isLoading: isMarinaEditWizardLoadingSelector(state),
        isActionSuccessful: isActionSuccessfulSelector(state),
        isMarinaEditWizardInitialized: isMarinaEditWizardInitializedSelector(state),
    }),
    {
        fetchMarinaDetails,
        resetToInitialMarinaEditWizardState,
    }
)(MarinaDetailsComponent);
