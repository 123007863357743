import {createSelector} from '@reduxjs/toolkit';
import {RootState} from '../reducers';
import {IMarinaEditWizardState} from '../reducers/marinaEditWizardSlice';

export const selectMarinaEditWizardSlice = (state: RootState) => {
    return state.marinaEditWizard;
};

export const currentlyEditedMarina = createSelector([selectMarinaEditWizardSlice], (state: IMarinaEditWizardState) => state.marina);

export const isMarinaEditWizardLoadingSelector = createSelector(
    [selectMarinaEditWizardSlice],
    (state: IMarinaEditWizardState) => state.isMarinaEditWizardLoading
);
export const isMarinaEditWizardInitializedSelector = createSelector(
    [selectMarinaEditWizardSlice],
    (state: IMarinaEditWizardState) => state.isMarinaEditWizardInitialized
);

export const isActionSuccessfulSelector = createSelector(
    [selectMarinaEditWizardSlice],
    (state: IMarinaEditWizardState) => state.isActionSuccessful
);

export const marinaEditWizardErrorSelector = createSelector(
    [selectMarinaEditWizardSlice],
    (state: IMarinaEditWizardState) => state.marinaEditWizardError
);

export const currentlyEditedControlId = createSelector(
    [selectMarinaEditWizardSlice],
    (state: IMarinaEditWizardState) => state.currentlyEditedControlId
);

export const tagListSelector = createSelector([selectMarinaEditWizardSlice], (state: IMarinaEditWizardState) => state.tags);
