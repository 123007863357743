import React from 'react';
import {
    AllUserConnections,
    ApiSingleResponseBase,
    ChatContact,
    ChatMessage,
    ChatServerMessageConfirmPayload,
    ChatServerMessagePayload,
    MediaFile,
    MessageContentType,
    PeerConnection,
    SignalAnswer,
    SignalCandidate,
    SignalOffer,
} from '../../../types';
import io, {Socket} from 'socket.io-client';
import {expand, from, Observable, of, OperatorFunction, Subject, Subscription} from 'rxjs';
import {catchError, map, mergeMap, take} from 'rxjs/operators';
import WebWorker from '../../Chat/fileWorkerSetup';
import worker from '../../Chat/fileWorker';
import {
    MESSAGES_NUMBER_PER_PAGE,
    REACT_APP_NODE_CHAT,
    REACT_APP_STUN_1_URL,
    REACT_APP_STUN_2_URL,
    REACT_APP_TURN_1_CREDENTIAL,
    REACT_APP_TURN_1_URL,
    REACT_APP_TURN_1_USERNAME,
} from '../../Chat/config';
import uniqBy from 'lodash/uniqBy';
import cloneDeep from 'lodash/cloneDeep';
import merge from 'lodash/merge';
import {sendMessageToOfflineAPI} from '../../../api/messaging/sendMessageToOffline';
import {saveFile} from '../../../api/fileHandling/saveFile';
import {ChatServerMessage, MediaObjectAPIResponse} from '../chat.types';
import NULL_PEER_CONNECTION from './NullConnection';
import jwt_decode from 'jwt-decode';
import {postMessageReadConfirmationAPI} from '../../../api/messaging/postMessageReadConfirmation';
import {getMessagesFromUserAPI} from '../../../api/messaging/getMessagesFromUser';
import {AjaxResponse} from 'rxjs/ajax';

export interface IWithChatConnectionProps {
    authToken: string;
    accountId: string;
    isAuthenticated: boolean;
    channelsToJoin?: ChatContact[];
    username?: string;
    RTCConfiguration?: RTCConfiguration;

    getContacts: () => Observable<any>;
    confirmMessageRead?: (messages: ChatServerMessageConfirmPayload[]) => Observable<any>;
    convertServerMessageIntoChatMessage?: (message: any) => ChatMessage;

    errorHandler?: (errorMessage: string) => any;
    saveFile: (file: any) => Promise<any>;
}

enum SocketMessages {
    LOGGED_IN = 'logged_in',
    JOIN_CHANNELS = 'join_channels',
    CHANNEL_JOINED = 'channel_joined',
    USER_JOINED_CHANNEL = 'user_joined_channel',
    CANDIDATE = 'candidate',
    OFFER = 'offer',
    ANSWER = 'answer',
    CONNECTION = 'connection',
    DISCONNECTING = 'disconnecting',
    USER_HAS_LEFT = 'user_has_left',
    CONNECT = 'connect',
    DISCONNECT = 'disconnect',
    CONNECT_ERROR = 'connect_error',
    ALLOWED_CHANNELS_LIST = 'allowed_channels_list',
    ERROR = 'error',
    NOTIFICATION = 'notification',
}

interface IWithChatConnectionState {
    userPeerConnections: AllUserConnections;
    chunksArrays: {[roomId: string]: string[]};
    selectedChatRoomId: string;
    alert: string[];
    nodeServerConnected: boolean;
    hadConnectionError: boolean;
}

export default <P extends IWithChatConnectionProps>(
    ConnectedComponent: React.ComponentType<P>,
    socketURLWithPort?: string
    //todo
    // config: ChatConfig
): any =>
    class WithChatConnection extends React.Component<IWithChatConnectionProps, IWithChatConnectionState> {
        private socket: Socket | null = null;
        private worker;
        private errors: Subject<Error> = new Subject();
        private subscriptions: Subscription[] = [];
        private reconnectionTimeout: null | ReturnType<typeof setTimeout> = null;
        private contactsTimeout: null | ReturnType<typeof setTimeout> = null;

        private configuration: RTCConfiguration = this.props.RTCConfiguration || {
            iceServers: [
                {urls: [REACT_APP_STUN_1_URL, REACT_APP_STUN_2_URL]},
                {
                    urls: REACT_APP_TURN_1_URL,
                    credential: REACT_APP_TURN_1_CREDENTIAL,
                    username: REACT_APP_TURN_1_USERNAME,
                },
            ],
        };

        constructor(props: IWithChatConnectionProps) {
            super(props);
            this.state = {
                userPeerConnections: {},
                chunksArrays: {},
                selectedChatRoomId: '',
                alert: [],
                nodeServerConnected: false,
                hadConnectionError: false,
            };
        }

        componentDidMount() {
            this.worker = new WebWorker(worker);
            this.worker.addEventListener('message', (e) => {
                const room = e.data.room,
                    message = e.data.message;

                this.setState((state) => {
                    const unseen = this.countUnseen(room, state.userPeerConnections[room].unseenMessages);
                    return {
                        alert: unseen === 0 ? state.alert.filter((id) => id !== room) : [...state.alert, room],
                        userPeerConnections: {
                            ...state.userPeerConnections,
                            [room]: {
                                ...state.userPeerConnections[room],
                                unseenMessages: unseen,
                                messages: [...state.userPeerConnections[room].messages, message],
                            },
                        },
                        chunksArrays: {...state.chunksArrays, [room]: []},
                    };
                });
            });

            this.handleSocketConnection();
        }

        componentDidUpdate(prevProps: IWithChatConnectionProps, prevState: IWithChatConnectionState) {
            if (prevProps.authToken !== this.props.authToken && !this.socket?.connected) {
                //login to chat on token
                this.socket?.connect();
            }

            if (prevProps.channelsToJoin !== this.props.channelsToJoin) {
                this.props.channelsToJoin.forEach((contact) => {
                    this.updatePeerConnection({}, contact.roomId);
                    console.log('GET ME RESULTS! ', contact.unreadMessagesCount);
                    if (contact.unreadMessagesCount > 0) {
                        this.getMessagesFromUser(contact.accountId).subscribe();
                    }
                });
                const channels = this.channelIds;
                this.socket?.emit(SocketMessages.JOIN_CHANNELS, {channels: channels, socketId: this.socket.id});
            }

            if (prevState.selectedChatRoomId !== this.state.selectedChatRoomId && this.state.selectedChatRoomId) {
                //todo UWAGA na razie nie ma accountId w hoc

                const contactId = this.partnerId(this.state.selectedChatRoomId);
                this.getMessagesFromUser(contactId, 1).subscribe();
            }

            if (!prevState.nodeServerConnected && this.state.nodeServerConnected) {
                const channels = this.channelIds;
                // const channelsToSend = channels.map( channel => {
                //     if(prevProps.channelsToJoin?.find( prevChannel => prevChannel.accountId === channel) ) {
                //         return
                //     }
                //     return channel;
                // });
                this.socket?.emit(SocketMessages.JOIN_CHANNELS, {channels: channels, socketId: this.socket.id});
            }

            if (!prevState.nodeServerConnected && this.state.nodeServerConnected && this.state.hadConnectionError) {
                this.setState({hadConnectionError: false});
                this.socket?.emit(SocketMessages.JOIN_CHANNELS, {channels: this.channelIds, socketId: this.socket.id});
                //todo! dc ogarnięcie
                //new messages on reconnect after 3s
                this.contactsTimeout = setTimeout(() => {
                    this.props.getContacts().subscribe((contacts) => {
                        if (contacts && Array.isArray(contacts)) {
                            contacts.map((contact) => this.getMessagesFromUser(contact?.accountId).subscribe());
                        }
                    });
                }, 3000);
            }
        }

        componentWillUnmount() {
            this.reconnectionTimeout && clearTimeout(this.reconnectionTimeout);
            this.contactsTimeout && clearTimeout(this.contactsTimeout);
            this.socket.disconnect();
            Object.keys(this.state.userPeerConnections).forEach((room: string) => this.closeConnection(room));
            this.subscriptions.forEach((subscription) => subscription.unsubscribe());
        }

        render() {
            return (
                <ConnectedComponent
                    {...(this.props as P)}
                    errors={this.errors}
                    confirmMessageRead={this.confirmMessageRead}
                    hasNodeConnection={!!this.socket?.connected}
                    hasUnreadMessages={this.state.alert}
                    addMessage={this.addMessage}
                    allowedChannels={this.props.channelsToJoin}
                    selectedChatRoomId={this.state.selectedChatRoomId}
                    getMessagesFromUser={this.getMessagesFromUser}
                    setSelectedChatRoomId={this.setSelectedChatRoomId}
                    peerConnections={this.state.userPeerConnections}
                />
            );
        }

        private handleSocketConnection() {
            this.socket = io(socketURLWithPort || REACT_APP_NODE_CHAT, {
                reconnectionDelay: 60000,
                auth: {token: this.props.authToken},
            });

            if (this.socket instanceof Socket) {
                this.socket.on(SocketMessages.CONNECT, () => this.setState({nodeServerConnected: true}));
                this.socket.on(SocketMessages.DISCONNECT, () => this.setState({nodeServerConnected: false}));

                this.socket.on(SocketMessages.USER_HAS_LEFT, ({room}) => this.closeConnection(room));

                // Handling token expiration and multi login
                this.socket.on(SocketMessages.CONNECT_ERROR, (error: any) => {
                    if (error.message === 'already_online') {
                        this.reconnectionTimeout = setTimeout(() => {
                            this.socket?.connect();
                        }, 5000);
                    }
                    this.errors.next(error);
                });

                this.socket.io.on('reconnect', () => {
                    this.setState(() => ({hadConnectionError: true}));
                });

                this.socket.on(SocketMessages.CHANNEL_JOINED, ({id, clientsNumber, channelId}) => {
                    if (clientsNumber > 1) {
                        this.setOnlineState(true, channelId);
                    }
                    // this.getMessagesFromUser(id)
                });

                this.socket.on(SocketMessages.ANSWER, async ({toRoom, answer}: SignalAnswer) => {
                    const remoteDesc = await new RTCSessionDescription(answer);
                    const peerConnection = this.state.userPeerConnections[toRoom].connection;
                    await peerConnection.setRemoteDescription(remoteDesc);
                    this.updatePeerConnection({connection: peerConnection}, toRoom);
                });

                this.socket.on(SocketMessages.CANDIDATE, async ({toRoom, candidate}: SignalCandidate) => {
                    try {
                        if (!candidate) {
                            return;
                        }
                        const peerConnection = this.state.userPeerConnections[toRoom].connection;
                        if (peerConnection) {
                            await peerConnection.addIceCandidate(new RTCIceCandidate(candidate));
                            this.updatePeerConnection({connection: peerConnection}, toRoom);
                        }
                    } catch (e) {
                        return;
                    }
                });

                this.socket.on(SocketMessages.OFFER, async ({toRoom, offer}: SignalOffer) => {
                    const connectionRoom = this.state.userPeerConnections?.[toRoom];
                    if (connectionRoom.connection && connectionRoom.makingOffer && !connectionRoom.peerIsPolite) {
                        return;
                    }

                    const peerConnection = new RTCPeerConnection(this.configuration);

                    peerConnection.onicecandidate = ({candidate}: SignalCandidate) =>
                        this.socket?.emit(SocketMessages.CANDIDATE, {toRoom, candidate});

                    peerConnection.ondatachannel = (event) => {
                        const receiveChannel = event.channel;
                        receiveChannel.onopen = () => {
                            this.clearBuffer(toRoom);
                        };
                        receiveChannel.onclose = () => {
                            this.setOnlineState(false, toRoom);
                        };
                        receiveChannel.onmessage = (message: any) => this.handleMessage(message, toRoom);
                        this.updatePeerConnection({connection: peerConnection, channel: receiveChannel}, toRoom);
                    };

                    peerConnection.onconnectionstatechange = () => {
                        if (peerConnection.connectionState === 'failed') {
                            if (this.state.userPeerConnections[toRoom].connectionAttemptNumber < 1) {
                                this.setConnectionAttemptNumber(toRoom);
                                this.sendOffer(toRoom);
                            } else {
                                // this.clearBufferOffline(toRoom);
                                //todo Trzeba zmienic na error message - nowy typ
                                // this.state.userPeerConnections[toRoom].messagesBuffer.forEach(message => {
                                //     if(typeof message !== 'string') this.removePlaceholder(message, toRoom)
                                // })
                            }
                        }
                    };

                    peerConnection.oniceconnectionstatechange = () => {
                        if (peerConnection.iceConnectionState === 'failed') {
                            // @ts-ignore
                            if (peerConnection?.restartIce) {
                                // @ts-ignore
                                peerConnection.restartIce();
                            }
                        }
                    };

                    await peerConnection.setRemoteDescription(new RTCSessionDescription(offer));
                    const answer = await peerConnection.createAnswer();
                    await peerConnection.setLocalDescription(answer);
                    this.updatePeerConnection({connection: peerConnection}, toRoom);
                    this.socket?.emit(SocketMessages.ANSWER, {toRoom, answer});
                });

                // this.socket.on(SocketMessages.ALLOWED_CHANNELS_LIST, (ids: {[key:string]: boolean}) => {
                //     const allowedChannels = this.props.channelsToJoin.map( channel => {
                //         if(ids[channel.accountId]) {
                //             return channel
                //         }
                //     })
                //     console.log('Allowed Channels in state ', allowedChannels)
                //     this.setState({allowedChannels})
                // })

                this.socket.on(SocketMessages.USER_JOINED_CHANNEL, (data: string, room: string) => {
                    this.setOnlineState(true, room);
                    this.setPolitePeer(true, room);
                });
            }
        }

        private handleError(error) {
            console.log('HANDLE ERROR');
            this.errors.next(error);
            return of('');
        }

        private async sendOffer(room: string) {
            if (this.state.userPeerConnections[room].makingOffer) {
                return;
            }

            const peerConnection = await new RTCPeerConnection(this.configuration);
            const createdChannel = await peerConnection.createDataChannel(room);

            peerConnection.onconnectionstatechange = () => {
                if (peerConnection.connectionState === 'failed') {
                    if (this.state.userPeerConnections[room].connectionAttemptNumber < 1) {
                        this.setConnectionAttemptNumber(room);
                        this.sendOffer(room);
                    } else {
                        //todo to samo
                        // this.clearBufferOffline(room);
                        // this.state.userPeerConnections[room].messagesBuffer.forEach(message => {
                        //     if(typeof message !== 'string') this.removePlaceholder(message, room)
                        // })
                    }
                }
            };

            peerConnection.oniceconnectionstatechange = () => {
                if (peerConnection.iceConnectionState === 'failed') {
                    // @ts-ignore
                    if (peerConnection?.restartIce) {
                        // @ts-ignore
                        peerConnection.restartIce();
                    }
                }
            };

            peerConnection.onnegotiationneeded = async () => {
                try {
                    this.setIsMakingOffer(true, room);

                    //Channel Methods:
                    createdChannel.onopen = () => {
                        this.clearBuffer(room);
                    };
                    createdChannel.onclose = () => {
                        this.setOnlineState(false, room);
                    };
                    createdChannel.onmessage = (message: MessageEvent) => this.handleMessage(message, room);

                    const offer = await peerConnection.createOffer();
                    await peerConnection.setLocalDescription(offer);
                    this.updatePeerConnection({connection: peerConnection, channel: createdChannel}, room);
                    this.socket?.emit(SocketMessages.OFFER, {toRoom: room, offer});
                } catch (err: any) {
                    return this.errors.next(err);
                } finally {
                    this.setIsMakingOffer(false, room);
                }
            };

            peerConnection.onicecandidate = ({candidate}: SignalCandidate) =>
                this.socket?.emit(SocketMessages.CANDIDATE, {
                    toRoom: room,
                    candidate,
                });
        }

        private get channelIds() {
            const channels = this.props.channelsToJoin;
            if (channels.length > 0) {
                return channels.map((channel) => channel.accountId);
            }
            return [];
        }

        private setIsMakingOffer(makingOffer: boolean, room: string) {
            this.setState((state) => ({
                userPeerConnections: {
                    ...state.userPeerConnections,
                    [room]: {
                        ...state.userPeerConnections[room],
                        makingOffer: makingOffer,
                    },
                },
            }));
        }

        private setPolitePeer(isPolite: boolean, room: string) {
            this.setState((state) => ({
                userPeerConnections: {
                    ...state.userPeerConnections,
                    [room]: {
                        ...state.userPeerConnections[room],
                        peerIsPolite: isPolite,
                    },
                },
            }));
        }

        private clearBuffer(room: string) {
            const selectedRoom = this.state.userPeerConnections[room];
            const updatedMessages = cloneDeep(selectedRoom.messages);

            selectedRoom.messagesBuffer.forEach((textMessage) => {
                if (typeof textMessage === 'string') {
                    return selectedRoom.channel.send(JSON.stringify(textMessage));
                }

                selectedRoom.messages.forEach((msg, index) => {
                    if (msg.messageId === textMessage[0].messageId) {
                        const message = cloneDeep(textMessage[0]);
                        message.messageId = textMessage[1];
                        console.log('DANE w clear buffer ', msg);
                        return updatedMessages.splice(index, 1, message);
                    }
                    return msg;
                });

                const fileMessage = Object.assign({}, textMessage[0], {messageMedia: '', messageId: textMessage[1]});
                console.log('FILE MESSAGE ', fileMessage);
                return selectedRoom.channel.send(JSON.stringify(fileMessage));
            });

            console.log(' ZMIANY ', updatedMessages);

            this.setState((state) => ({
                userPeerConnections: {
                    ...state.userPeerConnections,
                    [room]: {
                        ...state.userPeerConnections[room],
                        messages: updatedMessages,
                        messagesBuffer: [],
                    },
                },
            }));
        }

        private setOnlineState(isPeerOnline: boolean, room: string) {
            this.setState((state) => ({
                userPeerConnections: {
                    ...state.userPeerConnections,
                    [room]: {
                        ...state.userPeerConnections[room],
                        peerIsOnline: isPeerOnline,
                    },
                },
            }));
        }

        private partnerId(roomId: string) {
            //todo: Remove when got accountId
            const decoded: any = jwt_decode(this.props.authToken);
            console.log('TOKEN ', decoded);
            const accountId = decoded?.account_id;
            return roomId.split('.').find((id) => id !== accountId);
        }

        //todo: wip - zmiany z oko, zastąpi 5 innych metod
        private updatePeerConnection(update: Partial<PeerConnection>, toRoom: string) {
            const updatedConnection = merge({}, NULL_PEER_CONNECTION, {channelId: toRoom}, this.state.userPeerConnections[toRoom], update);

            this.setState((state) => ({
                userPeerConnections: {
                    ...state.userPeerConnections,
                    [toRoom]: updatedConnection,
                },
            }));
        }

        private closeConnection(room: string) {
            const connection: PeerConnection = this.state.userPeerConnections?.[room];
            if (!connection) {
                return;
            }

            connection.channel?.close();
            connection.connection?.close();

            this.setState((state) => ({
                chunksArrays: {
                    ...state.chunksArrays,
                    [room]: [],
                },
                userPeerConnections: {
                    ...state.userPeerConnections,
                    [room]: {
                        biggestPageNumber: 0,
                        makingOffer: false,
                        peerIsOnline: false,
                        peerIsPolite: false,
                        connection: null,
                        channelId: room,
                        unseenMessages: 0,
                        messagesBuffer: [],
                        messages: state.userPeerConnections?.[room]?.messages || [],
                        channel: null,
                        connectionAttemptNumber: 0,
                        totalMessagesNumber: 0,
                    },
                },
            }));
        }

        private setSelectedChatRoomId = (roomId: string) =>
            this.setState((state) => ({
                selectedChatRoomId: roomId,
                alert: state.alert.filter((id) => id !== roomId),
                userPeerConnections: {
                    ...state.userPeerConnections,
                    [roomId]: {
                        ...state.userPeerConnections[roomId],
                        unseenMessages: 0,
                    },
                },
            }));

        private addMyMessage(message: ChatMessage, room: string) {
            this.setState((state) => ({
                userPeerConnections: {
                    ...state.userPeerConnections,
                    [room]: {
                        ...state.userPeerConnections[room],
                        messages: state?.userPeerConnections?.[room]?.messages
                            ? [...state.userPeerConnections[room].messages, message]
                            : [message],
                    },
                },
            }));
        }

        private addMessage = (message: ChatMessage | string, room: string) => {
            const connectionRoom = this.state.userPeerConnections[room];

            //Done 1: add Placeholder
            console.log('room to add message ', room);
            if (typeof message !== 'string') {
                const placeholderMessage = {
                    ...message,
                    messageType: MessageContentType.PLACEHOLDER,
                };
                this.addMyMessage(placeholderMessage, room);
            }

            //Done 2: save message
            this.subscriptions.push(
                this.saveIncomingFileOrDataToServer(message, room).subscribe((messageServerId: string) => {
                    if (!messageServerId && typeof message !== 'string') {
                        console.log('OBRAZKOWE PROBLEMY');
                        return this.removePlaceholder(message, room, '', true);
                    }

                    if (typeof message !== 'string' && (message as ChatMessage).messageType === MessageContentType.FILE) {
                        //create URL
                        message.messageMedia = URL.createObjectURL(message.messageMedia);
                    }
                    //offline
                    if ((!connectionRoom || !connectionRoom.peerIsOnline) && typeof message !== 'string' && 'from' in message) {
                        console.log('offline ', message, room);
                        //todo remove placeholder ?

                        return this.removePlaceholder(message, room, messageServerId);
                        // return this.addMyMessage(message, room);
                    }
                    //online & connected
                    if (connectionRoom && connectionRoom.peerIsOnline && connectionRoom?.channel?.readyState === 'open') {
                        console.log('online & connected');
                        if (typeof message === 'string') {
                            connectionRoom.channel?.send(JSON.stringify(message));
                            return;
                        } else {
                            const messageToSendWebrtc = cloneDeep(message);
                            messageToSendWebrtc.messageId = messageServerId;
                            if (messageToSendWebrtc.messageType === MessageContentType.FILE) {
                                const fileMessage = {...messageToSendWebrtc};
                                delete fileMessage.messageMedia;
                                connectionRoom.channel?.send(JSON.stringify(fileMessage));
                            } else {
                                connectionRoom.channel?.send(JSON.stringify(messageToSendWebrtc));
                            }

                            return this.removePlaceholder(message, room, messageServerId);
                            // return this.addMyMessage(message, room)
                        }
                    }
                    if (
                        connectionRoom &&
                        connectionRoom.peerIsOnline &&
                        !connectionRoom.makingOffer &&
                        typeof message !== 'string' &&
                        'from' in message
                    ) {
                        if (!connectionRoom.connection || connectionRoom.connection.connectionState === 'closed') {
                            console.log('online and not connected');
                            this.sendOffer(room);
                        }
                    }

                    //add message to buffer
                    this.setState((state) => ({
                        userPeerConnections: {
                            ...state.userPeerConnections,
                            [room]: {
                                ...state.userPeerConnections[room],
                                messagesBuffer: state?.userPeerConnections?.[room]?.messagesBuffer
                                    ? [
                                          ...state.userPeerConnections[room].messagesBuffer,
                                          typeof message === 'string' ? message : [message, messageServerId],
                                      ]
                                    : [typeof message === 'string' ? message : [message, messageServerId]],
                            },
                        },
                    }));
                })
            );
        };

        private removePlaceholder(message: ChatMessage, room: string, serverId: string, error = false) {
            const updatedMessages = cloneDeep(this.state.userPeerConnections[room].messages);

            this.state.userPeerConnections[room].messages.map((msg, index) => {
                if (msg.messageId === message.messageId) {
                    if (error) {
                        const errorMessage = cloneDeep(message);
                        errorMessage.messageType = MessageContentType.ERROR;
                        return updatedMessages.splice(index, 1, errorMessage);
                    }
                    const newMessage = cloneDeep(message);
                    newMessage.messageId = serverId;
                    return updatedMessages.splice(index, 1, newMessage);
                }
                return msg;
            });

            this.setState((state) => ({
                userPeerConnections: {
                    ...state.userPeerConnections,
                    [room]: {
                        ...state.userPeerConnections[room],
                        messages: updatedMessages,
                        totalMessagesNumber: error
                            ? state.userPeerConnections[room].totalMessagesNumber
                            : state.userPeerConnections[room].totalMessagesNumber + 1,
                    },
                },
            }));
        }

        private saveIncomingFileOrDataToServer(message: ChatMessage | string, room: string): Observable<string> {
            if (typeof message === 'string') {
                return of(null);
            }
            if (message.messageType === MessageContentType.FILE) {
                console.log('file message save ', message);
                return this.saveFileToServer(message.messageMedia, message.messageContent, message.to);
            }
            console.log('saveMessageToServer');
            return this.saveMessageToServer(message, room);
        }

        private saveFileToServer(fullFile: File, fileName: string, contactId: string): Observable<any> {
            const serverMessage: ChatServerMessagePayload = {
                toAccountId: contactId,
                content: fileName,
                mediaObjectId: null,
            };

            const formData = new FormData();
            formData.append('file', fullFile, fileName);

            return from(saveFile(this.props.authToken, formData)).pipe(
                // take(1),
                // mergeMap((resp: OperatorFunction<AjaxResponse<ApiSingleResponseBase<MediaFile>>>) => {
                //     return resp.json()
                // }),
                // mergeMap((fileUploadObj: MediaObjectAPIResponse) => {
                //         const fileId = fileUploadObj.id
                //         serverMessage.mediaObjectId = fileId;

                //         return sendMessageToOfflineAPI(this.props.authToken, serverMessage)
                // }),
                // take(1),
                // map((message:ChatServerMessage) => {
                //     return message.id
                // }),
                // TODO PT - KONIECZNIE NAPRAWIC!!!! - do omowienia z Maciejem ?
                catchError((er) => this.props.errorHandler(er))
            );
        }

        private saveMessageToServer(message: ChatMessage, room: string): Observable<string> {
            const serverMessage: ChatServerMessagePayload = {
                toAccountId: message.to,
                content: message.messageContent,
                mediaObjectId: null,
            };

            return sendMessageToOfflineAPI(this.props.authToken, serverMessage).pipe(
                take(1),
                map((message: ChatServerMessage) => message.id),
                catchError((error) => this.handleError(error))
            );
        }

        private setConnectionAttemptNumber(room: string) {
            this.setState((state) => ({
                userPeerConnections: {
                    ...state.userPeerConnections,
                    [room]: {
                        ...state.userPeerConnections[room],
                        connectionAttemptNumber: state.userPeerConnections[room].connectionAttemptNumber + 1,
                    },
                },
            }));
        }

        private createChannel(accountId, contactAccountId) {
            return [accountId, contactAccountId].sort().join('.');
        }

        private getMessagesFromUser = (accountId: string, page?: number) => {
            if (!this.props.channelsToJoin || this.props.channelsToJoin?.length === 0 || accountId === this.props.accountId) {
                return of(true);
            }

            const contact = this.props.channelsToJoin.find((channel: ChatContact) => channel.accountId === accountId);

            let allChatMessagesToAdd = [];
            let allUnseenMessagesCount = 0;
            let pageNumber = page || 1;

            if (contact) {
                return getMessagesFromUserAPI(this.props.authToken, accountId, page || 1).pipe(
                    expand((mappedResponse) => {
                        const chatMessages = mappedResponse.messages[0];
                        const unseenMessages = mappedResponse.messages[1];
                        const totalMessagesNumber = mappedResponse.totalResults;
                        const apiMessagesNumber = unseenMessages.length;
                        const peerConnection = this.state.userPeerConnections[contact.roomId];
                        const oldMessages = peerConnection.messages;
                        allUnseenMessagesCount = allUnseenMessagesCount + unseenMessages.length;
                        allChatMessagesToAdd = [...chatMessages, ...allChatMessagesToAdd];

                        // if first message is already loaded get next page
                        const firstMessageFromNewPageIsAlreadyInChat = !!oldMessages.find(
                            (mes) => mes.messageId === chatMessages?.[0]?.messageId
                        );
                        const newMessagesLengthIsEqualToUnseenMessagesLength = chatMessages.length === unseenMessages.length;

                        const needMoreResults =
                            (firstMessageFromNewPageIsAlreadyInChat || newMessagesLengthIsEqualToUnseenMessagesLength) &&
                            totalMessagesNumber > 0 &&
                            Math.ceil(totalMessagesNumber / MESSAGES_NUMBER_PER_PAGE) > pageNumber &&
                            !page;

                        // console.log('Unseen: ',unseenMessages,
                        //     ' Chat: ',chatMessages,
                        //     ' oldMessages ', oldMessages,
                        //     ' Math: ', Math.ceil(totalMessagesNumber/MESSAGES_NUMBER_PER_PAGE),
                        //     ' NUMBER ', pageNumber);
                        // console.log('Need more results ? ', needMoreResults, ' firstMessageFromNewPageIsAlreadyInChat ? ',
                        //     firstMessageFromNewPageIsAlreadyInChat, ' newMessagesLengthIsEqualToUnseenMessagesLength? ',
                        //     newMessagesLengthIsEqualToUnseenMessagesLength, 'totalMessagesNumber is greater than 0 ', totalMessagesNumber)
                        if (needMoreResults) {
                            console.log('I needMoreResults ! Get next page! ');
                            pageNumber++;
                            return getMessagesFromUserAPI(this.props.authToken, accountId, pageNumber);
                        }

                        const roomId = contact.roomId;
                        const messagesInState = this.state.userPeerConnections[roomId].messages;
                        const messagesToAddToState = uniqBy([].concat(allChatMessagesToAdd, messagesInState), 'messageId');
                        // console.log('DONE ALL MESSAGE GETTING ON page ', pageNumber,
                        //     ' All results To Add ', allChatMessagesToAdd, ' after uniq test ', messagesToAddToState)
                        this.setState((state) => ({
                            // alert: apiMessagesNumber > 0 ? [].concat(state.alert, roomId) : [].concat(state.alert),
                            userPeerConnections: {
                                ...state.userPeerConnections,
                                [roomId]: {
                                    ...state.userPeerConnections[roomId],
                                    unseenMessages: this.state.selectedChatRoomId === roomId ? 0 : allUnseenMessagesCount,
                                    // messages: [].concat(state?.userPeerConnections?.[roomId]?.messages || [], messagesObject[roomId])
                                    messages: messagesToAddToState,
                                    biggestPageNumber:
                                        state.userPeerConnections[roomId]?.biggestPageNumber > pageNumber
                                            ? state.userPeerConnections[roomId]?.biggestPageNumber
                                            : pageNumber,
                                    totalMessagesNumber:
                                        totalMessagesNumber > state.userPeerConnections[roomId].totalMessagesNumber
                                            ? totalMessagesNumber
                                            : state.userPeerConnections[roomId].totalMessagesNumber,
                                },
                            },
                        }));
                        return of(true);
                    }),
                    take(1),
                    catchError((error) => this.handleError(error))
                );
            }
            return of(true);
        };

        private confirmMessageRead = (messagesId: string[]) => {
            postMessageReadConfirmationAPI(this.props.authToken, messagesId)
                .pipe(
                    take(1),
                    catchError((error) => this.handleError(error))
                )
                .subscribe();
        };

        private countUnseen(roomId: string, unseenMessages: number): number {
            if (this.state.selectedChatRoomId === roomId) {
                return 0;
            }
            return unseenMessages + 1;
        }

        private handleMessage(message: MessageEvent, room: string) {
            const messageContent = typeof message.data === 'string' ? JSON.parse(message.data) : message.data;

            if (!messageContent.messageType) {
                this.setState((state) => ({
                    chunksArrays: {
                        ...state.chunksArrays,
                        [room]: state.chunksArrays[room] ? [...state.chunksArrays?.[room], messageContent] : [messageContent],
                    },
                }));
            } else if (messageContent?.messageType === MessageContentType.FILE) {
                this.worker.postMessage({room, messageMedia: this.state.chunksArrays[room], message: messageContent});
            } else {
                this.setState((state) => {
                    const unseen = this.countUnseen(room, state.userPeerConnections[room].unseenMessages);
                    return {
                        alert: unseen === 0 ? state.alert.filter((id) => id !== room) : [...state.alert, room],
                        userPeerConnections: {
                            ...state.userPeerConnections,
                            [room]: {
                                ...state.userPeerConnections[room],
                                unseenMessages: unseen,
                                messages: [...state.userPeerConnections[room].messages, messageContent],
                                totalMessagesNumber: state.userPeerConnections[room].totalMessagesNumber + 1,
                            },
                        },
                    };
                });
            }
        }
    };
