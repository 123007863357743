import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {IModelDictionaryDatum} from '../../model/dictionaryDatum';

export interface IBaseDictionaryState {
    isLoading: boolean;
    isInitialized: boolean;
    error: string | null;
}

export interface IChangeIsLoading {
    isLoading: boolean;
}

export interface IChangeIsInitialized {
    isInitialized: boolean;
}

export interface ISetError {
    error: string | null;
}

export interface IVesselTypeState extends IBaseDictionaryState {
    vesselTypes: IModelDictionaryDatum[] | null;
}

export interface IChangeVesselType {
    vesselTypes: IModelDictionaryDatum[] | null;
}

const initialState: IVesselTypeState = {
    vesselTypes: null,
    isLoading: false,
    isInitialized: false,
    error: null,
};

const vesselTypeSlice = createSlice({
    name: 'vesselType',
    initialState: initialState,
    reducers: {
        changeVesselType: {
            reducer: (state: IVesselTypeState, action: PayloadAction<IChangeVesselType>) => {
                return {
                    vesselTypes: action.payload.vesselTypes,
                    isLoading: state.isLoading,
                    isInitialized: state.isInitialized,
                    error: state.error,
                };
            },
            prepare(vesselTypes: IModelDictionaryDatum[] | null) {
                return {
                    payload: {vesselTypes: vesselTypes},
                };
            },
        },
        changeIsVesselTypeLoading: {
            reducer: (state: IVesselTypeState, action: PayloadAction<IChangeIsLoading>) => {
                return {
                    vesselTypes: state.vesselTypes,
                    isLoading: action.payload.isLoading,
                    isInitialized: state.isInitialized,
                    error: state.error,
                };
            },
            prepare(isLoading: boolean) {
                return {
                    payload: {isLoading: isLoading},
                };
            },
        },
        changeIsVesselTypeInitialized: {
            reducer: (state: IVesselTypeState, action: PayloadAction<IChangeIsInitialized>) => {
                return {
                    vesselTypes: state.vesselTypes,
                    isLoading: state.isLoading,
                    isInitialized: action.payload.isInitialized,
                    error: state.error,
                };
            },
            prepare(isInitialized: boolean) {
                return {
                    payload: {isInitialized: isInitialized},
                };
            },
        },
        setVesselTypeError: {
            reducer: (state: IVesselTypeState, action: PayloadAction<ISetError>) => {
                return {
                    vesselTypes: state.vesselTypes,
                    isLoading: state.isLoading,
                    isInitialized: state.isInitialized,
                    error: action.payload.error,
                };
            },
            prepare(error: string | null) {
                return {
                    payload: {error: error},
                };
            },
        },
        fetchVesselTypes: (state: IVesselTypeState) => {
            return {
                ...state,
                isLoading: true,
            };
        },
        fetchAllDictionaryData: (state: IVesselTypeState) => {
            return {
                ...state,
            };
        },
    },
});

export const {
    changeVesselType,
    changeIsVesselTypeLoading,
    changeIsVesselTypeInitialized,
    setVesselTypeError,
    fetchVesselTypes,
    fetchAllDictionaryData,
} = vesselTypeSlice.actions;

export default vesselTypeSlice.reducer;
