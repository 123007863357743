import React from 'react';
import {connect} from 'react-redux';
import {addAlert} from '../../../store/reducers/alertSlice';
import {AlertType} from '../../../types';
import Loader from '../../Loader';
import Translation from '../../Translation';
import {RouterProps} from '../../RouterWrapperComponent';
import {withRouter} from '../../../index';
import {confirmRegistration} from '../../../store/reducers/authSlice';

interface IConnectedConfirmRegistrationProps {
    readonly addAlert: typeof addAlert;
    readonly confirmRegistration: typeof confirmRegistration;
}

interface IExternalConfirmRegistrationProps {
    readonly isLoading: boolean;
}

interface IConfirmRegistrationProps extends IConnectedConfirmRegistrationProps, IExternalConfirmRegistrationProps, RouterProps {}

interface IConfirmRegistrationState {}

class ConfirmRegistration extends React.Component<IConfirmRegistrationProps, IConfirmRegistrationState> {
    render() {
        return (
            <article className="auth-article">
                <Loader showLoader={this.props.isLoading} />
                <header className="auth-form-header confirm-form-header">
                    <h1 className="auth-title confirm-title">
                        <Translation text="auth.confirmRegistration.title" />
                    </h1>
                </header>
                <div className="registration-confirmation-container">
                    <p className="registration-confirmation-info">
                        <Translation text="auth.confirmRegistration.subtitle" />
                    </p>
                    <button className="btn btn-primary btn-confirm confirm-registration" onClick={() => this.confirmRegistration()}>
                        <Translation text="buttons.confirm" />
                    </button>
                </div>
            </article>
        );
    }

    private confirmRegistration = (): void => {
        const params = this.props.params;

        if (!(params as any).id) {
            this.props.addAlert({message: 'auth.alert.invalidConfirmRegistrationURL', type: AlertType.WARNING});
            return;
        }

        this.props.confirmRegistration((params as any).id);
    };
}

export default connect(() => ({}), {
    addAlert,
    confirmRegistration,
})(withRouter(ConfirmRegistration));
