import React from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import {IFormConfig, UserRole, WithRouterWrapperProps} from '../../../types';
import Form from '../../Form';
import Loader from '../../Loader';
import Translation from '../../Translation';
import {LanguageLocaleType} from '../../../constants/locales';
import {registerUser} from '../../../store/reducers/authSlice';
import {withRouterWrapper} from '../../hoc/withRouterWrapper';

export enum UserRegistrationRoles {
    OPERATOR = 'operator',
    SKIPPER = 'skipper',
}

interface IConnectedRegisterProps {
    readonly registerUser: typeof registerUser;
}

interface IExternalRegisterProps {
    readonly userRole: UserRole;
    readonly envData: any;
    readonly isLoading: boolean;
    readonly formConfig: IFormConfig;
}

interface IRegisterProps extends IConnectedRegisterProps, IExternalRegisterProps, WithRouterWrapperProps {}

interface IRegisterState {
    formConfig: IFormConfig;
    value: any;
}

class Registration extends React.Component<IRegisterProps, IRegisterState> {
    constructor(props: IRegisterProps) {
        super(props);

        this.state = {
            formConfig: this.props.formConfig,
            value: null,
        };
    }

    componentDidMount() {
        const email = this.props.router.location.search.split('?email=').pop();
        if (email) {
            this.setState({value: {email: email}});
        }
    }

    render() {
        const isUserBoastman = this.props.userRole === UserRole.BOATSMAN,
            loginUrl = isUserBoastman ? '/boatsman/auth/login' : '/auth/login';

        return (
            <article className="auth-article">
                <header className="auth-form-header">
                    <h1 className="auth-title">
                        <Translation text="auth.register.title" />
                    </h1>
                </header>
                <Loader showLoader={this.props.isLoading} />
                <Form config={this.state.formConfig} submitForm={this.register} controlName={'registrationForm'} value={this.state.value} />
                <div className="auth-subtitle-container">
                    <p className="auth-subtitle redirect-link-container">
                        <Link className="auth-redirect-link" to={loginUrl}>
                            <Translation text="auth.register.logIn" />
                        </Link>
                    </p>
                </div>
            </article>
        );
    }

    private register = (event: any, formData: any, valid: boolean, touched: boolean): void => {
        event.preventDefault();
        if (!valid || !touched) {
            return;
        }
        const returnUrl = `${this.props.envData.REACT_APP_URL}/auth/confirm-registration`;

        const role = this.props.userRole === UserRole.SKIPPER ? UserRegistrationRoles.SKIPPER : UserRegistrationRoles.OPERATOR;

        let userPayload = {
            username: formData.email,
            password: formData.plainPassword,
            locale: LanguageLocaleType.EN,
            type: role,
            returnUrl: returnUrl,
        };

        this.props.registerUser(userPayload);
    };
}

export default connect(() => ({}), {registerUser})(withRouterWrapper(Registration));
