import {RestQueryParams, marineHubAPI} from 'marine-panel-common-web';
import {Observable} from 'rxjs';
import {IChangeMarinaPhone} from '../../store/reducers/marinaEditWizardSlice';

export interface IChangeMarinaPhoneInput {
    phone: string;
}

export function changeMarinaPhoneAPI(payload: IChangeMarinaPhone, authToken: string | null): Observable<any> {
    const headers = {
        Authorization: `Bearer ${authToken}`,
    };

    return marineHubAPI.put(`api/marinas/${payload.marinaId}/change_phone`, payload.phone, new RestQueryParams(), headers);
}
