import {LogOut, Settings} from 'react-feather';
import React from 'react';
import {ISideMenuGroup, ISideMenuHeader, ISideMenuItem} from 'marine-panel-common-web';

const subMenu = (onLogout?: () => void): (ISideMenuGroup | ISideMenuItem | ISideMenuHeader)[] => [
    {
        id: 'settings',
        title: 'menuItems.settings',
        icon: <Settings size={20} />,
        navLink: '/panel/settings',
        navLinkClassname: 'settings-link',
    },
    {
        id: 'logout',
        title: 'menuItems.logout',
        icon: <LogOut size={20} />,
        navLinkClassname: 'logout-button',
        onClick: () => {
            if (onLogout) {
                return onLogout();
            }
        },
    },
];

export default subMenu;
