import {createSelector} from '@reduxjs/toolkit';
import {IBoatCertificatesState} from '../reducers/boatCertificatesSlice';
import {RootState} from '../reducers';

export const selectBoatCertificatesSlice = (state: RootState): IBoatCertificatesState => {
    return state.boatCertificates;
};

export const boatCertificatesSelector = createSelector(
    [selectBoatCertificatesSlice],
    (state: IBoatCertificatesState) => state.boatCertificates
);

export const boatCertificatesPaginationSelector = createSelector(
    [selectBoatCertificatesSlice],
    (state: IBoatCertificatesState) => state.pagination
);

export const boatCertificatesMetadataSelector = createSelector(
    [selectBoatCertificatesSlice],
    (state: IBoatCertificatesState) => state.boatCertificatesMetadata
);

export const isBoatCertificatesLoadingSelector = createSelector(
    [selectBoatCertificatesSlice],
    (state: IBoatCertificatesState) => state.isLoading
);

export const isBoatCertificatesInitializedSelector = createSelector(
    [selectBoatCertificatesSlice],
    (state: IBoatCertificatesState) => state.isInitialized
);

export const boatCertificatesErrorSelector = createSelector([selectBoatCertificatesSlice], (state: IBoatCertificatesState) => state.error);
