import {RestQueryParams, marineHubAPI} from 'marine-panel-common-web';
import {Observable} from 'rxjs';
import {IChangeMarinaDescription} from '../../store/reducers/marinaEditWizardSlice';

export interface IChangeMarinaDescriptionInput {
    description: string;
}

export function changeMarinaDescriptionAPI(payload: IChangeMarinaDescription, authToken: string | null): Observable<any> {
    const headers = {
        Authorization: `Bearer ${authToken}`,
    };

    return marineHubAPI.put(`api/marinas/${payload.marinaId}/change_description`, payload.description, new RestQueryParams(), headers);
}
