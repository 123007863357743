import React from 'react';
import {IProgressRingProps} from '../../types';

type Props = IProgressRingProps;

class ProgressRing extends React.Component<Props> {
    normalizedRadius: number;
    circumference: number;

    constructor(props: Props) {
        super(props);

        this.normalizedRadius = this.props.radius - 2;
        this.circumference = this.normalizedRadius * 2 * Math.PI;
    }

    render() {
        const progress = this.props.progress ? this.props.progress : 0;
        const strokeDashoffset = this.circumference - (progress / 100) * this.circumference;

        return (
            <svg height={this.props.radius * 2} width={this.props.radius * 2}>
                <circle
                    stroke="#44A744"
                    fill="transparent"
                    strokeWidth={this.props.stroke}
                    strokeDasharray={this.circumference + ' ' + this.circumference}
                    style={{strokeDashoffset}}
                    r={this.normalizedRadius}
                    cx={this.props.radius}
                    cy={this.props.radius}
                />
            </svg>
        );
    }
}

export default ProgressRing;
