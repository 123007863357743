import {RestQueryParams, marineHubAPI} from 'marine-panel-common-web';
import {Observable} from 'rxjs';
import {IChangeMarinaName} from '../../store/reducers/marinaEditWizardSlice';

export interface IChangeMarinaNameInput {
    name: string | null;
}

export function changeMarinaNameAPI(payload: IChangeMarinaName, authToken: string | null): Observable<any> {
    const headers = {
        Authorization: `Bearer ${authToken}`,
    };
    return marineHubAPI.put(`api/marinas/${payload.marinaId}/change_name`, payload.name, new RestQueryParams(), headers);
}
