import React from 'react';
import Translation from '../Translation';

class Footer extends React.Component {
    render() {
        return (
            <footer className="footer">
                <h2 className="sr-only">
                    <Translation text="auth.authFooter.srTitle" />
                </h2>
                <div className="footer-container">
                    <div className="footer-details">
                        <h3 className="column-title">
                            <Translation text="auth.authFooter.addressColumn.title" />
                        </h3>
                    </div>
                    <div className="parp-information-wrapper" />
                    <div className="social-icons-wrapper">
                        <a href="/" className="social-link">
                            <span className="icon facebook" />
                            <span className="sr-only">
                                <Translation text="auth.authFooter.socialIcons.facebook" />
                            </span>
                        </a>
                        <a href="/" className="social-link">
                            <span className="icon linkedin" />
                            <span className="sr-only">
                                <Translation text="auth.authFooter.socialIcons.linkedin" />
                            </span>
                        </a>
                        <a href="/" className="social-link">
                            <span className="icon instagram" />
                            <span className="sr-only">
                                <Translation text="auth.authFooter.socialIcons.instagram" />
                            </span>
                        </a>
                    </div>
                </div>
            </footer>
        );
    }
}

export default Footer;
