import React, {Component} from 'react';
import UserDropdown from "../UserDropdown";
import {INavbarUserProps} from "../../../types";


type Props = INavbarUserProps;

class NavbarUser extends Component<Props> {

    render() {
        return (
            <ul className='nav navbar-nav align-items-center justify-content-center'>
                <UserDropdown account={this.props.account}
                              userMenu={this.props.userMenu}
                              unauthorizedUserMenu={this.props.unauthorizedUserMenu}
                              isAuthenticated={this.props.isAuthenticated}/>
            </ul>
        );
    }
}

export default NavbarUser;
