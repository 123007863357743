import {Observable} from 'rxjs';
import {RestQueryParams} from '../base/queryParams';
import {noTokenCheckMarineHubAPI} from '../provider/noTokenCheckMarineHubAPI';
import {LoginApiResponse} from '../../types';

export function loginAPI(
    username: string,
    password: string
): Observable<LoginApiResponse> {
    return noTokenCheckMarineHubAPI.post(
        'api/auth/token',
        {
            username: username,
            password: password
        },
        new RestQueryParams()
    );
}
