export const formatHoursAndMinutes = (hours: number, minutes: number): string => {
    return `${formatTimeDigits(hours)}:${formatTimeDigits(minutes)}`;
};

export const formatTimeDigits = (digits: number): string => {
    if (0 === digits) {
        return '00';
    }
    if (10 > digits) {
        return `0${digits}`;
    }

    return digits.toFixed(0);
};
