import {RestQueryParams, marineHubAPI} from 'marine-panel-common-web';
import {Observable} from 'rxjs';
import {ISetGalleryOrderPayload} from '../../store/reducers/marinaEditWizardSlice';

export function changeGalleryOrderAPI(payload: ISetGalleryOrderPayload, authToken: string | null): Observable<any> {
    const headers = {
        Authorization: `Bearer ${authToken}`,
    };

    return marineHubAPI.put(
        `api/marinas/${payload.marinaId}/change_photo_positions`,
        {photos: payload.photos},
        new RestQueryParams(),
        headers
    );
}
