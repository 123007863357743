import {Observable} from 'rxjs';
import {AccountBasicInfo, ApiSingleResponseBase} from '../../types';
import {marineHubAPI} from '../provider/marineHubAPI';
import {RestQueryParams} from '../base/queryParams';

export function getAccountBasicInfoAPI(authToken: string): Observable<ApiSingleResponseBase<AccountBasicInfo>> {
    return marineHubAPI.get('api/me', new RestQueryParams(), {
        Authorization: `Bearer ${authToken}`,
    });
}
