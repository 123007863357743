import React, {Component} from 'react';
import parse from 'html-react-parser';
import {IItemDescriptionProps, IItemDescriptionState} from '../../types';
import Translation from '../Translation';

type Props = IItemDescriptionProps;
type State = IItemDescriptionState;

class ItemDescription extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            isDescriptionShortened: true,
        };
    }

    render() {
        return <>{this.renderDescription()}</>;
    }

    private renderDescription = () => {
        if (!this.props.description) {
            return null;
        }

        if (this.props.description.length < 50) {
            return <span>{parse(this.props.description)}</span>;
        }

        const shortenedDescription = this.props.description.substr(0, 50) + '...';
        if (this.state.isDescriptionShortened) {
            return (
                <div>
                    <span>{parse(shortenedDescription)}</span>
                    <button
                        className="toggle-details-button"
                        type="button"
                        onClick={() => this.setState({isDescriptionShortened: !this.state.isDescriptionShortened})}>
                        <Translation text={`mentors.mentorItem.readMore`} />
                    </button>
                </div>
            );
        }
        return (
            <div>
                <span>{parse(this.props.description)}</span>
                <button
                    className="toggle-details-button"
                    type="button"
                    onClick={() => this.setState({isDescriptionShortened: !this.state.isDescriptionShortened})}>
                    <Translation text={`mentors.mentorItem.readLess`} />
                </button>
            </div>
        );
    };
}

export default ItemDescription;
