import React from 'react';
import {IFormButtonProps, IFormButtonState, TooltipPlacement} from '../../../types';
import {fixInjectedProperties, lazyInject} from '../../../ioc';
import FormStateEmitter from '../../../service/formStateEmitter';
import {DisableStrategyFactory} from './disableStrategyFactory';
import {defaultOnNullOrUndefined} from '../../../utils/runtimeUtils';
import Tooltip from '../../Tooltip';

type Props = IFormButtonProps;
type State = IFormButtonState;

export enum FormButtonType {
    BUTTON = 'button',
    SUBMIT = 'submit',
    RESET = 'reset',
}

export enum FormButtonDisabledStrategy {
    UNTOUCHED_OR_INVALID = 'untouched_or_invalid',
}

class FormButton extends React.Component<Props, State> {
    private get buttonType(): FormButtonType {
        return this.props.buttonType || FormButtonType.BUTTON;
    }

    @lazyInject('FormStateEmitter')
    private stateEmitter: FormStateEmitter;

    private unregisterAsListener?: () => void;

    constructor(props: Props) {
        super(props);

        this.state = {
            mappedOutputValue: null,
            isFormValid: false,
            isFormTouched: false,
            disabled: null,
        };

        fixInjectedProperties(this);
    }

    componentDidMount(): void {
        this.unregisterAsListener = this.stateEmitter.register(
            this.props.formId,
            (mappedOutputValue: any, isFormValid: boolean, isFormTouched: boolean) => {
                let disabled: boolean;
                const handlerType = typeof this.props.disabled;
                switch (handlerType) {
                    case 'boolean':
                        disabled = this.props.disabled as boolean;
                        break;

                    case 'function':
                        disabled = (this.props.disabled as any)(mappedOutputValue, isFormValid, isFormTouched);
                        break;

                    case 'string':
                    default:
                        disabled = new DisableStrategyFactory()
                            .create(this.props.disabled as FormButtonDisabledStrategy)
                            .isDisabled(mappedOutputValue, isFormValid, isFormTouched);
                        break;
                }

                this.setState({
                    mappedOutputValue,
                    isFormValid,
                    isFormTouched,
                    disabled,
                });
            }
        );
    }

    componentWillUnmount(): void {
        if (this.unregisterAsListener) {
            this.unregisterAsListener();
        }
    }

    render() {
        return (
            <div
                className={`${defaultOnNullOrUndefined(this.props.defaultContainerStyles, 'button-container mt-4')} ${
                    this.props.containerStyles
                }`}>
                <button
                    name={this.props.name}
                    id={this.props.name}
                    onClick={(e) => this.onButtonClicked(e)}
                    className={`${defaultOnNullOrUndefined(this.props.defaultInputStyles, 'btn btn-theme')} ${this.props.inputStyles} ${
                        this.state.disabled ? this.props.disabledStyles : this.props.enabledStyles
                    }`}
                    type={this.buttonType}
                    disabled={this.state.disabled}>
                    {this.props.preIconStyles && <i className={this.props.preIconStyles} />}
                    {this.props.btnHasTooltip && this.props.btnTooltipText ? (
                        <Tooltip tooltipText={this.props.btnTooltipText} target={this.props.name} placement={TooltipPlacement.TOP}>
                            <span className={this.props.innerStyles}>{this.props.btnText}</span>
                        </Tooltip>
                    ) : (
                        <span className={this.props.innerStyles}>{this.props.btnText}</span>
                    )}
                    {this.props.postIconStyles && <i className={this.props.postIconStyles} />}
                </button>
            </div>
        );
    }

    private onButtonClicked(e: any): boolean {
        if (e && e.preventDefault) {
            e.preventDefault();
        }
        if (this.props.onButtonClicked) {
            this.props.onButtonClicked(this.props.name, this.buttonType, e);
        }
        if (this.props.customClickHandler) {
            this.props.customClickHandler(this.props.name, this.buttonType, e);
        }

        return false;
    }
}

export default FormButton;
