import {RestQueryParams, marineHubAPI} from 'marine-panel-common-web';
import {Observable} from 'rxjs';
import {IChangeMarinaAttractions} from '../../store/reducers/marinaEditWizardSlice';

export interface IChangeMarinaAttractionsInput {
    attractions: string;
}

export function changeMarinaAttractionsAPI(payload: IChangeMarinaAttractions, authToken: string | null): Observable<any> {
    const headers = {
        Authorization: `Bearer ${authToken}`,
    };

    return marineHubAPI.put(`api/marinas/${payload.marinaId}/change_attractions`, payload.attractions, new RestQueryParams(), headers);
}
