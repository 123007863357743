import React from 'react';
import styles from './styles.module.scss';
import Avatar from "../../../../Avatar";
import classnames from "classnames";
import {Award, Coffee} from "react-feather";
import {AvatarColor, ChatContact, ChatMessage, ChatType, MessageContentType} from "../../../../../types";
import ChatListItemContent from "./ChatListItemContent";

interface IChatListItemProps {
    readonly message: ChatMessage;
    readonly authorType: ChatType;
    readonly prevMessageAuthorType: ChatType | null;
    readonly errorModuleHandle: (errorMessage: string) => void;
    readonly chatContact: ChatContact | undefined;
}

class ChatListItem extends React.Component<IChatListItemProps> {

    private menuElt: any;

    constructor(props: IChatListItemProps) {
        super(props);
        this.menuElt = React.createRef();
    }

    render() {
        const message = this.props.message,
            sameAuthorOfPrevMessage = this.props.prevMessageAuthorType === this.props.authorType;

        return (
            <React.Fragment>
                <div
                    className={classnames('chat', {
                        'chat-left': this.props.authorType === ChatType.RESPONSE
                    })}
                >
                    {!sameAuthorOfPrevMessage && (
                        <div className='chat-avatar'>
                            <Avatar
                                imgWidth={36}
                                imgHeight={36}
                                tag={'div'}
                                color={AvatarColor.PRIMARY}
                                className='box-shadow-1 cursor-pointer'
                                //todo avatar
                                img={this.props.authorType === ChatType.RESPONSE ? (this.props.chatContact.avatarUrl ? this.props.chatContact.avatarUrl : undefined) : undefined}
                                icon={this.props.authorType !== ChatType.RESPONSE ? <Coffee/> : <Award/>}
                            />
                        </div>
                    )}
                    <div className='chat-body'>
                           <div className='chat-content'>
                               <ChatListItemContent
                                   errorModuleHandle={this.props.errorModuleHandle}
                                   message={message}
                                   contentType={this.props.message.messageType}
                                   messageType={this.props.authorType}
                               />
                           </div>
                    </div>
                </div>



              {/*  <div className={`${styles.messageItem} ${this.props.authorType === ChatType.MESSAGE ? styles.message : ''}`}>*/}
              {/*      <div className={styles.messageContainer}>*/}
              {/*          {this.renderAvatar(*/}
              {/*              avatarImage,*/}
              {/*              message.user ? message.user.user_nickname : "",*/}
              {/*              ChatType.MESSAGE*/}
              {/*          )}*/}
              {/*          <div className={styles.messageContent}>*/}
              {/*              <div*/}
              {/*                  className={`${styles.messageDetails} ${*/}
              {/*                      messageType !== MessageContentType.IMAGE ? "text" : ""*/}
              {/*                  }*/}
              {/*${this.props.authorType === ChatType.MESSAGE ? styles.message : styles.response}`}*/}
              {/*              >*/}
              {/*                  <ChatListItemContent*/}
              {/*                      errorModuleHandle={this.props.errorModuleHandle}*/}
              {/*                      downloadFile={this.props.downloadFile}*/}
              {/*                      message={message}*/}
              {/*                      contentType={ChatType.MESSAGE}*/}
              {/*                      messageType={this.props.type}*/}
              {/*                  />*/}
              {/*                  <div className={styles.messageDate}>*/}
              {/*                      {this.convertDate(message.date)}*/}
              {/*                  </div>*/}
              {/*              </div>*/}
              {/*          </div>*/}
              {/*      </div>*/}
              {/*  </div>*/}
            </React.Fragment>
        );
    }

    // private renderAvatar(
    //     avatarImage: string | undefined,
    //     messageAuthor: string | undefined,
    //     type: ChatType
    // ) {
    //     if (avatarImage && this.props.type === MessageContentType.RESPONSE) {
    //         return (
    //             <div
    //                 className={`${styles.authorAvatar} ${styles.authorImage}
    //       ${type === ChatType.RESPONSE ? styles.response : ""}`}
    //             >
    //                 <img src={avatarImage} alt="avatar" />
    //             </div>
    //         );
    //     }
    //     if (messageAuthor && this.props.type === MessageContentType.RESPONSE) {
    //         let authorName = messageAuthor.charAt(0);
    //         return (
    //             <div
    //                 className={`${styles.authorAvatar} ${
    //                     type === ChatType.RESPONSE ? styles.response : ""
    //                 }`}
    //             >
    //                 <span>{authorName}</span>
    //             </div>
    //         );
    //     }
    // }

    // private convertDate(value: Date): string {
    //     if (value === null || value === undefined) {
    //         return "";
    //     }
    //
    //     const options = {
    //         hour: "2-digit",
    //         minute: "2-digit",
    //         hour12: true
    //     };
    //
    //     return new Date(value).toLocaleString("en-GB", options as any);
    // }
}

export default ChatListItem;
