import {authTokenSelector, Marina} from 'marine-panel-common-web';
import {FC, useEffect} from 'react';
import {connect} from 'react-redux';
import {Modal} from 'reactstrap';
import {RootState} from '../../../store/reducers';
import {isModalOpenSelector, marinaModalSelector, nameSelector, photoIdSelector} from '../../../store/selectors/modalSelectors';
import {changeIsModalOpen, ModalNames, resetToInitialModalState} from '../../../store/reducers/modalSlice';
import SetLocationModal from '../../Marinas/WizardEdit/WizardContent/Common/SetLocationModal';
import {deletePhoto, SelectedItemType, setIsActionSuccessful, setPhotoAsCover} from '../../../store/reducers/marinaEditWizardSlice';
import CreateExtraModal from '../../Marinas/WizardEdit/WizardContent/Marina/Extras/CreateExtraModal';
import ChangeExtraModal from '../../Marinas/WizardEdit/WizardContent/Marina/Extras/ChangeExtraModal';
import CreateMarinaModal from '../../Marinas/CreateMarinaModal';
import AddPhotoModal from './AddPhotoModal';
import ChangePhotoModal from './ChangePhotoModal';
import DeletePhotoModal from './DeletePhotoModal';

interface IModalComponentProps {
    readonly toggleModalOpen: () => void;
    readonly modalName: ModalNames | null | undefined;
    readonly isOpen: boolean;
    readonly resetToInitialModalState: typeof resetToInitialModalState;
    readonly setPhotoAsCover: typeof setPhotoAsCover;
    readonly deletePhoto: typeof deletePhoto;
    readonly changeIsModalOpen: typeof changeIsModalOpen;
    readonly setIsActionSuccessful: typeof setIsActionSuccessful;
    readonly authToken: string;
    readonly marina: Marina | null;
    readonly photoId: string | null;
}

const ModalComponent: FC<IModalComponentProps> = ({
    toggleModalOpen,
    modalName,
    isOpen,
    marina,
    resetToInitialModalState,
    setPhotoAsCover,
    deletePhoto,
    changeIsModalOpen,
    setIsActionSuccessful,
    authToken,
    photoId,
}) => {
    useEffect(() => {
        return () => {
            resetToInitialModalState();
        };
    }, []);

    const renderModal = (modalName: ModalNames | null | undefined) => {
        let content;

        switch (modalName) {
            case ModalNames.SET_MARINA_LOCATION:
                content = <SetLocationModal locationType={SelectedItemType.MARINA} toggleModalOpen={toggleModalOpen} />;
                break;
            case ModalNames.CREATE_MARINA:
                content = <CreateMarinaModal toggleModalOpen={toggleModalOpen} />;
                break;
            case ModalNames.CREATE_EXTRA:
                content = <CreateExtraModal toggleModalOpen={toggleModalOpen} />;
                break;
            case ModalNames.EDIT_EXTRA:
                content = <ChangeExtraModal toggleModalOpen={toggleModalOpen} />;
                break;
            case ModalNames.DELETE_PHOTO:
                content = (
                    <DeletePhotoModal toggleModalOpen={toggleModalOpen} marina={marina} deletePhoto={deletePhoto} photoId={photoId} />
                );
                break;
            case ModalNames.CHANGE_PHOTO:
                content = <ChangePhotoModal toggleModalOpen={toggleModalOpen} marina={marina} setPhotoAsCover={setPhotoAsCover} />;
                break;
            case ModalNames.ADD_PHOTO:
                content = (
                    <AddPhotoModal
                        toggleModalOpen={toggleModalOpen}
                        marina={marina}
                        changeIsModalOpen={changeIsModalOpen}
                        setIsActionSuccessful={setIsActionSuccessful}
                        authToken={authToken}
                    />
                );
                break;
            default:
                content = null;
        }
        return content;
    };

    return (
        <Modal isOpen={isOpen} toggle={() => toggleModalOpen()}>
            {renderModal(modalName)}
        </Modal>
    );
};

export default connect(
    (state: RootState) => ({
        modalName: nameSelector(state),
        isOpen: isModalOpenSelector(state),
        photoId: photoIdSelector(state),
        authToken: authTokenSelector(state),
        marina: marinaModalSelector(state),
    }),
    {
        resetToInitialModalState,
        setPhotoAsCover,
        deletePhoto,
        changeIsModalOpen,
        setIsActionSuccessful,
    }
)(ModalComponent);
