import {RestQueryParams, marineHubAPI} from 'marine-panel-common-web';
import {Observable} from 'rxjs';
// import {IChangeMarinaLocationPayload} from '../store/reducers/marinaEditWizardSlice';

export function changeMarinaLocationAPI(payload: any | null, authToken: string | null): Observable<any> {
    const headers = {
        Authorization: `Bearer ${authToken}`,
    };
    if (payload === null) return new Observable();
    const payloadItem = {location: payload.location};

    return marineHubAPI.put(`api/marinas/${payload.marinaId}/change_location`, payloadItem, new RestQueryParams(), headers);
}
