import React from 'react';
import {Route, Routes} from 'react-router-dom';
import Registration from './Registration';
import AuthHeader from './AuthHeader';
import Login from './Login';
import Toast from '../Toast';
import ResetPassword from './ResetPassword';
import NewPassword from './NewPassword';
import ConfirmRegistration from './ConfirmRegistration';
import {IAuthPanelProps} from '../../types';
import NotFound from '../Maintenance/NotFound';
import Footer from '../Footer';
import {withRouterWrapper} from '../hoc/withRouterWrapper';
import {initAuthTokenChange} from '../../store/reducers/authSlice';
import {connect} from 'react-redux';
import {isAuthPageLoadingSelector} from '../../store/selectors/authSelectors';
import {RootState} from '../../store/reducers';
import AccessibilityMenu from '../AccessibilityMenu';

type Props = IAuthPanelProps;

class AuthPanel extends React.Component<Props> {
    componentDidMount(): void {
        if (!this.isProductionMode()) {
            if (this.props.router && this.props.router.location && this.props.router.location.search) {
                const token = this.props.router.location.search.split('?token=').pop();
                this.setAuthToken(token);
            }
        }
    }

    render() {
        const authRoute = this.props.router?.location?.pathname;
        const lastPartOfUrl = authRoute?.split('/')[2];
        return (
            <div className="auth-page">
                <div className={`auth-content ${this.props.userRole.toLowerCase()}`}>
                    <div className={`auth-left-bg ${lastPartOfUrl}`}  />
                    <main className="auth-section">

                        <div className="auth-section-wrapper">
                            <AuthHeader userRole={this.props.userRole} />
                            <Routes>
                                {this.props.formConfig?.login && (
                                    <Route
                                        path="/login"
                                        key="auth"
                                        element={<Login userRole={this.props.userRole} formConfig={this.props.formConfig.login} />}
                                    />
                                )}

                                {this.props.formConfig?.registration && (
                                    <Route
                                        path="/register"
                                        key="register"
                                        element={
                                            <Registration
                                                userRole={this.props.userRole}
                                                envData={this.props.envData}
                                                isLoading={this.props.isLoading}
                                                formConfig={this.props.formConfig.registration}
                                            />
                                        }
                                    />
                                )}

                                {this.props.formConfig?.resetPassword && (
                                    <Route
                                        path="/reset-password"
                                        key="reset-password"
                                        element={
                                            <ResetPassword
                                                userRole={this.props.userRole}
                                                envData={this.props.envData}
                                                isLoading={this.props.isLoading}
                                                formConfig={this.props.formConfig.resetPassword}
                                            />
                                        }
                                    />
                                )}

                                <Route
                                    path="/confirm-registration/:id"
                                    key="confirm-registration"
                                    element={<ConfirmRegistration isLoading={this.props.isLoading} />}
                                />

                                {this.props.formConfig?.newPassword && (
                                    <Route
                                        path="/new-password/:id"
                                        key="new-password"
                                        element={
                                            <NewPassword formConfig={this.props.formConfig.newPassword} isLoading={this.props.isLoading} />
                                        }
                                    />
                                )}

                                <Route path="*" element={<NotFound />} />
                            </Routes>
                        </div>
                        <AccessibilityMenu />

                    </main>
                </div>
                <Footer />
                <Toast />
            </div>
        );
    }

    private setAuthToken = (token: string | null) => {
        if (!token) {
            return;
        }

        this.props.initAuthTokenChange(token, this.props.userRole);
    };

    private isProductionMode = (): boolean => {
        return process.env.REACT_APP_ENV === 'prod';
    };
}

export default connect(
    (state: RootState) => ({
        isLoading: isAuthPageLoadingSelector(state),
    }),
    {initAuthTokenChange}
)(withRouterWrapper(AuthPanel));
