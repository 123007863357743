import {marineHubAPI, RestQueryParams} from 'marine-panel-common-web';
import {Observable} from 'rxjs';

export function deleteArticleAPI(authToken: string | null, articleId: string): Observable<any> {
    const headers = {
        Authorization: `Bearer ${authToken}`,
    };

    return marineHubAPI.delete(`api/articles/${articleId}`, new RestQueryParams(), headers);
}
