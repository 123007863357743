import {AccessibilityMenu, Translation} from 'marine-panel-common-web';
import React from 'react';
import {Card, CardBody} from 'reactstrap';
import LayoutWrapper from '../LayoutWrapper';
import ChangePassword from './ChangePassword';

interface ISettingsProps {}

const Settings: React.FC<ISettingsProps> = () => {
    return (
        <LayoutWrapper>
            <h2 className="section-title">
                <Translation text="settings.title" />
            </h2>
            <Card className="payment-details-card">
                <CardBody>
                    <ChangePassword />
                </CardBody>
            </Card>
            <AccessibilityMenu />
        </LayoutWrapper>
    );
};

export default Settings;
