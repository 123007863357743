import {createSelector} from '@reduxjs/toolkit';
import {ICountryState} from '../reducers/countrySlice';

export const selectCountry = (state: any): ICountryState => {
    return state.country;
};

export const countriesSelector = createSelector([selectCountry], (state: ICountryState) => state.countries);

export const countriesLoadingSelector = createSelector([selectCountry], (state: ICountryState) => state.isLoading);

export const countriesInitializedSelector = createSelector([selectCountry], (state: ICountryState) => state.isInitialized);

export const countriesErrorSelector = createSelector([selectCountry], (state: ICountryState) => state.error);
