import {FC} from 'react';
import {useMap} from 'react-leaflet';

interface IChangeMapViewProps {
    center: {
        lat: number;
        lng: number;
    };
    zoom: number;
}

const ChangeMapView: FC<IChangeMapViewProps> = ({center, zoom}) => {
    const map = useMap();
    map.setView(center, zoom);
    return null;
};

export default ChangeMapView;
