import React from 'react';
import {connect} from 'react-redux';
import {WithTranslation, withTranslation} from 'react-i18next';
import {AlertObject, removeAlert} from '../../store/reducers/alertSlice';
import {alertsSelector, disabledAlertsSelector} from '../../store/selectors/alertSelectors';
import {AlertType, IToastProps, IToastState} from '../../types';
import {isSameValue, Translation} from '../../index';
import {Toast, ToastBody} from 'reactstrap';
import {AlertOctagon, AlertTriangle, CheckCircle, Info} from 'react-feather';
import {IconSize} from '../../constants/miscellaneous';

type Props = IExtendedToastProps;
type State = IToastState;

interface IExtendedToastProps extends IToastProps, WithTranslation {}

type IIconItem = {
    label: any;
    icon: any;
};

class ToastComponent extends React.Component<Props, State> {
    constructor(props: any) {
        super(props);

        this.state = {
            alerts: this.props.alerts,
            alertsDisabled: this.props.alertsDisabled,
        };
    }

    componentDidMount() {
        if (this.props.alerts) {
            this.removeAlerts();
        }
    }

    componentDidUpdate(prevProps: Readonly<Props>): void {
        if (this.props.alerts && !isSameValue(this.props.alerts, prevProps.alerts)) {
            this.removeAlerts();
        }
    }

    render() {
        if (this.props.alertsDisabled || !this.props.alerts.length) {
            return null;
        }

        return (
            <React.Fragment>
                {this.props.alerts.map((alert: AlertObject) => {
                    const message: any = !alert.isTranslated ? alert.message : <Translation text={alert.message} />,
                        isTypeWarning = this.props.alertType === AlertType.WARNING || alert.type === AlertType.WARNING,
                        alertDetails = this.getAlertIcon(alert.type);
                    return (
                        <Toast key={`toast_${alert.id}`} className={`${isTypeWarning ? 'warning' : ''} ${alert.type ? alert.type : ''}`}>
                            <ToastBody>
                                {alertDetails.icon && <div className="toast-icon">{alertDetails.icon}</div>}
                                <div className="toast-message-wrapper">
                                    {alertDetails.label && <p className="toast-heading">{alertDetails.label}</p>}
                                    <span className="toast-message">{message}</span>
                                </div>
                            </ToastBody>
                            <button type="button" className="ms-1 btn-close" onClick={() => this.closeToast(alert.id)} />
                        </Toast>
                    );
                })}
            </React.Fragment>
        );
    }

    private getAlertIcon = (type: AlertType): IIconItem | null => {
        const {t} = this.props;
        let alertDetails: IIconItem | null = {
            label: t(`alerts.${type}`),
            icon: null,
        };
        switch (type) {
            case AlertType.SUCCESS:
                alertDetails.icon = <CheckCircle size={IconSize.Toast} />;
                break;
            case AlertType.INFO:
                alertDetails.icon = <Info size={IconSize.Toast} />;
                break;
            case AlertType.WARNING:
                alertDetails.icon = <AlertTriangle size={IconSize.Toast} />;
                break;
            case AlertType.ERROR:
                alertDetails.icon = <AlertOctagon size={IconSize.Toast} />;
                break;
            default:
                alertDetails.icon = null;
                break;
        }
        return alertDetails;
    };

    private closeToast = (id: number) => {
        this.props.removeAlert(id);
    };

    private removeAlerts = () => {
        this.props.alerts.map((alert: AlertObject) => {
            if (alert.displayFor !== 0) {
                setTimeout(() => {
                    this.props.removeAlert(alert.id);
                }, alert.displayFor);
            }

            return alert;
        });
    };
}

export default withTranslation()(
    connect(
        (state: any) => ({
            alerts: alertsSelector(state),
            alertsDisabled: disabledAlertsSelector(state),
        }),
        {
            removeAlert,
        }
    )(ToastComponent)
);
