import React, {useEffect, useState} from 'react';
import {useTranslation, withTranslation, WithTranslation} from 'react-i18next';
import {Card, CardBody, CardHeader, Table} from 'reactstrap';
import LayoutWrapper from '../LayoutWrapper';
import {IModelApiResponseViewObject, Loader, LoaderType, Pagination, Switch, Translation} from 'marine-panel-common-web';
import {connect} from 'react-redux';
import {
    isOperatorsPageLoadingSelector,
    operatorsPageMetadataSelector,
    operatorsSelector,
} from '../../store/selectors/operatorsPageSelectors';
import {RootState} from '../../store/reducers';
import {IAccountBasicInfo, IOperatorFullInfo} from '../../model/outputItems';
import {
    changeOperatorsPagePagination,
    changeOperatorStatus,
    fetchOperators,
    resetToInitialOperatorsPageState,
} from '../../store/reducers/operatorsPageSlice';
import styles from './styles.module.scss';
import InviteOperatorModal from './InviteOperatorModal';
import useModal from '../../utils/modalHook';
import SubscriptionHistoryModal from './SubscriptionHistoryModal';
import OperatorMarinasModal from './OperatorMarinasModal';

interface IConnectedOperatorsProps {
    readonly isLoading: boolean;
    readonly operators: IOperatorFullInfo[] | null;
    readonly operatorsPageMetadata: IModelApiResponseViewObject | null;
    readonly fetchOperators: typeof fetchOperators;
    readonly changeOperatorStatus: typeof changeOperatorStatus;
    readonly changeOperatorsPagePagination: typeof changeOperatorsPagePagination;
    readonly resetToInitialOperatorsPageState: typeof resetToInitialOperatorsPageState;
}

interface IOperatorsProps extends IConnectedOperatorsProps, WithTranslation {}

const Operators: React.FC<IOperatorsProps> = ({
    operators,
    isLoading,
    operatorsPageMetadata,
    fetchOperators,
    changeOperatorsPagePagination,
    changeOperatorStatus,
    resetToInitialOperatorsPageState,
}) => {
    const [selectedOperatorId, setSelectedOperatorId] = useState<string | null>(null),
        [isModalShown, toggle] = useModal(),
        [isSubscriptionModalShown, toggleSubscriptionModal] = useModal(),
        [isMarinasModalShown, toggleMarinasModal] = useModal(),
        {t} = useTranslation();

    useEffect(() => {
        fetchOperators();

        return () => {
            resetToInitialOperatorsPageState();
        };
    }, [fetchOperators]);

    return (
        <LayoutWrapper>
            <Card className="transactions-history">
                <CardHeader>
                    <h2 className="section-subtitle">
                        <Translation text="operators.title" />
                    </h2>

                    <button className="btn btn-create invite-operator-button" onClick={() => toggle()}>
                        <Translation text="operators.inviteOperator" />
                    </button>
                </CardHeader>
                <CardBody>
                    <Table responsive className="operators-table">
                        <thead>
                            <tr>
                                <th>
                                    <Translation text="operators.table.name" />
                                </th>
                                <th>
                                    <Translation text="operators.table.contact" />
                                </th>
                                <th>
                                    <Translation text="operators.table.connectedMarinas" />
                                </th>
                                <th>
                                    <Translation text="operators.table.active" />
                                </th>
                                <th />
                            </tr>
                        </thead>
                        <tbody>{renderOperatorsTable()}</tbody>
                    </Table>

                    <div className={styles.paginationContainer}>
                        <Pagination listMetadata={operatorsPageMetadata} changePage={changeOperatorsPagePagination} itemsPerPage={10} />
                    </div>

                    <Loader type={LoaderType.Local} showLoader={isLoading} />
                </CardBody>
            </Card>

            {isModalShown ? <InviteOperatorModal isModalOpen={isModalShown} toggleModal={toggle} /> : null}
            {isSubscriptionModalShown ? (
                <SubscriptionHistoryModal
                    isModalOpen={isSubscriptionModalShown}
                    toggleModal={toggleOperatorSubscriptionModal}
                    operatorId={selectedOperatorId}
                />
            ) : null}
            <OperatorMarinasModal
                isModalOpen={isMarinasModalShown}
                toggleModal={toggleOperatorMarinasModal}
                selectedOperatorId={selectedOperatorId}
            />
        </LayoutWrapper>
    );

    function renderOperatorsTable() {
        if (null === operators || !operators.length) {
            return (
                <tr>
                    <td>
                        <Translation text="operators.table.noData" />
                    </td>
                </tr>
            );
        }

        return operators.map((item: IOperatorFullInfo) => {
            return (
                <tr key={item.id}>
                    <td>{renderOperatorDetails(item)}</td>
                    <td>{renderOperatorContactDetails(item.user)}</td>
                    <td>
                        <div className="badge-wrapper">
                            <div className="badge">{item.marinaCount}</div>
                            <button
                                className="btn btn-underline connected-marinas-button"
                                onClick={() => toggleOperatorMarinasModal(item.operator.id)}>
                                <Translation text="buttons.view" />
                            </button>
                        </div>
                    </td>
                    <td>
                        <Switch
                            name="marina-status"
                            checked={item.active}
                            handleChange={(e: any) => changeOperatorActiveStatus(item.operator.id, e)}
                        />
                    </td>
                    <td>
                        <button
                            className="btn btn-underline subscription-history-button"
                            onClick={() => toggleOperatorSubscriptionModal(item.operator.id)}>
                            <Translation text="operators.table.subscriptionHistory" />
                        </button>
                    </td>
                </tr>
            );
        });
    }

    function renderOperatorDetails(operator: IOperatorFullInfo | null) {
        const userName =
                operator && operator.user && operator.user.firstName && operator.user.lastName
                    ? `${operator.user.firstName} ${operator.user.lastName}`
                    : t('marinas.connectedOperators.table.unknownOperator'),
            operatorName = operator?.operator?.name ? operator.operator.name : userName;

        return (
            <div className={styles.contactDetails}>
                <p>{operatorName}</p>
            </div>
        );
    }

    function renderOperatorContactDetails(user: IAccountBasicInfo | null) {
        if (user === null) {
            return <p>---</p>;
        }

        return (
            <div className={styles.contactDetails}>
                <p className={styles.phone}>{user.mobile ? user.mobile : '---'}</p>
                <p>{user.username ? user.username : '---'}</p>
            </div>
        );
    }

    function changeOperatorActiveStatus(operatorId: string, e: any) {
        const isChecked = e.target.checked;
        changeOperatorStatus(operatorId, isChecked);
    }

    function toggleOperatorSubscriptionModal(operatorId: string | null) {
        setSelectedOperatorId(operatorId);
        toggleSubscriptionModal();
    }

    function toggleOperatorMarinasModal(operatorId: string | null) {
        setSelectedOperatorId(operatorId);
        toggleMarinasModal();
    }
};

export default connect(
    (state: RootState) => ({
        isLoading: isOperatorsPageLoadingSelector(state),
        operators: operatorsSelector(state),
        operatorsPageMetadata: operatorsPageMetadataSelector(state),
    }),
    {
        fetchOperators,
        changeOperatorStatus,
        changeOperatorsPagePagination,
        resetToInitialOperatorsPageState,
    }
)(withTranslation()(Operators));
