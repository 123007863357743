import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {
    IBaseDictionaryState,
    IChangeIsInitialized,
    IChangeIsLoading,
    IChangePagePagination,
    IPaginationParams,
    ISetError,
    ISetPageMetadata,
} from './marinasSlice';
import {IOperatorFullInfo} from '../../model/outputItems';
import {initApiCall} from '../../utils/reduxHelpers';
import {IAdminMarinaListItem, IModelApiResponseViewObject, IOperatorSubscription} from 'marine-panel-common-web';
import {IChangeArticleActionComplete, IChangeArticleActionProcessing} from './articlesPageSlice';

export interface IOperatorsState extends IBaseDictionaryState {
    operators: IOperatorFullInfo[] | null;
    operatorsMetadata: IModelApiResponseViewObject | null;
    pagination: IPaginationParams | null;
    operatorSubscriptions: IOperatorSubscription[] | null;
    operatorConnectedMarinas: IAdminMarinaListItem[] | null;
    isInvitationProcessing: boolean;
    isInvitationComplete: boolean;
    isMarinaActionProcessing: boolean;
    isMarinaActionComplete: boolean;
}

export interface ISetOperators {
    readonly operators: IOperatorFullInfo[] | null;
}

export interface IFetchOperatorSubscriptions {
    readonly operatorId: string;
}

export interface ISetOperatorSubscriptions {
    readonly operatorSubscriptions: IOperatorSubscription[] | null;
}

export interface IInviteOperator {
    readonly email: string;
}

export interface IChangeIsInvitationProcessing {
    readonly isProcessing: boolean;
}

export interface IChangeIsInvitationComplete {
    readonly isComplete: boolean;
}

export interface IChangeOperatorStatus {
    readonly operatorId: string;
    readonly isActive: boolean;
}

export interface IFetchConnectedOperatorMarinas {
    readonly operatorId: string;
}

export interface ISetConnectedOperatorMarinas {
    readonly connectedOperatorsMarinas: IAdminMarinaListItem[] | null;
}

export interface IBindMarinaToOperator {
    readonly operatorId: string;
    readonly marinaId: string;
}

const initialState: IOperatorsState = {
    operators: null,
    operatorsMetadata: null,
    pagination: {
        page: 1,
        itemsPerPage: 10,
    },
    operatorSubscriptions: null,
    operatorConnectedMarinas: null,
    isInvitationProcessing: false,
    isInvitationComplete: false,
    isMarinaActionProcessing: false,
    isMarinaActionComplete: false,
    isLoading: false,
    isInitialized: false,
    error: null,
};

const operatorSlice = createSlice({
    name: 'operators',
    initialState: initialState,
    reducers: {
        changeOperators: {
            reducer: (state: IOperatorsState, action: PayloadAction<ISetOperators>) => {
                return {
                    ...state,
                    operators: action.payload.operators,
                };
            },
            prepare(operators: IOperatorFullInfo[] | null) {
                return {
                    payload: {operators: operators},
                };
            },
        },
        changeIsOperatorsPageLoading: {
            reducer: (state: IOperatorsState, action: PayloadAction<IChangeIsLoading>) => {
                return {
                    ...state,
                    isLoading: action.payload.isLoading,
                };
            },
            prepare(isLoading: boolean) {
                return {
                    payload: {isLoading: isLoading},
                };
            },
        },
        changeIsOperatorsPageInitialized: {
            reducer: (state: IOperatorsState, action: PayloadAction<IChangeIsInitialized>) => {
                return {
                    ...state,
                    isInitialized: action.payload.isInitialized,
                };
            },
            prepare(isInitialized: boolean) {
                return {
                    payload: {isInitialized: isInitialized},
                };
            },
        },
        setOperatorsPageError: {
            reducer: (state: IOperatorsState, action: PayloadAction<ISetError>) => {
                return {
                    ...state,
                    error: action.payload.error,
                };
            },
            prepare(error: string | null) {
                return {
                    payload: {error: error},
                };
            },
        },
        setOperatorsPageMetadata: {
            reducer: (state: IOperatorsState, action: PayloadAction<ISetPageMetadata>) => {
                return {
                    ...state,
                    operatorsMetadata: action.payload.metadata,
                };
            },
            prepare(metadata: IModelApiResponseViewObject | null) {
                return {
                    payload: {
                        metadata: metadata,
                    },
                };
            },
        },
        changeOperatorsPagePagination: {
            reducer: (state: IOperatorsState, action: PayloadAction<IChangePagePagination>) => {
                return {
                    ...state,
                    isLoading: true,
                    pagination: action.payload.pagination,
                };
            },
            prepare(pagination: IPaginationParams) {
                return {
                    payload: {pagination: pagination},
                };
            },
        },
        inviteOperator: {
            reducer: (state: IOperatorsState) => {
                return {
                    ...state,
                    isInvitationProcessing: true,
                };
            },
            prepare(email: string) {
                return {
                    payload: {email: email},
                };
            },
        },
        changeIsInvitationProcessing: {
            reducer: (state: IOperatorsState, action: PayloadAction<IChangeIsInvitationProcessing>) => {
                return {
                    ...state,
                    isInvitationProcessing: action.payload.isProcessing,
                };
            },
            prepare(isProcessing: boolean) {
                return {
                    payload: {isProcessing: isProcessing},
                };
            },
        },
        changeIsInvitationComplete: {
            reducer: (state: IOperatorsState, action: PayloadAction<IChangeIsInvitationComplete>) => {
                return {
                    ...state,
                    isInvitationComplete: action.payload.isComplete,
                };
            },
            prepare(isComplete: boolean) {
                return {
                    payload: {isComplete: isComplete},
                };
            },
        },
        fetchOperatorSubscriptions: {
            reducer: (state: IOperatorsState) => {
                return {
                    ...state,
                };
            },
            prepare(operatorId: string) {
                return {
                    payload: {operatorId: operatorId},
                };
            },
        },
        setOperatorSubscriptions: {
            reducer: (state: IOperatorsState, action: PayloadAction<ISetOperatorSubscriptions>) => {
                return {
                    ...state,
                    operatorSubscriptions: action.payload.operatorSubscriptions,
                };
            },
            prepare(operatorSubscriptions: IOperatorSubscription[] | null) {
                return {
                    payload: {operatorSubscriptions: operatorSubscriptions},
                };
            },
        },
        changeOperatorStatus: {
            reducer: (state: IOperatorsState) => {
                return {
                    ...state,
                    isLoading: true,
                };
            },
            prepare(operatorId: string, isActive: boolean) {
                return {
                    payload: {operatorId: operatorId, isActive: isActive},
                };
            },
        },
        fetchConnectedMarinas: {
            reducer: (state: IOperatorsState) => {
                return {
                    ...state,
                    // isLoading: true,
                };
            },
            prepare(operatorId: string) {
                return {
                    payload: {operatorId: operatorId},
                };
            },
        },
        setOperatorsConnectedMarinas: {
            reducer: (state: IOperatorsState, action: PayloadAction<ISetConnectedOperatorMarinas>) => {
                return {
                    ...state,
                    operatorConnectedMarinas: action.payload.connectedOperatorsMarinas,
                };
            },
            prepare(connectedOperatorsMarinas: IAdminMarinaListItem[] | null) {
                return {
                    payload: {connectedOperatorsMarinas: connectedOperatorsMarinas},
                };
            },
        },
        bindMarinaToOperator: {
            reducer: (state: IOperatorsState) => {
                return {
                    ...state,
                    isMarinaActionProcessing: true,
                };
            },
            prepare(operatorId: string, marinaId: string) {
                return {
                    payload: {operatorId: operatorId, marinaId: marinaId},
                };
            },
        },
        unbindMarinaFromOperator: {
            reducer: (state: IOperatorsState) => {
                return {
                    ...state,
                    isMarinaActionProcessing: true,
                };
            },
            prepare(operatorId: string, marinaId: string) {
                return {
                    payload: {operatorId: operatorId, marinaId: marinaId},
                };
            },
        },
        changeIsMarinaActionComplete: {
            reducer: (state: IOperatorsState, action: PayloadAction<IChangeArticleActionComplete>) => {
                return {
                    ...state,
                    isMarinaActionComplete: action.payload.isComplete,
                };
            },
            prepare(isComplete: boolean) {
                return {
                    payload: {isComplete: isComplete},
                };
            },
        },
        changeIsMarinaActionProcessing: {
            reducer: (state: IOperatorsState, action: PayloadAction<IChangeArticleActionProcessing>) => {
                return {
                    ...state,
                    isMarinaActionProcessing: action.payload.isProcessing,
                };
            },
            prepare(isProcessing: boolean) {
                return {
                    payload: {isProcessing: isProcessing},
                };
            },
        },
        fetchOperators: (state: IOperatorsState) => initApiCall(state),
        resetToInitialOperatorsPageState: () => {
            return {
                ...initialState,
            };
        },
    },
});

export const {
    changeOperators,
    changeIsOperatorsPageLoading,
    changeIsOperatorsPageInitialized,
    setOperatorsPageError,
    inviteOperator,
    changeIsInvitationComplete,
    changeIsInvitationProcessing,
    fetchOperators,
    changeOperatorsPagePagination,
    setOperatorsPageMetadata,
    changeOperatorStatus,
    fetchOperatorSubscriptions,
    fetchConnectedMarinas,
    setOperatorsConnectedMarinas,
    bindMarinaToOperator,
    unbindMarinaFromOperator,
    changeIsMarinaActionProcessing,
    changeIsMarinaActionComplete,
    setOperatorSubscriptions,
    resetToInitialOperatorsPageState,
} = operatorSlice.actions;

export default operatorSlice.reducer;
