import React, {Component} from 'react';
import styles from './styles.module.scss';

interface IYouTubeVideoProps {
    youtubeLink: string;
}

class YouTubeVideo extends Component<IYouTubeVideoProps, any> {
    render() {
        let embedId = this.youtubeVideoIdParser(this.props.youtubeLink);

        return (
            <div className={styles.youtubeVideoContainer}>
                <iframe
                    width="180"
                    height="100"
                    src={`https://www.youtube.com/embed/${embedId}`}
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    title="Embedded youtube"
                />
            </div>
        );
    }

    private youtubeVideoIdParser = (url: string): string => {
        const videoId = url.split(/^.*(youtu.be\/|v\/|embed\/|watch\?|youtube.com\/user\/[^#]*#([^\/]*?\/)*)\??v?=?([^#\&\?]*).*/);
        return videoId[3];
    };
}

export default YouTubeVideo;
