import React from "react";
import DatePicker from "react-datepicker";
import {ICalendarProps, ICalendarState} from "../../../types";
import CalendarTimeSlots from "./CalendarTimeSlots";
import {isNotNullOrUndefined, isNullOrUndefined} from "../../../utils/runtimeUtils";
import {isSameValue} from "../../..";
import {getDateFromString} from "../../../utils/dateUtils";

type Props = ICalendarProps;
type State = ICalendarState;

class CalendarFormControl extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      availableDates: null,
      selectedDay: null,
    };
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
    if (!isSameValue(this.props.availableDates, prevProps.availableDates)) {
      this.setState({availableDates: this.props.availableDates});
    }

    if (!isSameValue(this.props.selectedDate, prevProps.selectedDate)) {
      // if (this.props.selectedDate !== this.state.selectedDay) {
        this.setState({selectedDay: this.props.selectedDate.value});
      // }
    }
  }

  render() {
    return (
      <div className="row">
        <div className="col-12 mb-4">
          <DatePicker
            selected={this.state.selectedDay}
            onChange={(e) => this.onDateChange(e)}
            onMonthChange={(e) => this.onMonthChange(e)}
            minDate={new Date()}
            maxDate={this.props.maxDate ? getDateFromString(this.props.maxDate) : null}
            // maxDate={lastDay}
            // showMonthDropdown
            inline
            dayClassName={(date) =>
              this.props.isDayDisabled && this.getAppointmentDays(date) ? "disabled" : this.getAppointmentDays(date) ? "available" : null
            }
            filterDate={(date) =>
              isNullOrUndefined(this.props.isDateFiltered) || (isNotNullOrUndefined(this.props.isDateFiltered) && this.props.isDateFiltered)
                ? this.getAppointmentDays(date)
                : true
            }
          />
        </div>
        <div className="col-12">{this.renderTimeSlots()}</div>
      </div>
    );
  }

  private renderTimeSlots = () => {
    if (!this.props.availableTimeSlots) {
      return;
    }
    return (
      <CalendarTimeSlots
        onChange={this.props.onChange}
        selectedDate={this.state.selectedDay}
        availableTimeSlots={this.props.availableTimeSlots}
        placeholder={this.props.placeholder}
        checkboxSlots={this.props.checkboxSlots}
        isLabelHidden={this.props.isLabelHidden}
      />
    );
  };

  private getAppointmentDays(date: any): boolean | null {
    if (!this.state.availableDates) {
      return null;
    }

    return this.state.availableDates
      .map((el: any) => {
        const dateEl = new Date(el);
        return `${dateEl.getFullYear()}-${dateEl.getMonth() + 1}-${dateEl.getDate()}`;
      })
      .includes(`${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`);
  }

  private onMonthChange = (e: any) => {
    if (e) {
      const eventData = {
        target: {
          value: {
            name: "month",
            value: e,
          },
        },
      };
      this.props.onChange(eventData);
    }
  };

  private onDateChange = (e: any) => {
    if (e) {
      const eventData = {
        target: {
          value: {
            name: "date",
            value: e,
          },
        },
      };
      this.setState({selectedDay: e});
      this.props.onChange(eventData);
    }
  };
}

export default CalendarFormControl;
