import {IMultiselectOption} from '../types';
import {isNullOrUndefined} from './runtimeUtils';

export const isSameSelection = (a: IMultiselectOption | IMultiselectOption[], b: IMultiselectOption | IMultiselectOption[]) => {
    if (isNullOrUndefined(a) || isNullOrUndefined(b)) {
        return a === b;
    }
    const aIsArray = Array.isArray(a);
    const bIsArray = Array.isArray(b);
    if ((aIsArray && !bIsArray) || (!aIsArray && bIsArray)) {
        return false;
    }
    if (!aIsArray && !bIsArray) {
        return (a as IMultiselectOption).value === (b as IMultiselectOption).value;
    }
    if ((a as IMultiselectOption[]).length !== (b as IMultiselectOption[]).length) {
        return false;
    }
    const bValues: string[] = (b as IMultiselectOption[]).map((option) => option.value);

    return (a as IMultiselectOption[]).map((option) => option.value).every((value) => -1 !== bValues.indexOf(value));
};

export const isSameCollection = <T = any>(a?: T[], b?: T[], recursive: boolean = false): boolean => {
    if (isNullOrUndefined(a) || isNullOrUndefined(b)) {
        return a === b;
    }
    if (!Array.isArray(a) || !Array.isArray(b)) {
        return false;
    }
    if (a.length !== b.length) {
        return false;
    }

    return a.every((value, index) => {
        const compared = b[index];
        if (isNullOrUndefined(compared)) {
            return false;
        }

        return isSameValue(a, b, recursive);
    });
};

export const isSameValue = <T>(a?: T, b?: T, recursive: boolean = true): boolean => {
    if (Object.is(a, b)) {
        return true;
    }
    if (isNullOrUndefined(a) || isNullOrUndefined(b)) {
        return a === b;
    }
    if (typeof a !== 'object' || typeof b !== 'object') {
        return false;
    }
    const aKeys = Object.keys(a).filter((key) => a.hasOwnProperty(key)),
        bKeys = Object.keys(b).filter((key) => b.hasOwnProperty(key));
    if (aKeys.length !== bKeys.length) {
        return false;
    }

    return aKeys.every((key) => {
        const aValue = a[key],
            bValue = b[key];

        if (Object.is(aValue, bValue)) {
            return true;
        }
        if (!recursive) {
            return false;
        }

        return isSameValue(aValue, bValue, true);
    });
};
