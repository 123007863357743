import {RestQueryParams, marineHubAPI} from 'marine-panel-common-web';
import {Observable} from 'rxjs';
import {IChangeMarinaPolicyAndRule} from '../../store/reducers/marinaEditWizardSlice';

export interface IPolicyAndRulePayloadMarinaInput {
    field: string;
    value: string | null;
}

export function changeMarinaPoliciesAndRulesAPI(payload: IChangeMarinaPolicyAndRule, authToken: string | null): Observable<any> {
    const headers = {
        Authorization: `Bearer ${authToken}`,
    };

    return marineHubAPI.put(
        `api/marinas/${payload.marinaId}/update_policy_and_rules`,
        payload.marinaPolicyAndRuleInput,
        new RestQueryParams(),
        headers
    );
}
