import L, {LatLng, LatLngTuple} from 'leaflet';
import {GeoPoint, IAdminMarinaListItem, Marina} from 'marine-panel-common-web';
import {useEffect, useState} from 'react';
import {FeatureGroup, MapContainer, Marker, TileLayer} from 'react-leaflet';
import {EditControl} from 'react-leaflet-draw';
import {connect} from 'react-redux';
import {RootState} from '../../../../store/reducers';
import {currentlyEditedMarina} from '../../../../store/selectors/marinaEditWizardSelectors';
import {marinasSelector} from '../../../../store/selectors/marinaSelectors';
import {SelectedItemType} from '../../../../store/reducers/marinaEditWizardSlice';
import ChangeMapView from '../../WizardEdit/WizardContent/Marina/Details/MarinaLocation/ChangeMapView';

export interface IChangeBerthLocationPayload {
    berthId: string | null | undefined;
    location: any;
}

interface ILocationInputProps {
    readonly locationItem: SelectedItemType;
    readonly marina: Marina | null;
    readonly marinas: IAdminMarinaListItem[] | null;
    readonly updatedCoords: (payload: IChangeBerthLocationPayload) => void;
    readonly createdCoords: (payload: any) => void;
}
const LocationInput = (props: ILocationInputProps) => {
    const [currentlyEditedMarinaCoords, setCurrentlyEditedMarinaCoords] = useState<LatLngTuple | null>(null),
        [mapCenterLocation, setMapCenterLocation] = useState<LatLngTuple | null>(null);

    useEffect(() => {
        if (props.marina) {
            const marinaLocation = props.marina.location;
            setMapCenterLocation([marinaLocation.latitude, marinaLocation.longitude]);
            setCurrentlyEditedMarinaCoords([marinaLocation.latitude, marinaLocation.longitude]);
        }
    }, [props.marina]);

    const markerIcon = L.icon({
        iconUrl: require('../../../../assets/images/marina-icon.png'),
        iconSize: [16, 16],
        iconAnchor: [16, 16],
    });

    const renderEditWrapper = (editedMarina: Marina | null) => {
        const renderEditedItem = (currentlyEditedMarinaCoords: LatLngTuple | null) => {
            if (currentlyEditedMarinaCoords) {
                return <Marker icon={markerIcon} position={currentlyEditedMarinaCoords} />;
            }
            return null;
        };
        const isCreateMode = !currentlyEditedMarinaCoords;
        const saveEditedCoords = (edited: any) => {
            const layer = edited.layers.getLayers()[0],
                latLngs = layer.getLatLng();

            const coords: GeoPoint = changeLatLngToGeoPoint(latLngs);
            const editedCoords: LatLngTuple = [coords.latitude, coords.longitude];
            setCurrentlyEditedMarinaCoords(editedCoords);
            setMapCenterLocation(editedCoords);

            const payload: IChangeBerthLocationPayload = {
                berthId: editedMarina?.id,
                location: coords,
            };
            props.updatedCoords(payload);
        };
        const saveCreatedCoords = (created: any) => {
            if (created.layerType === 'marker') {
                const layer = created.layer,
                    latLngs = layer.getLatLng();

                const coords: GeoPoint = changeLatLngToGeoPoint(latLngs);
                const editedCoords: LatLngTuple = [coords.latitude, coords.longitude];
                setCurrentlyEditedMarinaCoords(editedCoords);
                setMapCenterLocation(editedCoords);

                props.createdCoords(coords);
            }
        };
        return (
            <FeatureGroup>
                <EditControl
                    position="bottomright"
                    edit={{
                        remove: false,
                    }}
                    onEdited={(edited: any) => saveEditedCoords(edited)}
                    onCreated={(created: any) => saveCreatedCoords(created)}
                    draw={{
                        polygon: false,
                        polyline: false,
                        circle: false,
                        marker: isCreateMode ? {icon: markerIcon} : false,
                        rectangle: false,
                        circlemarker: false,
                    }}
                />
                {renderEditedItem(currentlyEditedMarinaCoords)}
            </FeatureGroup>
        );
    };
    function changeLatLngToGeoPoint(latLngs: LatLng): GeoPoint {
        const latitude = parseFloat(Number(latLngs.lat).toFixed(7)),
            longitude = parseFloat(Number(latLngs.lng).toFixed(7));
        return {latitude: latitude, longitude: longitude};
    }

    return (
        <div className="location-map-wrapper">
            <MapContainer center={[0, 0]} zoom={mapCenterLocation ? 70 : 1} boundsOptions={{padding: [1, 1]}} maxZoom={22}>
                {renderEditWrapper(props.marina)}
                <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" maxNativeZoom={22} maxZoom={22}/>
                <ChangeMapView
                    center={
                        currentlyEditedMarinaCoords
                            ? {lat: currentlyEditedMarinaCoords[0], lng: currentlyEditedMarinaCoords[1]}
                            : {lat: 0, lng: 0}
                    }
                    zoom={currentlyEditedMarinaCoords ? 70 : 1}
                />
            </MapContainer>
        </div>
    );
};

export default connect(
    (state: RootState) => ({
        marina: currentlyEditedMarina(state),
        marinas: marinasSelector(state),
    }),
    {}
)(LocationInput);
