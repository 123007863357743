import React, {Component} from 'react';
import {IVerticalMenuHeaderProps} from "../../../types";
import {X} from 'react-feather';


type Props = IVerticalMenuHeaderProps;

class VerticalMenuHeader extends Component<Props, any> {
    render() {
        return (
            <>
                <div className='navbar-header'>
                    <span className='brand-logo'>
                      <img src={this.props.logoImage} alt='logo' />
                    </span>
                </div>
                <div className='nav-item nav-toggle'>
                    <div className='nav-link modern-nav-toggle cursor-pointer'>
                        <X
                            size={24}
                            data-tour='toggle-icon'
                            color="#000"
                            className='text-primary toggle-icon'
                            onClick={() => this.props.toggleMobileMenu ? this.props.toggleMobileMenu() : null} />
                    </div>
                </div>
            </>
        );
    }
}

export default VerticalMenuHeader;
