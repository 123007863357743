import {marineHubAPI, RestQueryParams} from 'marine-panel-common-web';
import {Observable} from 'rxjs';

export function unbindArticleFromMarinasAPI(authToken: string | null, articleId: string, marinaId: string[]): Observable<any> {
    const headers = {
            Authorization: `Bearer ${authToken}`,
        },
        payload = {
            ids: marinaId,
        };

    return marineHubAPI.put(`api/articles/${articleId}/unbind_from_marinas`, payload, new RestQueryParams(), headers);
}
