import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {LanguageLocale} from '../../constants/locales';
import {IBreadcrumbsItemPath} from '../../types';

export enum ContentWidth {
    BOXED = 'boxed',
    FULL = 'full',
}

export enum RouterTransition {
    FADE_IN = 'fadeIn',
    FADE_IN_LEFT = 'fadeInLeft',
    ZOOM_IN = 'zoomIn',
    NONE = 'none',
}

export interface ISagaState {
    activeLanguage: LanguageLocale;
    breadcrumbs: IBreadcrumbsItemPath[] | [];
    contentWidth: ContentWidth;
    routerTransition: RouterTransition;
    menuCollapsed: boolean;
    menuHidden: boolean;
    showLoader: boolean;
    contrastMode: boolean;
    fontSize: number;
}

export interface IChangeLanguage {
    activeLanguage: LanguageLocale;
}

export interface IChangeContrastMode {
    contrastMode: boolean;
}

export interface IChangeFontSize {
    fontSize: number;
}

export interface IChangeBreadcrumbs {
    breadcrumbs: IBreadcrumbsItemPath[];
}

export interface IChangeShowLoader {
    readonly payload: {
        showLoader: boolean;
    };
}

const initialState: ISagaState = {
    activeLanguage: LanguageLocale.EN,
    breadcrumbs: [],
    contentWidth: ContentWidth.BOXED,
    routerTransition: RouterTransition.FADE_IN,
    menuCollapsed: false,
    menuHidden: false,
    showLoader: false,
    contrastMode: false,
    fontSize: 10,
};

const sagaSlice = createSlice({
    name: 'saga',
    initialState: initialState,
    reducers: {
        changeActiveLanguage: {
            reducer: (state: ISagaState, action: PayloadAction<IChangeLanguage>) => {
                return {
                    activeLanguage: action.payload.activeLanguage,
                    ...state,
                };
            },
            prepare(activeLanguage: LanguageLocale) {
                return {
                    payload: {activeLanguage: activeLanguage},
                };
            },
        },
        changeContrastMode: {
            reducer: (state: ISagaState, action: PayloadAction<IChangeContrastMode>) => {
                return {
                    contrastMode: action.payload.contrastMode,
                    ...state,
                };
            },
            prepare(contrastMode: boolean) {
                return {
                    payload: {contrastMode: contrastMode},
                };
            },
        },
        changeFontSize: {
            reducer: (state: ISagaState, action: PayloadAction<IChangeFontSize>) => {
                return {
                    fontSize: action.payload.fontSize,
                    ...state,
                };
            },
            prepare(fontSize: number) {
                return {
                    payload: {fontSize: fontSize},
                };
            },
        },
        changeBreadcrumbs: {
            reducer: (state: ISagaState, action: PayloadAction<IChangeBreadcrumbs>) => {
                return {
                    ...state,
                    breadcrumbs: action.payload.breadcrumbs,
                };
            },
            prepare(breadcrumbs: IBreadcrumbsItemPath[]) {
                return {
                    payload: {breadcrumbs: breadcrumbs},
                };
            },
        },
        changeContentWidth: {
            reducer: (state: ISagaState, action: PayloadAction<{contentWidth: ContentWidth}>) => {
                return {
                    contentWidth: action.payload.contentWidth,
                    ...state,
                };
            },
            prepare(contentWidth: ContentWidth) {
                return {
                    payload: {contentWidth: contentWidth},
                };
            },
        },
        changeRouterTransition: {
            reducer: (state: ISagaState, action: PayloadAction<{routerTransition: RouterTransition}>) => {
                return {
                    ...state,
                    routerTransition: action.payload.routerTransition,
                };
            },
            prepare(routerTransition: RouterTransition) {
                return {
                    payload: {routerTransition: routerTransition},
                };
            },
        },
        changeMenuCollapsed: {
            reducer: (state: ISagaState, action: PayloadAction<{isMenuCollapsed: boolean}>) => {
                return {
                    ...state,
                    menuCollapsed: action.payload.isMenuCollapsed,
                };
            },
            prepare(isMenuCollapsed: boolean) {
                return {
                    payload: {isMenuCollapsed: isMenuCollapsed},
                };
            },
        },
        changeMenuHidden: {
            reducer: (state: ISagaState, action: PayloadAction<{isMenuHidden: boolean}>) => {
                return {
                    ...state,
                    menuHidden: action.payload.isMenuHidden,
                };
            },
            prepare(isMenuHidden: boolean) {
                return {
                    payload: {isMenuHidden: isMenuHidden},
                };
            },
        },
        changeShowLoader: {
            reducer: (state: ISagaState, action: IChangeShowLoader) => {
                return {
                    ...state,
                    showLoader: action.payload.showLoader,
                };
            },
            prepare(showLoader: boolean) {
                return {
                    payload: {showLoader: showLoader},
                };
            },
        },
    },
});

export const {
    changeActiveLanguage,
    changeBreadcrumbs,
    changeContentWidth,
    changeRouterTransition,
    changeMenuCollapsed,
    changeMenuHidden,
    changeShowLoader,
    changeContrastMode,
    changeFontSize,
} = sagaSlice.actions;

export default sagaSlice.reducer;
