import {GoogleMap, Marker, withGoogleMap, withScriptjs} from 'react-google-maps';
import React from 'react';
import {IMapProps} from '../../types';

type Props = IMapProps;

const Map = withScriptjs(
    withGoogleMap((props: Props) => {
        const marker =
            'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABcAAAAeCAMAAADw+3VaAAABIFBMVEUAAAD/AAD/gID/VVX/VVXbSUnfYGDjVVXoXV3qVVXvUFDxVVXmWVnnVVXoUVHpWVnrWFjsVVXuVVXqVVXrV1fpU1PrUlfsVVXpVVXpUlbrVFTpVFTqVVXqVFTrVVXrVFTrVVXoVFTqVVXrVVXpVVXqU1bpVFTqU1brVVXpVVXpVFTqVFbrVFbpVFbqVFTrU1XqU1XqVFbqVFTrU1XpU1XpVFTqU1XrU1XpVFbqVFbqU1XqVFTqVFXpVFXqVFXqVFXqVFXrVFXqVVXqVFXqVVXrVFXqVFXqVFXqVFXrVFXpVFXqVFXqVFXqVFXqVFXqVFXqVFXqVFXqVFXqVFXqVFXqVFXqVFXqVFXqVFXpVFXqVFXqVFXqVFXqVFXqVFX///+wwJ9YAAAAXnRSTlMAAQIDBgcICQsMEBIUFRYXGhseJCYuMjY5O0BGSElLTE5PV1pdYmprcnV2en2AiIqQkpSWmZqfoqSnqKmtsbm/xcjK0dPU19jd4OHk6Onr7O7v8PHy8/X3+Pn6+/3+S6QWrgAAAAFiS0dEX3PRUS0AAAEQSURBVBgZZcGJIgJhGAXQmxqVECohIZSylTVaqLEmyhKi5r7/Y/j+aaZpcg4GtNT5bavbuN6NYIRn+4mWfnEBtkCFIzrrGAjodOltQvGUOOY7DrFFxSgnprVotknlzgv4Hik6SZiCVSppYI3CSMLiq1HowBlFGUOxPkljDjcUCTjqFCm0KEJwHFNk8EsxCcchxR5aFFE4ShQZ6BRZDGltilWcUDSDsOUojFlsUKn6MLDSpdAB/xuVWgxCy3Wp5AAUaOrXj/KXbZq6MwDmvziuACXPMZ9hKKFXuu1jIE2Xez8sFY7oxWELv9BxAMfyD21XXozYoeVhCi4Fmp4jcJu4oPhYxDhvkXxfwn/e00YcQ399jYClMs+xVwAAAABJRU5ErkJggg==';

        return (
            // @ts-ignore
            <GoogleMap
                defaultZoom={15}
                defaultCenter={props.position}
                defaultOptions={{
                    fullscreenControl: false,
                    zoomControl: false,
                    keyboardShortcuts: false,
                }}>
                {props.isMarkerShown && <Marker position={props.position} icon={{url: marker}} />}
            </GoogleMap>
        );
    })
);

export default Map;
