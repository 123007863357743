import {RestQueryParams, marineHubAPI} from 'marine-panel-common-web';
import {Observable} from 'rxjs';
import {ICreateExtra} from '../../store/reducers/marinaEditWizardSlice';

export function addMarinaExtraAPI(payload: ICreateExtra, authToken: string | null): Observable<any> {
    const headers = {
        Authorization: `Bearer ${authToken}`,
    };

    return marineHubAPI.put(`api/marinas/${payload.marinaId}/add_extra`, payload.payload, new RestQueryParams(), headers);
}
