import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {IAdminMarinaListItem, IModelApiResponseViewObject, isSameValue} from 'marine-panel-common-web';
import {initApiCall} from '../../utils/reduxHelpers';
import {IChangeArticleActionComplete, IChangeArticleActionProcessing} from './articlesPageSlice';

export interface IPaginationParams {
    page: number;
    itemsPerPage: number;
}

export interface IBaseDictionaryState {
    isLoading: boolean;
    isInitialized: boolean;
    error: string | null;
}

export interface IChangeIsLoading {
    isLoading: boolean;
}

export interface IChangeIsInitialized {
    isInitialized: boolean;
}

export interface ISetError {
    error: string | null;
}

export interface IMarinasFilters {
    name: string;
}

export interface IMarinasState extends IBaseDictionaryState {
    marinas: IAdminMarinaListItem[] | null;
    connectedOperators: any;
    isMarinaActionProcessing: boolean;
    isMarinaActionComplete: boolean;
    // articlesMarinas: IAdminMarinaListItem[] | null;
    // operatorsMarinas: IAdminMarinaListItem[] | null;
    filters: IMarinasFilters | null;
    marinasMetadata: IModelApiResponseViewObject | null;
    pagination: IPaginationParams | null;
}

export interface ISetMarinas {
    readonly marinas: IAdminMarinaListItem[] | null;
}

export interface IChangePagePagination {
    pagination: IPaginationParams;
}

export interface ISetPageMetadata {
    metadata: IModelApiResponseViewObject | null;
}

export interface IChangeMarinasFilters {
    filters: IMarinasFilters | null;
}

export interface IChangeMarinaStatus {
    marinaId: string;
    isActive: boolean;
}

export interface IFetchConnectedMarinaOperators {
    readonly marinaId: string;
}

export interface ISetConnectedMarinaOperators {
    readonly connectedOperators: any;
}

export interface IAssignOperatorToMarina {
    readonly operatorId: string;
    readonly marinaId: string;
}

const initialState: IMarinasState = {
    marinas: null,
    connectedOperators: null,
    isMarinaActionComplete: false,
    isMarinaActionProcessing: false,
    // articlesMarinas: null,
    // operatorsMarinas: null,
    filters: null,
    marinasMetadata: null,
    pagination: {
        page: 1,
        itemsPerPage: 10,
    },
    isLoading: false,
    isInitialized: false,
    error: null,
};

const marinasSlice = createSlice({
    name: 'marinas',
    initialState: initialState,
    reducers: {
        changeMarinas: {
            reducer: (state: IMarinasState, action: PayloadAction<ISetMarinas>) => {
                return {
                    ...state,
                    marinas: action.payload.marinas,
                };
            },
            prepare(marinas: IAdminMarinaListItem[] | null) {
                return {
                    payload: {marinas: marinas},
                };
            },
        },
        changeIsMarinasPageLoading: {
            reducer: (state: IMarinasState, action: PayloadAction<IChangeIsLoading>) => {
                return {
                    ...state,
                    isLoading: action.payload.isLoading,
                };
            },
            prepare(isLoading: boolean) {
                return {
                    payload: {isLoading: isLoading},
                };
            },
        },
        changeIsMarinasPageInitialized: {
            reducer: (state: IMarinasState, action: PayloadAction<IChangeIsInitialized>) => {
                return {
                    ...state,
                    isInitialized: action.payload.isInitialized,
                };
            },
            prepare(isInitialized: boolean) {
                return {
                    payload: {isInitialized: isInitialized},
                };
            },
        },
        setMarinasPageError: {
            reducer: (state: IMarinasState, action: PayloadAction<ISetError>) => {
                return {
                    ...state,
                    error: action.payload.error,
                };
            },
            prepare(error: string | null) {
                return {
                    payload: {error: error},
                };
            },
        },
        setMarinasPageMetadata: {
            reducer: (state: IMarinasState, action: PayloadAction<ISetPageMetadata>) => {
                return {
                    ...state,
                    marinasMetadata: action.payload.metadata,
                };
            },
            prepare(metadata: IModelApiResponseViewObject | null) {
                return {
                    payload: {
                        metadata: metadata,
                    },
                };
            },
        },
        changeMarinasPagePagination: {
            reducer: (state: IMarinasState, action: PayloadAction<IChangePagePagination>) => {
                return {
                    ...state,
                    isLoading: true,
                    pagination: action.payload.pagination,
                };
            },
            prepare(pagination: IPaginationParams) {
                return {
                    payload: {pagination: pagination},
                };
            },
        },
        changeMarinasFilters: {
            reducer: (state: IMarinasState, action: PayloadAction<IChangeMarinasFilters>) => {
                if (isSameValue(action.payload.filters, state.filters)) {
                    return {
                        ...state,
                    };
                }

                return {
                    ...state,
                    isLoading: true,
                    filters: action.payload.filters,
                };
            },
            prepare(filters: IMarinasFilters | null) {
                return {
                    payload: {
                        filters: filters ? {name: filters.name} : null,
                    },
                };
            },
        },
        applyMarinasFilters: (state: IMarinasState) => {
            return {
                ...state,
                isLoading: true,
            };
        },
        changeMarinaStatus: {
            reducer: (state: IMarinasState) => {
                return {
                    ...state,
                    isLoading: true,
                };
            },
            prepare(marinaId: string, isActive: boolean) {
                return {
                    payload: {marinaId: marinaId, isActive: isActive},
                };
            },
        },
        fetchConnectedOperators: {
            reducer: (state: IMarinasState) => {
                return {
                    ...state,
                };
            },
            prepare(marinaId: string) {
                return {
                    payload: {marinaId: marinaId},
                };
            },
        },
        setMarinaConnectedOperators: {
            reducer: (state: IMarinasState, action: PayloadAction<ISetConnectedMarinaOperators>) => {
                return {
                    ...state,
                    connectedOperators: action.payload.connectedOperators,
                };
            },
            prepare(connectedOperators: any) {
                return {
                    payload: {connectedOperators: connectedOperators},
                };
            },
        },
        assignOperatorToMarina: {
            reducer: (state: IMarinasState) => {
                return {
                    ...state,
                    isMarinaActionProcessing: true,
                };
            },
            prepare(marinaId: string, operatorId: string) {
                return {
                    payload: {marinaId: marinaId, operatorId: operatorId},
                };
            },
        },
        unassignOperatorFromMarina: {
            reducer: (state: IMarinasState) => {
                return {
                    ...state,
                    isMarinaActionProcessing: true,
                };
            },
            prepare(marinaId: string, operatorId: string) {
                return {
                    payload: {marinaId: marinaId, operatorId: operatorId},
                };
            },
        },
        changeIsMarinaActionComplete: {
            reducer: (state: IMarinasState, action: PayloadAction<IChangeArticleActionComplete>) => {
                return {
                    ...state,
                    isMarinaActionComplete: action.payload.isComplete,
                };
            },
            prepare(isComplete: boolean) {
                return {
                    payload: {isComplete: isComplete},
                };
            },
        },
        changeIsMarinaActionProcessing: {
            reducer: (state: IMarinasState, action: PayloadAction<IChangeArticleActionProcessing>) => {
                return {
                    ...state,
                    isMarinaActionProcessing: action.payload.isProcessing,
                };
            },
            prepare(isProcessing: boolean) {
                return {
                    payload: {isProcessing: isProcessing},
                };
            },
        },
        fetchMarinas: (state: IMarinasState) => initApiCall(state),
        resetToInitialMarinasPageState: () => {
            return {
                ...initialState,
            };
        },
    },
});

export const {
    changeMarinas,
    changeIsMarinasPageLoading,
    changeIsMarinasPageInitialized,
    setMarinasPageError,
    setMarinasPageMetadata,
    changeMarinasPagePagination,
    changeMarinasFilters,
    applyMarinasFilters,
    changeMarinaStatus,
    fetchMarinas,
    fetchConnectedOperators,
    setMarinaConnectedOperators,
    assignOperatorToMarina,
    unassignOperatorFromMarina,
    changeIsMarinaActionProcessing,
    changeIsMarinaActionComplete,
    resetToInitialMarinasPageState,
} = marinasSlice.actions;

export default marinasSlice.reducer;
