import {Form, FormControlChangeType, Translation, Marina, MoneyInput, isNotNullOrUndefined} from 'marine-panel-common-web';
import {FC, useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {ModalBody, ModalFooter, ModalHeader} from 'reactstrap';
import {BehaviorSubject, Subscription} from 'rxjs';
import {filter, share} from 'rxjs/operators';
import {RootState} from '../../../../../../../store/reducers';
import {changeExtra, IChangeExtra} from '../../../../../../../store/reducers/marinaEditWizardSlice';
import {currentlyEditedMarina, isMarinaEditWizardLoadingSelector} from '../../../../../../../store/selectors/marinaEditWizardSelectors';
import {currentlyEditedItemIdSelector} from '../../../../../../../store/selectors/modalSelectors';
import {changeExtraFormConfig} from './changeExtraFormConfig';

interface IChangeExtraModalProps {
    readonly marina: Marina | null;
    readonly extraId: string | null;
    readonly changeExtra: typeof changeExtra;
    readonly toggleModalOpen: () => void;
}

export interface IChangeExtraModalValues {
    price: string | null;
    description: string | null;
    order: number | null;
}
const ChangeExtraModal: FC<IChangeExtraModalProps> = ({changeExtra, marina, extraId, toggleModalOpen}) => {
    function onValueStateChange(controlName: string, value: any, changeType: FormControlChangeType) {
        onValueStateChange$.next({controlName: controlName, value: value, changeType: changeType});
    }
    const [onValueStateChange$] = useState(() => new BehaviorSubject<any>(null)),
        [onValueStateChange$$] = useState(() => onValueStateChange$.pipe(share())),
        [values, setValue] = useState<IChangeExtraModalValues>({
            price: null,
            description: null,
            order: 0,
        }),
        subscriptions: Subscription[] = [];

    useEffect(() => {
        subscriptions.push(
            onValueStateChange$$
                .pipe(
                    filter((data: {controlName?: string; value?: {[name: string]: any}; changeType?: FormControlChangeType}) => {
                        return data && data?.changeType === FormControlChangeType.User;
                    })
                )
                .subscribe((data) => {
                    if (data.controlName) {
                        setValue((prevState: IChangeExtraModalValues) => {
                            return {
                                price: data.value && data.value.price ? data.value.price : prevState?.price,
                                description: data.value && data.value.description ? data.value.description : prevState?.description,
                                order: data.value && data.value.order ? data.value.order : prevState?.order,
                            };
                        });
                    }
                })
        );

        return () => {
            subscriptions.forEach((subscription) => subscription.unsubscribe());
        };
    }, []);

    useEffect(() => {
        if (marina !== null) {
            const extraFromList = marina?.extras?.find((extra) => extra.id === extraId);
            const extraOrder = marina?.extras?.findIndex((extra) => extra.id === extraId);
            if (extraFromList && isNotNullOrUndefined(extraFromList)) {
                setValue({
                    description: extraFromList ? extraFromList.description : null,
                    price: extraFromList?.price ? (Number(extraFromList.price.amount) / 100).toString() : null,
                    order: extraOrder ? extraOrder : null,
                });
            }
        }
    }, [extraId, marina]);

    let payload: IChangeExtra | null = null;
    if (marina && extraId) {
        let price: MoneyInput | null = null;
        if (values.price) {
            price = {
                amount: (Number(values.price) * 100).toString(),
                currency: 'EUR',
            };
        }
        const extraOrder = marina?.extras?.findIndex((extra) => extra.id === extraId);

        payload = {
            marinaId: marina.id,
            payload: {
                extraId: extraId,
                price: price,
                description: values.description,
                order: extraOrder,
            },
        };
    }
    function changeExtraAction(payload: any) {
        if (marina && payload) {
            changeExtra({marinaId: marina.id, payload: payload.payload});
        }
    }
    return (
        <div className="modal-wrapper">
            <ModalHeader className="modal-header" toggle={() => toggleModalOpen()}>
                <p className="main title">
                    <Translation text="modal.changeExtra.title" />
                </p>
            </ModalHeader>
            <ModalBody>
                <div className="form-control offers-form-control modal-form">
                    <Form
                        controlName={'marinaNameForm'}
                        config={changeExtraFormConfig(values)}
                        value={values}
                        onValueStateChange={onValueStateChange}
                    />
                </div>
            </ModalBody>
            <ModalFooter>
                <button className="sm-btn btn btn-underline btn-underline-primary" onClick={() => toggleModalOpen()}>
                    <Translation text="buttons.cancel" />
                </button>
                <button className="sm-btn btn btn-create" onClick={() => changeExtraAction(payload)}>
                    <Translation text="buttons.edit" />
                </button>
            </ModalFooter>
        </div>
    );
};

export default connect(
    (state: RootState) => ({
        marina: currentlyEditedMarina(state),
        extraId: currentlyEditedItemIdSelector(state),
        isLoading: isMarinaEditWizardLoadingSelector(state),
    }),
    {
        changeExtra,
    }
)(ChangeExtraModal);
