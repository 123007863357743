import React from 'react';
import {IQuantityInputProps, IQuantityInputState} from '../../../types';
import {isNotNullOrUndefined} from '../../../utils/runtimeUtils';
import Translation from '../../Translation';
import {InputType} from '../index';


type Props = IQuantityInputProps;
type State = IQuantityInputState;


class QuantityInput extends React.Component<Props, State> {

    render() {
        const minValue = this.props.minValue || 0;

        return (
            <div className={`custom-number-input-wrapper ${this.props.disabled ? 'disabled' : ''} `}>
                <button className="value-handler-button decrement"
                        onClick={(e: any) => this.decrementValue(e)}>
                    <span className="sr-only">
                        <Translation text={'button.srDecrementButton'}/>
                    </span>
                </button>
                <input max={this.props.maxValue}
                       min={minValue}
                       value={this.props.value || minValue}
                       onChange={(e) => this.onChange(e)}
                       name={this.props.name}
                       disabled={this.props.disabled}
                       type={InputType.NUMBER}
                       className={`${this.props.inputStyles} custom-number-input`}/>
                <button className="value-handler-button increment"
                        onClick={(e: any) => this.incrementValue(e)}>
                    <span className="sr-only">
                        <Translation text={'button.srIncrementButton'}/>
                    </span>
                </button>
                {this.props.measurementUnit &&
                <span className="measurement-unit">{this.props.measurementUnit}</span>}
            </div>
        );
    }

    private incrementValue = (e: any) => {
        e.preventDefault();
        if (true === this.props.disabled) {
            return;
        }
        const value = this.value;

        if (this.isSafeNumber(this.props.maxValue) && this.props.maxValue <= value) {
            return;
        }

        const incrementValue = this.props.stepValue ? this.props.stepValue : 1;
        this.props.handleChange(value + incrementValue);
    };

    private decrementValue = (e) => {
        e.preventDefault();
        if (true === this.props.disabled) {
            return;
        }
        let value = this.value;

        if (this.isSafeNumber(this.props.minValue) && this.props.minValue >= value) {
            return;
        }

        const decrementValue = this.props.stepValue ? this.props.stepValue : 1;
        this.props.handleChange(value - decrementValue);
    };

    private onChange = (e: any) => {
        const value = Number(e.target.value);
        if (!this.isSafeNumber(value)) {
            return;
        }
        const converted = Number(e.target.value);

        if (this.isSafeNumber(this.props.maxValue) && this.props.maxValue <= converted) {
            return;
        }
        if (this.isSafeNumber(this.props.minValue) && this.props.minValue >= converted) {
            return;
        }
        this.props.handleChange(converted);
    };

    private get value() {
        if (this.isSafeNumber(this.props.value)) {
            return this.props.value;
        }
        if (this.isSafeNumber(this.props.minValue)) {
            return this.props.minValue;
        }
        if (this.isSafeNumber(this.props.maxValue)) {
            return this.props.maxValue;
        }

        return 0;
    }

    private isSafeNumber(val: any): boolean {
        return isNotNullOrUndefined(val) && !Number.isNaN(val) && Number.isInteger(val);
    }
}

export default QuantityInput;
