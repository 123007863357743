import React, {useEffect, useState} from 'react';
import {Form, FormControlChangeType, IFormConfig, Loader, LoaderType, Translation} from 'marine-panel-common-web';
import {Modal, ModalBody, ModalHeader} from 'reactstrap';
import {filter, share} from 'rxjs/operators';
import {BehaviorSubject, Subscription} from 'rxjs';
import {sendInvitationFormConfig} from './sendInvitationFormConfig';
import {connect} from 'react-redux';
import styles from './styles.module.scss';
import {RootState} from '../../../store/reducers';
import ConfirmOperatorInvitation from './ConfirmOperatorInvitation';
import {changeIsInvitationComplete, inviteOperator} from '../../../store/reducers/operatorsPageSlice';
import {isInvitationCompleteSelector, isInvitationProcessingSelector} from '../../../store/selectors/operatorsPageSelectors';

interface IConnectedInviteOperatorModalProps {
    readonly isInvitationComplete: boolean;
    readonly isInvitationProcessing: boolean;
    readonly inviteOperator: typeof inviteOperator;
    readonly changeIsInvitationComplete: typeof changeIsInvitationComplete;
}

interface IExternalInviteOperatorModalProps {
    isModalOpen: boolean;
    toggleModal: () => void;
}

interface IInviteOperatorModalProps extends IConnectedInviteOperatorModalProps, IExternalInviteOperatorModalProps {}

const InviteOperatorModal: React.FC<IInviteOperatorModalProps> = ({
    isModalOpen,
    toggleModal,
    isInvitationProcessing,
    isInvitationComplete,
    inviteOperator,
    changeIsInvitationComplete,
}) => {
    const [shouldConfirmInvitation, setShouldConfirmInvitation] = useState<boolean>(false),
        [formValues, setFormValues] = useState<{[key: string]: any} | null>(null),
        [formConfig, setFormConfig] = useState<IFormConfig | null>(null),
        [onValueStateChange$] = useState(() => new BehaviorSubject<any>(null)),
        [onValueStateChange$$] = useState(() => onValueStateChange$.pipe(share())),
        subscriptions: Subscription[] = [];

    useEffect(() => {
        setFormConfig(sendInvitationFormConfig(() => toggleModal()));
        subscriptions.push(
            onValueStateChange$$
                .pipe(
                    filter((data: {controlName?: string; value?: {[name: string]: string}; changeType?: FormControlChangeType}) => {
                        return data && data?.changeType === FormControlChangeType.User;
                    })
                )
                .subscribe((data) => {
                    if (data.controlName && data.value) {
                        setFormValues({email: data.value.email});
                    }
                })
        );
        return () => {
            subscriptions.forEach((subscription) => subscription.unsubscribe());
            setShouldConfirmInvitation(false);
            changeIsInvitationComplete(false);
        };
    }, []);

    useEffect(() => {
        if (isInvitationComplete) {
            toggleModal();
        }
    }, [isInvitationComplete]);

    function onValueStateChange(controlName: string, value: any, changeType: FormControlChangeType) {
        onValueStateChange$.next({controlName: controlName, value: value, changeType: changeType});
    }

    return (
        <Modal isOpen={isModalOpen} toggle={() => toggleModal()}>
            <ModalHeader toggle={() => toggleModal()}>
                {shouldConfirmInvitation ? (
                    <Translation text="operators.confirmInvitation.title" />
                ) : (
                    <Translation text="operators.inviteOperatorModal.title" />
                )}
            </ModalHeader>
            <ModalBody>
                {shouldConfirmInvitation ? (
                    <ConfirmOperatorInvitation toggleModalContent={confirmInvitation} sendInvitation={sendInvitation} />
                ) : (
                    <div className={styles.modalContainer}>
                        {formConfig && (
                            <Form
                                config={formConfig}
                                onValueStateChange={onValueStateChange}
                                value={formValues}
                                controlName={'inviteOperatorForm'}
                                submitForm={confirmInvitation}
                            />
                        )}
                    </div>
                )}
            </ModalBody>
            <Loader type={LoaderType.Local} showLoader={isInvitationProcessing} />
        </Modal>
    );

    function confirmInvitation() {
        setShouldConfirmInvitation(!shouldConfirmInvitation);
    }

    function sendInvitation() {
        if (null !== formValues) {
            inviteOperator(formValues.email);
        }
    }
};

export default connect(
    (state: RootState) => ({
        isInvitationComplete: isInvitationCompleteSelector(state),
        isInvitationProcessing: isInvitationProcessingSelector(state),
    }),
    {
        inviteOperator,
        changeIsInvitationComplete,
    }
)(InviteOperatorModal);
