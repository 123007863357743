import React, {useEffect, useState} from 'react';
import {Modal, ModalBody, ModalHeader} from 'reactstrap';
import ConnectedMarinasList from '../../Operators/OperatorMarinasModal/ConnectedMarinasList';
import {IArticle, IArticleConnectedMarina, IArticleFullDetails, Translation} from 'marine-panel-common-web';
import {bindArticleToMarinas, fetchArticleDetails, unbindArticleFromMarinas} from '../../../store/reducers/articlesPageSlice';

interface IConnectedArticleMarinaModalProps {}

interface IExternalArticleMarinasModalProps {
    readonly isModalOpen: boolean;
    readonly toggleModal: (article: IArticle | null) => void;
    readonly selectedArticle: IArticle | null;
    readonly articleDetails: IArticleFullDetails | null;
    readonly fetchArticleDetails: typeof fetchArticleDetails;
    readonly bindArticleToMarinas: typeof bindArticleToMarinas;
    readonly unbindArticleFromMarinas: typeof unbindArticleFromMarinas;
}

interface IArticleMarinasModalProps extends IConnectedArticleMarinaModalProps, IExternalArticleMarinasModalProps {}

const ArticleMarinasModal: React.FC<IArticleMarinasModalProps> = ({
    isModalOpen,
    toggleModal,
    selectedArticle,
    fetchArticleDetails,
    articleDetails,
    bindArticleToMarinas,
    unbindArticleFromMarinas,
}) => {
    const [marinas, setMarinas] = useState<IArticleConnectedMarina[] | null>(null);

    useEffect(() => {
        if (null !== selectedArticle) {
            fetchArticleDetails(selectedArticle.id);
        }
    }, [selectedArticle]);

    useEffect(() => {
        if (null !== articleDetails) {
            setMarinas(articleDetails.marinas);
        } else {
            setMarinas(null);
        }
    }, [articleDetails]);

    return (
        <Modal isOpen={isModalOpen} toggle={() => toggleModal(null)}>
            <ModalHeader toggle={() => toggleModal(null)}>
                <Translation text="operators.addMarinaModal.title" />
            </ModalHeader>
            <ModalBody>
                <ConnectedMarinasList marinas={marinas} bindMarinaToArticle={bindMarina} unbindMarinaFromArticle={unbindMarina} />
            </ModalBody>
        </Modal>
    );

    function bindMarina(marinaId: string[]) {
        if (null === selectedArticle) {
            return;
        }

        bindArticleToMarinas(selectedArticle.id, marinaId);
    }

    function unbindMarina(marinaId: string[]) {
        if (null === selectedArticle) {
            return;
        }

        unbindArticleFromMarinas(selectedArticle.id, marinaId);
        toggleModal(null);
    }
};

export default ArticleMarinasModal;
