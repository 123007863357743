import React, {Component} from 'react';
import NavbarUser from './NavbarUser';
import NavbarNavigaiton from './NavbarNavigation';
import {INavbarProps} from '../../types';

type Props = INavbarProps;

class NavBar extends Component<Props, {}> {
    render() {
        return (
            <nav className="header-navbar">
                <div className="navbar-container d-flex justify-content-between">
                    <div className="bookmark-wrapper d-flex align-items-center">
                        <NavbarNavigaiton navigationMenu={this.props.navigationMenu} isTooltipVisible={false} />
                    </div>
                    <NavbarUser
                        isAuthenticated={this.props.isAuthenticated}
                        account={this.props.account}
                        unauthorizedUserMenu={this.props.unauthorizedUserMenu}
                        userMenu={this.props.userMenu}
                    />
                </div>
            </nav>
        );
    }
}

export default NavBar;
