import React, {useEffect, useState} from 'react';
import {IAdminMarinaListItem, IArticleConnectedMarina, Loader, LoaderType, Translation} from 'marine-panel-common-web';
import {Modal, ModalBody, ModalFooter, ModalHeader, Table} from 'reactstrap';
import {AlertCircle} from 'react-feather';
import useModal from '../../../../utils/modalHook';
import MarinasList from '../MarinasList';
import {changeIsMarinaActionComplete} from '../../../../store/reducers/operatorsPageSlice';

interface IExternalConnectedMarinasListProps {
    readonly marinas: IAdminMarinaListItem[] | IArticleConnectedMarina[] | null;
    readonly bindMarinaToArticle: (marinaId: string[]) => void;
    readonly unbindMarinaFromArticle: (marinaId: string[]) => void;
    readonly isMarinaActionComplete?: boolean;
    readonly isMarinaActionProcessing?: boolean;
    readonly changeIsMarinaActionComplete?: typeof changeIsMarinaActionComplete;
}

interface IConnectedMarinasListProps extends IExternalConnectedMarinasListProps {}

const ConnectedMarinasList: React.FC<IConnectedMarinasListProps> = ({
    marinas,
    bindMarinaToArticle,
    isMarinaActionComplete,
    changeIsMarinaActionComplete,
    unbindMarinaFromArticle,
}) => {
    const [isLoading, setIsLoading] = useState<boolean>(true),
        [selectedMarinaId, setSelectedMarinaId] = useState<string | null>(null),
        [isMarinaListShown, setIsMarinaListShown] = useState<boolean>(false),
        [isDetachMarinaModalShown, toggleDetachMarinaModal] = useModal();

    useEffect(() => {
        if (null !== marinas) {
            setIsLoading(false);
        }
    }, [marinas]);

    useEffect(() => {
        if (isMarinaActionComplete) {
            setIsMarinaListShown(false);

            if (isDetachMarinaModalShown) {
                toggleDetachMarinaModal();
            }
        }
        return () => {
            if (changeIsMarinaActionComplete) {
                changeIsMarinaActionComplete(false);
            }
        };
    }, [isMarinaActionComplete]);

    return (
        <>
            <div className="content-header justify-content-end mb-1">
                <button className="btn btn-create" onClick={() => setIsMarinaListShown(!isMarinaListShown)}>
                    <Translation text="operators.addMarinaModal.addMarina" />
                </button>
            </div>

            {isMarinaListShown ? <MarinasList connectedMarinas={marinas} bindMarina={bindMarinaToArticle} /> : null}
            {renderConnectedMarinas()}
            {renderDetachMarinaModal()}

            <Loader type={LoaderType.Local} showLoader={isLoading} />
        </>
    );

    function renderConnectedMarinas() {
        return (
            <Table responsive className="operators-table">
                <thead>
                    <tr>
                        <th>
                            <Translation text="operators.table.name" />
                        </th>
                        {/*<th>*/}
                        {/*    <Translation text="marinas.table.country" />*/}
                        {/*</th>*/}
                        <th />
                    </tr>
                </thead>
                <tbody>{renderTableRows()}</tbody>
            </Table>
        );
    }

    function renderTableRows() {
        if (null === marinas || !marinas.length) {
            return (
                <tr>
                    <td colSpan={2}>
                        <Translation text="operators.table.noData" />
                    </td>
                </tr>
            );
        }

        return marinas.map((item: IAdminMarinaListItem | IArticleConnectedMarina) => {
            return (
                <tr key={item.id}>
                    <td>{item.name}</td>
                    <td>
                        <button className="btn btn-underline" onClick={() => toggleDetachModal(item.id)}>
                            <Translation text="buttons.detach" />
                        </button>
                    </td>
                </tr>
            );
        });
    }

    function renderDetachMarinaModal() {
        return (
            <Modal isOpen={isDetachMarinaModalShown} toggle={() => toggleDetachModal(null)}>
                <ModalHeader toggle={() => toggleDetachModal(null)}>
                    <Translation text="operators.detachMarinaModal.title" />
                </ModalHeader>
                <ModalBody>
                    <p className="modal-subtitle">
                        <Translation text="operators.detachMarinaModal.subtitle" />
                    </p>
                    <p className="modal-description warning">
                        <span>
                            <AlertCircle size={16} />
                        </span>
                        <Translation text="operators.detachMarinaModal.description" />
                    </p>
                </ModalBody>
                <ModalFooter>
                    <button className="btn btn-underline" onClick={() => toggleDetachModal(null)}>
                        <Translation text="buttons.cancel" />
                    </button>
                    <button className="btn btn-primary" onClick={() => detachMarina(selectedMarinaId)}>
                        <Translation text="buttons.delete" />
                    </button>
                </ModalFooter>
            </Modal>
        );
    }

    function toggleDetachModal(marinaId: string | null) {
        toggleDetachMarinaModal();
        setSelectedMarinaId(marinaId);
    }

    function detachMarina(marinaId: string | null) {
        if (marinaId === null) {
            return;
        }
        let ids = [];
        ids.push(marinaId);
        unbindMarinaFromArticle(ids);
        toggleDetachModal(null);
    }
};

export default ConnectedMarinasList;
