import React from 'react';
import ReactPaginate from 'react-paginate';
import {IPaginationProps, IPaginationState} from '../../types';
import {isSameValue} from '../..';

const queryString = require('query-string');

type Props = IPaginationProps;
type State = IPaginationState;

class Pagination extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            selectedItemsPerPage: 10, // 25,
            selectedPage: 0,
            pageCount: 1,
        };
    }

    componentDidMount() {
        this.setTotalPageCount();
    }

    componentDidUpdate(prevProps: Readonly<Props>): void {
        if (!isSameValue(this.props.listMetadata, prevProps.listMetadata)) {
            this.setTotalPageCount();
        }

        if (!isSameValue(this.props.currentPage, prevProps.currentPage)) {
            this.setState({selectedPage: this.props.currentPage - 1});
        }
    }

    render() {
        return <React.Fragment>{this.renderPagination()}</React.Fragment>;
    }

    private renderPagination = () => {
        const prevBtn =
                'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAASCAYAAABWzo5XAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAACoSURBVHgB7dCxDcIwEAXQfyckRINCR4U8gjdAHoEJgA0ogQWgzAhhEosNGAHBAimhwOZIQeckF9GRL51s6awn3wF9so3PpltvUv0BWiLjEXu5GqlJJ+iLRNiIsE69Iw1yP7pCDWmQTzjV0CC1EEUqq5M4okXS0OO1kB9dpIrZ7rxEQ2qXbWRPYSgjEqzU6naYnzpBGoyboGvuSn4GV40J5PhFzN5b/F/eOlVAs3keErkAAAAASUVORK5CYII=',
            nextBtn =
                'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAASCAYAAABWzo5XAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAACwSURBVHgB5dCxDcIwEAXQuwtIUIDcIzJDGMGTABtAgUQ2oMwGZANGsJDoYQQkFrBoUsVHLOojtungNy7u9E7fAP+X2c4UMfskDTIik5fnq9oYBd9AjG4LDMV0TCYEw0/D+d6sEOjYbd2ejdO20jYJisGoD3ocdM3g1r7mZJSdpL1eyAdxyO+XVTKUl5clcFv7atRVE4/FIPeUz45BfORqrq1CEZ+BeAHdAhqwIciP5wW7qE+ahJWhdwAAAABJRU5ErkJggg==';

        return (
            <nav aria-label="Page navigation" className={`${this.state.pageCount === 0 ? 'hidden' : ''} pagination-container`}>
                <div>
                    <ReactPaginate
                        previousLabel={<img src={prevBtn} alt="Previous pagination button" />}
                        nextLabel={<img src={nextBtn} alt="Next pagination button" />}
                        breakLabel={'...'}
                        breakClassName={'break-me'}
                        pageCount={this.state.pageCount}
                        marginPagesDisplayed={1}
                        pageRangeDisplayed={3}
                        // initialPage={this.state.selectedPage}
                        onPageChange={this.onPageChange}
                        containerClassName={`pagination custom-pagination`}
                        activeClassName="active"
                        previousClassName="prev-link"
                        nextClassName="next-link"
                        disableInitialCallback={true}
                        forcePage={this.state.selectedPage}
                    />
                </div>
            </nav>
        );
    };

    private onPageChange = (data: {[key: string]: any}) => {
        let selectedPage = Number(data.selected);

        this.setState({selectedPage: selectedPage}, () => {
            let localSearchParams = {
                itemsPerPage: this.props.itemsPerPage ? this.props.itemsPerPage : this.state.selectedItemsPerPage,
                page: selectedPage + 1,
            };
            this.props.changePage(localSearchParams);
        });
    };

    private setTotalPageCount = () => {
        if (this.props.listMetadata) {
            const lastItemUrl = this.props.listMetadata['hydra:last'];

            if (lastItemUrl) {
                const str = lastItemUrl.split('?').pop(),
                    searchParams = queryString.parse(str),
                    totalPageNumber = searchParams && searchParams.page ? Number(searchParams.page) : 0;

                this.setState({pageCount: totalPageNumber});
            }
        } else {
            this.setState({pageCount: 0});
        }
    };
}

export default Pagination;
