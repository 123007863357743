import {
    ApiSingleResponseBase,
    DetachStripePaymentMethodInput,
    PaymentAccountOutput,
    PaymentServiceName,
} from "../../types";
import {Observable} from 'rxjs';
import {marineHubAPI} from '../provider/marineHubAPI';
import {RestQueryParams} from '../base/queryParams';

export function deletePaymentMethodAPI(
    paymentAccountId: string,
    paymentMethodInput: DetachStripePaymentMethodInput
): Observable<ApiSingleResponseBase<PaymentAccountOutput>> {
    return marineHubAPI.put(
        `api/payment_accounts/${paymentAccountId}/stripe/detach_payment_method`,
        paymentMethodInput,
        new RestQueryParams()
    );
}
