import {IControlConfigUpdateStrategy, IFormConfig, IFormControlConfig} from '../types';
import {access} from './accessorUtils';
import {isNotNullOrUndefined, isNullOrUndefined} from './runtimeUtils';

export const changeOnOtherFieldChanged = (
    config: IFormConfig,
    fieldPath: string,
    otherFieldPath: string,
    value: any,
    strategy: IControlConfigUpdateStrategy
) => {
    const targetValue = access(value, otherFieldPath);

    if (isNullOrUndefined(targetValue)) {
        return;
    }
    const control = access(config, fieldPath);

    if (isNotNullOrUndefined(control)) {
        strategy.process(control, value, targetValue);
    }
};

export class StaticValueControlConfigUpdateStrategy implements IControlConfigUpdateStrategy {
    constructor(private keys: string[], private value: boolean) {}

    process(control: IFormControlConfig, value: any, targetValue: any): void {
        this.keys.forEach((key) => {
            control[key] = this.value;
        });
    }
}

export class TargetValueControlConfigUpdateStrategy implements IControlConfigUpdateStrategy {
    constructor(private keys: string[]) {}

    process(control: IFormControlConfig, value: any, targetValue: any): void {
        this.keys.forEach((key) => {
            control[key] = targetValue;
        });
    }
}

export class CustomValueControlConfigUpdateStrategy implements IControlConfigUpdateStrategy {
    constructor(private keys: string[], private value: (targetValue: any, value: any) => any) {}

    process(control: IFormControlConfig, value: any, targetValue: any): void {
        this.keys.forEach((key) => {
            control[key] = this.value(targetValue, value);
        });
    }
}
