import {createSelector} from '@reduxjs/toolkit';
import {IArticlesState} from '../reducers/articlesPageSlice';
import {RootState} from '../reducers';

export const selectArticlesSlice = (state: RootState): IArticlesState => {
    return state.articles;
};

export const articlesSelector = createSelector([selectArticlesSlice], (state: IArticlesState) => state.articles);

export const articleDetailsSelector = createSelector([selectArticlesSlice], (state: IArticlesState) => state.articleDetails);

export const isArticlesPageLoadingSelector = createSelector([selectArticlesSlice], (state: IArticlesState) => state.isLoading);

export const articlesPagePaginationSelector = createSelector([selectArticlesSlice], (state: IArticlesState) => state.pagination);

export const articlesPageMetadataSelector = createSelector([selectArticlesSlice], (state: IArticlesState) => state.articlesMetadata);

export const isArticlesPageInitializedSelector = createSelector([selectArticlesSlice], (state: IArticlesState) => state.isInitialized);

export const articlesPageErrorSelector = createSelector([selectArticlesSlice], (state: IArticlesState) => state.error);

export const isArticleActionCompleteSelector = createSelector(
    [selectArticlesSlice],
    (state: IArticlesState) => state.isArticleActionComplete
);

export const isArticleActionProcessingSelector = createSelector(
    [selectArticlesSlice],
    (state: IArticlesState) => state.isArticleActionProcessing
);
