import {IAPIHandler} from './api';
import {QueryParams, RestQueryParams} from './queryParams';
import {Observable} from 'rxjs';

export class DefaultParamsAPIHandlerDecorator<ParamsType extends QueryParams<any> = RestQueryParams> implements IAPIHandler<ParamsType> {
    constructor(private readonly next: IAPIHandler<ParamsType>, private readonly provider: (params?: ParamsType) => ParamsType) {}

    get<T>(path: string, params?: ParamsType, headers?: Object): Observable<T> {
        return this.next.get<T>(path, this.provider(params), headers);
    }

    post<T>(path: string, payload: any, params?: ParamsType, headers?: Object): Observable<T> {
        return this.next.post<T>(path, payload, this.provider(params), headers);
    }

    put<T>(path: string, payload: any, params?: ParamsType, headers?: Object): Observable<T> {
        return this.next.put<T>(path, payload, this.provider(params), headers);
    }

    delete<T>(path: string, params?: ParamsType, headers?: Object): Observable<T> {
        return this.next.delete<T>(path, this.provider(params), headers);
    }
}
