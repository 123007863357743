import {marineHubAPI, RestQueryParams} from 'marine-panel-common-web';
import {Observable} from 'rxjs';
import {IAddArticlePayload} from './addArticle';

export function updateArticleAPI(authToken: string | null, articleId: string, payload: IAddArticlePayload): Observable<any> {
    const headers = {
        Authorization: `Bearer ${authToken}`,
    };

    return marineHubAPI.put(`api/articles/${articleId}`, payload, new RestQueryParams(), headers);
}
