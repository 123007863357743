import {PayloadAction} from '@reduxjs/toolkit';
import jwt_decode from 'jwt-decode';
import {combineEpics, Epic, ofType, StateObservable} from 'redux-observable';
import {of} from 'rxjs';
import {catchError, mergeMap, switchMap} from 'rxjs/operators';
import {getAccountDataAPI} from '../../api/account/getAccountData';
import {loginAPI} from '../../api/auth/loginAPI';
import {AlertType, UserRole} from '../../types';
import {
    mapResponseAccountToOperatorFullInfo,
    mapResponseAccountToSkipperFullInfo,
    setAccountState,
    setAccountStateFailure,
} from '../reducers/accountSlice';
import {addAlert} from '../reducers/alertSlice';
import {setAuthState} from '../reducers/authSlice';
import {getLoginCredentials, IGetLoginCredentials, setLoginFailure} from '../reducers/loginSlice';
import {fetchCountries} from '../reducers/countrySlice';
import {fetchVesselModels} from '../reducers/vesselModelSlice';
import {fetchVesselTypes} from '../reducers/vesselTypeSlice';

const loginStart: Epic = (action$, state$: StateObservable<any>) =>
    action$.pipe(
        ofType(getLoginCredentials.type),
        switchMap((action: PayloadAction<IGetLoginCredentials>): any => {
            return loginAPI(action.payload.username, action.payload.password).pipe(
                switchMap((resp: any) => {
                    const token = resp.token,
                        refresh_token = resp.refresh_token,
                        decoded = jwt_decode(resp.token),
                        userRoles = (decoded as any).roles,
                        accountId = (decoded as any).account_id;
                    if (isRoleMatched(userRoles, action.payload.role) !== undefined) {
                        return getAccountDataAPI(token, accountId).pipe(
                            mergeMap((resp: any) => {
                                const actions: any[] = [
                                        // finishLoginLoading(),
                                        setAuthState((state$ as any).value.login.username, token, refresh_token, true, userRoles),
                                    ],
                                    account = {
                                        skipper: mapResponseAccountToSkipperFullInfo(resp),
                                        operator: mapResponseAccountToOperatorFullInfo(resp),
                                        misc: resp.misc,
                                    };
                                if (isRoleMatched(userRoles, UserRole.SKIPPER)) {
                                    actions.push(setAccountState(account.skipper));
                                } else {
                                    actions.push(setAccountState(account.operator));
                                }

                                actions.push(fetchCountries());
                                actions.push(fetchVesselModels());
                                actions.push(fetchVesselTypes());

                                return of(...actions);
                            }),
                            catchError((error: any) => {
                                return of(
                                    setAccountStateFailure(error.toString()),
                                    setLoginFailure('alerts.noAccessError'),
                                    setAuthState(null, null, null, false, null),
                                    addAlert({
                                        message: error.response ? error.response.message : 'alerts.baseError',
                                        type: AlertType.WARNING,
                                    })
                                );
                            })
                        );
                    } else {
                        return of(
                            setLoginFailure('alerts.noAccessError'),
                            addAlert({
                                message: 'alerts.noAccessError',
                                type: AlertType.WARNING,
                            })
                        );
                    }
                }),

                catchError((error: any) => {
                    let errorMessage = null;
                    if (error && error.response && error.response.message) {
                        errorMessage =
                            error.response.message === 'Invalid credentials.'
                                ? 'alerts.authError'
                                : `Podczas logowania wystąpił błąd: ${error.response.message}. Spróbuj jeszcze raz.`;
                    }
                    return of(
                        setLoginFailure(error.toString()),
                        addAlert({
                            message: errorMessage ? errorMessage : 'alerts.baseError',
                            type: AlertType.WARNING,
                        })
                    );
                })
            );
        })
    );

export function isRoleMatched(roles: string[], substring: string): string | undefined {
    return roles.find((element: string) => {
        if (element.includes(substring)) {
            return true;
        }
    });
}

const loginEpic = combineEpics(loginStart);

export default loginEpic;
