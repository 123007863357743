import {Observable} from 'rxjs';
import {marineHubAPI} from '../provider/marineHubAPI';
import {RestQueryParams} from '../base/queryParams';
import {NotificationMessage} from '../../types';

export function editChatNotification(accessToken: string, payload: NotificationMessage, notificationId: string): Observable<any> {
    return marineHubAPI.put(`notifications/${notificationId}/chat`, payload, new RestQueryParams(), {
        Authorization: `Bearer ${accessToken}`,
    });
}
