import {RestQueryParams, marineHubAPI} from 'marine-panel-common-web';
import {Observable} from 'rxjs';
import {IChangeExtra} from '../../store/reducers/marinaEditWizardSlice';

export function changeMarinaExtraAPI(payload: IChangeExtra, authToken: string | null): Observable<any> {
    const headers = {
        Authorization: `Bearer ${authToken}`,
    };

    return marineHubAPI.put(`api/marinas/${payload.marinaId}/change_extra`, payload.payload, new RestQueryParams(), headers);
}
