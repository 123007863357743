import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {IMultiselectOption, Marina, GeoPoint, MoneyInput} from 'marine-panel-common-web';
import {IChangeMarinaAddressInput} from '../../api/marina/changeMarinaAddress';
import {IChangeMarinaAttractionsInput} from '../../api/marina/changeMarinaAttractions';
import {IChangeMarinaDescriptionInput} from '../../api/marina/changeMarinaDescription';
import {IChangeMarinaEmailInput} from '../../api/marina/changeMarinaEmail';
import {IChangeMarinaNameInput} from '../../api/marina/changeMarinaName';
import {IChangeMarinaPhoneInput} from '../../api/marina/changeMarinaPhone';
import {IPolicyAndRulePayloadMarinaInput} from '../../api/marina/changeMarinaPoliciesAndRules';
import {IChangeMarinaRestaurantsInput} from '../../api/marina/changeMarinaRestaurants';

export enum SelectedItemType {
    GENERAL = 'general',
    SECTOR = 'sector',
    BERTH = 'berth',
    RESERVATION = 'reservation',
    MARINA = 'marina',
}

export interface IMarinaEditWizardState {
    marina: Marina | null;
    tags: IMultiselectOption[] | [];
    sectorList: any[] | [];
    currentlyEditedControlId: string | null;
    isMarinaEditWizardLoading: boolean;
    isMarinaEditWizardInitialized: boolean;
    marinaEditWizardError: string | null;
    isActionSuccessful: boolean;
    isPhotosSectionLoading: boolean;
    isNameSectionLoading: boolean;
}

export interface ICreateMarina {
    name: string;
    restaurants: string | null;
    description: string | null;
    attractions: string | null;
    phone: string | null;
    email: string | null;
    location: GeoPoint;
    marinaTagIds: string[] | null;
}

export interface ICreateExtra {
    marinaId: string;
    payload: {
        name: string;
        price: MoneyInput | null;
        description: string | null;
        order: number | null;
    };
}

export interface IChangeExtra {
    marinaId: string;
    payload: {
        extraId: string;
        price: MoneyInput | null;
        description: string | null;
        order: number | null;
    };
}

export interface IChangeExtraOrderItem {
    extraId: string;
    price: MoneyInput | null;
    description: string | null;
    order: number | null;
}

export interface IChangeExtraOrder {
    marinaId: string;
    payload: IChangeExtraOrderItem[];
}
export interface IChangeMarinaAddress {
    marinaId: string;
    address: IChangeMarinaAddressInput;
}
export interface IChangeMarinaPhone {
    marinaId: string;
    phone: IChangeMarinaPhoneInput;
}

export interface IRemoveExtra {
    marinaId: string;
    payload: {
        extraId: string;
    };
}
export interface IChangeMarinaEmail {
    marinaId: string;
    email: IChangeMarinaEmailInput;
}

export interface IChangeMarinaName {
    marinaId: string;
    name: IChangeMarinaNameInput;
}

export interface IChangeMarinaAttractions {
    marinaId: string;
    attractions: IChangeMarinaAttractionsInput;
}

export interface IChangeMarinaRestaurants {
    marinaId: string;
    restaurants: IChangeMarinaRestaurantsInput;
}

export interface ISetMarinaDetails {
    marina: Marina;
}

export interface ISetMarinaTags {
    tags: IMultiselectOption[];
}

export interface IChangeIsMarinaEditWizardLoading {
    isMarinaEditWizardLoading: boolean;
}

export interface IChangeIsMarinaEditWizardInitialized {
    isMarinaEditWizardInitialized: boolean;
}

export interface IChangeIsPhotosSectionLoading {
    isPhotosSectionLoading: boolean;
}

export interface IChangeCurrentlyEditedControlId {
    currentlyEditedControlId: string | null;
}

export interface IGalleryOrderItem {
    id: string;
    position: number;
}

export interface ISetGalleryOrderPayload {
    marinaId: string | null | undefined;
    photos: IGalleryOrderItem[];
}

export interface IChangeMarinaLocationPayload {
    marinaId: string | null | undefined;
    location: any;
}

export interface IChangeMarinaDescription {
    marinaId: string | null | undefined;
    description: IChangeMarinaDescriptionInput;
}

export interface ISetIsActionSuccessful {
    isActionSuccessful: boolean;
}
export interface IChangeMarinaEditWizardError {
    marinaEditWizardError: string | null;
}

export interface IChangeMarinaPolicyAndRule {
    readonly marinaId: string | null;
    readonly marinaPolicyAndRuleInput: IPolicyAndRulePayloadMarinaInput;
}

const initialState: IMarinaEditWizardState = {
    marina: null,
    tags: [],
    sectorList: [],
    currentlyEditedControlId: null,
    isMarinaEditWizardInitialized: false,
    isMarinaEditWizardLoading: false,
    marinaEditWizardError: null,
    isActionSuccessful: false,
    isPhotosSectionLoading: false,
    isNameSectionLoading: false,
};

const marinaEditWizardSlice = createSlice({
    name: 'marinaEditWizard',
    initialState: initialState,
    reducers: {
        fetchMarinaDetails: {
            reducer: (state: IMarinaEditWizardState) => {
                return {
                    ...state,
                    isMarinaEditWizardLoading: true,
                };
            },
            prepare: (id: string | null | undefined) => {
                return {
                    payload: {
                        id: id,
                    },
                };
            },
        },
        fetchMarinaTags: (state: IMarinaEditWizardState) => {
            return {
                ...state,
                isMarinaEditWizardLoading: true,
            };
        },
        createMarina: {
            reducer: (state: IMarinaEditWizardState) => {
                return {
                    ...state,
                };
            },
            prepare(payload: ICreateMarina) {
                return {
                    payload: payload,
                };
            },
        },
        createExtra: {
            reducer: (state: IMarinaEditWizardState) => {
                return {
                    ...state,
                };
            },
            prepare(payload: ICreateExtra) {
                return {
                    payload: payload,
                };
            },
        },
        changeExtra: {
            reducer: (state: IMarinaEditWizardState) => {
                return {
                    ...state,
                };
            },
            prepare(payload: IChangeExtra) {
                return {
                    payload: payload,
                };
            },
        },
        changeExtraOrder: {
            reducer: (state: IMarinaEditWizardState) => {
                return {
                    ...state,
                    isMarinaEditWizardLoading: true,
                };
            },
            prepare(payload: IChangeExtraOrder) {
                return {
                    payload: payload,
                };
            },
        },
        removeExtra: {
            reducer: (state: IMarinaEditWizardState) => {
                return {
                    ...state,
                };
            },
            prepare(payload: IRemoveExtra) {
                return {
                    payload: payload,
                };
            },
        },
        changeIsBookingEnabled: {
            reducer: (state: IMarinaEditWizardState) => {
                return {
                    ...state,
                    isMarinaEditWizardLoading: true,
                };
            },
            prepare: (marinaId: string | null | undefined, appBookingEnabled: boolean | undefined) => {
                return {
                    payload: {
                        marinaId: marinaId,
                        appBookingEnabled: {appBookingEnabled},
                    },
                };
            },
        },
        changeIsMarinaActive: {
            reducer: (state: IMarinaEditWizardState) => {
                return {
                    ...state,
                    isMarinaEditWizardLoading: true,
                };
            },
            prepare: (marinaId: string | null | undefined, active: boolean | undefined) => {
                return {
                    payload: {
                        marinaId: marinaId,
                        active: active,
                    },
                };
            },
        },

        changeMarinaDescription: {
            reducer: (state: IMarinaEditWizardState) => {
                return {
                    ...state,
                    isMarinaEditWizardLoading: true,
                };
            },
            prepare: (marinaId: string | null | undefined, description: string | null | undefined) => {
                return {
                    payload: {
                        marinaId: marinaId,
                        description: {description},
                    },
                };
            },
        },
        changeMarinaRestaurants: {
            reducer: (state: IMarinaEditWizardState) => {
                return {
                    ...state,
                    isMarinaEditWizardLoading: true,
                };
            },
            prepare: (marinaId: string | null | undefined, restaurants: string | null | undefined) => {
                return {
                    payload: {
                        marinaId: marinaId,
                        restaurants: {restaurants},
                    },
                };
            },
        },
        changeMarinaAttractions: {
            reducer: (state: IMarinaEditWizardState) => {
                return {
                    ...state,
                    isMarinaEditWizardLoading: true,
                };
            },
            prepare: (marinaId: string | null | undefined, attractions: string | null | undefined) => {
                return {
                    payload: {
                        marinaId: marinaId,
                        attractions: {attractions},
                    },
                };
            },
        },

        changeMarinaAddress: {
            reducer: (state: IMarinaEditWizardState) => {
                return {
                    ...state,
                    isMarinaEditWizardLoading: true,
                };
            },
            prepare: (marinaId: string | null | undefined, address: string | null | undefined) => {
                return {
                    payload: {
                        marinaId: marinaId,
                        address: {address},
                    },
                };
            },
        },
        changeMarinaEmail: {
            reducer: (state: IMarinaEditWizardState) => {
                return {
                    ...state,
                    isMarinaEditWizardLoading: true,
                };
            },
            prepare: (marinaId: string | null | undefined, email: string | null | undefined) => {
                return {
                    payload: {
                        marinaId: marinaId,
                        email: {email},
                    },
                };
            },
        },
        changeMarinaPhone: {
            reducer: (state: IMarinaEditWizardState) => {
                return {
                    ...state,
                    isMarinaEditWizardLoading: true,
                };
            },
            prepare: (marinaId: string | null | undefined, phone: string | null | undefined) => {
                return {
                    payload: {
                        marinaId: marinaId,
                        phone: {phone},
                    },
                };
            },
        },
        archiveMarina: {
            reducer: (state: IMarinaEditWizardState) => {
                return {
                    ...state,
                    isMarinaEditWizardLoading: true,
                };
            },
            prepare: (marinaId: string | null | undefined) => {
                return {
                    payload: {
                        marinaId: marinaId,
                    },
                };
            },
        },
        changeMarinaLocation: {
            reducer: (state: IMarinaEditWizardState) => {
                return {
                    ...state,
                    isMarinaEditWizardLoading: true,
                };
            },
            prepare: (payload: IChangeMarinaLocationPayload) => {
                return {
                    payload,
                };
            },
        },
        changeMarinaName: {
            reducer: (state: IMarinaEditWizardState) => {
                return {
                    ...state,
                    isMarinaEditWizardLoading: true,
                };
            },
            prepare: (marinaId: string | null | undefined, name: string | null | undefined) => {
                return {
                    payload: {
                        marinaId: marinaId,
                        name: {name},
                    },
                };
            },
        },
        changeGalleryOrder: {
            reducer: (state: IMarinaEditWizardState) => {
                return {
                    ...state,
                    isPhotosSectionLoading: true,
                };
            },
            prepare: (orderPayload: ISetGalleryOrderPayload | null) => {
                return {
                    payload: {
                        marinaId: orderPayload?.marinaId,
                        photos: orderPayload?.photos,
                    },
                };
            },
        },
        addPhoto: {
            reducer: (state: IMarinaEditWizardState) => {
                return {
                    ...state,
                    isPhotosSectionLoading: true,
                };
            },
            prepare: (id: string) => {
                return {
                    payload: {
                        id: id,
                    },
                };
            },
        },
        setIsActionSuccessful: {
            reducer: (state: IMarinaEditWizardState, action: PayloadAction<ISetIsActionSuccessful>) => {
                return {
                    ...state,
                    isActionSuccessful: action.payload.isActionSuccessful,
                };
            },
            prepare: (isActionSuccessful: boolean) => {
                return {
                    payload: {
                        isActionSuccessful: isActionSuccessful,
                    },
                };
            },
        },
        changeCurrentlyEditedControlId: {
            reducer: (state: IMarinaEditWizardState, action: PayloadAction<IChangeCurrentlyEditedControlId>) => {
                return {
                    ...state,
                    currentlyEditedControlId: action.payload.currentlyEditedControlId,
                };
            },
            prepare: (currentlyEditedControlId: string | null) => {
                return {
                    payload: {
                        currentlyEditedControlId: currentlyEditedControlId,
                    },
                };
            },
        },
        deletePhoto: {
            reducer: (state: IMarinaEditWizardState) => {
                return {
                    ...state,
                    isPhotosSectionLoading: true,
                };
            },
            prepare: (id: string | null, marinaId: string | null | undefined) => {
                return {
                    payload: {
                        id: id,
                        marinaId: marinaId,
                    },
                };
            },
        },
        changeIsPhotosSectionLoading: {
            reducer: (state: IMarinaEditWizardState, action: PayloadAction<IChangeIsPhotosSectionLoading>) => {
                return {
                    ...state,
                    isPhotosSectionLoading: action.payload.isPhotosSectionLoading,
                };
            },
            prepare: (isPhotosSectionLoading: boolean) => {
                return {
                    payload: {
                        isPhotosSectionLoading: isPhotosSectionLoading,
                    },
                };
            },
        },
        setPhotoAsCover: {
            reducer: (state: IMarinaEditWizardState) => {
                return {
                    ...state,
                    isPhotosSectionLoading: true,
                };
            },
            prepare: (id: string | null, marinaId: string | null | undefined) => {
                return {
                    payload: {
                        id: id,
                        marinaId: marinaId,
                    },
                };
            },
        },
        fetchMarinaEditWizard: {
            reducer: (state: IMarinaEditWizardState) => {
                return {
                    ...state,
                    isMarinaEditWizardLoading: true,
                };
            },
            prepare: (id: string | null | undefined) => {
                return {
                    payload: {
                        id: id,
                    },
                };
            },
        },
        changeMarinaEditWizardError: {
            reducer: (state: IMarinaEditWizardState, action: PayloadAction<IChangeMarinaEditWizardError>) => {
                return {
                    ...state,
                    marinaEditWizardError: action.payload.marinaEditWizardError,
                };
            },
            prepare: (marinaEditWizardError: string | null) => {
                return {
                    payload: {
                        marinaEditWizardError: marinaEditWizardError,
                    },
                };
            },
        },
        setMarinaDetails: {
            reducer: (state: IMarinaEditWizardState, action: PayloadAction<ISetMarinaDetails>) => {
                return {
                    ...state,
                    marina: action.payload.marina,
                    isMarinaEditWizardInitialized: true,
                };
            },
            prepare: (marina: any) => {
                return {
                    payload: {
                        marina: marina,
                    },
                };
            },
        },
        setMarinaTags: {
            reducer: (state: IMarinaEditWizardState, action: PayloadAction<ISetMarinaTags>) => {
                return {
                    ...state,
                    tags: action.payload.tags,
                    isMarinaEditWizardInitialized: true,
                };
            },
            prepare: (tags: any) => {
                return {
                    payload: {
                        tags: tags,
                    },
                };
            },
        },
        changeIsMarinaEditWizardLoading: {
            reducer: (state: IMarinaEditWizardState, action: PayloadAction<IChangeIsMarinaEditWizardLoading>) => {
                return {
                    ...state,
                    isMarinaEditWizardLoading: action.payload.isMarinaEditWizardLoading,
                };
            },
            prepare: (isMarinaEditWizardLoading: boolean) => {
                return {
                    payload: {
                        isMarinaEditWizardLoading: isMarinaEditWizardLoading,
                    },
                };
            },
        },
        changeIsMarinaEditWizardInitialized: {
            reducer: (state: IMarinaEditWizardState, action: PayloadAction<IChangeIsMarinaEditWizardInitialized>) => {
                return {
                    ...state,
                    isMarinaEditWizardInitialized: action.payload.isMarinaEditWizardInitialized,
                };
            },
            prepare: (isMarinaEditWizardInitialized: boolean) => {
                return {
                    payload: {
                        isMarinaEditWizardInitialized: isMarinaEditWizardInitialized,
                    },
                };
            },
        },
        changeMarinaPolicyAndRule: {
            reducer: (state: IMarinaEditWizardState) => {
                return {
                    ...state,
                    isMarinaEditWizardLoading: true,
                };
            },
            prepare: (marinaId: string | null, marinaPolicyAndRuleInput: IPolicyAndRulePayloadMarinaInput) => {
                return {
                    payload: {
                        marinaId: marinaId,
                        marinaPolicyAndRuleInput: marinaPolicyAndRuleInput,
                    },
                };
            },
        },
        resetToInitialMarinaEditWizardState: () => {
            return {
                ...initialState,
            };
        },
    },
});

export const {
    fetchMarinaDetails,
    addPhoto,
    changeMarinaName,
    changeMarinaRestaurants,
    changeMarinaEmail,
    changeMarinaPhone,
    changeMarinaAttractions,
    changeMarinaAddress,
    changeIsBookingEnabled,
    changeMarinaDescription,
    fetchMarinaTags,
    setMarinaTags,
    changeIsMarinaActive,
    changeIsMarinaEditWizardInitialized,
    changeIsMarinaEditWizardLoading,
    setMarinaDetails,
    resetToInitialMarinaEditWizardState,
    changeMarinaEditWizardError,
    setPhotoAsCover,
    changeIsPhotosSectionLoading,
    createExtra,
    deletePhoto,
    setIsActionSuccessful,
    changeGalleryOrder,
    changeCurrentlyEditedControlId,
    changeMarinaLocation,
    archiveMarina,
    createMarina,
    changeExtra,
    removeExtra,
    changeMarinaPolicyAndRule,
    changeExtraOrder,
} = marinaEditWizardSlice.actions;

export default marinaEditWizardSlice.reducer;
