import {combineReducers} from 'redux';
import {createBrowserHistory} from 'history';
import {
    accountSlice,
    alertSlice,
    authSlice,
    changePasswordSlice,
    countrySlice,
    initLogout,
    loaderSlice,
    loginSlice,
    sagaSlice,
    vesselTypeSlice,
    vesselModelSlice,
} from 'marine-panel-common-web';
import {createReduxHistoryContext} from 'redux-first-history';
import marinasSlice from './marinasSlice';
import modalSlice from './modalSlice';
import operatorsPageSlice from './operatorsPageSlice';
import articlesPageSlice from './articlesPageSlice';
import skippersPageSlice from './skippersPageSlice';
import vesselModelsPageSlice from './vesselModelsPageSlice';
import vesselTypesPageSlice from './vesselTypesPageSlice';
import boatCertificatesSlice from './boatCertificatesSlice';
import marinaEditWizardSlice from './marinaEditWizardSlice';
import marinaTagsSlice from './marinasTagsSlice';

const {routerReducer} = createReduxHistoryContext({history: createBrowserHistory()});

const appReducer = combineReducers({
    auth: authSlice,
    login: loginSlice,
    account: accountSlice,
    changePassword: changePasswordSlice,
    alert: alertSlice,
    saga: sagaSlice,
    country: countrySlice,
    vesselType: vesselTypeSlice,
    vesselModel: vesselModelSlice,
    marinas: marinasSlice,
    operators: operatorsPageSlice,
    skippers: skippersPageSlice,
    articles: articlesPageSlice,
    router: routerReducer,
    modal: modalSlice,
    loader: loaderSlice,
    vesselModels: vesselModelsPageSlice,
    vesselTypes: vesselTypesPageSlice,
    boatCertificates: boatCertificatesSlice,
    marinaEditWizard: marinaEditWizardSlice,
    marinaTags: marinaTagsSlice,
});

const rootReducer = (state: ReturnType<typeof appReducer> | undefined, action: any) => {
    if (action.type === initLogout.type) {
        return appReducer(undefined, {type: undefined});
    }

    return appReducer(state, action);
};

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
