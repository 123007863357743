import {WithTranslation} from 'react-i18next';
import {FormControlType, InputType, MultiselectMenuPlacement, MultiSelectType} from './components/FormControl';
import {FormButtonDisabledStrategy, FormButtonType} from './components/FormControl/FormButton';
import {addAlert, AlertObject, removeAlert} from './store/reducers/alertSlice';
import {ReactElement, ReactNode} from 'react';
import {LanguageLocale} from './constants/locales';
import {Observable, Subject} from 'rxjs';
import {Moment} from 'moment';
import {MultiValue, SingleValue} from 'react-select';
import {initAuthTokenChange} from './store/reducers/authSlice';

export enum AuthType {
    LOGIN = 'login',
    REGISTER = 'register',
    NEW_PASSWORD = 'new_password',
    CHANGE_PASSWORD = 'change_password',
    RESET_PASSWORD = 'reset_password',
    ACCEPT_INVITATION = 'accept_invitation',
    CONFIRM_REGISTRATION = 'confirm_registration',
}

export enum LoaderType {
    Local = 'local',
    Global = 'global',
}

export enum AlertType {
    INFO = 'info',
    WARNING = 'warning',
    SUCCESS = 'success',
    ERROR = 'error',
}

export enum CustomCardType {
    MODAL_CARD = 'modal-card',
    BASIC = 'basic',
}

export enum FormControlChangeType {
    Init = 'init', // when setting initial value
    Internal = 'internal', // when internal program changes change the value
    User = 'user', // when user (UI) changes the value
}

export enum CurrencyType {
    EURO = 'EUR',
    DOLLAR = 'DOL',
    PLN = 'PLN',
}

export enum Locale {
    LOCALE_EN = 'en_US',
    LOCALE_PL = 'pl_PL',
}

export type AccountLocaleInput = {
    locale: Locale; // locale
};

export interface IBlockerEvent {
    startsAt: Date;
    endsAt: Date;
    isFree: boolean;
}

export enum UserRole {
    USER = 'ROLE_USER',
    SKIPPER = 'ROLE_SKIPPER',
    OWNER = 'ROLE_OWNER',
    OPERATOR_ADMIN = 'ROLE_OPERATOR_ADMIN',
    ADMIN = 'ROLE_ADMIN',
    PUBLIC = 'ROLE_PUBLIC_ACCESS',
    BOATSMAN = 'ROLE_BOATSMAN',
}

export enum AsyncWaterApiStatus {
    DRAFT = 'draft',
    IN_PROGRESS = 'in_progess',
    COMPLETED = 'completed',
    FAILED = 'failed',
}

export type ProfileSettingsModalForm = {
    firstName: string;
    lastName: string;
    mobile: string;
};

export type ChangePasswordModalForm = {
    oldPassword: string;
    newPassword: string;
    retypePassword: string;
};

export type ChangeUserPasswordInput = {
    oldPassword: string;
    newPassword: string;
};

export type LoginApiResponse = {
    token: string | null;
    refresh_token: string | null;
};

export type FullAccount = {
    skipper: SkipperFullInfo;
    operator: OperatorFullInfo;
    misc: string | null;
};

export type DecodedToken = {
    account_id: string;
    exp: number;
    iat: number;
    is_refresh_token: false;
    roles: UserRole[];
    user_id: string;
    username: string;
};

export interface ApiError extends Partial<Error> {
    message: string;
    name?: string;
    response?: {
        xhr: any;
        '@context': string;
        '@type': string;
        'hydra:title': string;
        'hydra:description': string;
        violations: [
            {
                propertyPath: string;
                message: string;
                code: number | null;
            }
        ];
    };
    type?: AlertType;
}

export type AvatarInput = {
    mediaObjectId: string; // id MediaObject
};

export type SkipperFullInfo = {
    id: string;
    skipperId: string;
    account: AccountBasicInfo;
    paymentAccount: PaymentAccount | null;
    activeVessel: Vessel | null;
};

export type Vessel = {
    id: string;
    active: boolean;
    name: string;
    registrationNumber: string;
    length: number;
    width: number;
    draft: number;
    height: number;
    vesselType: VesselType;
    vesselModel: VesselModel;
};

export type VesselType = {
    id: string;
    name: string;
    boatCert: BoatCertificate;
    name_i18n: INameTranslation;
};

export type BoatCertificate = {
    ageYear: number;
    beam: number;
    className: string;
    crewWeight: number;
    division: string;
    draft: number;
    dspl: number;
    dsplSailing: number;
    id: number;
    imsl: number;
    loa: number;
    wss: number;
};

export type CustomVesselInput = {
    name: string;
    registrationNumber: string;
    vesselTypeId: string;
};

export type VesselModel = {
    id: string;
    name: string;
    length: number;
    width: number;
    draft: number;
    height: number;
    name_i18n: INameTranslation;
    vesselTypeId: string;
};

export type OperatorFullInfo = {
    id: string | null;
    user: AccountBasicInfo | null;
    operator: Operator;
    stripeChargesEnabled: boolean;
    misc: string | null;
};

export type AccountBasicInfo = {
    id: string;
    username: string;
    firstName: string;
    lastName: string;
    locale: string;
    avatar: MediaFile | null;
    mobile: string | null;
    misc: string | null;
};

export type CalendarConfigDates = {
    startDate: Moment | string;
    endDate?: Moment | string;
};

export type SearchConfigDates = {
    startDate: string;
    endDate?: string;
};

export enum BerthFilterTypes {
    WATER = 'water',
    ELECTRICITY = 'electricity',
    NO_MURINGS = 'no_murings',
    BUOY = 'buoy',
    ANCHORING = 'anchoring',
    NO_SIDE_TO_SIDE_PASS_THROUGH = 'no_side_to_side_pass_through',
    BERTH_WIFI = 'berth_wifi',
}

export enum MarinaFilterTypes {
    LAUNDRY = 'laundry',
    MARINA_WIFI = 'marina_wifi',
    RESTAURANTS = 'restaurants',
    CREDIT_CARD_PAYMENTS = 'credit_card_payments',
    SHOP = 'shop',
    PETROL_STATION = 'petrol_station',
}

export type MarinaFilters = {
    [key in MarinaFilterTypes]: boolean;
};

export type BerthFilters = {
    [key in BerthFilterTypes]: boolean;
};

export type BerthSearchInput = Partial<
    BerthFilters &
        MarinaFilters & {dist_rad: number; 'berth.sector.marina.id': string; page: number; itemsPerPage: number} & SearchConfigDates
> & {
    bestMatch: string;
    dist_long?: number;
    dist_lat?: number;
    max_length?: number;
    max_width?: number;
    max_height?: number;
    max_draft?: number;
    vessel_type?: string;
};

export type YachtSearchFilters = {
    type: string;
    length: number;
    width: number;
    draft: number;
    height: number;
    yachtName: string;
};

export type PaymentAccount = {
    id: string;
    vendorPaymentAccounts: VendorPaymentAccount[];
};

export type VendorPaymentAccount = {
    id: string;
    paymentMethods: PaymentMethod[];
};
export type PaymentMethod = {
    id: string;
    cardNumber: string | null;
    cardHolderName: string | null;
    expiryDate: string | null;
    cvc: string | null;
};

export type Operator = {
    id: string;
    name: string | null;
    mobile: string | null;
    address: string | null;
    city: string | null;
    country: Country | null;
};

export type Country = {
    id: string;
    name: string;
};

export type AttachStripePaymentMethodInput = {
    vendorPaymentMethodId: string;
};

export type PaymentMethodInput = {
    cardNumber: string;
    cardHolderName: string;
    expiryDate: string;
    cv: string;
};

export type PaymentAccountOutput = {
    id: string; // id PaymentAccount
    vendorPaymentAccounts: VendorPaymentAccount[];
};

export enum PaymentServiceName {
    STRIPE = 'stripe',
}

export type DetachStripePaymentMethodInput = {
    systemPaymentMethodId: string;
};

export enum TimeSlotsType {
    CALENDAR = 'calendar',
    WEEK_CALENDAR = 'week-calendar',
}

export enum TooltipPlacement {
    AUTO = 'auto',
    AUTO_START = 'auto-start',
    AUTO_END = 'auto-end',
    TOP = 'top',
    TOP_START = 'top-start',
    TOP_END = 'top-end',
    RIGHT = 'right',
    RIGHT_START = 'right-start',
    RIGHT_END = 'right-end',
    BOTTOM = 'bottom',
    BOTTOM_START = 'bottom-start',
    BOTTOM_END = 'bottom-end',
    LEFT = 'left',
    LEFT_START = 'left-start',
    LEFT_END = 'left-end',
}

export enum ConsultationStatus {
    FINISHED = 'finished',
    NOT_STARTED = 'not_started',
    STARTED = 'started',
    SCHEDULED = 'scheduled',
    CANCELLED = 'cancelled',
    DRAFT = 'draft',
}

export enum DateRangePickerPlacement {
    TOP = 'top',
    BOTTOM = 'bottom',
    RIGHT = 'right',
    LEFT = 'left',
    BOTTOM_START = 'bottomStart',
    BOTTOM_END = 'bottomEnd',
    TOP_START = 'topStart',
    TOP_END = 'topEnd',
    LEFT_START = 'leftStart',
    RIGHT_START = 'rightStart',
    LEFT_END = 'leftEnd',
    RIGHT_END = 'rightEnd',
    AUTO = 'auto',
    AUTO_VERTICAL = 'autoVertical',
    AUTO_VERTICAL_START = 'autoVerticalStart',
    AUTO_VERTICAL_END = 'autoVerticalEnd',
    AUTO_HORIZONTAL = 'autoHorizontal',
    AUTO_HORIZONTAL_START = 'autoHorizontalStart',
    AUTO_HORIZONTAL_END = 'autoHorizontalEnd',
}

export enum MediaDeviceType {
    AUDIO_INPUT = 'audioinput',
    AUDIO_OUTPUT = 'audiooutput',
    VIDEO_INPUT = 'videoinput',
}

export enum MessageContentType {
    TEXT = 'text',
    IMAGE = 'image',
    FILE = 'file',
    PLACEHOLDER = 'placeholder',
    ERROR = 'error',
}

export enum NodeErrorTypes {
    NOT_AUTHORIZED = 'not_authorized',
    NO_TOKEN = 'no_token',
    ALREADY_ONLINE = 'already_online',
    EXPIRED_TOKEN = 'expired_token',
    WEBSOCKET_ERROR = 'websocket error',
}

export enum ChatType {
    MESSAGE = 'message',
    RESPONSE = 'response',
    // UPDATE = "update"
}

export enum ApexRadialbarType {
    LINE = 'line',
    AREA = 'area',
    BAR = 'bar',
    HISTOGRAM = 'histogram',
    PIE = 'pie',
    DONUT = 'donut',
    RADIAL_BAR = 'radialBar',
    SCATTER = 'scatter',
    BUBBLE = 'bubble',
    HEATMAP = 'heatmap',
    TREEMAP = 'treemap',
    BOX_PLOT = 'boxPlot',
    CANDLESTICK = 'candlestick',
    RADAR = 'radar',
    POLAR_AREA = 'polarArea',
    RANGE_BAR = 'rangeBar',
}

export enum ReactstrapColors {
    PRIMARY = 'primary',
    SECONDARY = 'secondary',
    SUCCESS = 'success',
    DANGER = 'danger',
    WARNING = 'warning',
    INFO = 'info',
    LIGHT = 'light',
    DARK = 'dark',
}

export enum AvatarSize {
    SM = 'sm',
    LG = 'lg',
    XL = 'xl',
}

export enum AvatarStatus {
    ONLINE = 'online',
    OFFLINE = 'offline',
    AWAY = 'away',
    BUSY = 'busy',
}

export enum AvatarColor {
    PRIMARY = 'primary',
    SECONDARY = 'secondary',
    SUCCESS = 'success',
    DANGER = 'danger',
    INFO = 'info',
    WARNING = 'warning',
    DARK = 'dark',
    LIGHT_PRIMARY = 'light-primary',
    LIGHT_SECONDARY = 'light-secondary',
    LIGHT_SUCCESS = 'light-success',
    LIGHT_DANGER = 'light-danger',
    LIGHT_INFO = 'light-info',
    LIGHT_WARNING = 'light-warning',
    LIGHT_DARK = 'light-dark',
}

export type IAvatarProps = {
    img?: any;
    icon?: ReactElement<any>;
    src?: string;
    badgeUp?: boolean;
    content?: string;
    badgeText?: string;
    className?: string;
    imgClassName?: string;
    contentStyles?: {[key: string]: any};
    size?: AvatarSize;
    tag: any;
    status?: AvatarStatus;
    imgHeight?: string | number;
    imgWidth?: string | number;
    badgeColor?: AvatarColor;
    color: AvatarColor;
    initials?: any;
};

export type ISidebarProps = {
    menuCollapsed: boolean;
    currentActiveItem: any;
    skin: string;
    logoImage: string;
    menuData: (ISideMenuGroup | ISideMenuItem | ISideMenuHeader)[][];
    setMenuCollapsed?: (isMenuCollapsed: boolean) => void;
    toggleMobileMenu?: () => void;
    isMenuMobile?: boolean;
};

export type ISideMenuGroup = {
    id: string;
    title: string;
    icon: any;
    navLink?: string;
    children?: ISideMenuItem[];
    badge?: string;
    badgeText?: string;
    navLinkClassname?: string;
};

export type ISideMenuItem = {
    id: string;
    title: string;
    icon: any;
    navLink?: string;
    onClick?: () => void;
    badge?: string;
    badgeText?: string;
    navLinkClassname?: string;
};

export type ISideMenuHeader = {
    header: string;
};

export type IVerticalMenuHeaderProps = {
    setGroupOpen: any;
    logoImage: string;
    toggleMobileMenu?: () => void;
};

export type IVerticalNavMenuGroupProps = {
    item: any;
    groupOpen: any;
    activeItem: any;
    parentItem: any;
    groupActive: any;
    currentActiveGroup: any;
    setGroupOpen: any;
    setActiveItem: any;
    setGroupActive: any;
    setCurrentActiveGroup: any;
    currentActiveItem: any;
};

export type IVerticalNavMenuLinkProps = {
    item: any;
    activeItem: any;
    setActiveItem: any;
    currentActiveItem: any;
};

export type IVerticalNavMenuSectionHeaderProps = {
    item: ISideMenuHeader;
};

export type IVerticalNavMenuItemsProps = {
    items: any;
    activeItem: any;
    setActiveItem: any;
    currentActiveItem: any;
    groupOpen: {[key: string]: any}[] | [];
    parentItem: ISideMenuGroup | null;
    groupActive: {[key: string]: any}[] | [];
    currentActiveGroup: {[key: string]: any}[] | [];
    setGroupOpen: any;
    setGroupActive: any;
    setCurrentActiveGroup: any;
    toggleMobileMenu?: () => void;
};

export type IPrice = {
    readonly amount: string;
    readonly currency: ICurrency;
    config?: any;
};

export type ICurrency = {
    readonly name: string;
};

export type ILoaderProps = {
    showLoader: boolean;
    type?: string;
    showGlobalLoader?: boolean;
    showLocalLoader?: boolean;
    ref?: any;
    color?: ReactstrapColors;
    transparent?: boolean;
};

export type ILoaderState = {
    showLocalLoader: boolean;
};

export type IToastProps = {
    readonly alerts: AlertObject[];
    readonly alertsDisabled: boolean;
    readonly removeAlert: typeof removeAlert;
    readonly alertType?: AlertType;
};

export type IToastState = {
    alerts: AlertObject[];
    alertsDisabled: boolean;
};

export type ITranslationProps = {
    readonly text: string;
    readonly config?: {[key: string]: any};
};

export type IProgressRingProps = {
    radius: number;
    stroke: number;
    progress: number;
};

export type IDetailsProps = {
    children?: any;
    showLocalLoader?: boolean;
};

export type IValidationRuleResult = {
    valid: boolean;
    errorMessages: string[];
};

export type IValidationRule = {
    name: string;
    params: {[key: string]: any};
};

export type IValidationConfig = IValidationRule[];

export type IInputControlOptions = {
    value: any;
    displayValue: string;
};

export type InputDataMapper = (data: any, config?: any) => any;

export type OutputDataMapper = (data: any, previousStateSnapshot: any, controlName: string, changeType: FormControlChangeType) => any;

export type ChangeHandler = (e: any) => void;

export type ButtonClickHandler = (key: string, type: FormButtonType, event: any) => void;

export type MultiselectChangeHandler = (values: SingleValue<IMultiselectOption> | MultiValue<IMultiselectOption[]>, action: any) => void;

export type DataAccessor = (data: any, key: string | number) => any;

export type IFormControls = IFormGroupConfig[] | {[key: string]: IFormGroupConfig | IFormControlConfig};

export type IBaseFormConfig = {
    inputDataMapper?: InputDataMapper; // used for mapping input (props) value to form value
    outputDataMapper?: OutputDataMapper; // used for mapping form value to output value
    validationRules?: IValidationConfig;
};

export type IBaseGroupedFormConfig = {
    class: string;
    dataAccessor?: DataAccessor; // used for retrieving control value from group value
    controls: IFormControls;
} & IBaseFormConfig;

export type IFormGroupConfig = {
    controlType: 'group' | 'fieldset';
    key: string; // used for identifying group in the model
    fieldsetTitle?: string;
    groupLabel?: string;
} & IBaseGroupedFormConfig;

export type IFormControlConfig = {
    controlType: 'control';
    defaultValue?: any;

    formControlType: FormControlType;
    placeholder?: string;
    label?: string;
    richTextLabel?: string;
    isLabelHidden?: boolean;
    hostClass: string;
    type?: InputType;
    isCurrencyInput?: boolean;
    options?: IInputControlOptions[];
    multiselectOptions?: IMultiselectOption[] | (() => IMultiselectOption[]);
    disabled?: boolean;
    additionalStyles?: any;
    renderPreIcon?: () => any;
    checkboxSlots?: boolean;
    isDateFiltered?: boolean;
    isDayDisabled?: boolean;
    readonly?: boolean;

    multiselectType?: MultiSelectType;
    isCurrencySelect?: boolean;
    isComponentCustom?: boolean;
    isCustomLogoOption?: boolean;
    isCustomMultiValueContainer?: boolean;
    isGroupedComponentCustom?: boolean;
    isGroupedLabelTranslated?: boolean;
    openMenuOnClick?: boolean;
    hideValueOnFocus?: boolean;
    isSearchable?: boolean;
    handleMultiselectInputChange?: any;
    menuPlacement?: MultiselectMenuPlacement;
    firstOptionValue?: MultiSelectType;
    handleChange?: MultiselectChangeHandler;
    cols?: number;
    rows?: number;
    maxDate?: string | Date;
    minDate?: string | Date;
    popperPlacement?: any;
    dateFormat?: string;
    minLength?: number;
    maxLength?: number;
    pattern?: string;
    openToDate?: string | Date;
    showMonthDropdown?: boolean;
    showYearDropdown?: boolean;
    inline?: boolean;
    selected?: any;
    onChange?: any;
    selectedDate?: Date | null;
    availableDates?: Date[] | null;
    availableTimeSlots?: {[key: string]: any}[] | null;
    checkboxLabel?: string;
    labelStyles?: string;
    isCheckboxLabelRaw?: boolean;
    isCheckboxReversed?: boolean;
    checked?: boolean;
    stripePublicKey?: any;
    stripeAccount?: string;
    tooltipText?: string;
    currency?: CurrencyType;
    recommendationsDates?: Date[] | null;
    eventDates?: Date[] | null;
    buttonName?: string;
    defaultStyles?: string;
    inputStyles?: string;
    wrapperStyles?: string;
    defaultContainerStyles?: string;
    containerStyles?: string;
    btnText?: string;
    customClickHandler?: ButtonClickHandler;
    buttonType?: FormButtonType;
    buttonDisabled?:
        | boolean
        | FormButtonDisabledStrategy
        | ((mappedOutputValue: any, isFormValid: boolean, isFormTouched: boolean) => void);
    disabledStyles?: string;
    enabledStyles?: string;
    btnInnerStyles?: string;
    btnPreIconStyles?: string;
    btnPostIconStyles?: string;
    stepValue?: number;
    customNumberIndicator?: string;

    onDateChange?: any;
    onMonthChange?: any;
    handleRadioChange?: (e: any) => void;

    daysNumberDisplay?: number;
    startDate?: Date;
    availableConsultationSlots?: {[key: string]: any}[] | null;
    timeSlotsType?: TimeSlotsType;
    slotsNumberDisplayed?: number;

    rangeStep?: number;
    rangeMinValue?: number;
    rangeMaxValue?: number;
    rangeValueUnit?: string;
    autocomplete?: string;
    customIncrementButtons?: boolean;
    step?: number;

    maxValue?: number;
    minValue?: number;

    dateRangePlacement?: any;
    dateRangesConfig?: any[];
    defaultStartValue?: Date;
    defaultEndValue?: Date;

    rangeLabels?: {[key: string]: any};
    labelFormat?: (value: number) => void;
    useWholePathAsName?: boolean;

    onFileChange?: (imageId: string, imageFile: FileType) => void;
    isFileRemovable?: boolean;
    acceptedFileExtension?: string;

    btnHasTooltip?: boolean;
    btnTooltipText?: string;
    measurementUnit?: string;
} & IBaseFormConfig;

export type IGroupedMultiSelectConfig = {
    label: DateRangePickerPlacement;
    options: IMultiselectOption[];
};

export type IMultiselectOption = {
    value: string;
    label: string;
};

export type IGroupedMultiselectOption = {
    label: string;
    options: IMultiselectOption[] | [];
};

export type IBaseFormControlProps = {
    config: IFormConfig | IFormGroupConfig | IFormControlConfig;

    controlName: string;
    value: any;
    key?: string;
    formControlName?: string;

    onValidationStateChange?: (controlName: string, isValid: boolean, errorMessages: ReadonlyArray<string>) => void;
    onTouchedStateChange?: (controlName: string, touched: boolean) => void;
    onValueStateChange?: (controlName: string, value: any, changeType: FormControlChangeType) => void;
    onButtonClicked?: ButtonClickHandler;
};

export type IFormConfig = {
    controlType: 'form' | 'fieldset';
    fieldsetTitle?: string;
} & IBaseGroupedFormConfig;

export type IBaseGroupedFormControlProps = {
    config: IFormConfig | IFormGroupConfig;
} & IBaseFormControlProps;

export type IBaseGroupedFormControlState = {
    childValidationState: {[key: string]: boolean};
    mappedOutputValue: {[key: string]: any}; // output
} & IBaseFormControlState;

export type IBaseInternalFormControlProps = {
    handleChange: ChangeHandler;
    handleMultiselectChange?: MultiselectChangeHandler;
    submitTouched: boolean;
    onButtonClicked?: ButtonClickHandler;
    formId: string;
    controlPath: string;
};

export type IBaseFormControlState = {
    valid: boolean;
    touched: boolean;
    value: any; // input
    errorMessages: string[];
};

export type IFormProps = {
    config: IFormConfig;
    submitForm?: (event: any, mappedOutputValue: any, isFormValid: boolean, isFormTouched: boolean) => void;
} & IBaseGroupedFormControlProps;

export type IFormState = {
    submitted: boolean;
    formId: string;
} & IBaseGroupedFormControlState;

export type IFormControlsGroupProps = {
    config: IFormGroupConfig;
} & IBaseGroupedFormControlProps &
    IBaseInternalFormControlProps;

export type IFormControlsGroupState = {} & IBaseGroupedFormControlState;

export type IFormControlProps = {
    config: IFormControlConfig;
    controlName: string;
} & IBaseFormControlProps &
    IBaseInternalFormControlProps &
    WithTranslation;

export type IFormControlState = {} & IBaseFormControlState;

export type ICalendarProps = {
    onChange: any;
    selectedDate: any;
    availableDates: Date[] | null;
    availableTimeSlots: {[key: string]: any}[] | null;
    placeholder: string;
    checkboxSlots: boolean;
    isDateFiltered?: boolean;
    isDayDisabled?: boolean;
    isLabelHidden?: boolean;
    maxDate?: string | Date;
};

export type IDatePickerInputProps = {
    value: string;
    name: string;
    id: string;
    placeholderText: string;
    disabledKeyboardNavigation: boolean;
    handleChange: (e: any) => void;
    inputStyles?: string;
    disabled?: boolean;
    maxDate?: string | Date;
    minDate?: string | Date;
    openToDate?: string | Date;
    minLength?: number;
    maxLength?: number;
    pattern?: string;
    onChange: (e: any) => void;
    onChangeRaw: (e: any) => void;
    onSelect: (e: any) => void;
    showMonthDropdown?: boolean;
    showYearDropdown?: boolean;
    inline?: boolean;
    dateFormat?: string;
    popperPlacement?: any;
};

export type IDateRangePickerProps = {
    onChange: any;
    name: string;
    value?: any;
    placeholder?: string;
    placement?: DateRangePickerPlacement;
    ranges?: any[];
    defaultStartValue?: Date;
    defaultEndValue?: Date;
    defaultValue?: any;
    renderValue?: any;
    allowedMaxDays?: number;
} & WithTranslation;

export type IDateRangePickerState = {
    defaultDateValue: any;
};

export type ICalendarState = {
    availableDates: Date[] | null;
    selectedDay: Date | null;
};

export type ICalendarTimeSlotsProps = {
    onChange: any;
    selectedDate: any;
    availableTimeSlots: {[key: string]: any}[] | null;
    placeholder: string;
    checkboxSlots: boolean;
    isLabelHidden?: boolean;
    expandedSlotsShown?: boolean;
    timeSlotsType?: TimeSlotsType;
    slotsNumberDisplayed?: number;
};

export type ICalendarTimeSlotsState = {};

export type ICheckboxProps = {
    name: string;
    handleChange: ChangeHandler;
    inputStyles?: string;
    labelStyles?: string;
    checked: boolean;
    value?: any;
    disabled?: boolean;
    label?: string;
    isLabelRaw?: boolean;
    isLabelTranslated?: boolean;
    additionalStyles?: any;
    isCheckboxReversed?: boolean;
};

export type ICheckboxState = {
    checked: boolean;
};

export type IEventCalendarProps = {
    onChange: any;
    selectedDate: Date | null;
    eventDates: any[] | null;
    placeholder: string;
    minDate?: string | Date;
    maxDate?: string | Date;
};

export type IEventCalendarState = {
    availableDates: Date[] | null;
    eventDates: any[] | null;
    selectedDate: Date | null;
    selectedDay: Date | null;
};

export type IFormButtonProps = {
    name: string;
    btnText: string;
    buttonType?: FormButtonType;
    defaultInputStyles?: string;
    inputStyles?: string;
    defaultContainerStyles?: string;
    containerStyles?: string;
    disabled?: boolean | FormButtonDisabledStrategy | ((mappedOutputValue: any, isFormValid: boolean, isFormTouched: boolean) => void);
    disabledStyles?: string;
    enabledStyles?: string;
    onButtonClicked?: ButtonClickHandler;
    customClickHandler?: ButtonClickHandler;
    innerStyles?: string;
    preIconStyles?: string;
    postIconStyles?: string;
    btnHasTooltip?: boolean;
    btnTooltipText?: string;
    formId: string;
};

export type IFormButtonState = {
    mappedOutputValue: any;
    isFormValid: boolean;
    isFormTouched: boolean;
    disabled?: boolean;
};

export type IInputProps = {
    value: any;
    name: string;
    type: InputType;
    placeholder: string;
    handleChange: ChangeHandler;
    inputStyles?: string;
    disabled?: boolean;
    maxDate?: string | Date;
    minDate?: string | Date;
    minLength?: number;
    maxLength?: number;
    pattern?: string;
    step?: number;
    readonly?: boolean;
    currency?: CurrencyType;
    isCurrencyInput?: boolean;
    autocomplete?: string;
    customIncrementButtons?: boolean;
    renderPreIcon?: () => any;
    customNumberIndicator?: string;
};
export type IMultiSelectProps = {
    multiselectType: MultiSelectType;
    handleChange?: MultiselectChangeHandler;
    value?: IMultiselectOption[] | {[key: string]: any};
    options: IMultiselectOption[] | IGroupedMultiSelectConfig[] | (() => IMultiselectOption[] | IGroupedMultiSelectConfig[]);
    name: string;
    isClearable?: boolean;
    isDisabled?: boolean;
    placeholder?: string;
    groupedOptions?: IGroupedMultiSelectConfig[];
    menuPlacement?: MultiselectMenuPlacement;
    isCurrencySelect?: boolean;
    control?: IFormControlConfig;
    isComponentCustom?: boolean;
    isCustomLogoOption?: boolean;
    isCustomMultiValueContainer?: boolean;
    isGroupedComponentCustom?: boolean;
    openMenuOnClick?: boolean;
    hideValueOnFocus?: boolean;
    handleInputChange?: any;
    isGroupedLabelTranslated?: boolean;
    isSearchable?: boolean;
};

export type IMultiSelectState = {
    value: string | null;
    valueHidden: boolean;
};

export type IRadioProps = {
    value: any;
    name: string;
    placeholder: string;
    handleChange: ChangeHandler;
    options: any[];
    inputStyles?: string;
    wrapperStyles?: string;
    disabled?: boolean;
};

export type ISelectProps = {
    value: any;
    name: string;
    placeholder: string;
    handleChange: ChangeHandler;
    options: any[] | (() => any[]);
    inputStyles?: string;
    disabled?: boolean;
    firstOptionValue?: string;
};

export type ISwitchProps = {
    name: string;
    handleChange: ChangeHandler;
    inputStyles?: string;
    checked: boolean | undefined;
    disabled?: boolean;
};

export type ISwitchState = {
    isChecked: boolean | undefined;
};

export type ITextareaProps = {
    name: string;
    value: string;
    placeholder: string;
    handleChange: ChangeHandler;
    inputStyles: string;
    cols?: number;
    rows?: number;
    minLength?: number;
    maxLength?: number;
    disabled: boolean;
};

export type IHeaderItemProps = {
    left: number;
    width: number;
    label: string | number;
    customStyle?: string;
};

export type IHeaderProps = {
    dayWidth: any;
    nowPosition: any;
    currentDay: any;
    numVisibleDays: any;
    mode: string;
    scrollLeft: any;
    headerData: any;
};

export type ICreateLinkProps = {
    start: {[key: string]: any};
    onFinishCreateLink: any;
};

export type ICreateLinkState = {
    x: number;
    y: number;
};

export type ILinkProps = {
    start: {[key: string]: any};
    end: {[key: string]: any};
    onSelectItem?: any;
    item?: any;
    isSelected?: boolean;
};

export type ILinkViewPortProps = {
    selectedItem: any;
    onSelectItem: any;
    dayWidth: any;
    itemHeight: any;
    interactiveMode: any;
    taskToCreate: any;
    onFinishCreateLink: any;
    changingTask: any;
    scrollLeft: any;
    nowPosition: any;
    scrollTop: any;
    startRow: any;
    endRow: any;
    data: any;
    links: any;
};

export type ILinkViewPortState = {
    links: any[];
    data: any[];
    selectedItem: any;
    dayWidth: any;
    changingTask: any;
};

export type ITaskRowProps = {
    onUpdateTask: any;
    item: any;
    top: number;
    itemHeight: number;
    index: number;
    label: string;
    onSelectItem: any;
    isSelected: any;
};

export type IScheduleItem = {
    from: string;
    to: string;
    icon: string;
    name: string;
    id: string;
};

export type ISchedules = {
    scheduleList: IScheduleItem[];
    schoolName: string;
    id: string;
};

export type ITaskListProps = {
    itemHeight: number;
    startRow: number;
    endRow: number;
    selectedItem: any;
    onUpdateTask: any;
    onSelectItem: any;
    onScroll: any;
    data: ISchedules[] | [];
};

export type IDataTaskProps = {
    left: number;
    width: number;
    top: number;
    onChildDrag: any;
    onStartCreateLink: any;
    onFinishCreateLink: any;
    item: IScheduleItem;
    nowPosition: any;
    onTaskChanging: any;
    dayWidth: any;
    onUpdateTask: any;
    isSelected: boolean;
    color: string;
    height: number;
    onSelectItem: any;
    label: string;
    iconBackgroundColor?: string;
};

export type IDataTaskState = {
    dragging: boolean;
    left: number;
    width: number;
    mode: number;
};

export type IDataRowProps = {
    top: number;
    itemHeight: number;
    children: any;
    label: string;
    left: number;
};

export type IDataViewPortProps = {
    ref: any;
    scrollLeft: any;
    scrollTop: any;
    onScroll: any;
    boundaries: any;
    itemHeight: number;
    startRow: number;
    endRow: number;
    data: any;
    nowPosition: any;
    dayWidth: any;
    selectedItem: any;
    onSelectItem: any;
    onStartCreateLink;
    onFinishCreateLink: any;
    onTaskChanging: any;
    onUpdateTask: any;
    onMouseDown: any;
    onMouseMove: any;
    onTouchStart: any;
    onTouchMove: any;
    onMouseUp: any;
    onMouseLeave: any;
    onTouchEnd: any;
    onTouchCancel: any;
};

export type ITimelineItem = {
    title: string;
    content: string;
    color: string;
    className?: string;
    isTimelineComplete?: boolean;
    timelineLink?: string;
    meta?: string;
    metaIcon?: boolean;
    metaClassName?: string;
    customContent?: React.ReactElement;
    icon?: React.ReactElement;
    activeTab?: number;
    linkClassName?: string;
};

export type ITimelineProps = {
    data: ITimelineItem[];
    className?: string;
} & WithTranslation;

export type IBreadcrumbsItemPath = {
    labelKey: string;
    path: string;
    icon?: string;
    isLinkExternal?: boolean;
    isItemActive?: boolean;
};

export type IBreadcrumbsProps = {
    breadcrumbs: IBreadcrumbsItemPath[];
};

export interface IBlockerEvent {
    startsAt: Date;
    endsAt: Date;
    isFree: boolean;
}

export type IWeekCalendarProps = {
    daysNumberDisplay: number;
    startDate?: Date; // string | Date;
    onChange: any;
    availableConsultationSlots: {[key: string]: any} | null;
    selectedDate?: Date;
    placeholder?: string;
    checkboxSlots?: any;
    isLabelHidden?: boolean;
    timeSlotsType?: TimeSlotsType;
    slotsNumberDisplayed?: number;
};

export type IWeekCalendarState = {
    value: any;
    isLoading: boolean;
    calendarStartDate: Date;
    selectedDatesList: Date[] | null;
    selectedDate: Date | null;
    availableDates: any | null;
    timeSlots: {[key: string]: any}[] | null;
    expandedSlotsShown: boolean;
};

export type IPaginationProps = {
    listMetadata: {[key: string]: any} | null;
    changePage: any;
    itemsPerPage?: number;
    currentPage?: number;
};

export type IPaginationState = {
    selectedItemsPerPage: number;
    selectedPage: number;
    pageCount: number;
};

export type IRangeControlProps = {
    step: number;
    minValue: number;
    maxValue: number;
    valueUnit: string;
    onChange: any;
    label: string;
    value: any;
    defaultValue?: number[];
};

export type IRangeControlState = {
    values: number[];
};

export type IRangePickerProps = {
    labels: {[key: string]: any};
    onChange: any;
    defaultValue?: number[];
    labelFormat?: (value: number) => void;
    disabled?: boolean;
};

export type IRangePickerState = {
    value: string[] | null;
};

export type IQuantityInputProps = {
    name: string;
    handleChange: any;
    value?: number;
    maxValue?: number;
    minValue?: number;
    inputStyles?: string;
    disabled?: boolean;
    stepValue?: number;
    measurementUnit?: string;
};

export type ITooltipProps = {
    target: string;
    tooltipText: string;
    placement?: TooltipPlacement;
    innerClassName?: string;
};

export type ITooltipState = {
    isOpen: boolean;
};

export type IQuantityInputState = {};

export type LegalRestQueryParamValue = string | number | boolean | null;

export type IRawRestQueryParams = {path: string; val: LegalRestQueryParamValue}[];

export interface IControlConfigUpdateStrategy {
    process(control: IFormControlConfig, value: any, targetValue: any): void;
}

export type IPriceProps = {
    readonly price?: IPrice | null;
    readonly amountComponent?: (amount: number) => ReactNode;
    readonly currencyComponent?: (code: string) => ReactNode;
    readonly separateWithNbsp?: boolean;
};

export type IPriceState = {};

export type IItemDescriptionProps = {
    description: string | null;
};

export type IItemDescriptionState = {
    isDescriptionShortened: boolean;
};

export type FileType = {
    name?: string;
    fileKey?: number | string;
    blobFile?: File;
    status?: 'inited' | 'uploading' | 'error' | 'finished';
    progress?: number;
    url?: string;
};

export type IFileUploaderProps = {
    readonly authToken: string;
    readonly addAlert: typeof addAlert;
    readonly onImageChange: (imageId: string, imageFile: FileType) => void;
    readonly label?: string;
    readonly isFileRemovable?: boolean;
    readonly onFileChange?: (file: FileType[]) => void;
    readonly onFileUpload?: (file: any) => void;
    readonly isDragDropHidden?: boolean;
    readonly onUpload?: () => void;
    readonly acceptedFileExtension?: string;
};

export type IFileUploaderState = {
    readonly value: any[];
    readonly isProcessing: boolean;
    readonly inputFileUploaded: boolean;
    readonly progressValue: number | null;
};

export type IDateComponentProps = {
    date: string | number | Date | moment.Moment;
    activeLanguage: LanguageLocale;
    interval?: number;
    locale?: string;
    format?: string;
};

export type WithLocation = {
    location: {
        hash: string;
        key: string;
        pathname: string;
        search: string;
        state: {[key: string]: any} | null;
    };
};

export type WithNavigate = {
    navigate: (to: string, options?: {replace?: boolean; state?: any}) => void;
};

export type WithParams = {
    params: {[key: string]: any};
};

export type IDeviceSelectProps = {
    data: any;
    onSelect: any;
    label: string;
    isDisabled?: boolean;
} & WithTranslation;

export type IDeviceSelectState = {
    items: any[];
    value: {[key: string]: any} | null;
};

export type IProgressBarProps = {
    completed: any;
};

export type IVideoSettingsProps = {
    changeAudioInputDevice: (id: string) => void;
    changeAudioOutputDevice: (id: string) => void;
    changeVideoInputDevice: (id: string) => void;
    isModalVisible: boolean;
    toggleModal: () => void;
};

export type IVideoSettingsState = {
    cameraDevices: any[];
    speakerDevices: any[];
    microphoneDevices: any[];
    selectedAudioInputId: string | null;
    selectedAudioOutputId: string | null;
    selectedVideoInputId: string | null;
    microphoneAudioLevel: number | undefined;
};

export type IBasicModalProps = {
    isModalShown: boolean;
    closeModal: any;
    children?: any;
    isModalLocal?: boolean;
    isModalCustom?: boolean;
    wrapperInlineStyles?: React.CSSProperties;
};

export type IAgoraVideoCallProps = {
    userSecret: string | null;
    consultation: {[key: string]: any} | null;
    changeScreenMode: (isFullScreen: boolean) => void;
    changeOnlineConsultationStatus: (status: ConsultationStatus) => void;
    audioInputDevice: string | null;
    audioOutputDevice: string | null;
    videoInputDevice: string | null;
    appId: string;
    transcode: string;
    attendeeMode: string;
    attendeeRole: UserRole | null;
    videoProfile: string;
    baseMode: string;
    consultationStartTime: string;
    channel: string | null;
    uid: string | null;
    token: string | null;
};

export type IAgoraVideoCallState = {
    displayMode: string;
    streamList: any[];
    readyState: boolean;
    isFullScreen: boolean;
    isConsultationFinished: boolean;
    isMicEnabled: boolean;
    isCameraEnabled: boolean;
    windowWidth: number;
    localStreamId: string | null;
    isFullScreenMode: boolean;
    isSettingsModalShown: boolean;
};

export type FileStreamType = {
    file?: string;
    done?: boolean;
    fileName?: string;
    fullFile?: File;
};

//chat types
export class ChatMessage {
    from: string;
    to: string;
    date: Date;
    messageType: MessageContentType;
    messageContent?: string;
    messageMedia?: any;
    messageId?: string;
    messagePreview?: string;
    constructor({from, to, messageType, messageContent, messageId}) {
        this.from = from || '';
        this.to = to || '';
        this.messageType = messageType || '';
        this.messageContent = messageContent || '';
        this.date = new Date();
        this.messageId = messageId;
    }
}

export type ChatServerMessage = {
    '@context': string;
    '@id': string;
    '@type': string;
    id: string;
    fromAccountId: string;
    toAccountId: string;
    content: string;
    sentAt: string;
    receivedAt: string;
    mediaObject: {
        '@context': string;
        '@id': string;
        '@type': string;
        id: string;
        fileUrls: {
            largeThumb: string;
            mediumThumb: string;
            original: string;
            smallThumb: string;
            tinyThumb: string;
        } | null;
        contentUrl: string;
    };
};

export type PeerConnection = {
    biggestPageNumber: number;
    peerIsOnline: boolean;
    connection: RTCPeerConnection | null;
    channelId: string;
    messagesBuffer: ([ChatMessage, string] | string)[];
    messages: ChatMessage[];
    unseenMessages: number;
    channel: RTCDataChannel | null;
    peerIsPolite: boolean;
    makingOffer: boolean;
    connectionAttemptNumber: number;
    totalMessagesNumber: number;
};

export type ChatServerMessagePayload = {
    toAccountId: string;
    mediaObjectId: string | null;
    content: string;
};

export type AllUserConnections = {
    [roomId: string]: PeerConnection;
};

export type ChatContact = {
    roomId: string;
    accountId: string;
    firstName: string;
    lastName: string;
    unreadMessagesCount: number;
    berthName: string;
    berthId: string;
    marinaName: string;
    marinaId: string;
    avatarUrl?: string;
};

export type Notification = {
    content: string;
    reminderId: string;
    disabled: boolean;
    sendAtDateTime: string;
};

export type NotificationMessage = {
    repeat: boolean;
    targets: NotificationMessageTarget[];
    owners: string[];
};

export type NotificationMessageTarget = {
    account: string;
    sendAtDateTime: string;
    content: {
        fromUserId: string;
        rawMessage: string;
    };
};

// export type ChatServerMessage = {
//     '@id': string;
//     '@type': "Message";
//     content: string;
//     from: {
//         '@id': string;
//         '@type': string;
//         id: string;
//         login: string;
//         registrationConfirmed: boolean;
//         resetPasswordComponentUrl: null;
//     }
//     id: string;
//     mediaObject: {
//         '@id': string;
//         ' @type': string;
//         original: string;
//         contentUrl: string;
//         contentUrlMappedByMode: {
//             stream: string;
//             view: string;
//         };
//         id: string;
//         owner: any;
//         public: boolean;
//     };
//     receivedAt: null;
//     sentAt: string;
//     to: {
//         '@id': string;
//         '@type': string;
//         id: string;
//         login: string;
//         registrationConfirmed: boolean;
//         resetPasswordComponentUrl: null;
//     }
// }

export type ToggleNotificationPayload = {
    notificationsToSwitch: [
        {
            disabled: boolean;
            disabledUntil?: string;
            notificationId: string;
        }
    ];
};

export type ChatServerMessageConfirmPayload = {
    messageId: string;
    receivedAt: string;
};

export type SignalAnswer = {
    toRoom: string;
    answer: RTCSessionDescription;
};

export type SignalOffer = {
    toRoom: string;
    offer: RTCSessionDescription;
};

export type SignalCandidate = {
    toRoom?: string;
    candidate: RTCIceCandidate;
};

export type IChatProps = {
    peerConnections: AllUserConnections;
    addMessage: (message: ChatMessage | string, room: string) => void;
    setSelectedChatRoomId: (roomId: string) => void;
    selectedChatRoomId: string;
    isConnected: boolean;
    userName: string;
    userId: string;
    accountId: string;
    hasUnreadMessages: string[];
    showOnlineIndicator?: boolean;
    chatContactsList?: ChatContact[];
    errors: Subject<Error>;

    errorHandler?: (errorMessage: string) => any;
    saveMessage?: (message: ChatServerMessagePayload) => Observable<any>;
    getNotification?: (accountId: string) => Observable<Notification>;
    saveNotification?: (notification: NotificationMessage) => Observable<any>;
    toggleNotification?: (payload: ToggleNotificationPayload) => Observable<any>;
    editNotification?: (payload: NotificationMessage, notificationId: string) => Observable<any>;
    clearNotification?: (notificationId: string) => Observable<any>;

    saveFile?: (file: any) => Promise<any>;
    downloadFile?: (url: string) => Promise<any>;
};

export type IChatState = {
    nodeErrors: any;
    isLoading: boolean;
    error: string | null;
};

export type IChatActionsProps = {
    addFile: (data: FileStreamType) => void;
    error: string;
    connection: PeerConnection | null;
    errorModuleHandle: (errorMessage: string) => void;
} & WithTranslation &
    IBaseChatProps;

export type IChatActionsState = {
    isEmojiShown: boolean;
    messageInput: string;
    imgUrl: string | null;
    isDeleteModalOpen: boolean;
    file: File | null;
    currentChunk: number;
    sendingFile: boolean;
};

export type IChatHeaderProps = {
    readonly peerUserName: string;
    readonly showOnlineIndicator?: boolean;
};

export type IChatListProps = {
    messages: ChatMessage[];
    userId: string;
    addFile: (data: FileStreamType) => void;
    error: string;
    errorModuleHandle: (errorMessage: string) => void;
    downloadFile?: (url: string) => Promise<any>;
    connection: PeerConnection | null;
} & IBaseChatProps;

export type IChatListItemMenuProps = {
    readonly message: ChatMessage;
    readonly contentType: ChatType;
    readonly messageType?: MessageContentType;
    readonly downloadFile?: (url: string) => Promise<any>;
    readonly errorModuleHandle: (errorMessage: string) => void;
};

export type IChatListItemProps = {
    readonly message: any;
    readonly type: MessageContentType;
    readonly downloadFile?: (url: string) => Promise<any>;
    readonly errorModuleHandle: (errorMessage: string) => void;
};

export type IBaseChatProps = {
    readonly addMessage: (message: ChatMessage | string, room: string, berthId: string) => void; // (comment: string) => void;
    readonly addFile?: (data: FileStreamType) => void;
};

export type IChatViewProps = {
    readonly messages: ChatMessage[];
    readonly connection: PeerConnection | null;
    readonly userId: string;
    readonly userName: string;
    readonly error: string;
    readonly errorModuleHandle: (errorMessage: string) => void;
    readonly showOnlineIndicator?: boolean;
    readonly downloadFile?: (url: string) => Promise<any>;
} & WithTranslation &
    IBaseChatProps;

export type IChatHostProps = {
    connection: PeerConnection | null;
    selectedChat: string | null;
    addMessage: (message: ChatMessage | string, room: string) => void;
    error: string;
    errorModuleHandle: (errorMessage: string) => void;
    userId: string;
    userName: string;
    chatContactId: string;
    showOnlineIndicator?: boolean;
    errorHandler?: (errorMessage: string) => any;
    saveMessage?: (message: ChatServerMessagePayload) => Observable<any>;
    saveFile?: (file: any) => Promise<any>;
    downloadFile?: (url: string) => Promise<any>;
} & WithTranslation;

export type ICounterProps = {
    onlineConsultation: {[key: string]: any};
    userRole: UserRole;
    endConsultation: () => void;
    userSecret: string | null;
};

export type ICounterState = {
    count: number;
    isConsultationExceeded: boolean;
};

export type IUpcomingCallProps = {
    consultationTime: string;
    attendeeRole: UserRole | null;
    consultationId: string | null;
    changeConsultationStatus: (status: ConsultationStatus) => void;
    authToken: string | null;
};

export type IVideoChatProps = {
    error: string | null;
    isFullScreenMode: boolean;
    authToken: string | null;
    isAuthenticated: boolean;
    changeOnlineConsultationStatus: (status: ConsultationStatus) => void;
    onlineConsultation: {[key: string]: any};
    userSecret: string | null;
    userId: string | null;
    userToken: string | null;
    userRole: UserRole;
    consultationStatus: ConsultationStatus;
}; // & RouteComponentProps;

export type IVideoChatState = {
    isLoading: boolean;
    participantsPresent: boolean;
    shouldConsultationStart: boolean;
    contacts: any;
};

export type IWizardStep = {
    id: string;
    title: string;
    subtitle: string;
    icon: any;
    content: any;
    className?: string;
};

export type IWizardProps = {
    steps: IWizardStep[];
    type?: string;
    instance?: any;
    options?: {[key: string]: any};
    className?: string;
    separator?: any;
    headerClassName?: string;
    contentClassName?: string;
    activeTab?: number;
};

export type IWizardState = {
    activeIndex: number;
};

export type IApexRadialbarProps = {
    item: {
        value: number;
        series: number[];
    };
    chart?: {
        type?: ApexRadialbarType;
        height?: number;
        width?: number;
    };
};

export type IMapProps = {
    isMarkerShown: boolean;
    position: {lat: number; lng: number};
    googleMapURL: string;
    loadingElement: ReactElement<any>;
    containerElement: ReactElement<any>;
    mapElement: ReactElement<any>;
};

export type INavBarItem = {
    id: string;
    target: string;
    isBookmarked: boolean;
    title: string;
    icon: string;
    link: string;
};

export type IUserMenuItem = {
    id: string;
    title?: string;
    icon?: string;
    action?: () => void;
    link?: string;
    isDivider?: boolean;
};

export type INavbarNavigationProps = {
    navigationMenu: INavBarItem[];
    isTooltipVisible: boolean;
};

export type INavbarUserProps = {
    account: {[key: string]: any} | null;
    userMenu: (action?: () => void) => IUserMenuItem[];
    unauthorizedUserMenu: IUserMenuItem[];
    isAuthenticated: boolean;
};

export type IUserDropdownProps = {
    account: {[key: string]: any} | null;
    isAuthenticated: boolean;
    userMenu: (action?: () => void) => IUserMenuItem[];
    unauthorizedUserMenu: IUserMenuItem[];
};

export type INavbarProps = {
    navigationMenu: INavBarItem[];
    account: {[key: string]: any} | null;
    isAuthenticated: boolean;
    userMenu: (action?: () => void) => IUserMenuItem[];
    unauthorizedUserMenu: IUserMenuItem[];
};

export type IAccordionItem = {
    title: string;
    description: string;
    icon: string;
};

export type IAccordionComponentProps = {
    items: IAccordionItem[];
};

export type ApiResponseBase<T> = {
    '@id': string;
    '@type': string;
    'hydra:search': any;
    'hydra:totalItems': number;
    'hydra:view': any;
    'hydra:member': T;
    '@context'?: Context;
};

export type ApiSingleResponseBase<T> = {
    '@id': string;
    '@type': string;
    '@context'?: Context;
} & T;

type Context = {
    '@vocab': string;
    contentUrl: 'MediaObjectOutput/contentUrl';
    filePath: 'MediaObjectOutput/filePath';
    hydra: 'http://www.w3.org/ns/hydra/core#';
    id: 'MediaObjectOutput/id';
};

export type MediaFile = {
    id: string;
    fileUrls: {[key in FileUrlKey]: string};
    contentUrl: string;
};

export enum FileUrlKey {
    LARGE_THUMB = 'largeThumb',
    MEDIUM_THUMB = 'mediumThumb',
    ORIGINAL = 'original',
    SMALL_THUMB = 'smallThumb',
    TINY_THUMB = 'tinyThumb',
}

export type VesselFormInput = {
    vesselType: VesselType | null; // id VesselType
    vesselModel: VesselModel | null; // id VesselModel
    length: number;
    width: number;
    draft: number;
    height: number;
    name: string;
    registrationNumber: string;
};

export type VesselInput = {
    length: number;
    width: number;
    draft: number;
    height: number;
    name: string;
    registrationNumber: string;
    vesselTypeId: string; // id VesselType
    vesselModelId: string | null; // id VesselModel
};

export type CustomVessel = Vessel;

export type PreReservation = {
    id: string; // id PreReservation
    berthId: string; // id Berth
    berthPricingStrategyId: string; // id BerthPricingStrategy
    name: string; // nazwa BerthPricingStrategy
    berthName: string; // nazwa Berth
    price: Money;
    marina: MarinaBroad;
    berthPublicTags: Tag[]; // tablica na podstawie BerthPublicTag
    from: string;
    to: string;
};

export type ReservationListing = {
    id: string;
    from: Date;
    to: Date;
    status: ReservationStatus;
    price: Money;
    marina: MarinaBroad;
    berth: BerthBroad;
    berthPricingStrategyName: string;
    vessel: VesselBroad | null;
    customVessel: CustomVessel | null;
    skipper: SkipperBroadOutput | null;
    customSkipper: CustomSkipper | null;
    trip: TripBroad;
    opinion: Opinion | null;
};

export type SkipperBroadOutput = {
    id: string;
    firstName: string | null;
    lastName: string | null;
    username: string;
    mobile: string | null;
};

export type CustomSkipper = {
    name: string;
    email: string;
    phone: string;
};

export type ReservationMarina = Pick<Marina, 'id' | 'name' | 'cover' | 'location'> & {};

export type Reservation = {
    id: string;
    dates: string[];
    status: ReservationStatus;
    price: Money;
    berth: Berth;
    berthPricingStrategy: BerthPricingStrategyBroad | null;
    vessel: VesselBroad | null;
    customVessel: CustomVessel | null;
    skipper: SkipperBroadOutput | null;
    customSkipper: CustomSkipper | null;
    paidAt: Date | null;
    arrivedAt: Date | null;
    finishedAt: Date | null;
    note: string | null;
    number: string | null;
    isManual: boolean;
    vesselType: VesselType | null;
    marina: ReservationMarina | null;
};

export type VesselPosition = {
    id: string;
    position: GeoPointOutput;
    createdAt: any;
};

export type MoneyInput = {
    amount: string;
    currency: string;
};

export type PreReservationInput = {
    vesselId: string | null; // id Vessel
    berthId: string; // id Berth
    price: MoneyInput;
    dates: string;
    customVesselName: string | null;
    customVesselRegistrationNumber: string | null;
    vesselTypeId: string | null; // id VesselType
};

export type UpdatePreReservationInput = Omit<PreReservationInput, 'berthId' | 'price' | 'dates'> & {tripId: string | null};

export type CreateReservationInput = {
    preReservationId: string; // id PreReservation
    vesselId: string | null; // id Vessel
    customVesselName: string | null;
    customVesselRegistrationNumber: string | null;
    tripId: string | null; // id Trip
    paymentMethodId: string; // id PaymentMethod
};

export type CreateReservationInputForm = {
    vesselType: VesselType | null;
    preReservationId: string; // id PreReservation
    vessel: Vessel | null; // id Vessel
    customVesselName: string | null;
    customVesselRegistrationNumber: string | null;
    trip: Trip | null; // id Trip
    paymentMethodId: string; // id PaymentMethod
};

export type Trip = {
    id: string; // id Trip
    name: string;
    description: string | null;
    dates: string[];
    active: boolean;
};
export type BerthBroadOutput = BerthBroad;

export type PayForReservation = {
    id: string; // id Reservation
    berth: BerthBroad;
    berthPricingStrategyId: string; // id BerthPricingStrategy
    price: Money;
    marina: MarinaBroad | null;
    dates: string[];
    vessel: VesselBroad | null;
    customVessel: CustomVessel | null;
    trip: TripBroad | null;
    tripName: string | null;
    // todo - pola powiązane z płatnością
};

export type BerthBroad = {
    id: string; // id Berth
    sectorId: string; // id Sector
    rating: number; // ocena przemnożona przez 10
    name: string;
    location: GeoPolygon;
    archivedAt: string | null;
    operator: Operator;
    latestOpinions: Opinion[]; // ostatnie 3 opinie
    chatContactAccountId: string;
    berthPublicTags: AssignedTag[];
    prices: BerthDayPricingOutput[];
};

export type TripBroad = Pick<Trip, 'id' | 'name'>;
export type VesselBroadOutput = VesselBroad;
export type VesselBroad = Pick<Vessel, 'id' | 'name' | 'registrationNumber'>;
export type MarinaBroadView = Omit<Marina, 'photos' | 'cover' | 'publicTags' | 'address' | 'berthPricingStrategies' | 'extras'> & {
    location: GeoPointOutput;
};
export type MarinaBroad = Pick<Marina, 'id' | 'name' | 'description' | 'active' | 'publicTags' | 'cover' | 'location'> & {
    sectors: SectorBroad[];
};

export type IAdminMarinaListItem = {
    id: string;
    name: string;
    cover: MediaFile | null;
    active: boolean;
    operatorCount: number;
};

export type SectorBroad = {
    id: string; // id Sector
    name: string;
};

export type GeoPointOutput = {
    latitude: number;
    longitude: number;
};

export type Marina = {
    id: string;
    name: string;
    location: GeoPointOutput;
    rating: number;
    description: string;
    phone: string;
    email: string;
    address: string | null;
    extras: Extra[];
    publicTags: AssignedTag[];
    attractions: string | null;
    restaurants: string | null;
    berthPricingStrategies: BerthPricingStrategy[];
    policyAndRules: MarinaPolicyAndRules;
    photos: MarinaMediaObject[];
    cover: MediaFile | null;
    active: boolean;
    sectors?: SectorBroad[];
};

export type Sector = {
    id: string;
    marinaId: string;
    name: string;
    description: string;
    location: GeoPolygon;
    berths: Berth[];
};

export type GeoPoint = {
    longitude: number;
    latitude: number;
};
export type GeoPolygon = {
    vertexes: GeoPointOutput[];
};

export type Opinion = {
    id: string;
    rating: number;
    description: string | null;
    createdAt: Date;
};

export type Berth = {
    id: string;
    sector: SectorWithMarina | null;
    rating: number | null;
    name: string;
    location: GeoPolygon;
    archivedAt: string | null;
    active: boolean;
    appBookingEnabled: boolean;
    maxLength: number | null;
    maxWidth: number | null;
    maxDraft: number | null;
    maxHeight: number | null;
    canBeArchived: boolean;
    tags: AssignedTagOutput[];
    opinions: Opinion[];
    description: string | null;
    note: string | null;
    policyAndRules: BerthPolicyAndRules;
    photos: BerthMediaObjectOutput[];
    cover: MediaFile | null;
    pricingStrategies: BerthPricingStrategyBroad[];
    prices: BerthDayPricingOutput[];
};

export type BerthPricingStrategyBroadOutput = BerthPricingStrategyBroad;

export type BerthPricingStrategyBroad = {
    id: string;
    name: string;
    price: Money;
    yachtTypes: VesselType[];
};

export type BerthMediaObjectOutput = {
    id: string;
    file: MediaFile;
    enabled: boolean;
};

export type AssignedTagOutput = {
    id: string;
    tag: Tag;
    description: string | null;
};

export type SectorWithMarina = {
    id: string; // id Sector
    name: string;
    marina: {
        id: string; // id Marina
        name: string;
    };
};

export type MarinaMediaObject = {
    id: string; // id MarinaHasMediaObject
    file: MediaFile;
    enabled: boolean;
};
export type BerthPolicyAndRules = MarinaPolicyAndRules;
export type MarinaPolicyAndRules = {
    [key: string]: string | null;
};

export type AssignedTag = {
    id: string;
    tag: Tag;
    description: string | null;
};

export type Tag = {
    id: string;
    name: string;
    code: string | null; // nazwa ikony, ale bez rozszerzenia pliku
    type: TagType;
    name_i18n: {
        en: string;
    };
};

export enum TagType {
    MARINA_PUBLIC = 'marina_public',
    BERTH_PUBLIC = 'berth_public',
    BERTH_PRIVATE = 'berth_private',
    ARTICLE = 'article',
}

export type WaterRoute = {
    route: GeoPoint[];
};

export type Extra = {
    price: Money | null;
    id: string;
    name: string;
    description: string;
};

export enum Currency {
    EURO = 'EUR',
}

export type Money = {
    amount: string;
    currency: {
        name: Currency;
    };
};

export type BerthPricingStrategy = {
    id: string;
    name: string;
    basePrice: Money;
    basePriceGross: Money;
    currentPrice: Money;
    calendarRules: PricingStrategyCalendarRule[];
    commission: string;
    yachtTypes: string[];
    berth: BerthBroad;
    active: boolean;
};

export type BerthPricingStrategyInput = {
    berthId: string;
    name: string;
    yachtTypes: string[];
    basePrice: MoneyInput;
    basePriceGross: MoneyInput;
    commission: string;
    calendarRules: PricingStrategyCalendarRuleInput[];
};

export type PricingStrategyCalendarRule = {
    startsAt: string | Date;
    endsAt: string | Date;
    price: Money | null;
    active: boolean;
};

export type PricingStrategyCalendarRuleInput = {
    startsAt: string | Date;
    endsAt: string | Date;
    price: MoneyInput | null;
    active: boolean;
};

export interface IPricingStrategyDefinition {
    id: string;
    name: string;
    yachtTypes: string[];
    basePrice: MoneyInput;
    basePriceGross: MoneyInput;
    commission: string;
    calendarRules: PricingStrategyCalendarRule[];
}

export type PricingStrategyDefinitionInput = {
    name: string;
    yachtTypes: string[];
    basePrice: MoneyInput;
    basePriceGross: MoneyInput;
    commission: string;
    calendarRules: PricingStrategyCalendarRuleInput[];
};

export type BerthPricingStrategySearchResult = {
    id: string; // id BerthPricingStrategy
    marina: MarinaBroad | null;
    price: Money;
    name: string;
    berth: BerthBroad;
    vesselTypes: VesselType[];
    route: RouteOutput | null;
};

export enum MarinaMediaObjectType {
    MAIN = 'main',
    GENERAL = 'general',
}

export enum ReservationStatus {
    DRAFT = 'draft',
    ACTIVE = 'active',
    INACTIVE = 'inactive',
    CANCELLED = 'cancelled',
    FINISHED = 'finished',
    APP_BOOKING = 'app_booking',
    YACHT_ARRIVED = 'yacht_arrived',
    PAID = 'paid',
    IS_MANUAL = 'is_manual',
}

export enum BerthStatus {
    RESERVED = 'reserved',
    ACTIVE = 'active',
    INACTIVE = 'inactive',
    AVAILABLE = 'available',
    TEMP_RESERVED = 'temp_reserved',
    APP_BOOKING = 'app_booking',
    PAID = 'paid',
    DISABLED = 'disabled',
    YACHT_ARRIVED = 'yacht_arrived',
}

//** typy nowe **

export type BerthListing = {
    id: string; // id Berth
    sector: SectorWithMarinaOutput | null;
    rating: number; // ocena przemnożona przez 10
    name: string;
    location: GeoPolygon;
    archivedAt: string | null;
    active: boolean;
    appBookingEnabled: boolean;
    tags: AssignedTagOutput[];
    opinions: Opinion[]; // ostatnie 3 opinie
    prices: BerthDayPricingOutput[];
    cover: MediaFile | null;
};

export type BerthDayPricingOutput = {
    netPrice: MoneyOutput;
    grossPrice: MoneyOutput;
    commissionValue: MoneyOutput;
    commission: string; // %
    strategyName: string;
    startsAt: string;
    endsAt: string;
};

export type MoneyOutput = {
    amount: string;
    currency: {
        name: Currency;
    };
};

export type SectorWithMarinaOutput = {
    id: string; // id Sector
    name: string;
    marina: MarinaLimitedOutput;
};

export type MarinaLimitedOutput = {
    id: string; // id Marina
    name: string;
};

export type IModelApiResponseViewObject = {[key: string]: any};

export type WithRouterWrapperProps = {
    router: {
        params: {[key: string]: any};
        navigate: (to: string, options?: {replace?: boolean; state?: any}) => void;
        location: {
            hash: string;
            key: string;
            pathname: string;
            search: string;
            state: {[key: string]: any} | null;
        };
    };
};

export interface IReservationsReportOutput {
    paidOut: Money;
    gross: Money;
    reservations: IReservationsReportItemOutput[];
}

export interface IReservationsReportItemOutput {
    id: string;
    dates: string[];
    status: ReservationStatus;
    price: Money;
    netPrice: Money;
    berth: BerthBroad;
    vessel: VesselBroad | null;
    customVessel: CustomVessel | null;
    number: string;
    cardNumber: string;
}

export interface IBillingInfo {
    id: string;
    companyName: string | null;
    email: string | null;
    taxId: string | null;
    mobile: string | null;
    address: string;
    state: string | null;
    city: string | null;
    zip: string | null;
    country: Country | null;
    stripeChargesEnabled: boolean;
}

export type IAuthPanelProps = {
    readonly isLoading: boolean;
    readonly userRole: UserRole;
    readonly formConfig: any;
    readonly envData: any;
    readonly initAuthTokenChange: typeof initAuthTokenChange;
} & WithRouterWrapperProps;

export type IAuthHeaderProps = {
    readonly userRole: UserRole;
};

export type IReservationPayment = {
    berth: {
        id: string;
        name: string;
        sectorId: string;
    };
    createdAt: string;
    id: string;
    paidAt: string;
    price: Money;
};

export type IAccountPublic = {
    readonly avatar: MediaFile;
    readonly firstName: string;
    readonly id: string;
    readonly lastName: string;
    readonly username: string;
};

export type INameTranslation = {
    readonly en: string;
};

export type IArticle = {
    readonly id: string;
    readonly active: boolean;
    readonly author: IAccountPublic;
    readonly createdAt: string;
    readonly marinaCount: number;
    readonly shortContent: string;
    readonly shortContent_i18n: INameTranslation;
    readonly tags: Tag[];
    readonly title: string;
    readonly title_i18n: INameTranslation;
    readonly updatedAt: string;
};

export type IArticleFullDetails = {
    readonly marinas: IArticleConnectedMarina[];
    readonly pages: IArticlePage[];
    readonly subtitle: string;
    readonly subtitle_i18n: INameTranslation;
} & IArticle;

export type IArticlePage = {
    readonly content: string;
    readonly content_i18n: INameTranslation;
    readonly id: string;
    readonly page: number;
};

export type IArticleConnectedMarina = {
    readonly id: string;
    readonly name: string;
};

export type IOperatorSubscription = {
    readonly id: string;
    readonly name: string;
    readonly name_i18n: {
        en: string;
    };
    readonly description: string;
    readonly description_i18n: {
        en: string | null;
    };
    readonly payment: string;
    readonly roles: string[];
    readonly activeFrom: string | null;
    readonly activeUntil: string | null;
    readonly cancelled: boolean;
    readonly current: boolean;
    readonly netPrice: Money;
    readonly grossPrice: Money;
    readonly invoiceNumber: string | null;
};

export type AsyncTaskOutput<T> = {
    id: string;
    createdAt: string;
    updatedAt: string;
    status: AsyncWaterApiStatus;
    output: T;
    totalItems: number;
};

export type RouteOutput = {
    time: number;
    distance: number;
    waypoints: GeoPointOutput[];
};

export type LatLng = {
    latitude: number;
    longitude: number;
};

export type GeoPointInput = {
    latitude: number;
    longitude: number;
};

export type RouteInput = {
    waypoints: GeoPointInput[];
    vesselTypeId: string;
};

export type MarinaRouteNavigation = {
    marinaId: string;
    status: AsyncWaterApiStatus;
    route: RouteOutput | null;
};

export type MarinaSearchOutput = {
    id: string; // id Marina
    name: string;
    priceMin: MoneyOutput | null;
    priceMax: MoneyOutput | null;
    location: GeoPointOutput;
    publicTags: Tag[];
};

export type MarinaStateObject = {[marinaId: string]: MarinaSearchOutput};

export type SearchMarinaFilters = {
    dist_rad?: number;
    dist_lat?: number;
    dist_long?: number;
    name?: string;
    in_polygon?: string;
    page?: number;
};

export type AsyncTaskPaginatedOutput<T> = {
    id: string;
    createdAt: string;
    updatedAt: string;
    status: AsyncWaterApiStatus;
    output: T;
    totalItems: number;
};
