import React from 'react';
import Moment from 'react-moment';
import {IDateComponentProps} from '../../types';
import {activeLanguageSelector, DateFormat} from '../..';
import {connect} from 'react-redux';

type Props = IDateComponentProps;

class DateComponent extends React.Component<Props> {
    render() {
        return (
            <Moment
                interval={this.props.interval ? this.props.interval : 0}
                locale={this.props.locale ? this.props.locale : this.props.activeLanguage}
                format={this.props.format ? this.props.format : DateFormat.DateDefaultDisplay}>
                {this.props.date}
            </Moment>
        );
    }
}

export default connect(
    (state: any) => ({
        activeLanguage: activeLanguageSelector(state),
    }),
    {}
)(DateComponent);
