import {ChatContact, FullAccount} from "../../../types";

interface BerthChat {
	id: string; // id Berth
	sector: SectorWithMarina | null;
	name: string;
}

interface SectorWithMarina {
	id: string; // id Sector
	name: string;
	marina: {
		id: string; // id Marina
		name: string;
	};
}

// export interface ChatContact {
// 	roomId: string;
// 	accountId: string;
// 	firstName: string;
// 	lastName: string;
// 	unreadMessagesCount: number;
// 	berthName: string;
// 	berthId: string;
// 	marinaName: string;
// 	marinaId: string;
// 	avatarUrl?: string;
// }

export interface ServerChatContact {
	'@id': string;
	'@type': 'Account';
	unread: number; // ilość nieprzeczytanych wiadomości z tym kontaktem
	contact: FullAccount;
	berth: BerthChat;
}

export const serverChatContactIntoChatContactMapper = (contacts: ServerChatContact[], accountId: string): ChatContact[] => {
	return contacts.map((contact) => {
		if (contact.contact.operator) {
			return fromServerOperatorIntoChatContact(contact, accountId);
		}
		return fromServerUserIntoChatContact(contact, accountId);
	});
};

function fromServerOperatorIntoChatContact(contact: ServerChatContact, accountId: string): ChatContact {
	return {
		roomId: createChannelId(contact.contact.operator.user?.id || '', contact.berth.id, accountId),
		accountId: contact?.contact.operator?.user?.id || '',
		firstName: contact?.contact?.operator?.user?.firstName || '',
		lastName: contact?.contact?.operator?.user?.lastName || '',
		unreadMessagesCount: contact?.unread,
		berthId: contact.berth.id,
		berthName: contact?.berth.name || '',
		marinaName: contact.berth.sector?.marina.name || '',
		marinaId: contact.berth.sector?.marina.id || '',
		avatarUrl: contact?.contact?.operator?.user?.avatar?.fileUrls?.tinyThumb
			? contact?.contact?.operator?.user?.avatar?.fileUrls?.tinyThumb
			: undefined,
	};
}

function fromServerUserIntoChatContact(contact: ServerChatContact, accountId: string): ChatContact {
	return {
		roomId: createChannelId(contact.contact.skipper.account?.id || '', contact.berth.id, accountId),
		accountId: contact?.contact.skipper?.account?.id || '',
		firstName: contact?.contact?.skipper?.account?.firstName || '',
		lastName: contact?.contact?.skipper?.account?.lastName || '',
		unreadMessagesCount: contact?.unread,
		berthId: contact.berth.id,
		berthName: contact?.berth.name || '',
		marinaName: contact.berth.sector?.marina.name || '',
		marinaId: contact.berth.sector?.marina.id || '',
		avatarUrl: contact?.contact?.skipper?.account?.avatar?.fileUrls?.tinyThumb
			? contact.contact.skipper.account.avatar.fileUrls.tinyThumb
			: undefined,
	};
}

function createChannelId(userAccountId: string, berthId: string, contactAccountId: string) {
	return [userAccountId, berthId, contactAccountId].sort().join('.');
}
