import {Observable} from 'rxjs';
import {marineHubAuthAPI} from '../provider/marineHubAuth';
import {RestQueryParams} from '../base/queryParams';
import {IAuthTokens} from '../../store/reducers/authSlice';

export function refreshTokenAPI(
    refreshToken: string,
    ): Observable<IAuthTokens> {
        const payload = {
            refresh_token: refreshToken
        }
        return marineHubAuthAPI.post(
            'api/auth/refresh',
            payload,
            new RestQueryParams(),
        );
    }
