import {combineEpics} from 'redux-observable';
import {
    accountEpic,
    changePasswordEpic,
    dictionaryDataEpic,
    loginEpic,
    reauthenticateEpic,
    renewAuthTokenEpic,
    authEpic,
} from 'marine-panel-common-web';
import marinasEpic from './marinasEpic';
import operatorsPageEpic from './operatorsPageEpic';
import articlesPageEpic from './articlesPageEpic';
import skippersPageEpic from './skippersPageEpic';
import vesselModelsPageEpic from './vesselModelsPageEpic';
import vesselTypesPageEpic from './vesselTypesPageEpic';
import boatCertificatesEpic from './boatCertificatesEpic';
import marinaEditWizardEpic from './marinaEditWizardEpic';
import marinaTagsEpic from './marinasTagsEpic';

export const rootEpic = combineEpics(
    dictionaryDataEpic,
    marinasEpic,
    loginEpic,
    renewAuthTokenEpic,
    authEpic,
    reauthenticateEpic,
    changePasswordEpic,
    accountEpic,
    operatorsPageEpic,
    skippersPageEpic,
    articlesPageEpic,
    vesselModelsPageEpic,
    vesselTypesPageEpic,
    boatCertificatesEpic,
    marinaEditWizardEpic,
    marinaTagsEpic
);
