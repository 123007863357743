import {IModelApiResponseViewObject} from '../model/base';

export const getErrorMessage = (error: any) => {
    let errorMessage;
    if (error.response && error.response.message) {
        errorMessage = error.response.message;
    } else if (error.response && error.response['hydra:description']) {
        errorMessage = error.response['hydra:description'];
    } else {
        errorMessage = 'Something went wrong. Please try again later.';
    }

    return errorMessage;
};

export const getMetadataDetails = (hydraViewData: any): IModelApiResponseViewObject | null => {
    const viewData = hydraViewData;
    let metadata: IModelApiResponseViewObject | null = null;

    if (viewData && viewData.hasOwnProperty('hydra:first') && viewData.hasOwnProperty('hydra:last')) {
        metadata = {
            'hydra:first': viewData['hydra:first'],
            'hydra:last': viewData['hydra:last'],
            'hydra:next': viewData['hydra:next'],
        };
    }

    if (viewData && viewData.hasOwnProperty('hydra:next') && metadata) {
        metadata['hydra:next'] = viewData['hydra:next'];
    }

    if (viewData && viewData.hasOwnProperty('hydra:previous') && metadata) {
        metadata['hydra:previous'] = viewData['hydra:previous'];
    }

    return metadata;
};
