import {createSelector} from '@reduxjs/toolkit';
import {IAuthState} from '../reducers/authSlice';

export const selectAuth = (state: any): IAuthState | null => {
    return state.auth;
};

export const usernameSelector = createSelector([selectAuth], (state?: IAuthState) => (state ? state.username : null));

export const authTokenSelector = createSelector([selectAuth], (state?: IAuthState) => (state ? state.authToken : null));

export const refreshTokenSelector = createSelector([selectAuth], (state?: IAuthState) => (state ? state.refreshToken : null));

export const isAuthenticatedSelector = createSelector([selectAuth], (state?: IAuthState) => (state ? state.isAuthenticated : false));

export const userRolesSelector = createSelector([selectAuth], (state?: IAuthState) => (state ? state.userRoles : null));

export const isAuthPageLoadingSelector = createSelector([selectAuth], (state?: IAuthState) => (state ? state.isAuthPageLoading : false));

// export const userIdSelector = createSelector([selectAuth], (state: IAuthState) => state.userId);

// export const authAccountIdSelector = createSelector([selectAuth], (state: IAuthState) => state.accountId);
