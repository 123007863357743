import {IValidationConfig, IValidationRule, IValidationRuleResult} from '../types';

export enum ValidationRules {
    IS_REQUIRED = 'isRequired',
    MIN_LENGTH = 'minLength',
    MAX_LENGTH = 'maxLength',
    IS_EMAIL = 'isEmail',
    ARE_FIELDS_SAME = 'areFieldsSame',
    IS_NUMBER = 'isNumber',
    IS_MAX_VALUE = 'isMAxValue',
    IS_TRUE = 'isTrue',
    IS_DATE_GT_THAN = 'isDateGtThan',
    IS_DATE_GTE_THAN = 'isDateGteThan',
    IS_DATE_LTE_THAN = 'isDateLteThan',
    IS_VALID = 'isValid',
    IS_ARRAY_SOME = 'isArraySome',
    IS_ARRAY_EVERY = 'isArrayEvery',
    IS_ARRAY_NONE = 'isArrayNone',
    IS_POSITIVE_NUMBER_OR_NULL = 'isPositiveNumberOrNull',
    IS_VALID_DECIMAL_PART_NUMBER = 'isValidDecimalPartNumber',
}

const validateFormControl = (value: any, rules: IValidationRule[]) => {
    let isValid = true;

    for (const rule of rules) {
        if (rule.name === ValidationRules.MIN_LENGTH) {
            isValid = isValid && minLengthValid(value, rule);
        } else if (rule.name === ValidationRules.MAX_LENGTH) {
            isValid = isValid && maxLengthValid(value, rule);
        } else if (rule.name === ValidationRules.IS_EMAIL) {
            isValid = isValid && isEmailValid(value);
        } else if (rule.name === ValidationRules.IS_REQUIRED) {
            isValid = isValid && isRequiredValid(value);
        } else if (rule.name === ValidationRules.ARE_FIELDS_SAME) {
            isValid = isValid && isPasswordSame(value, rule);
        } else if (rule.name === ValidationRules.IS_NUMBER) {
            isValid = isValid && isNumber(value);
        } else if (rule.name === ValidationRules.IS_MAX_VALUE) {
            isValid = isValid && isMaxValue(value, rule.params.maxValue);
        } else if (rule.name === ValidationRules.IS_TRUE) {
            isValid = isValid && isTrue(value);
        } else if (rule.name === ValidationRules.IS_DATE_GT_THAN) {
            isValid = isValid && isDateGtThan(value, rule);
        } else if (rule.name === ValidationRules.IS_DATE_GTE_THAN) {
            isValid = isValid && isDateGteThan(value, rule);
        } else if (rule.name === ValidationRules.IS_DATE_LTE_THAN) {
            isValid = isValid && isDateLteThan(value, rule);
        } else if (rule.name === ValidationRules.IS_VALID) {
            isValid = isValid && isCallbackValid(value, rule);
        } else if (rule.name === ValidationRules.IS_ARRAY_SOME) {
            isValid = isValid && isArraySomeValid(value, rule);
        } else if (rule.name === ValidationRules.IS_ARRAY_EVERY) {
            isValid = isValid && isArrayEveryValid(value, rule);
        } else if (rule.name === ValidationRules.IS_ARRAY_EVERY) {
            isValid = isValid && isArrayEveryValid(value, rule);
        } else if (rule.name === ValidationRules.IS_POSITIVE_NUMBER_OR_NULL) {
            isValid = isValid && isPositiveNumberOrNull(value);
        } else if (rule.name === ValidationRules.IS_VALID_DECIMAL_PART_NUMBER) {
            isValid = isValid && isValidDecimalPartNumber(value, rule);
        } else {
            isValid = true;
        }
    }

    return isValid;
};

const validateMessage = (value: any, rules: IValidationRule[]) => {
    const errorMessage: any = [];

    for (const rule of rules) {
        if (rule.name === ValidationRules.MIN_LENGTH && !minLengthValid(value, rule)) {
            errorMessage.push('formValidation.errors.minLength');
        }
        if (rule.name === ValidationRules.MAX_LENGTH && !maxLengthValid(value, rule)) {
            errorMessage.push('formValidation.errors.maxLength');
        }

        if (rule.name === ValidationRules.IS_EMAIL && !isEmailValid(value)) {
            errorMessage.push('formValidation.errors.isEmailValid');
        }

        if (rule.name === ValidationRules.IS_REQUIRED && !isRequiredValid(value)) {
            errorMessage.push('formValidation.errors.isRequired');
        }

        if (rule.name === ValidationRules.ARE_FIELDS_SAME && !isPasswordSame(value, rule)) {
            errorMessage.push('formValidation.errors.isPasswordSame');
        }

        if (rule.name === ValidationRules.IS_NUMBER && !isNumber(value)) {
            errorMessage.push('formValidation.errors.isNumber');
        }
        if (rule.name === ValidationRules.IS_MAX_VALUE && !isMaxValue(value, rule.params.maxValue)) {
            errorMessage.push('formValidation.errors.isHigherThanMaxValue');
        }
        if (rule.name === ValidationRules.IS_DATE_GT_THAN && !isDateGtThan(value, rule)) {
            errorMessage.push(`formValidation.errors.isStartDateValid`);
        }

        if (rule.name === ValidationRules.IS_DATE_GTE_THAN && !isDateGteThan(value, rule)) {
            errorMessage.push(`formValidation.errors.isStartDateGreaterOrEvenValid`);
        }

        if (rule.name === ValidationRules.IS_DATE_LTE_THAN && !isDateLteThan(value, rule)) {
            errorMessage.push(`formValidation.errors.isEndDateValid`);
        }

        if (rule.name === ValidationRules.IS_VALID && !isCallbackValid(value, rule)) {
            errorMessage.push(rule.params.errorMessage);
        }

        if (rule.name === ValidationRules.IS_ARRAY_SOME && !isArraySomeValid(value, rule)) {
            errorMessage.push(rule.params.errorMessage);
        }

        if (rule.name === ValidationRules.IS_ARRAY_EVERY && !isArrayEveryValid(value, rule)) {
            errorMessage.push(rule.params.errorMessage);
        }

        if (rule.name === ValidationRules.IS_ARRAY_NONE && !isArrayNoneValid(value, rule)) {
            errorMessage.push(rule.params.errorMessage);
        }
        if (rule.name === ValidationRules.IS_POSITIVE_NUMBER_OR_NULL && !isPositiveNumberOrNull(value)) {
            errorMessage.push('formValidation.errors.isPositiveNumberOrNull');
        }
        if (rule.name === ValidationRules.IS_VALID_DECIMAL_PART_NUMBER && !isValidDecimalPartNumber(value, rule)) {
            errorMessage.push('formValidation.errors.isValidDecimalNumber');
        }
    }

    return errorMessage;
};

const isArraySomeValid = (value: any, rule: IValidationRule) => {
    if (null === value || undefined === value) {
        return true;
    }
    if (!Array.isArray(value)) {
        return false;
    }
    return (value as []).some((childValue) => validateFormControl(childValue, rule.params.rules));
};

const isArrayEveryValid = (value: any, rule: IValidationRule) => {
    if (null === value || undefined === value) {
        return true;
    }
    if (!Array.isArray(value)) {
        return false;
    }
    return (value as []).every((childValue) => validateFormControl(childValue, rule.params.rules));
};

const isArrayNoneValid = (value: any, rule: IValidationRule) => {
    if (null === value || undefined === value) {
        return true;
    }
    if (!Array.isArray(value)) {
        return false;
    }
    return !(value as []).every((childValue) => validateFormControl(childValue, rule.params.rules));
};

const isCallbackValid = (value: any, rule: IValidationRule) => {
    return rule.params.callback(value);
};

const minLengthValid = (value: string, rule: IValidationRule) => {
    return null !== value && undefined !== value && value.length >= rule.params.length;
};

const maxLengthValid = (value: string, rule: IValidationRule) => {
    return null !== value && undefined !== value && value.length <= rule.params.length;
};

const isRequiredValid = (value: any) => {
    if (Array.isArray(value)) {
        return value.length > 0;
    }
    if (value !== null && typeof value === 'object') {
        return true;
    }
    if (typeof value === 'string') {
        return value.trim() !== '';
    }

    return null !== value && undefined !== value && false !== value;
};

const isEmailValid = (value: any) => {
    let re =
        /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    return re.test(String(value).toLowerCase());
};

const isPasswordSame = (value: any, rule: IValidationRule) => {
    if (null === value || undefined === value) {
        return true;
    }
    const values = (rule.params.accessors as []).map((accessor: (value: any) => any) => accessor(value));

    if (values.length < 1) {
        return true;
    }
    const reference = values[0];

    return values.every((val) => val === reference);
};

const isNumber = (value: any) => {
    let numReg = new RegExp('^[0-9]+$');
    return numReg.test(String(value).toLowerCase());
};

const isMaxValue = (value: any, maxValue: number) => {
    if (!value) return true;
    return Number(value) < maxValue;
};

const isPositiveNumberOrNull = (value: any) => {
    if (!value) return true;
    let numReg = new RegExp(/^\+?([1-9]\d*)$/);
    return numReg.test(String(value).toLowerCase());
};

const isDateGtThan = (value: any, rule: IValidationRule): boolean => {
    const date = new Date(rule.params.valueAccessor(value));
    const compareAgainst = rule.params.compareAccessor(value);
    const compareDate = compareAgainst ? new Date(compareAgainst) : new Date();

    return date > compareDate;
};

const isDateGteThan = (value: any, rule: IValidationRule): boolean => {
    const date = new Date(rule.params.valueAccessor(value));
    const compareAgainst = rule.params.compareAccessor(value);
    const compareDate = compareAgainst ? new Date(compareAgainst) : new Date();

    return date >= compareDate;
};

const isDateLteThan = (value: any, rule: IValidationRule) => {
    const date = new Date(rule.params.valueAccessor(value));
    const compareAgainst = rule.params.compareAccessor(value);
    const compareDate = compareAgainst ? new Date(compareAgainst) : new Date();

    return date <= compareDate;
};

const isTrue = (value: any) => {
    return value === true;
};

const isLowercase = (value: string) => {
    const lowercaseReg = /(?=.*[a-z])/g;
    return lowercaseReg.test(value);
};

const isSpecialCharacter = (value: string) => {
    const specialCharacterReg = /[-._!"`'#%&,:;<>=@{}~\$\(\)\*\+\/\\\?\[\]\^\|]+/g;
    return specialCharacterReg.test(value);
};

const isValidDecimalPartNumber = (value: string, rule: IValidationRule) => {
    const numberRegex = /^\d+(\.\d{1,2})?$/;
    return numberRegex.test(value);
};

const validators = {validateFormControl, validateMessage};

const runRule = (value: any, rule: IValidationRule): IValidationRuleResult => {
    const result = {
        valid: true,
        errorMessages: [],
    };

    if (!validateFormControl(value, [rule])) {
        result.valid = false;
        result.errorMessages = validateMessage(value, [rule]);
    }

    return result;
};

export const runValidators = (value: any, config?: IValidationConfig): IValidationRuleResult => {
    const errorMessages = [];
    let valid = true;

    if (null !== config && undefined !== config) {
        config.forEach((rule) => {
            const ruleResult = runRule(value, rule);
            if (!ruleResult.valid) {
                valid = false;
                errorMessages.push(...ruleResult.errorMessages);
            }
        });
    }

    return {
        valid,
        errorMessages,
    };
};

export default validators;
