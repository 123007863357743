import {isNotNullOrUndefined} from './runtimeUtils';

export const diffKeys = (a: object, b?: object) => {
    const keys = [];
    const hasB = isNotNullOrUndefined(b);

    Object.keys(a).forEach((key) => {
        if (!a.hasOwnProperty(key)) {
            return;
        }
        if (!hasB || a[key] !== b[key]) {
            keys.push(key);
        }
    });
    if (hasB) {
        Object.keys(b).forEach((key) => {
            if (!b.hasOwnProperty(key)) {
                return;
            }
            if (a[key] !== b[key] && -1 === keys.indexOf(key)) {
                keys.push(key);
            }
        });
    }

    return keys;
};
