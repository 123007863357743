import React from 'react';
import 'emoji-mart/css/emoji-mart.css';
import ChatView from './ChatView';
import {
    ChatContact,
    ChatMessage,
    ChatServerMessagePayload,
    FileStreamType,
    MessageContentType,
    PeerConnection
} from "../../../types";
import {Observable} from "rxjs";
import {WithTranslation, withTranslation} from "react-i18next";


interface IConnectedChatHostProps {
}

interface IExternalChatHostProps {
    getMessagesFromUser: (accountId: string, page?: number) => Observable<boolean>;
    connection: PeerConnection | null;
    selectedChat: string | null;
    addMessage: (message: ChatMessage | string, room: string) => void;
    error: string;
    errorModuleHandle: (errorMessage: string) => void;
    accountId: string;
    userName: string;
    chatContact: ChatContact | undefined;
    confirmMessageRead: (messages: string[]) => void;
    showOnlineIndicator?: boolean;
    errorHandler?: (errorMessage: string) => any;
    saveMessage?: (message: ChatServerMessagePayload) => Observable<any>;
    saveFile?: (file:any) => Promise<any>;
}

interface IChatHostProps
    extends IConnectedChatHostProps, WithTranslation,
        IExternalChatHostProps {}


class Chat extends React.Component<IChatHostProps> {
    uuid = require("uuid/v4");

    render() {
        const messages = this.props?.connection?.messages || [];
        return (
            <ChatView
                chatContact={this.props.chatContact}
                getMessagesFromUser={this.props.getMessagesFromUser}
                accountId={this.props.accountId}
                userName={this.props.userName}
                connection={this.props.connection}
                selectedChat={this.props.selectedChat}
                confirmMessageRead={this.props.confirmMessageRead}
                messages={messages}
                addMessage={this.addMessage}
                error={this.props.error}
                errorModuleHandle={this.props.errorModuleHandle}
                showOnlineIndicator={this.props.showOnlineIndicator}
                addFile={this.addFile}
            />
        );
    }

    private addFile = ({file, done, fileName, fullFile}: FileStreamType) => {
        const selectedChat = this.props.selectedChat;
        if(done && fileName) {
            const fileMessage = new ChatMessage({
                from: this.props.accountId,
                to: this.props.chatContact.accountId,
                messageType: MessageContentType.FILE,
                messageContent: fileName,
                messageId: this.uuid(),
            })

            this.props.addMessage(Object.assign(fileMessage, {
                messageContent: fileName,
                messageMedia: fullFile,
                messageType: MessageContentType.FILE}), this.props.selectedChat)
        } else {
            this.props.addMessage(file as string, selectedChat)
        }
    }

    private addMessage = (message: string) => {
        if(!message) {
            return;
        }

        const textMessage = new ChatMessage({
            from: this.props.accountId,
            to: this.props.chatContact.accountId,
            messageType: MessageContentType.TEXT,
            messageContent: message,
            messageId: this.uuid(),
        })

        this.props.addMessage(textMessage, this.props.selectedChat)
    };
}

export default withTranslation()(Chat);
