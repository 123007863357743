import React, {useEffect} from 'react';
import {Card, CardBody, CardHeader, Table} from 'reactstrap';
import LayoutWrapper from '../LayoutWrapper';
import {
    AccountBasicInfo,
    IModelApiResponseViewObject,
    Loader,
    LoaderType,
    Pagination,
    SkipperFullInfo,
    Translation,
} from 'marine-panel-common-web';
import {connect} from 'react-redux';
import {isSkippersPagesLoadingSelector, skippersSelector, skippersPageMetadataSelector} from '../../store/selectors/skippersPageSelectors';
import {RootState} from '../../store/reducers';
import {changeSkippersPagePagination, fetchSkippers, resetToInitialSkippersPageState} from '../../store/reducers/skippersPageSlice';
import {useTranslation} from 'react-i18next';

interface IConnectedSkippersProps {
    readonly isLoading: boolean;
    readonly skippers: SkipperFullInfo[] | null;
    readonly skippersPageMetadata: IModelApiResponseViewObject | null;
    readonly fetchSkippers: typeof fetchSkippers;
    readonly changeSkippersPagePagination: typeof changeSkippersPagePagination;
    readonly resetToInitialSkippersPageState: typeof resetToInitialSkippersPageState;
}

interface ISkippersProps extends IConnectedSkippersProps {}

const Skippers: React.FC<ISkippersProps> = ({
    skippers,
    isLoading,
    fetchSkippers,
    resetToInitialSkippersPageState,
    skippersPageMetadata,
    changeSkippersPagePagination,
}) => {
    const {t} = useTranslation();

    useEffect(() => {
        fetchSkippers();

        return () => {
            resetToInitialSkippersPageState();
        };
    }, [fetchSkippers]);

    return (
        <LayoutWrapper>
            <Card className="transactions-history">
                <CardHeader>
                    <h2 className="section-subtitle">
                        <Translation text="skippers.title" />
                    </h2>
                </CardHeader>
                <CardBody>
                    <Table responsive className="skippers-table">
                        <thead>
                            <tr>
                                <th>
                                    <Translation text="skippers.table.name" />
                                </th>
                                <th>
                                    <Translation text="skippers.table.phone" />
                                </th>
                                <th>
                                    <Translation text="skippers.table.email" />
                                </th>
                            </tr>
                        </thead>
                        <tbody>{renderSkippersTable()}</tbody>
                    </Table>
                    <div>
                        <Pagination listMetadata={skippersPageMetadata} changePage={changeSkippersPagePagination} itemsPerPage={10} />
                    </div>
                    <Loader type={LoaderType.Local} showLoader={isLoading} />
                </CardBody>
            </Card>
        </LayoutWrapper>
    );

    function renderSkippersTable() {
        if (null === skippers || !skippers.length) {
            return (
                <tr>
                    <td colSpan={3}>
                        <Translation text="operators.table.noData" />
                    </td>
                </tr>
            );
        }

        return skippers.map((item: SkipperFullInfo) => {
            return (
                <tr key={item.id}>
                    <td className="fw-bold">{renderSkipperDetails(item.account)}</td>
                    <td>{item?.account?.mobile ? item.account.mobile : '---'}</td>
                    <td>{item?.account?.username ? item.account.username : '---'}</td>
                </tr>
            );
        });
    }

    function renderSkipperDetails(skipper: AccountBasicInfo | null) {
        const skipperName =
            skipper && skipper.firstName && skipper.lastName
                ? `${skipper.firstName} ${skipper.lastName}`
                : t('skippers.table.unknownSkipper');

        return <p>{skipperName}</p>;
    }
};

export default connect(
    (state: RootState) => ({
        isLoading: isSkippersPagesLoadingSelector(state),
        skippers: skippersSelector(state),
        skippersPageMetadata: skippersPageMetadataSelector(state),
    }),
    {
        fetchSkippers,
        changeSkippersPagePagination,
        resetToInitialSkippersPageState,
    }
)(Skippers);
