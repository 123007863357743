import {Observable} from 'rxjs';
import {RestQueryParams} from '../base/queryParams';
import {marineHubAPI} from '../provider/marineHubAPI';

export function confirmRegistrationAPI(authToken: string): Observable<any> {
    return marineHubAPI.post(
        `api/users/confirm_registration`,
        {},
        new RestQueryParams(),
        {
            'X-Authentication-Token': authToken
        }
    );
}
