import {Translation} from 'marine-panel-common-web';
import {useState} from 'react';
import {connect} from 'react-redux';
import {ModalBody, ModalFooter, ModalHeader} from 'reactstrap';
import {changeMarinaLocation, SelectedItemType} from '../../../../../../store/reducers/marinaEditWizardSlice';
import {changeIsModalOpen, resetToInitialModalState} from '../../../../../../store/reducers/modalSlice';
import LocationInput, {IChangeBerthLocationPayload} from '../../../../CreateMarinaModal/LocationInput';

interface ILocationModalProps {
    readonly locationType: SelectedItemType;
    readonly toggleModalOpen: () => void;
    readonly changeMarinaLocation: typeof changeMarinaLocation;
}

const SetLocationModal: React.FC<ILocationModalProps> = ({locationType, toggleModalOpen, changeMarinaLocation}) => {
    const [locationPayload, setLocationPayload] = useState<IChangeBerthLocationPayload | null>(null);

    const isBerth = locationType === SelectedItemType.BERTH,
        modalTitleText = isBerth ? 'modal.setLocation.titleBerth' : 'modal.setLocation.titleMarina';
    const handleClick = () => {
        if (locationType === SelectedItemType.MARINA && locationPayload) {
            changeMarinaLocation({
                marinaId: locationPayload.berthId,
                location: locationPayload.location,
            });
        }
    };
    return (
        <div className="modal-wrapper">
            <ModalHeader toggle={() => toggleModalOpen()}>
                <p className="main title">
                    <Translation text={modalTitleText} />
                </p>
            </ModalHeader>
            <ModalBody>
                <div className="form-control offers-form-control modal-form">
                    <p className="title">
                        <Translation text={`modal.setLocation.${isBerth ? 'subtitleBerth' : 'subtitleMarina'}`} />
                    </p>
                    <p className="secondary-title">
                        <Translation text={`modal.setLocation.${isBerth ? 'instructionsBerth' : 'instructionsMarina'}`} />
                    </p>
                    <LocationInput
                        updatedCoords={(payload: IChangeBerthLocationPayload) => setLocationPayload(payload)}
                        createdCoords={(payload: IChangeBerthLocationPayload) => setLocationPayload(payload)}
                        locationItem={locationType}
                    />
                </div>
            </ModalBody>
            <ModalFooter>
                <button className="btn btn-underline btn-underline-primary" onClick={() => toggleModalOpen()}>
                    <Translation text="buttons.cancel" />
                </button>
                <button className="btn btn-create" onClick={handleClick}>
                    <Translation text="buttons.update" />
                </button>
            </ModalFooter>
        </div>
    );
};

export default connect(() => ({}), {
    resetToInitialModalState,
    changeIsModalOpen,
    changeMarinaLocation,
})(SetLocationModal);
