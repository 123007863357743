import {createSelector} from '@reduxjs/toolkit';
import {RootState} from '../reducers';
import {ISagaState} from '../reducers/sagaSlice';

export const selectSaga = (state: RootState): ISagaState => {
    return state.saga;
};

export const activeLanguageSelector = createSelector([selectSaga], (state: ISagaState) => state.activeLanguage);

export const breadcrumbsSelector = createSelector([selectSaga], (state: ISagaState) => state.breadcrumbs);

export const contentWidthSelector = createSelector([selectSaga], (state: ISagaState) => state.contentWidth);

export const routerTransitionSelector = createSelector([selectSaga], (state: ISagaState) => state.routerTransition);

export const menuCollapsedSelector = createSelector([selectSaga], (state: ISagaState) => state.menuCollapsed);

export const menuHiddenSelector = createSelector([selectSaga], (state: ISagaState) => state.menuHidden);

export const showLoaderSelector = createSelector([selectSaga], (state: ISagaState) => state.showLoader);

export const fontSizeSelector = createSelector([selectSaga], (state: ISagaState) => state.fontSize);

export const contrastModeSelector = createSelector([selectSaga], (state: ISagaState) => state.contrastMode);
