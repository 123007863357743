import {IAPIHandler} from './api';
import {RxJsApiHandler} from './rxJsApiHandler';
import {MappingAPIHandlerDecorator} from './mappingAPIHandlerDecorator';
import {DefaultParamsAPIHandlerDecorator} from './defaultParamsAPIHandlerDecorator';
import {FilteringAPIHandlerDecorator} from './filteringAPIHandlerDecorator';
import memoizee from 'memoizee';
import {MemoizingAPIHandlerDecorator} from './memoizingAPIHandlerDecorator';

export enum ApiHandlerType {
    Rest,
}

export interface IAPIHandlerOptions<T = any, ParamsType = any> {
    readonly type?: ApiHandlerType; // Rest is default
    readonly url?: string;
    readonly defaultHeaders?: Object;
    readonly mapper?: (data: any) => T;
    readonly filter?: (data?: any, payload?: any, params?: ParamsType) => T;
    readonly isList?: boolean;
    readonly defaultParamsProvider?: (params?: ParamsType) => ParamsType;
    readonly memoize?: memoizee.Options<any>;
}

export function createAPIHandler(options: IAPIHandlerOptions): IAPIHandler<any> {
    let handler: IAPIHandler<any>;

    handler = new RxJsApiHandler(options.url, options.defaultHeaders) as any;

    if (options.mapper) {
        if (typeof options.isList !== 'boolean') {
            throw new Error(`Option "isList" is required when defining an API handler with a "mapper" option.`);
        }
        handler = new MappingAPIHandlerDecorator(handler, options.mapper, options.isList as boolean);
    }
    if (options.filter) {
        handler = new FilteringAPIHandlerDecorator(handler, options.filter);
    }
    if (options.defaultParamsProvider) {
        handler = new DefaultParamsAPIHandlerDecorator(handler, options.defaultParamsProvider);
    }
    if (options.memoize) {
        handler = new MemoizingAPIHandlerDecorator(handler, options.memoize);
    }
    return handler;
}
