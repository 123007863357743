import React, {useEffect, useState} from 'react';
import {Modal, ModalBody, ModalHeader} from 'reactstrap';
import {BoatCertificate, Form, FormControlChangeType, IFormConfig, Loader, LoaderType, Translation} from 'marine-panel-common-web';
import {BehaviorSubject, Subscription} from 'rxjs';
import {filter, share} from 'rxjs/operators';
import {addVesselTypeFormConfig} from './addVesselTypeFormConfig';
import {IVesselType} from '../../../../api/addVesselType';
import {addVesselType, changeIsActionComplete} from '../../../../store/reducers/vesselTypesPageSlice';
import {connect} from 'react-redux';
import {boatCertificatesSelector} from '../../../../store/selectors/boatCertificatesSelectors';
import {RootState} from '../../../../store/reducers';
import {
    isVesselTypeActionCompleteSelector,
    isVesselTypeActionProcessingSelector,
} from '../../../../store/selectors/vesselTypesPageSelectors';
import {fetchBoatCertificates, resetToInitialBoatCertificatesState} from '../../../../store/reducers/boatCertificatesSlice';

interface IConnectedAddVesselTypeModalProps {
    readonly boatCertificates: BoatCertificate[] | null;
    readonly isVesselTypeActionComplete: boolean;
    readonly isVesselTypeActionProcessing: boolean;
    readonly fetchBoatCertificates: typeof fetchBoatCertificates;
    readonly addVesselType: typeof addVesselType;
    readonly changeIsActionComplete: typeof changeIsActionComplete;
    readonly resetToInitialBoatCertificatesState: typeof resetToInitialBoatCertificatesState;
}

interface IExternalAddVesselTypeModalProps {
    readonly isModalShown: boolean;
    readonly toggleModal: () => void;
}

interface IAddVesselTypeModalProps extends IConnectedAddVesselTypeModalProps, IExternalAddVesselTypeModalProps {}

const AddVesselTypeModal: React.FC<IAddVesselTypeModalProps> = ({
    isModalShown,
    toggleModal,
    boatCertificates,
    addVesselType,
    fetchBoatCertificates,
    isVesselTypeActionComplete,
    isVesselTypeActionProcessing,
    changeIsActionComplete,
    resetToInitialBoatCertificatesState,
}) => {
    const [formValues, setFormValues] = useState<{[key: string]: any} | null>(null),
        [formConfig, setFormConfig] = useState<IFormConfig | null>(null),
        [onValueStateChange$] = useState(() => new BehaviorSubject<any>(null)),
        [onValueStateChange$$] = useState(() => onValueStateChange$.pipe(share())),
        subscriptions: Subscription[] = [];

    useEffect(() => {
        if (isVesselTypeActionComplete) {
            toggleModal();
        }
    }, [isVesselTypeActionComplete]);

    useEffect(() => {
        if (boatCertificates) {
            setForm();
        }

        return () => {
            resetToInitialBoatCertificatesState();
            changeIsActionComplete(false);
        };
    }, [boatCertificates]);

    useEffect(() => {
        setForm();
        subscriptions.push(
            onValueStateChange$$
                .pipe(
                    filter((data: {controlName?: string; value?: {[name: string]: string}; changeType?: FormControlChangeType}) => {
                        return data && data?.changeType === FormControlChangeType.User;
                    })
                )
                .subscribe((data) => {
                    if (data.controlName && data.value) {
                        setFormValues({
                            name: data.value.name,
                            class: data.value.class,
                        });
                    }
                })
        );
        return () => {
            subscriptions.forEach((subscription) => subscription.unsubscribe());
            setFormValues(null);
        };
    }, []);

    function onValueStateChange(controlName: string, value: any, changeType: FormControlChangeType) {
        onValueStateChange$.next({controlName: controlName, value: value, changeType: changeType});
    }

    function setForm() {
        const boatTypes = boatCertificates ? convertToMultiselectLabels(boatCertificates) : [];
        setFormConfig(
            addVesselTypeFormConfig(
                boatTypes,
                () => toggleModal(),
                (value: any, controlName: string) => handleMultiselectInputChange(value, controlName)
            )
        );
    }

    return (
        <Modal isOpen={isModalShown} toggle={() => toggleModal()}>
            <ModalHeader toggle={() => toggleModal()}>
                <Translation text="administration.vesselTypes.addVesselType.title" />
            </ModalHeader>
            <ModalBody>
                {formConfig && (
                    <Form
                        config={formConfig}
                        submitForm={vesselTypeAction}
                        onValueStateChange={onValueStateChange}
                        value={formValues}
                        controlName={'vesselTypeForm'}
                    />
                )}
            </ModalBody>
            <Loader type={LoaderType.Local} showLoader={isVesselTypeActionProcessing} />
        </Modal>
    );

    function vesselTypeAction() {
        if (null !== formValues) {
            const vesselType: IVesselType = {
                name: formValues.name,
                boatCertId: Number(formValues.class),
            };

            return addVesselType({vesselTypes: [vesselType]});
        }
    }

    function convertToMultiselectLabels(boatCertificates: BoatCertificate[]) {
        return boatCertificates.map((item) => {
            const boatCertificateLabel = `${item.className} (loa: ${item.loa}, imsl: ${item.imsl} wss: ${item.wss}, draft: ${item.draft}, beam: ${item.beam})`;
            return {label: boatCertificateLabel, value: String(item.id)};
        });
    }

    function handleMultiselectInputChange(value: string, controlName: string) {
        if (value.length >= 3) {
            if (controlName === 'class') {
                const queryParams = [{path: 'className', val: value}];
                fetchBoatCertificates(queryParams);
            }
        }
    }
};

export default connect(
    (state: RootState) => ({
        boatCertificates: boatCertificatesSelector(state),
        isVesselTypeActionComplete: isVesselTypeActionCompleteSelector(state),
        isVesselTypeActionProcessing: isVesselTypeActionProcessingSelector(state),
    }),
    {
        fetchBoatCertificates,
        addVesselType,
        changeIsActionComplete,
        resetToInitialBoatCertificatesState,
    }
)(AddVesselTypeModal);
