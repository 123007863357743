import {marineHubAPI, RestQueryParams} from 'marine-panel-common-web';
import {Observable} from 'rxjs';

export function inviteOperatorAPI(authToken: string | null, email: string): Observable<any> {
    const headers = {
            Authorization: `Bearer ${authToken}`,
        },
        payload = {
            email: email,
        };

    return marineHubAPI.post(`api/operators/invite`, payload, new RestQueryParams(), headers);
}
