import {Navigate} from 'react-router-dom';
import {isAuthenticatedSelector, UserRole, withRouterWrapper, WithRouterWrapperProps} from '../..';
import {connect} from 'react-redux';
import React, {useEffect} from 'react';
import {initAuthTokenChange} from '../../store/reducers/authSlice';
import {basicAccountMiscSelector} from '../../store/selectors/accountSelectors';

type Props = {
    isAuthenticated: boolean;
    children: any;
    initAuthTokenChange: typeof initAuthTokenChange;
    userRole: UserRole;
    accountMisc: string | null;
    authUrl?: string;
    checkIsBerthInit?: boolean;
    [key: string]: any;
} & WithRouterWrapperProps;

const PrivateRoute = ({
    isAuthenticated,
    authUrl = '/auth/login',
    children,
    checkIsBerthInit,
    initAuthTokenChange,
    userRole,
    router,
    accountMisc,
}: Props) => {
    useEffect(() => {
        if (!isProductionMode()) {
            if (router && router.location && router.location.search) {
                const token = router.location.search.split('?token=').pop();
                setAuthToken(token);
            }
        }
    }, []);

    const setAuthToken = (token: string | null) => {
        if (!token) {
            return;
        }

        initAuthTokenChange(token, userRole);
    };

    const isProductionMode = (): boolean => {
        return process.env.REACT_APP_ENV === 'prod';
    };
    const isBerthInitPassed = (): boolean => {
        if (!checkIsBerthInit) {
            return true;
        }
        if (!accountMisc) {
            return false;
        }
        return accountMisc.includes('berthInitPassed');
    };
    if (!isAuthenticated) {
        return <Navigate replace to={authUrl} />;
    }
    if (isBerthInitPassed() === false && checkIsBerthInit === true) {
        return <Navigate replace to="/berth-init" />;
    }

    return children;
};

export default connect(
    (state: any) => ({
        isAuthenticated: isAuthenticatedSelector(state),
        accountMisc: basicAccountMiscSelector(state),
    }),
    {initAuthTokenChange}
)(withRouterWrapper(PrivateRoute));
