import React from 'react';
import 'emoji-mart/css/emoji-mart.css';
import { withTranslation, WithTranslation } from 'react-i18next';
import ChatHeader from './ChatHeader';
import ChatList from './ChatList';
import {ChatContact, ChatMessage, FileStreamType, PeerConnection} from "../../../../types";
import {Observable} from "rxjs";

export interface IBaseChatProps {
    readonly addMessage: (comment: string) => void;
    readonly addFile: (data: FileStreamType) => void;
}

interface IChatViewProps extends WithTranslation, IBaseChatProps {
    readonly chatContact: ChatContact | undefined;
    readonly getMessagesFromUser: (accountId: string, page?: number) => Observable<boolean>;
    readonly messages: ChatMessage[];
    readonly connection: PeerConnection | null;
    readonly accountId: string;
    readonly userName: string;
    readonly error: string;
    readonly errorModuleHandle: (errorMessage: string) => void;
    readonly showOnlineIndicator?: boolean;
    readonly selectedChat: string | null;
    readonly confirmMessageRead: (messages: string[]) => void;
}

class ChatView extends React.Component<IChatViewProps> {

    render() {
        return (
            <div className='chat-app-window'>
                <div className='active-chat'>
                    {this.props.selectedChat &&
                        <ChatHeader
                            chatContact={this.props.chatContact}
                            peerUserName={this.props.userName}
                            showOnlineIndicator={this.props.showOnlineIndicator}
                        />
                    }
                    <ChatList
                        confirmMessageRead={this.props.confirmMessageRead}
                        getMessagesFromUser={this.props.getMessagesFromUser}
                        chatContact={this.props.chatContact}
                        selectedChat={this.props.selectedChat}
                        messages={this.props.messages}
                        connection={this.props.connection}
                        addMessage={this.props.addMessage}
                        addFile={this.props.addFile}
                        accountId={this.props.accountId}
                        error={this.props.error}
                        errorModuleHandle={this.props.errorModuleHandle}
                    />
                </div>
            </div>
        );
    }
}

export default withTranslation()(ChatView);
