import * as React from 'react';
import {useTranslation} from 'react-i18next';
import i18n from 'i18next';

type Props = {
    text: string;
    additionalData?: {[key: string]: string | number};
};

export const Translation = (props: Props) => {
    const {t} = useTranslation();

    return <React.Fragment>{t(props.text, props.additionalData ? props.additionalData : undefined)}</React.Fragment>;
};

export const translatePathToValue = (translationPath: string, variables?: {[key: string]: string | number}) =>
    i18n.t(translationPath, variables);
