import {Observable} from 'rxjs';
import {RestQueryParams} from '../base/queryParams';
import {LanguageLocaleType} from "../../constants/locales";
import {noTokenCheckMarineHubAPI} from '../provider/noTokenCheckMarineHubAPI';


export interface IRegisterPayload {
    username: string;
    password: string;
    locale: LanguageLocaleType;
    type: string;
    returnUrl: string;
}

export function registerAPI(payload: IRegisterPayload): Observable<any> {
    return noTokenCheckMarineHubAPI.post(
        `api/users/register`,
        payload,
        new RestQueryParams(),
        {}
    );
}
