import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {
    IBaseDictionaryState,
    IChangeIsInitialized,
    IChangeIsLoading,
    IChangePagePagination,
    IPaginationParams,
    ISetError,
    ISetPageMetadata,
} from './marinasSlice';
import {BoatCertificate, IModelApiResponseViewObject, IRawRestQueryParams} from 'marine-panel-common-web';

export interface IBoatCertificatesState extends IBaseDictionaryState {
    boatCertificates: BoatCertificate[] | null;
    boatCertificatesMetadata: IModelApiResponseViewObject | null;
    pagination: IPaginationParams | null;
}

export interface ISetBoatCertificates {
    readonly boatCertificates: BoatCertificate[] | null;
}

export interface IFetchBoatCertificates {
    params?: IRawRestQueryParams | null;
}

const initialState: IBoatCertificatesState = {
    boatCertificates: null,
    boatCertificatesMetadata: null,
    pagination: {
        page: 1,
        itemsPerPage: 10,
    },
    isLoading: false,
    isInitialized: false,
    error: null,
};

const boatCertificatesSlice = createSlice({
    name: 'boatCertificates',
    initialState: initialState,
    reducers: {
        changeBoatCertificates: {
            reducer: (state: IBoatCertificatesState, action: PayloadAction<ISetBoatCertificates>) => {
                return {
                    ...state,
                    boatCertificates: action.payload.boatCertificates,
                };
            },
            prepare(boatCertificates: BoatCertificate[] | null) {
                return {
                    payload: {boatCertificates: boatCertificates},
                };
            },
        },
        changeIsBoatCertificatesLoading: {
            reducer: (state: IBoatCertificatesState, action: PayloadAction<IChangeIsLoading>) => {
                return {
                    ...state,
                    isLoading: action.payload.isLoading,
                };
            },
            prepare(isLoading: boolean) {
                return {
                    payload: {isLoading: isLoading},
                };
            },
        },
        changeIsBoatCertificatesInitialized: {
            reducer: (state: IBoatCertificatesState, action: PayloadAction<IChangeIsInitialized>) => {
                return {
                    ...state,
                    isInitialized: action.payload.isInitialized,
                };
            },
            prepare(isInitialized: boolean) {
                return {
                    payload: {isInitialized: isInitialized},
                };
            },
        },
        setBoatCertificatesError: {
            reducer: (state: IBoatCertificatesState, action: PayloadAction<ISetError>) => {
                return {
                    ...state,
                    error: action.payload.error,
                };
            },
            prepare(error: string | null) {
                return {
                    payload: {error: error},
                };
            },
        },
        setBoatCertificatesMetadata: {
            reducer: (state: IBoatCertificatesState, action: PayloadAction<ISetPageMetadata>) => {
                return {
                    ...state,
                    boatCertificatesMetadata: action.payload.metadata,
                };
            },
            prepare(metadata: IModelApiResponseViewObject | null) {
                return {
                    payload: {
                        metadata: metadata,
                    },
                };
            },
        },
        changeBoatCertificatesPagination: {
            reducer: (state: IBoatCertificatesState, action: PayloadAction<IChangePagePagination>) => {
                return {
                    ...state,
                    isLoading: true,
                    pagination: action.payload.pagination,
                };
            },
            prepare(pagination: IPaginationParams) {
                return {
                    payload: {pagination: pagination},
                };
            },
        },
        fetchBoatCertificates: {
            reducer: (state: IBoatCertificatesState) => {
                return {
                    ...state,
                    isLoading: true,
                };
            },
            prepare(params: IRawRestQueryParams | null) {
                return {
                    payload: {params},
                };
            },
        },
        resetToInitialBoatCertificatesState: () => {
            return {
                ...initialState,
            };
        },
    },
});

export const {
    changeBoatCertificates,
    changeIsBoatCertificatesLoading,
    changeIsBoatCertificatesInitialized,
    setBoatCertificatesError,
    fetchBoatCertificates,
    changeBoatCertificatesPagination,
    setBoatCertificatesMetadata,
    resetToInitialBoatCertificatesState,
} = boatCertificatesSlice.actions;

export default boatCertificatesSlice.reducer;
