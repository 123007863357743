import {
    Money,
    Currency,
    MoneyInput,
    PricingStrategyCalendarRule,
    PricingStrategyCalendarRuleInput,
    deepCloneObject,
} from 'marine-panel-common-web';

// Edit wizard subsection scroller helper function

export function scrollSmoothTo(elementId: string, navigation: any) {
    var element = document.getElementById(elementId);

    if (!element) return;
    element.scrollIntoView({
        block: 'start',
        behavior: 'smooth',
    });
    navigation(`#${elementId}`);
}

export const convertPriceToInputValue = (price: string): string => {
    return Math.floor(Number(price) / 100).toString();
};

export function transformValueToPrice(value: string): Money {
    return {
        amount: value,
        currency: {
            name: Currency.EURO,
        },
    };
}

export function transformValueToPayloadPrice(value: string): Money {
    const convertedValue = Math.floor(Number(value) * 100);
    return {
        amount: convertedValue.toString(),
        currency: {
            name: Currency.EURO,
        },
    };
}

export function transformValueToMoneyInput(value: string): MoneyInput {
    const convertedValue = Math.floor(Number(value) * 100);
    return {
        amount: convertedValue.toString(),
        currency: Currency.EURO,
    };
}
export function transformMoneyToMoneyInput(price: Money): MoneyInput {
    return {
        amount: price.amount.toString(),
        currency: price.currency.name,
    };
}

export function transformCalendarRulesToPayloadInputs(calendarRules: PricingStrategyCalendarRule[]): PricingStrategyCalendarRuleInput[] {
    const transformedCalendarRules: PricingStrategyCalendarRuleInput[] = [];
    calendarRules.forEach((rule) => {
        if (rule.price) {
            transformedCalendarRules.push({
                ...deepCloneObject(rule),
                price: transformMoneyToMoneyInput(rule.price),
            });
        }
    });
    return transformedCalendarRules;
}
