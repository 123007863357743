import {Marina, Translation} from 'marine-panel-common-web';
import {FC} from 'react';
import {ModalBody, ModalFooter, ModalHeader} from 'reactstrap';
import {deletePhoto} from '../../../../store/reducers/marinaEditWizardSlice';
import styles from '../style.module.scss';

interface IDeletePhotoModalProps {
    readonly deletePhoto: typeof deletePhoto;
    readonly marina: Marina | null;
    readonly photoId: string | null;
    readonly toggleModalOpen: () => void;
}

const DeletePhotoModal: FC<IDeletePhotoModalProps> = ({deletePhoto, marina, photoId, toggleModalOpen}) => {
    return (
        <>
            <ModalHeader className="delete-modal" toggle={() => toggleModalOpen()}>
                <Translation text="modal.deletePhoto.title" />
            </ModalHeader>
            <ModalBody className={styles.container}>
                <div className={styles.content}>
                    <p>
                        <Translation text="modal.deletePhoto.description" />
                    </p>
                </div>
            </ModalBody>
            <ModalFooter>
                <button className="btn btn-underline btn-underline-primary close-modal-button" onClick={() => toggleModalOpen()}>
                    <Translation text="buttons.cancel" />
                </button>
                <button className="btn btn-create delete-photo-button" onClick={() => deletePhoto(photoId, marina?.id)}>
                    <Translation text="buttons.delete" />
                </button>
            </ModalFooter>
        </>
    );
};

export default DeletePhotoModal;
