import React from 'react';
import {Location, NavigateFunction, Params, useLocation, useNavigate, useParams} from 'react-router-dom';

export interface RouterProps {
    navigate: NavigateFunction;
    readonly params: Params<string>;
    location: Location;
}

export const withRouter = (Component: React.ComponentType<any>) => {
    const WithRouter = (props: any) => {
        const location = useLocation();
        const navigate = useNavigate();
        const params = useParams();
        return <Component {...props} location={location} navigate={navigate} params={params} />;
    };
    return WithRouter;
};
