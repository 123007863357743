import React from 'react';
import {connect} from 'react-redux';
import {Uploader} from 'rsuite';
import {accountSelector, authTokenSelector, isNullOrUndefined, isSameValue, Loader, updateAccount} from '../..';
import {API_URL} from '../../config/entrypoint';
import {RootState} from '../../store/reducers';
import {FileType, LoaderType} from '../../types';
import {deepCloneObject} from '../..';
import Translation from '../Translation';
import styles from './styles.module.scss';
import {IModelMediaObject} from '../../model/mediaObject';
import {IFileOutput} from '../../model/user';

export interface IFileUploadListElement {
    name: string;
    fileKey: string;
    url: string;
}

interface IConnectedAccountImageHostProps {
    readonly authToken: string;
    readonly account: any;
    readonly updateAccount: typeof updateAccount;
}

interface IExtendedAccountImageHostProps {
    readonly notificationCount?: number;
    readonly onImageChange?: (imageId: string) => void;
}

interface IAccountImageHostProps extends IConnectedAccountImageHostProps, IExtendedAccountImageHostProps {}

interface IAccountImageHostState {
    accountImage: string | Blob | null;
    value: any[];
    userImage: IFileUploadListElement | null;
    isProcessing: boolean;
    inputFileUploaded: boolean;
}

class AccountImageHost extends React.Component<IAccountImageHostProps, IAccountImageHostState> {
    constructor(props: IAccountImageHostProps) {
        super(props);
        this.state = {
            accountImage: null,
            userImage: null,
            value: [],
            isProcessing: false,
            inputFileUploaded: false,
        };
    }

    componentDidMount() {
        this.getAccountImage();
    }

    componentDidUpdate(prevProps: Readonly<IAccountImageHostProps>): void {
        if (!isSameValue(this.props.account, prevProps.account)) {
            this.getAccountImage();
        }
    }

    render() {
        // if (!this.props.account) {
        //     return null;
        // }
        return (
            <div className={`${styles.userInfoImageWrapper} ${styles['edit-mode']}`}>
                <Loader type={LoaderType.Local} showLoader={this.state.isProcessing} />
                {this.renderPlaceholder()}
                <Uploader
                    draggable
                    action={''}
                    removable={false}
                    multiple={false}
                    shouldQueueUpdate={this.shouldUpdate}
                    className={`profile-image-uploader ${this.state.accountImage || this.state.userImage ? 'visible' : ''}`}
                    accept="image/png, image/jpeg"
                    listType="picture"
                    onChange={this.addFile}
                    autoUpload={false}
                    fileList={this.state.value}>
                    <div>
                        <button className="btn btn-primary select-file-button">
                            <Translation text="buttons.choosePhoto" />
                        </button>
                    </div>
                </Uploader>

                <div className={styles.removeContainer}>
                    {this.state.accountImage ? (
                        <button className="btn btn-primary save-changes-button" onClick={() => this.saveImage()}>
                            <Translation text={`buttons.${this.state.userImage ? 'change' : 'upload'}`} />
                        </button>
                    ) : null}

                    {this.state.accountImage ? (
                        <button className="btn btn-outline-primary remove-file-button" onClick={() => this.removeImage()}>
                            <Translation text={`buttons.remove`} />
                        </button>
                    ) : null}
                </div>
            </div>
        );
    }

    private shouldUpdate = (fileList: FileType[], newFile: FileType | FileType[]) => {
        const clonedNewFileObj = Object.assign({}, newFile as any),
            fileItem = clonedNewFileObj[0];
        if (fileItem && fileItem.blobFile) {
            const itShouldUpdate = fileItem.blobFile.size < 1048576;
            if (!itShouldUpdate) {
                // this.alertManagerService.addAlert('profileImageComponent.buttons.imageTooBig');
            }
            return itShouldUpdate;
        }
        return false;
    };

    private addFile = (file: any) => {
        return this.setState({
            value: file.slice(file.length - 1),
            accountImage: file.length > 1 ? file[1].blobFile : file[0].blobFile,
        });
    };

    private removeImage = () => {
        if (this.state.userImage) {
            return this.setState({value: [this.state.userImage], accountImage: null});
        }
        return this.setState({value: [], accountImage: null});
    };

    private saveImage = (file?: any) => {
        if (!this.state.accountImage && !file) {
            return;
        }
        this.setState({isProcessing: true});
        const formData = new FormData();
        const imageData = this.state.accountImage;
        formData.append('file', imageData);
        formData.append('public', 'true');

        window
            .fetch(`${API_URL as string}/api/media_objects`, {
                method: 'POST',
                body: formData,
                headers: new Headers({
                    Authorization: 'Bearer ' + this.props.authToken,
                }),
            })
            .then((resp: any) => {
                return resp.json().then((fileUploadObj: IModelMediaObject) => {
                    if (fileUploadObj) {
                        // let updatedAccount = deepCloneObject(this.props.account);
                        // if (updatedAccount === null) {
                        //     updatedAccount = {
                        //         avatar: {
                        //             id: fileUploadObj.id,
                        //             contentUrls: fileUploadObj.contentUrls
                        //         }
                        //     }
                        // } else {
                        //     updatedAccount.avatar = {
                        //         id: fileUploadObj.id,
                        //         contentUrls: fileUploadObj.contentUrls
                        //     }
                        // }

                        const contentUrl = fileUploadObj.contentUrls.original.substring(
                                fileUploadObj.contentUrls.original.lastIndexOf('/') + 1
                            ),
                            avatar: IFileOutput = {
                                id: fileUploadObj.id,
                                fileUrls: [],
                                contentUrl: contentUrl,
                                // contentUrl: fileUploadObj.contentUrls
                            },
                            mediaList: IFileUploadListElement = {
                                name: 'avatar',
                                fileKey: fileUploadObj.id,
                                url: fileUploadObj.contentUrls.mediumThumb,
                            };
                        //
                        let updatedAccount = deepCloneObject(this.props.account);
                        const payload: any = {
                            avatar: avatar,
                            accountId: updatedAccount.id,
                        };
                        // this.props.changeAccountAvatar(payload);
                        this.setState({isProcessing: false, userImage: mediaList, value: [mediaList]});
                    }
                });
            });
    };

    private deleteImage = () => {
        this.setState({isProcessing: true});
        let updatedAccount = deepCloneObject(this.props.account);
        updatedAccount.logo = updatedAccount.logo?.id ? updatedAccount.logo.id : updatedAccount.logo;
        updatedAccount.avatar = null;
        // this.props.changeAccountAvatar(null);
        this.setState({isProcessing: false, accountImage: null});
    };

    private getAccountImage = () => {
        // toDo check if organization or candidat
        if (isNullOrUndefined(this.props.account) && isNullOrUndefined(this.props.account.avatar)) {
            this.setState({userImage: null, value: []});
        } else {
            const imageFile = this.props.account?.avatar,
                imageId = imageFile?.id;
            let mediaList: IFileUploadListElement = {
                name: 'avatar',
                fileKey: imageId,
                url: imageFile?.contentUrls?.mediumThumb,
            };
            this.setState({userImage: mediaList, value: [mediaList]});
        }
    };

    private renderPlaceholder = () => {
        if (this.state.userImage || this.state.accountImage || this.state.value.length) {
            return null;
        }

        return (
            <div className={styles.userPhotoPlaceholder}>
                <span>{this.renderUserInitials()}</span>
            </div>
        );
    };

    private renderUserInitials = () => {
        if (!this.props.account || !this.props.account.firstName || !this.props.account.lastName) {
            return 'XX';
        }

        if (this.props.account.name) {
            return this.props.account.name.charAt(0) || '';
        }

        return `${this.props.account.firstName.charAt(0)} ${this.props.account.lastName.charAt(0)}`.toUpperCase();
    };
}

export default connect(
    (state: RootState) => ({
        authToken: authTokenSelector(state),
        account: accountSelector(state),
    }),
    {
        updateAccount,
    }
)(AccountImageHost);
