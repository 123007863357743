import {createAPIHandler} from '../base/apiFactory';
import {API_URL} from '../../config/entrypoint';

export const noTokenCheckMarineHubAPI = createAPIHandler(
    {
        url: API_URL,
        defaultHeaders: {
            Accept: 'application/ld+json',
            'Content-type': 'application/json',
        },
        mapper: (data: any): any => {
            return data;
        },
        isList: false,
    }
);
