import React, {Component} from 'react';
import {getDisplayName, IModelAccount} from '../../model/account';

interface IUserDisplayNameProps {
    account: IModelAccount;
}

class UserDisplayName extends Component<IUserDisplayNameProps> {
    render() {
        return <>{getDisplayName(this.props.account)}</>;
    }
}

export default UserDisplayName;
