export type ICustomTimezone = {
        [key: string]: string
}
export const i18nTimezones: ICustomTimezone = {
        'America/Godthab': 'Greenland',
        'Atlantic/Azores': 'Azores',
        'Atlantic/Cape_Verde': 'Cape Verde Islands',
        GMT: 'UTC',
        'Europe/London': 'Edinburgh, London',
        'Europe/Dublin': 'Dublin',
        'Europe/Lisbon': 'Lisbon',
        'Africa/Casablanca': 'Casablanca, Monrovia',
        'Atlantic/Canary': 'Canary Islands',
        'Europe/Belgrade': 'Belgrade, Bratislava, Budapest, Ljubljana, Prague',
        'Europe/Sarajevo': 'Sarajevo, Skopje, Warsaw, Zagreb',
        'Europe/Brussels': 'Brussels, Copenhagen, Madrid, Paris',
        'Europe/Amsterdam': 'Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna',
        'Africa/Algiers': 'West Central Africa',
        'Europe/Bucharest': 'Bucharest',
        'Africa/Cairo': 'Cairo',
        'Europe/Helsinki': 'Helsinki, Kiev, Riga, Sofia, Tallinn, Vilnius',
        'Europe/Athens': 'Athens, Istanbul, Minsk',
        'Asia/Jerusalem': 'Jerusalem',
        'Africa/Harare': 'Harare, Pretoria',
        'Europe/Moscow': 'Moscow, St. Petersburg, Volgograd',
        'Asia/Kuwait': 'Kuwait, Riyadh',
        'Africa/Nairobi': 'Nairobi',
        'Asia/Baghdad': 'Baghdad',
};
