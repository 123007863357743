import {
    collectionInputDataMapper,
    collectionOutputDataMapper,
    FormButtonType,
    FormControlType,
    IFormConfig,
    IMultiselectOption,
    InputType,
    MultiSelectType,
    ValidationRules,
} from 'marine-panel-common-web';

export const addVesselTypeFormConfig = (
    vesselTypes: IMultiselectOption[],
    dismissModal: () => void,
    handleMultiselectInputChange?: any
): IFormConfig => ({
    controlType: 'form',
    class: 'default-form vessel-type-form',
    dataAccessor: (data: any) => data,
    outputDataMapper: (data: any, previousStateSnapshot: any) => {
        Object.assign(previousStateSnapshot, data);
        return previousStateSnapshot;
    },
    controls: [
        {
            key: 'name',
            controlType: 'group',
            class: 'row custom-row',
            controls: {
                name: {
                    controlType: 'control',
                    defaultValue: '',
                    formControlType: FormControlType.INPUT,
                    validationRules: [{name: ValidationRules.IS_REQUIRED, params: {}}],
                    placeholder: 'administration.vesselTypes.addVesselType.form.placeholders.name',
                    isLabelHidden: false,
                    label: 'administration.vesselTypes.addVesselType.form.labels.name',
                    type: InputType.TEXT,
                    hostClass: 'col-xl-12 form-control',
                },
            },
        },
        {
            key: 'class',
            controlType: 'group',
            class: 'row custom-row',
            controls: {
                class: {
                    controlType: 'control',
                    defaultValue: '',
                    formControlType: FormControlType.AUTOCOMPLETE,
                    multiselectType: MultiSelectType.SINGLE,
                    multiselectOptions: vesselTypes ? vesselTypes : [],
                    validationRules: [{name: ValidationRules.IS_REQUIRED, params: {}}],
                    placeholder: 'administration.vesselTypes.addVesselType.form.placeholders.class',
                    label: 'administration.vesselTypes.addVesselType.form.labels.class',
                    hostClass: 'col-xl-12 form-control',
                    inputDataMapper: collectionInputDataMapper,
                    outputDataMapper: collectionOutputDataMapper,
                    openMenuOnClick: false,
                    hideValueOnFocus: true,
                    handleMultiselectInputChange: (value: any) => {
                        if (handleMultiselectInputChange) {
                            return handleMultiselectInputChange(value, 'class');
                        }
                    },
                },
            },
        },
        {
            controlType: 'group',
            key: 'add_vessel_model_buttons',
            class: 'row justify-content-end',
            controls: {
                previousButton: {
                    buttonName: 'cancel',
                    controlType: 'control',
                    formControlType: FormControlType.BUTTON,
                    buttonType: FormButtonType.BUTTON,
                    inputStyles: 'btn-underline btn-underline-primary d-flex align-items-center',
                    hostClass: 'mb-0 w-auto',
                    disabled: false,
                    defaultContainerStyles: '',
                    containerStyles: '',
                    defaultValue: null,
                    btnText: 'buttons.cancel',
                    buttonDisabled: () => {
                        return false;
                    },
                    customClickHandler: () => {
                        return dismissModal();
                    },
                },
                submitButton: {
                    buttonName: 'submit',
                    controlType: 'control',
                    formControlType: FormControlType.BUTTON,
                    buttonType: FormButtonType.SUBMIT,
                    inputStyles: 'btn-submit btn-create d-flex align-items-center',
                    hostClass: 'mb-0 align-items-end w-auto',
                    defaultContainerStyles: '',
                    containerStyles: '',
                    defaultValue: null,
                    btnText: 'buttons.submit',
                    buttonDisabled: (mappedOutputValue: any, isFormValid: boolean) => {
                        return !isFormValid;
                    },
                },
            },
        },
    ],
});
