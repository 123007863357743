import React from 'react';
import Avatar from "../../../../Avatar";
import {Coffee, Menu} from 'react-feather';
import {AvatarColor, AvatarStatus, ChatContact, ChatType} from "../../../../../types";
import {IconSize} from '../../../../../constants/miscellaneous';

interface IChatHeaderProps {
    readonly peerUserName: string;
    readonly showOnlineIndicator?: boolean;
    readonly chatContact: ChatContact | undefined;
}

class ChatHeader extends React.Component<IChatHeaderProps> {

    render() {
        return (
            <React.Fragment>
                <div className='chat-navbar'>
                    <header className='chat-header'>
                        <div className='d-flex align-items-center'>
                            <div className='d-block d-lg-none me-1' >
                                <Menu size={IconSize.ChatHeaderMenu} />
                            </div>
                            <Avatar
                                imgHeight='36'
                                imgWidth='36'
                                img={this.props.chatContact.avatarUrl ? this.props.chatContact.avatarUrl : undefined}
                                icon={<Coffee size={IconSize.ChatAvatar}/>}
                                className='avatar-border'
                                color={AvatarColor.PRIMARY}
                                status={this.props.showOnlineIndicator ? AvatarStatus.ONLINE : AvatarStatus.OFFLINE}
                                tag={'div'}
                            />
                            <h6 className='mb-0'>{this.props.peerUserName}</h6>
                        </div>
                    </header>
                </div>
                {/*<div className={styles.chatHeader}>*/}
                {/*    <p>{this.props.peerUserName}</p>*/}
                {/*    {this.props.showOnlineIndicator &&*/}
                {/*    <div className={styles.status}>*/}
                {/*        <span className={styles.statusIcon}/>*/}
                {/*        <p className={styles.statusInfo}><Translation text="videoChat.chat.status.online"/></p>*/}
                {/*    </div>*/}
                {/*    }*/}
                {/*</div>*/}
            </React.Fragment>
        );
    }
}

export default ChatHeader;
