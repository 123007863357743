import {RestQueryParams, marineHubAPI} from 'marine-panel-common-web';
import {Observable} from 'rxjs';

export interface ITagPayload {
    marinaTagId: string;
}

export function addMarinaTagAPI(id: string | null, authToken: string | null, payload: ITagPayload | null): Observable<any> {
    const headers = {
        Authorization: `Bearer ${authToken}`,
    };

    return marineHubAPI.put(`api/marinas/${id}/add_tag`, payload, new RestQueryParams(), headers);
}
