import React from 'react';
import {Translation} from 'marine-panel-common-web';
import styles from './styles.module.scss';

interface IConnectedRecommendationOfferModalProps {}

interface IExternalConfirmOperatorInvitationProps {
    readonly toggleModalContent: () => void;
    readonly sendInvitation: () => void;
}

interface IConfirmOperatorInvitationProps extends IConnectedRecommendationOfferModalProps, IExternalConfirmOperatorInvitationProps {}

const ConfirmOperatorInvitation: React.FC<IConfirmOperatorInvitationProps> = ({toggleModalContent, sendInvitation}) => {
    return (
        <div className={styles.modalContainer}>
            <p className={styles.subtitle}>
                <Translation text="operators.confirmInvitation.subtitle" />
            </p>

            <p className={styles.description}>
                <Translation text="operators.confirmInvitation.description" />
            </p>

            <div className={styles.btnContainer}>
                <button
                    className="btn btn-underline btn-underline-primary me-1 cancel-invitation-button"
                    onClick={() => toggleModalContent()}>
                    <Translation text="buttons.cancel" />
                </button>

                <button className="invite-operator-button btn btn-create" onClick={() => sendInvitation()}>
                    <Translation text="buttons.confirm" />
                </button>
            </div>
        </div>
    );
};

export default ConfirmOperatorInvitation;
