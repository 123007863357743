import {InputType, ValidationRules, FormControlType, IFormConfig, FormButtonType} from 'marine-panel-common-web';

export const marinaEmailFormConfig = (value?: any, dismissModal?: () => void): IFormConfig => {
    return {
        controlType: 'form',
        class: 'default-form',
        dataAccessor: (data: any) => data,
        outputDataMapper: (data: any, previousStateSnapshot: any) => {
            Object.assign(previousStateSnapshot, data);
            return previousStateSnapshot;
        },
        controls: [
            {
                key: 'marina_email',
                controlType: 'group',
                class: 'row',
                controls: {
                    email: {
                        controlType: 'control',
                        defaultValue: value ? value : '',
                        formControlType: FormControlType.INPUT,
                        validationRules: [{name: ValidationRules.IS_REQUIRED, params: {}}],
                        placeholder: '',
                        isLabelHidden: false,
                        label: '',
                        type: InputType.TEXT,
                        hostClass: 'col-xl-12 form-control',
                    },
                },
            },
            {
                controlType: 'group',
                key: 'change_email_buttons',
                class: 'edit-form-control-footer native',
                controls: {
                    cancelButton: {
                        buttonName: 'cancel',
                        controlType: 'control',
                        formControlType: FormControlType.BUTTON,
                        buttonType: FormButtonType.BUTTON,
                        inputStyles: 'sm-btn btn btn-underline btn-underline-primary',
                        hostClass: 'mb-0 w-auto',
                        disabled: false,
                        defaultContainerStyles: '',
                        containerStyles: '',
                        defaultValue: null,
                        btnText: 'buttons.cancel',
                        buttonDisabled: () => {
                            return false;
                        },
                        customClickHandler: () => {
                            return dismissModal ? dismissModal() : null;
                        },
                    },
                    submitButton: {
                        buttonName: 'submit',
                        controlType: 'control',
                        formControlType: FormControlType.BUTTON,
                        buttonType: FormButtonType.SUBMIT,
                        inputStyles: 'sm-btn btn btn-create',
                        hostClass: 'mb-0 align-items-end w-auto',
                        defaultContainerStyles: '',
                        containerStyles: '',
                        defaultValue: null,
                        btnText: 'buttons.update',
                        buttonDisabled: (mappedOutputValue: any, isFormValid: boolean) => {
                            return !isFormValid;
                        },
                    },
                },
            },
        ],
    };
};
