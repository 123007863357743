import React from 'react';
import {getTrackBackground, Range} from 'react-range';
import {IRangeControlProps, IRangeControlState} from "../../../types";
import Translation from "../../Translation";
import {isNotNullOrUndefined, isSameValue} from "../../..";


type Props = IRangeControlProps;
type State = IRangeControlState;

class RangeControl extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
          values: this.props.defaultValue ? this.props.defaultValue : [0, this.props.maxValue]
        }
    }

    componentDidUpdate(prevProps: Readonly<Props>): void {
        if (!isSameValue(this.props.value, prevProps.value) &&
            isNotNullOrUndefined(this.props.value) &&
            (Array.isArray(this.props.value) && 0 === this.props.value.length)) {
            this.setState({values: this.props.defaultValue ? this.props.defaultValue : [0, this.props.maxValue]});
        }
    }

    render() {
        let values = this.state.values;
        return (
            <div className="range-control-wrapper">
                <div className="label-container">
                    <label className="label"><Translation text={this.props.label} /></label>
                    <output id="output" className="output">
                        <p>{this.state.values[0]} - {this.state.values[1]}</p>
                        <p className="output-unit">
                            <Translation text={this.props.valueUnit} />
                        </p>
                    </output>
                </div>

                <Range draggableTrack
                       values={this.state.values}
                       step={this.props.step}
                       min={this.props.minValue}
                       max={this.props.maxValue}
                       onChange={this.onRangeChange}
                       renderTrack={({ props, children }) => (
                           <div onMouseDown={props.onMouseDown}
                                onTouchStart={props.onTouchStart}
                                style={{...props.style}}
                                className="track">
                               <div ref={props.ref}
                                    style={{
                                        background: getTrackBackground({
                                            values,
                                            colors: ['#ccc', '#009fe3', '#ccc'],
                                            min: this.props.minValue,
                                            max: this.props.maxValue
                                        })
                                    }}
                                    className="track-background">
                                   {children}
                               </div>
                           </div>
                       )}
                       renderThumb={({ props }) => (
                           <div {...props}
                                style={{...props.style}}
                                className="thumb">
                           </div>
                       )}
                />
            </div>
        );
    }

    private onRangeChange = (values: number[]) => {
        this.setState({values: values});
        this.props.onChange(values);
    }
}

export default RangeControl;
