import * as React from 'react';
import {Translation, IconSize, isNullOrUndefined} from 'marine-panel-common-web';
import {X} from 'react-feather';

interface IEditFormControlProps {
    readonly currentItemId: string;
    readonly editedItemId: string | null;
    readonly setEditedItemId: (id: string | null) => void;
    readonly onConfirm: () => void;
    readonly editedChildren: any;
    readonly displayChildren: any;
    readonly titleTranslationKey: string | null;
    readonly mainTitle?: boolean;
    readonly confirmButtonsDisabled?: boolean;
    readonly children?: any;
}

const EditFormControl: React.FunctionComponent<IEditFormControlProps> = (props) => {
    const isEdited: boolean = props.editedItemId === props.currentItemId;

    const renderConfirmButtons = () => {
        if (isNullOrUndefined(props.confirmButtonsDisabled)) {
            return (
                <div className="edit-form-control-footer">
                    <button className="sm-btn btn btn-underline btn-underline-primary" onClick={() => props.setEditedItemId(null)}>
                        <Translation text="buttons.cancel" />
                    </button>
                    <button className="sm-btn btn btn-create" onClick={props.onConfirm}>
                        <Translation text="buttons.update" />
                    </button>
                </div>
            );
        }
    };
    return (
        <section
            id={props.currentItemId}
            data-section={props.currentItemId}
            className={`details-edit-form-control ${isEdited ? 'edit-mode' : ''}`}>
            <div className="edit-form-control-body-wrapper">
                <div className={`edit-form-control-header ${!props.titleTranslationKey ? 'justify-content-end' : ''}`}>
                    {props.titleTranslationKey && (
                        <h3 className={`title ${props.mainTitle ? 'main' : ''}`}>
                            <Translation text={props.titleTranslationKey} />
                        </h3>
                    )}
                    <button className="btn btn-underline" onClick={() => props.setEditedItemId(isEdited ? null : props.currentItemId)}>
                        {isEdited ? (
                            <X size={IconSize.BerthDeleteButton} />
                        ) : (
                            <span className="secondary-title edit">
                                <Translation text="buttons.edit" />
                            </span>
                        )}
                    </button>
                </div>
                {isEdited ? props.editedChildren : props.displayChildren}
            </div>
            {renderConfirmButtons()}
        </section>
    );
};

export default EditFormControl;
