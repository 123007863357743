import {Tabs, Translation} from 'marine-panel-common-web';
import React, {useState} from 'react';
import LayoutWrapper from '../LayoutWrapper';
import VesselModels from './VesselModels';
import VesselTypes from './VesselTypes';

interface IAdministrationProps {}

const Administration: React.FC<IAdministrationProps> = () => {
    const [selectedTab, setSelectedTab] = useState(0);

    const selectTab = (tabNumber: number) => {
        setSelectedTab(tabNumber);
    };

    return (
        <LayoutWrapper>
            <h2 className="section-title">
                <Translation text="administration.title" />
            </h2>

            <Tabs chosenTab={selectedTab} selectedTab={selectTab}>
                <div className="tab-headers">
                    <span>
                        <Translation text={'administration.tabs.vessels'} />
                    </span>
                    <span>
                        <Translation text={'administration.tabs.vesselModels'} />
                    </span>
                </div>
                <div className="tabs-content">
                    <div>
                        <VesselTypes />
                    </div>
                    <div>
                        <VesselModels />
                    </div>
                </div>
            </Tabs>
        </LayoutWrapper>
    );
};

export default Administration;
