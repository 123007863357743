import {
    addAlert,
    AlertType,
    ApiError,
    authTokenSelector,
    flattenObj,
    getMetadataDetails,
    handleApiError,
    IModelApiResponseViewObject,
    IRawRestQueryParams,
} from 'marine-panel-common-web';
import {combineEpics, Epic, ofType, StateObservable} from 'redux-observable';
import {Observable, of} from 'rxjs';
import {catchError, switchMap} from 'rxjs/operators';
import {RootState} from '../reducers';
import {
    changeIsSkippersPageLoading,
    changeSkippers,
    changeSkippersPagePagination,
    fetchSkippers,
    setSkippersPageError,
    setSkippersPageMetadata,
} from '../reducers/skippersPageSlice';
import {getSkippersAPI} from '../../api/getSkippers';
import {skippersPagePaginationSelector} from '../selectors/skippersPageSelectors';

const fetchSkippersEpic: Epic = (action$, state$: StateObservable<RootState>) => {
    return getSkippers(action$, state$, fetchSkippers);
};

const changeSkippersPaginationEpic: Epic = (action$, state$: StateObservable<RootState>) => {
    return getSkippers(action$, state$, changeSkippersPagePagination);
};

const getSkippers = (action$: Observable<any>, state$: StateObservable<RootState>, actionType: any) => {
    return action$.pipe(
        ofType(actionType.type),
        switchMap((): any => {
            const authToken = authTokenSelector(state$.value),
                paginationParams = skippersPagePaginationSelector(state$.value),
                params = paginationParams ? flattenObj(paginationParams) : null;

            return getSkippersList(authToken, params);
        }),
        catchError((error) => of(...errorActions(error)))
    );
};

export const getSkippersList = (authToken: string, params?: IRawRestQueryParams | null) => {
    return getSkippersAPI(authToken, params).pipe(
        switchMap((resp: any) => {
            const skipperList = resp['hydra:member'],
                metadata = getMetadataDetails(resp['hydra:view']),
                actions = successActions(skipperList, metadata);
            return of(...actions);
        }),
        catchError((error: any) => of(...errorActions(error)))
    );
};

const errorActions = (error: ApiError) => {
    const errorObj = handleApiError(error);
    errorObj.type = AlertType.WARNING;
    return [addAlert(errorObj), changeIsSkippersPageLoading(false), setSkippersPageError(errorObj.message)];
};

const successActions = (skippers: any[], metadata: IModelApiResponseViewObject | null): any[] => {
    return [changeSkippers(skippers), setSkippersPageMetadata(metadata), changeIsSkippersPageLoading(false)];
};

const skippersPageEpic = combineEpics(fetchSkippersEpic, changeSkippersPaginationEpic);

export default skippersPageEpic;
