import React, {useEffect, useState} from 'react';
import {DateComponent, IOperatorSubscription, Loader, LoaderType, Price, Translation} from 'marine-panel-common-web';
import {Badge, Modal, ModalBody, ModalHeader, Table} from 'reactstrap';
import {connect} from 'react-redux';
import {fetchOperatorSubscriptions, setOperatorSubscriptions} from '../../../store/reducers/operatorsPageSlice';
import {operatorSubscriptionsSelector} from '../../../store/selectors/operatorsPageSelectors';
import {RootState} from '../../../store/reducers';

interface IConnectedSubscriptionHistoryModalProps {
    readonly subscriptions: IOperatorSubscription[] | null;
    readonly fetchOperatorSubscriptions: typeof fetchOperatorSubscriptions;
    readonly setOperatorSubscriptions: typeof setOperatorSubscriptions;
}

interface IExternalSubscriptionHistoryModalProps {
    isModalOpen: boolean;
    toggleModal: (operatorId: string | null) => void;
    operatorId: string | null;
}

interface ISubscriptionHistoryModalProps extends IConnectedSubscriptionHistoryModalProps, IExternalSubscriptionHistoryModalProps {}

const SubscriptionHistoryModal: React.FC<ISubscriptionHistoryModalProps> = ({
    isModalOpen,
    toggleModal,
    subscriptions,
    operatorId,
    fetchOperatorSubscriptions,
    setOperatorSubscriptions,
}) => {
    const [isLoading, setIsLoading] = useState<boolean>(true);

    useEffect(() => {
        if (null !== operatorId) {
            fetchOperatorSubscriptions(operatorId);
        }

        return () => {
            // setIsLoading(true);
            setOperatorSubscriptions(null);
        };
    }, [operatorId]);

    useEffect(() => {
        if (subscriptions !== null) {
            setIsLoading(false);
        }
    }, [subscriptions]);

    return (
        <Modal isOpen={isModalOpen} toggle={() => toggleModal(null)}>
            <ModalHeader toggle={() => toggleModal(null)}>
                <Translation text="operators.subscriptionsModal.title" />
            </ModalHeader>
            <ModalBody>{renderSubscriptions()}</ModalBody>
            <Loader type={LoaderType.Local} showLoader={isLoading} />
        </Modal>
    );

    function renderSubscriptions() {
        return (
            <Table responsive className="operators-table">
                <thead>
                    <tr>
                        <th>
                            <Translation text="operators.subscriptionsModal.table.name" />
                        </th>
                        <th>
                            <Translation text="operators.subscriptionsModal.table.grossAmount" />
                        </th>
                        <th>
                            <Translation text="operators.subscriptionsModal.table.invoiceNumber" />
                        </th>
                        <th>
                            <Translation text="operators.subscriptionsModal.table.startDate" />
                        </th>
                        <th>
                            <Translation text="operators.subscriptionsModal.table.endDate" />
                        </th>
                        <th>
                            <Translation text="operators.subscriptionsModal.table.status" />
                        </th>
                    </tr>
                </thead>
                <tbody>{renderTableRows()}</tbody>
            </Table>
        );
    }

    function renderTableRows() {
        if (null === subscriptions || !subscriptions.length) {
            return (
                <tr>
                    <td colSpan={6}>
                        <Translation text="operators.table.noData" />
                    </td>
                </tr>
            );
        }

        return subscriptions.map((item: IOperatorSubscription) => {
            return (
                <tr key={item.id}>
                    <td>{item.name}</td>
                    <td>
                        <Price price={item.grossPrice} />
                    </td>
                    <td>{item.invoiceNumber}</td>
                    <td>{item.activeFrom ? <DateComponent date={item.activeFrom} format="DD/MM/YYYY" /> : '---'}</td>
                    <td>{item.activeUntil ? <DateComponent date={item.activeUntil} format="DD/MM/YYYY" /> : '---'}</td>
                    <td>
                        {item.current ? (
                            <Badge color={'primary'} className="" pill>
                                <Translation text="operators.subscriptionsModal.activeSubscription" />
                            </Badge>
                        ) : null}
                    </td>
                </tr>
            );
        });
    }
};

export default connect(
    (state: RootState) => ({
        subscriptions: operatorSubscriptionsSelector(state),
    }),
    {
        fetchOperatorSubscriptions,
        setOperatorSubscriptions,
    }
)(SubscriptionHistoryModal);
