import {RestQueryParams, marineHubAPI} from 'marine-panel-common-web';
import {Observable} from 'rxjs';
import {IChangeMarinaEmail} from '../../store/reducers/marinaEditWizardSlice';

export interface IChangeMarinaEmailInput {
    email: string;
}

export function changeMarinaEmailAPI(payload: IChangeMarinaEmail, authToken: string | null): Observable<any> {
    const headers = {
        Authorization: `Bearer ${authToken}`,
    };

    return marineHubAPI.put(`api/marinas/${payload.marinaId}/change_email`, payload.email, new RestQueryParams(), headers);
}
