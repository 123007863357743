import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {
    ChangeUserPasswordInput,
    DetachStripePaymentMethodInput,
    Locale,
    PaymentAccountOutput,
    ProfileSettingsModalForm,
    OperatorFullInfo,
    AccountBasicInfo,
    SkipperFullInfo,
} from '../../types';
import merge from 'lodash/merge';
import {initApiCall} from '../../utils/reduxHelpers';
import {isNotNullOrUndefined} from '../../utils/runtimeUtils';

export const NULL_ACCOUNT: OperatorFullInfo = {
    id: '',
    operator: {
        id: '',
        name: null,
        mobile: null,
        address: null,
        city: null,
        country: null,
    },
    user: {
        id: '',
        username: '',
        firstName: '',
        lastName: '',
        locale: '',
        avatar: null,
        mobile: null,
        misc: null, // Account.misc
    },
    misc: null,
    stripeChargesEnabled: false,
};

export interface IAccountState {
    account: OperatorFullInfo | SkipperFullInfo | null;
    misc: string | null;
    isLoading: boolean;
    isInitialized: boolean;
    error: string | null;
}

interface ISetAccountState {
    account: OperatorFullInfo | AccountBasicInfo | SkipperFullInfo;
}

export interface IUpdateAccount {
    accountId: string;
    account: ProfileSettingsModalForm;
}

export interface IUpdateSkipperFullAccount {
    accountPayload: IUpdateSkipperFullAccountPayload;
}

export interface IUpdateOperatorFullAccount {
    accountPayload: IUpdateOperatorFullAccountPayload;
}

export interface IGetFullAccount {
    accountId: string;
}

export interface IUpdateAvatar {
    avatar: any;
    accountId: string;
}

export interface IUpdateLocale {
    locale: Locale;
}

export interface IUpdateMisc {
    misc: string | null;
}

interface IUpdateAccountSuccess {
    account: OperatorFullInfo | AccountBasicInfo;
}

interface IUpdateAccountFailure {
    error: string;
}

export interface ISetAccountStateFailure {
    error: string;
}

interface ISetShowUpdateBasicInfoModal {
    showModal: boolean;
}

interface ISetShowChangeAvatarModal {
    showModal: boolean;
}

interface ISetShowChangePasswordModal {
    showModal: boolean;
}

export interface ISetShowCardRemoveModal {
    showModal: boolean;
}

export interface IRemovePaymentMethod {
    paymentAccountId: string;
    paymentMethodInput: DetachStripePaymentMethodInput;
}

export interface IChangeUserPasswordInput {
    passwordForm: ChangeUserPasswordInput;
}

export interface IUpdatePaymentAccountSuccess {
    paymentAccount: PaymentAccountOutput;
}

export interface IChangeUserPasswordInputSuccess {}

export interface IUpdateSkipperFullAccountPayload {
    firstName: string;
    lastName: string;
    mobile: string | null;
    mediaObjectId: string | null;
}

export interface IUpdateOperatorFullAccountPayload {
    operator: {
        operator: {
            name: string;
            address: string;
            city: string;
            countryId: string;
        }
    };
    firstName: string;
    lastName: string;
    mobile: string;
}

const initialState: IAccountState = {
    account: NULL_ACCOUNT,
    misc: null,
    isLoading: false,
    isInitialized: false,
    error: null,
};

export const mapResponseAccountToSkipperFullInfo = (response: any): SkipperFullInfo | null => {
    return response.skipper !== null
        ? {
              id: response.skipper.id,
              skipperId: response.skipper.skipperId,
              account: response.skipper.account,
              paymentAccount: response.skipper.paymentAccount,
              activeVessel: response.skipper.activeVessel,
          }
        : null;
};

export const mapResponseAccountToOperatorFullInfo = (response: any): OperatorFullInfo | null => {
    return response.operator !== null
        ? {
              id: response.operator.id,
              user: response.operator.user,
              operator: response.operator.operator,
              stripeChargesEnabled: false,
              misc: response.misc,
          }
        : null;
};

function actionPayloadIsOperatorAccount(data: OperatorFullInfo | AccountBasicInfo | SkipperFullInfo): data is OperatorFullInfo {
    return (
        isNotNullOrUndefined(data) &&
        isNotNullOrUndefined((data as OperatorFullInfo).operator) &&
        isNotNullOrUndefined((data as OperatorFullInfo).operator.id)
    );
}

function actionPayloadIsSkipperAccount(data: OperatorFullInfo | AccountBasicInfo | SkipperFullInfo): data is SkipperFullInfo {
    return isNotNullOrUndefined(data) && isNotNullOrUndefined((data as SkipperFullInfo).skipperId);
}

const accountSlice = createSlice({
    name: 'account',
    initialState: initialState,
    reducers: {
        setAccountState: {
            reducer: (state: IAccountState, action: PayloadAction<ISetAccountState>) => {
                const account: OperatorFullInfo | SkipperFullInfo = actionPayloadIsOperatorAccount(action.payload.account)
                    ? merge(
                          {},
                          NULL_ACCOUNT,
                          actionPayloadIsOperatorAccount(action.payload.account)
                              ? action.payload.account
                              : {account: action.payload.account}
                      )
                    : actionPayloadIsSkipperAccount(action.payload.account)
                    ? action.payload.account
                    : NULL_ACCOUNT;
                const updatedMisc = actionPayloadIsOperatorAccount(action.payload.account) ? action.payload.account.user.misc : null;
                return {
                    ...state,
                    account: account,
                    isInitialized: true,
                    isLoading: false,
                    misc: updatedMisc,
                };
            },
            prepare(account: OperatorFullInfo | AccountBasicInfo | SkipperFullInfo) {
                return {
                    payload: {
                        account: account,
                    },
                };
            },
        },
        updateAccountSuccess: {
            reducer: (state: IAccountState, action: PayloadAction<IUpdateAccountSuccess>) => {
                const updatedData = merge(
                    {},
                    state.account,
                    actionPayloadIsOperatorAccount(action.payload.account) ? action.payload.account : {account: action.payload.account}
                );
                const updatedMisc = action.payload.account.misc;
                return {
                    ...state,
                    isLoading: false,
                    showUpdateBasicInfoModal: false,
                    showChangeAvatarModal: false,
                    account: updatedData,
                    misc: updatedMisc,
                };
            },
            prepare(account: OperatorFullInfo | AccountBasicInfo) {
                return {
                    payload: {
                        account: account,
                    },
                };
            },
        },
        setPaymentAccountState: {
            reducer: (state: IAccountState, action: PayloadAction<IUpdatePaymentAccountSuccess>) => {
                const updatedData = state.account ? merge({}, state.account, {paymentAccount: action.payload.paymentAccount}) : null;
                return {
                    ...state,
                    isLoading: false,
                    showCardRemoveModal: false,
                    account: updatedData,
                };
            },
            prepare(paymentAccount: PaymentAccountOutput) {
                return {
                    payload: {
                        paymentAccount,
                    },
                };
            },
        },
        setAccountStateFailure: {
            reducer: (state: IAccountState, action: PayloadAction<ISetAccountStateFailure>) => {
                return {
                    ...state,
                    error: action.payload.error,
                };
            },
            prepare(error: string) {
                return {
                    payload: {
                        error: error,
                    },
                };
            },
        },
        updateAccountFailure: {
            reducer: (state: IAccountState, action: PayloadAction<IUpdateAccountFailure>) => {
                return {
                    ...state,
                    isInitialized: true,
                    error: action.payload.error,
                    isLoading: false,
                };
            },
            prepare(error: string) {
                return {
                    payload: {
                        error: error,
                    },
                };
            },
        },
        changePasswordSuccess: {
            reducer: (state: IAccountState) => {
                return {
                    ...state,
                    isLoading: false,
                    showChangePasswordModal: false,
                };
            },
            prepare() {
                return {
                    payload: {},
                };
            },
        },
        updateAccount: {
            reducer: (state: IAccountState) => {
                return {
                    ...state,
                    isLoading: true,
                };
            },
            prepare(accountId: string, account: ProfileSettingsModalForm) {
                return {
                    payload: {accountId: accountId, account: account},
                };
            },
        },
        getAccount: (state: IAccountState) => initApiCall(state),
        getFullAccount: (state: IAccountState, action: PayloadAction<IGetFullAccount>) => initApiCall(state, action),
        // updateAccount: (state: IAccountState, action: PayloadAction<IUpdateAccount>) => initApiCall(state, action),
        updateSkipperFullAccount: (state: IAccountState, action: PayloadAction<IUpdateSkipperFullAccountPayload>) =>
            initApiCall(state, action),
        updateOperatorFullAccount: (state: IAccountState, action: PayloadAction<IUpdateOperatorFullAccount>) =>
            initApiCall(state, action),
        changeAvatar: (state: IAccountState, action: PayloadAction<IUpdateAvatar>) => initApiCall(state, action),
        changeLocale: (state: IAccountState, action: PayloadAction<IUpdateLocale>) => initApiCall(state, action),
        changeMisc: (state: IAccountState, action: PayloadAction<IUpdateMisc>) => initApiCall(state, action),
        changePassword: (state: IAccountState, action: PayloadAction<IChangeUserPasswordInput>) => initApiCall(state, action),
        removePaymentMethod: (state: IAccountState, action: PayloadAction<IRemovePaymentMethod>) => initApiCall(state, action),
    },
});

export const {
    setAccountState,
    updateAccount,
    updateSkipperFullAccount,
    updateAccountSuccess,
    updateAccountFailure,
    setAccountStateFailure,
    getAccount,
    changeAvatar,
    getFullAccount,
    changePassword,
    changePasswordSuccess,
    changeLocale,
    changeMisc,
    removePaymentMethod,
    setPaymentAccountState,
    updateOperatorFullAccount,
} = accountSlice.actions;

export default accountSlice.reducer;
