import {marineHubAPI, RestQueryParams} from 'marine-panel-common-web';
import {Observable} from 'rxjs';

type MarinaStatusPayload = {
    marinaId: string;
    active: boolean;
};

export function changeMarinaActiveStatusAPI(statusPayload: MarinaStatusPayload, authToken: string | null): Observable<any> {
    const headers = {
            Authorization: `Bearer ${authToken}`,
        },
        payload = {
            active: statusPayload.active,
        };

    return marineHubAPI.put(`api/marinas/${statusPayload.marinaId}/change_active`, payload, new RestQueryParams(), headers);
}
