import React from 'react';
import {connect} from 'react-redux';
import {Uploader} from 'rsuite';
import {RootState} from '../../../store/reducers';
import Translation from '../../Translation';
import {addAlert, AlertType, authTokenSelector, IconSize} from '../../..';
import {convertApiErrorToErrorMessage} from '../../../utils/alertUtils';
import {FileType, IFileUploaderProps, IFileUploaderState} from '../../../types';
import {File} from 'react-feather';

type Props = IFileUploaderProps;
type State = IFileUploaderState;

class InputFileUploader extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            value: [],
            isProcessing: false,
            inputFileUploaded: false,
            progressValue: null,
        };
    }

    render() {
        return (
            <div className="uploader-control">
                <div className="uploader-input-icon">
                    <File size={IconSize.FileUploader} />
                </div>
                <div className="uploader-wrapper">
                    <Uploader
                        removable={this.props.isFileRemovable}
                        className={`form-input ${this.state.value.length > 0 ? 'uploaded' : 'empty'}`}
                        action={``}
                        multiple={false}
                        autoUpload={false}
                        onChange={this.handleChange}
                        headers={{Authorization: 'Bearer ' + this.props.authToken}}
                        onSuccess={this.handleUploadSuccess}
                        fileList={this.state.value}
                        onProgress={this.updateProgressData}
                        onRemove={(file: FileType) => this.removeFile(file)}
                        onError={this.handleUploadError}
                        onUpload={this.props.onFileUpload}
                        accept={this.props.acceptedFileExtension ? this.props.acceptedFileExtension : null}
                        listType="text">
                        {this.props.label && (
                            <div>
                                <button
                                    className={`${
                                        this.state.value.length ? 'btn btn-outline-primary' : 'btn btn-primary'
                                    } file-upload-button`}
                                    onClick={(e: any) => e.preventDefault()}>
                                    {this.state.value.length ? (
                                        <Translation text={`buttons.change`} />
                                    ) : (
                                        <Translation text={`buttons.add`} />
                                    )}
                                </button>
                            </div>
                        )}
                    </Uploader>
                    <div className="format-details">
                        {this.props.acceptedFileExtension ? <p>Upload document in {this.props.acceptedFileExtension} format</p> : null}
                    </div>
                </div>
            </div>
        );
    }

    private handleChange = (file: any) => {
        if (!file.length) {
            return;
        }
        this.props.onFileChange && this.props.onFileChange(file);
        return this.setState({
            value: file,
        });
    };

    private handleUploadSuccess = (response: any) => {
        if (response) {
            this.setState({inputFileUploaded: true, progressValue: null});
        }
    };

    private handleUploadError = (error: any) => {
        const message = convertApiErrorToErrorMessage(error);
        this.props.addAlert({message: message, type: AlertType.WARNING});
        this.setState({progressValue: null, inputFileUploaded: false});
    };

    private removeFile = (file: FileType) => {
        const uploadedFiles = this.state.value.filter((item: FileType) => item.fileKey !== file.fileKey);

        this.setState({value: uploadedFiles, inputFileUploaded: false});
    };

    private updateProgressData = (progressValue: number) => {
        this.setState({progressValue: progressValue});
    };
}

export default connect(
    (state: RootState) => ({
        authToken: authTokenSelector(state),
    }),
    {
        addAlert,
    }
)(InputFileUploader);
