import React, {Component} from 'react';
import {useLocation, useNavigate, useParams} from 'react-router-dom';

export const withRouterWrapper = (Component) => {
    function WithRouterComponent(props) {
        let location = useLocation();
        let navigate = useNavigate();
        let params = useParams();
        return <Component {...props} router={{location, navigate, params}} />;
    }
    return WithRouterComponent;
};
