import {PayloadAction} from '@reduxjs/toolkit';
import {
    addAlert,
    AlertType,
    ApiError,
    authTokenSelector,
    flattenObj,
    getMetadataDetails,
    handleApiError,
    IModelApiResponseViewObject,
    IRawRestQueryParams,
} from 'marine-panel-common-web';
import {combineEpics, Epic, ofType, StateObservable} from 'redux-observable';
import {Observable, of} from 'rxjs';
import {catchError, switchMap} from 'rxjs/operators';
import {RootState} from '../reducers';
import {
    bindMarinaToOperator,
    changeIsInvitationComplete,
    changeIsInvitationProcessing,
    changeIsMarinaActionComplete,
    changeIsMarinaActionProcessing,
    changeIsOperatorsPageLoading,
    changeOperators,
    changeOperatorsPagePagination,
    changeOperatorStatus,
    fetchConnectedMarinas,
    fetchOperators,
    fetchOperatorSubscriptions,
    IBindMarinaToOperator,
    IChangeOperatorStatus,
    IFetchConnectedOperatorMarinas,
    IFetchOperatorSubscriptions,
    IInviteOperator,
    inviteOperator,
    setOperatorsConnectedMarinas,
    setOperatorsPageError,
    setOperatorsPageMetadata,
    setOperatorSubscriptions,
    unbindMarinaFromOperator,
} from '../reducers/operatorsPageSlice';
import {getOperatorsAPI} from '../../api/getOperators';
import {inviteOperatorAPI} from '../../api/inviteOperator';
import {getOperatorSubscriptionsAPI} from '../../api/getOperatorSubscriptions';
import {changeOperatorStatusAPI} from '../../api/changeOperatorStatus';
import {operatorsPaginationSelector} from '../selectors/operatorsPageSelectors';
import {getMarinasAPI} from '../../api/marina/getMarinas';
import {assignOperatorToMarinaAPI} from '../../api/assignOperatorToMarinas';
import {unassignOperatorFromMarinaAPI} from '../../api/unassignOperatorFromMarinas';

const fetchOperatorsEpic: Epic = (action$: Observable<any>, state$: StateObservable<RootState>) => {
    return getOperators(action$, state$, fetchOperators);
};

const changeOperatorsPaginationEpic: Epic = (action$, state$: StateObservable<RootState>) => {
    return getOperators(action$, state$, changeOperatorsPagePagination);
};

const inviteOperatorEpic: Epic = (action$: Observable<any>, state$: StateObservable<RootState>) =>
    action$.pipe(
        ofType(inviteOperator.type),
        switchMap((action: PayloadAction<IInviteOperator>) => {
            const authToken = authTokenSelector(state$.value),
                email = action.payload.email;

            return inviteOperatorAPI(authToken, email).pipe(
                switchMap(() => {
                    const message = 'operators.confirmInvitation.invitationSent';
                    return of(changeIsInvitationProcessing(false), changeIsInvitationComplete(true), addAlert({message: message}));
                }),
                catchError((error: any) => of(...invitationErrorActions(error)))
            );
        }),
        catchError((error: any) => of(...invitationErrorActions(error)))
    );

const fetchOperatorSubscriptionsEpic: Epic = (action$: Observable<any>, state$: StateObservable<RootState>) =>
    action$.pipe(
        ofType(fetchOperatorSubscriptions.type),
        switchMap((action: PayloadAction<IFetchOperatorSubscriptions>) => {
            const authToken = authTokenSelector(state$.value),
                operatorId = action.payload.operatorId,
                params = [{path: 'operator.id', val: operatorId}];

            return getOperatorSubscriptionsAPI(authToken, params).pipe(
                switchMap((resp: any) => {
                    return of(setOperatorSubscriptions(resp['hydra:member']));
                }),
                catchError((error: any) => of(...errorActions(error)))
            );
        }),
        catchError((error: any) => of(...errorActions(error)))
    );

const changeOperatorStatusEpic: Epic = (action$: Observable<any>, state$: StateObservable<RootState>) => {
    return action$.pipe(
        ofType(changeOperatorStatus.type),
        switchMap((action: PayloadAction<IChangeOperatorStatus>): any => {
            const authToken = authTokenSelector(state$.value),
                operatorId = action.payload.operatorId,
                isActive = action.payload.isActive;

            return changeOperatorStatusAPI(authToken, operatorId, isActive).pipe(
                switchMap(() => {
                    const message = 'operators.operatorStatusUpdated';
                    return of(changeIsOperatorsPageLoading(false), addAlert({message: message}));
                }),
                catchError((error) => of(...errorActions(error)))
            );
        }),
        catchError((error) => of(...errorActions(error)))
    );
};

const assignOperatorToMarinaEpic: Epic = (action$: Observable<any>, state$: StateObservable<RootState>) => {
    return action$.pipe(
        ofType(bindMarinaToOperator.type),
        switchMap((action: PayloadAction<IBindMarinaToOperator>): any => {
            const authToken = authTokenSelector(state$.value),
                operatorId = action.payload.operatorId,
                marinaId = action.payload.marinaId;

            return assignOperatorToMarinaAPI(authToken, marinaId, operatorId).pipe(
                switchMap(() => {
                    const message = 'operators.addMarinaModal.marinaConnected';
                    return of(...updateSuccessActions(message, operatorId));
                }),
                catchError((error) => of(...updateErrorActions(error)))
            );
        }),
        catchError((error) => of(...updateErrorActions(error)))
    );
};

const unassignOperatorFromMarinaEpic: Epic = (action$: Observable<any>, state$: StateObservable<RootState>) => {
    return action$.pipe(
        ofType(unbindMarinaFromOperator.type),
        switchMap((action: PayloadAction<IBindMarinaToOperator>): any => {
            const authToken = authTokenSelector(state$.value),
                operatorId = action.payload.operatorId,
                marinaId = action.payload.marinaId;

            return unassignOperatorFromMarinaAPI(authToken, marinaId, operatorId).pipe(
                switchMap(() => {
                    const message = 'operators.addMarinaModal.marinaDisconnected';
                    return of(...updateSuccessActions(message, operatorId));
                }),
                catchError((error) => of(...updateErrorActions(error)))
            );
        }),
        catchError((error) => of(...updateErrorActions(error)))
    );
};

const fetchOperatorMarinasEpic: Epic = (action$: Observable<any>, state$: StateObservable<RootState>) =>
    action$.pipe(
        ofType(fetchConnectedMarinas.type),
        switchMap((action: PayloadAction<IFetchConnectedOperatorMarinas>) => {
            const authToken = authTokenSelector(state$.value),
                operatorId = action.payload.operatorId,
                params = [{path: 'operators.id', val: operatorId}];

            return getMarinasAPI(authToken, params).pipe(
                switchMap((resp: any) => {
                    return of(setOperatorsConnectedMarinas(resp['hydra:member']));
                }),
                catchError((error: any) => of(...errorActions(error)))
            );
        }),
        catchError((error: any) => of(...errorActions(error)))
    );

const getOperators = (action$: Observable<any>, state$: StateObservable<RootState>, actionType: any) => {
    return action$.pipe(
        ofType(actionType.type),
        switchMap((): any => {
            const authToken = authTokenSelector(state$.value),
                paginationParams = operatorsPaginationSelector(state$.value),
                params = paginationParams ? flattenObj(paginationParams) : null;

            return getOperatorsList(authToken, params);
        }),
        catchError((error) => of(...errorActions(error)))
    );
};

export const getOperatorsList = (authToken: string, params?: IRawRestQueryParams | null) => {
    return getOperatorsAPI(authToken, params).pipe(
        switchMap((resp: any) => {
            const operatorsList = resp['hydra:member'],
                metadata = getMetadataDetails(resp['hydra:view']),
                actions = successActions(changeOperators(operatorsList), metadata);
            return of(...actions);
        }),
        catchError((error: any) => of(...errorActions(error)))
    );
};

const errorActions = (error: ApiError) => {
    const errorObj = handleApiError(error);
    errorObj.type = AlertType.WARNING;
    return [addAlert(errorObj), changeIsOperatorsPageLoading(false), setOperatorsPageError(errorObj.message)];
};

const invitationErrorActions = (error: ApiError) => {
    const errorObj = handleApiError(error);
    errorObj.type = AlertType.WARNING;
    return [
        addAlert(errorObj),
        changeIsInvitationComplete(false),
        changeIsInvitationProcessing(false),
        setOperatorsPageError(errorObj.message),
    ];
};

const successActions = (changeSliceList: any, metadata: IModelApiResponseViewObject | null): any[] => {
    return [changeSliceList, setOperatorsPageMetadata(metadata), changeIsOperatorsPageLoading(false)];
};

const updateSuccessActions = (successMessage: string, operatorId: string): any[] => {
    return [
        changeIsMarinaActionProcessing(false),
        changeIsMarinaActionComplete(true),
        addAlert({message: successMessage}),
        fetchOperators(),
        fetchConnectedMarinas(operatorId),
    ];
};

const updateErrorActions = (error: any): any[] => {
    const errorObj = handleApiError(error);
    errorObj.type = AlertType.WARNING;
    return [
        changeIsMarinaActionProcessing(false),
        changeIsMarinaActionComplete(true),
        setOperatorsPageError(errorObj.message),
        addAlert(errorObj),
    ];
};

const operatorsPageEpic = combineEpics(
    fetchOperatorsEpic,
    fetchOperatorSubscriptionsEpic,
    inviteOperatorEpic,
    changeOperatorStatusEpic,
    changeOperatorsPaginationEpic,
    fetchOperatorMarinasEpic,
    assignOperatorToMarinaEpic,
    unassignOperatorFromMarinaEpic
);

export default operatorsPageEpic;
