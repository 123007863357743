import React from 'react';
import {ITextareaProps} from "../../../types";

type Props = ITextareaProps;

class Textarea extends React.Component<Props> {

  render() {
    return (
      <textarea name={this.props.name}
                value={this.props.value || ''}
                placeholder={this.props.placeholder}
                onChange={this.props.handleChange}
                className={`${this.props.inputStyles}`}
                cols={this.props.cols}
                rows={this.props.rows}
                minLength={this.props.minLength}
                maxLength={this.props.maxLength}
                disabled={this.props.disabled}
      />
    );
  }
}

export default Textarea;
