import {ISideMenuGroup, ISideMenuHeader, ISideMenuItem} from 'marine-panel-common-web';
import {Anchor, Grid, MessageSquare, Send, User} from 'react-feather';

const menu = (): (ISideMenuGroup | ISideMenuItem | ISideMenuHeader)[] => [
    {
        id: 'operators',
        title: 'menuItems.operators',
        icon: <User size={20} />,
        navLink: '/panel/operators',
        navLinkClassname: 'operators-link',
    },
    {
        id: 'marinas',
        title: 'menuItems.marinas',
        icon: <Anchor size={20} />,
        navLink: '/panel/marinas',
        navLinkClassname: 'marinas-link',
    },
    {
        id: 'skippers',
        title: 'menuItems.skippers',
        icon: <Send size={20} />,
        navLink: '/panel/skippers',
        navLinkClassname: 'skippers-link',
    },
    {
        id: 'articles',
        title: 'menuItems.articles',
        icon: <MessageSquare size={20} />,
        navLink: '/panel/articles',
        navLinkClassname: 'articles-link',
    },
    // {
    //     id: 'reports',
    //     title: 'menuItems.reports',
    //     icon: <TrendingUp size={20} />,
    //     navLink: '/panel/reports',
    //     navLinkClassname: 'reports-link',
    // },
    {
        id: 'administration',
        title: 'menuItems.administration',
        icon: <Grid size={20} />,
        navLink: '/panel/administration',
        navLinkClassname: 'administration-link',
    },
];
export default menu;
