import {API_URL} from '../../config/entrypoint';
import {ajax, AjaxResponse} from 'rxjs/ajax';
import {Observable} from 'rxjs';
import {ApiSingleResponseBase, MediaFile} from '../../types';

export function saveFile(authToken: string, formData: FormData): Observable<AjaxResponse<ApiSingleResponseBase<MediaFile>>> {
    return ajax({
        url: `${API_URL}/api/media_objects`,
        method: 'POST',
        body: formData,
        headers: {
            Authorization: 'Bearer ' + authToken,
            'Content-Type': 'multipart/form-data',
        },
    });
}
