import {API_URL} from '../../config/entrypoint';
import {createAPIHandler} from '../base/apiFactory';

export const marineHubAPI = createAPIHandler({
    url: API_URL,
    defaultHeaders: {
        Accept: 'application/ld+json',
        'Content-Type': 'application/ld+json',
        'Accept-Language': 'pl-PL, pl;q=0.9, en;q=0.5, *;q=0.2',
    },
    mapper: (data: any): any => {
        return data;
    },
    isList: false,
});
