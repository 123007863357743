import {
    collectionInputDataMapper,
    collectionOutputDataMapper,
    FormButtonType,
    FormControlType,
    IFormConfig,
    IMultiselectOption,
    InputType,
    MultiSelectType,
    ValidationRules,
} from 'marine-panel-common-web';

export const addVesselModelFormConfig = (vesselTypes: IMultiselectOption[], dismissModal: () => void): IFormConfig => ({
    controlType: 'form',
    class: 'default-form vessel-type-form',
    dataAccessor: (data: any) => data,
    outputDataMapper: (data: any, previousStateSnapshot: any) => {
        Object.assign(previousStateSnapshot, data);
        return previousStateSnapshot;
    },
    controls: [
        {
            key: 'name',
            controlType: 'group',
            class: 'row custom-row',
            controls: {
                name: {
                    controlType: 'control',
                    defaultValue: '',
                    formControlType: FormControlType.INPUT,
                    validationRules: [{name: ValidationRules.IS_REQUIRED, params: {}}],
                    placeholder: 'administration.vesselModels.addVesselModel.form.placeholders.name',
                    isLabelHidden: false,
                    label: 'administration.vesselModels.addVesselModel.form.labels.name',
                    type: InputType.TEXT,
                    hostClass: 'col-xl-12 form-control',
                },
            },
        },
        {
            key: 'length',
            controlType: 'group',
            class: 'row custom-row',
            controls: {
                length: {
                    controlType: 'control',
                    defaultValue: '',
                    hostClass: 'col-xl-12 form-control fixed-number-indicator',
                    formControlType: FormControlType.INPUT,
                    isLabelHidden: false,
                    validationRules: [
                        {name: ValidationRules.IS_VALID_DECIMAL_PART_NUMBER, params: {}},
                        {name: ValidationRules.IS_MAX_VALUE, params: {maxValue: 999}},
                    ],
                    label: 'administration.vesselModels.addVesselModel.form.labels.length',
                    placeholder: 'administration.vesselModels.addVesselModel.form.placeholders.length',
                    type: InputType.NUMBER,
                    customNumberIndicator: 'm',
                },
            },
        },
        {
            key: 'width',
            controlType: 'group',
            class: 'row custom-row',
            controls: {
                width: {
                    controlType: 'control',
                    defaultValue: '',
                    hostClass: 'col-xl-12 form-control fixed-number-indicator',
                    formControlType: FormControlType.INPUT,
                    isLabelHidden: false,
                    validationRules: [
                        {name: ValidationRules.IS_VALID_DECIMAL_PART_NUMBER, params: {}},
                        {name: ValidationRules.IS_MAX_VALUE, params: {maxValue: 999}},
                    ],
                    label: 'administration.vesselModels.addVesselModel.form.labels.width',
                    placeholder: 'administration.vesselModels.addVesselModel.form.placeholders.width',
                    type: InputType.NUMBER,
                    customNumberIndicator: 'm',
                },
            },
        },
        {
            key: 'draft',
            controlType: 'group',
            class: 'row custom-row',
            controls: {
                draft: {
                    controlType: 'control',
                    defaultValue: '',
                    hostClass: 'col-xl-12 form-control fixed-number-indicator',
                    formControlType: FormControlType.INPUT,
                    isLabelHidden: false,
                    validationRules: [
                        {name: ValidationRules.IS_VALID_DECIMAL_PART_NUMBER, params: {}},
                        {name: ValidationRules.IS_MAX_VALUE, params: {maxValue: 999}},
                    ],
                    label: 'administration.vesselModels.addVesselModel.form.labels.draft',
                    placeholder: 'administration.vesselModels.addVesselModel.form.placeholders.draft',
                    type: InputType.NUMBER,
                    customNumberIndicator: 'm',
                },
            },
        },
        {
            key: 'height',
            controlType: 'group',
            class: 'row custom-row',
            controls: {
                height: {
                    controlType: 'control',
                    defaultValue: '',
                    hostClass: 'col-xl-12 form-control fixed-number-indicator',
                    formControlType: FormControlType.INPUT,
                    isLabelHidden: false,
                    validationRules: [
                        {name: ValidationRules.IS_VALID_DECIMAL_PART_NUMBER, params: {}},
                        {name: ValidationRules.IS_MAX_VALUE, params: {maxValue: 999}},
                    ],
                    label: 'administration.vesselModels.addVesselModel.form.labels.height',
                    placeholder: 'administration.vesselModels.addVesselModel.form.placeholders.height',
                    type: InputType.NUMBER,
                    customNumberIndicator: 'm',
                },
            },
        },
        {
            key: 'type',
            controlType: 'group',
            class: 'row custom-row',
            controls: {
                type: {
                    controlType: 'control',
                    defaultValue: '',
                    formControlType: FormControlType.AUTOCOMPLETE,
                    multiselectType: MultiSelectType.SINGLE,
                    multiselectOptions: vesselTypes ? vesselTypes : [],
                    validationRules: [{name: ValidationRules.IS_REQUIRED, params: {}}],
                    placeholder: 'administration.vesselModels.addVesselModel.form.placeholders.type',
                    label: 'administration.vesselModels.addVesselModel.form.labels.type',
                    hostClass: 'col-xl-12 form-control berth-type-control',
                    inputDataMapper: collectionInputDataMapper,
                    outputDataMapper: collectionOutputDataMapper,
                },
            },
        },
        {
            controlType: 'group',
            key: 'add_vessel_model_buttons',
            class: 'row justify-content-end',
            controls: {
                previousButton: {
                    buttonName: 'cancel',
                    controlType: 'control',
                    formControlType: FormControlType.BUTTON,
                    buttonType: FormButtonType.BUTTON,
                    inputStyles: 'btn-underline btn-underline-primary d-flex align-items-center',
                    hostClass: 'mb-0 w-auto',
                    disabled: false,
                    defaultContainerStyles: '',
                    containerStyles: '',
                    defaultValue: null,
                    btnText: 'buttons.cancel',
                    buttonDisabled: () => {
                        return false;
                    },
                    customClickHandler: () => {
                        return dismissModal();
                    },
                },
                submitButton: {
                    buttonName: 'submit',
                    controlType: 'control',
                    formControlType: FormControlType.BUTTON,
                    buttonType: FormButtonType.SUBMIT,
                    inputStyles: 'btn-submit btn-create d-flex align-items-center',
                    hostClass: 'mb-0 align-items-end w-auto',
                    defaultContainerStyles: '',
                    containerStyles: '',
                    defaultValue: null,
                    btnText: 'buttons.submit',
                    buttonDisabled: (mappedOutputValue: any, isFormValid: boolean) => {
                        return !isFormValid;
                    },
                },
            },
        },
    ],
});
