export enum DateFormat {
    DateDefaultDisplay = 'DD/MM/YYYY',
    FormDateDefault = 'dd/MM/yyyy',
    InputBasicDateDefault = 'YYYY-MM-DD',
    WeekCalendarDayFormat = 'ddd',
    WeekCalendarDateFormat = 'DD.MM',
    ReservationDatepicker = 'MM-YYYY',
}

export enum IconSize {
    Toast = 24,
    Default = 16,
    Small = 12,
    Medium = 18,
    Large = 20,
    XLarge = 24,
    CardHeader = 24,
    Timeline = 20,
    ChatHeaderMenu = 21,
    ChatAvatar = 36,
    ChatUserListAvatar = 42,
    ChatSearch = 14,
    FileUploader = 60,
    NavigationNavLink = 18,
    NavigationMenuItem = 14,
    UserNavAvatar = 18,
    UserMenuItem = 14,
    CloseCard = 18,
    PriceChangeButton = 12,
    BerthDeleteButton = 24,
    DeleteReservationModal = 20,
    DeleteReservationButton = 18,
    EditReservationButton = 18,
    BerthDetailsIcon = 18,
    ReservationSkipperDetails = 18,
    MainMenuItems = 20,
    SubMenuItems = 20,
    ProfileSteps = 18,
    StepperSeparator = 17,
    ProfileStepper = 14,
    PaymentsHeader = 20,
    ChangePhotoModalButton = 24,
    StatusBadge = 16,
    ViewReservationButton = 12,
}
