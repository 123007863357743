import {marineHubAPI, RestQueryParams} from 'marine-panel-common-web';
import {Observable} from 'rxjs';
import {ICreateMarina} from '../../store/reducers/marinaEditWizardSlice';

export function createMarinaAPI(actionPayload: ICreateMarina, authToken: string | null): Observable<any> {
    let headers = undefined;

    if (authToken) {
        headers = {
            Authorization: `Bearer ${authToken}`,
        };
    }
    return marineHubAPI.post(`api/marinas/rich_create`, actionPayload, new RestQueryParams(), headers);
}
