import {RestQueryParams, marineHubAPI} from 'marine-panel-common-web';
import {Observable} from 'rxjs';
import {IChangeMarinaRestaurants} from '../../store/reducers/marinaEditWizardSlice';

export interface IChangeMarinaRestaurantsInput {
    restaurants: string;
}

export function changeMarinaRestaurantsAPI(payload: IChangeMarinaRestaurants, authToken: string | null): Observable<any> {
    const headers = {
        Authorization: `Bearer ${authToken}`,
    };

    return marineHubAPI.put(`api/marinas/${payload.marinaId}/change_restaurants`, payload.restaurants, new RestQueryParams(), headers);
}
