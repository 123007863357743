import React, {useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Card, CardBody, CardHeader, Table} from 'reactstrap';
import {
    Loader,
    LoaderType,
    IAdminMarinaListItem,
    Translation,
    InputType,
    InputBasic,
    isNotNullOrUndefined,
    Pagination,
    IModelApiResponseViewObject,
    IArticleConnectedMarina,
} from 'marine-panel-common-web';
import {
    IMarinasFilters,
    applyMarinasFilters,
    changeMarinasFilters,
    changeMarinasPagePagination,
    fetchMarinas,
    resetToInitialMarinasPageState,
} from '../../../../store/reducers/marinasSlice';
import {connect} from 'react-redux';
import {RootState} from '../../../../store/reducers';
import {isMarinasPageLoadingSelector, marinasPageMetadataSelector, marinasSelector} from '../../../../store/selectors/marinaSelectors';
import styles from './styles.module.scss';
import {BehaviorSubject, Subscription} from 'rxjs';
import {filter, share} from 'rxjs/operators';

interface IConnectedMarinasListProps {
    readonly isLoading: boolean;
    readonly marinas: IAdminMarinaListItem[] | null;
    readonly marinasPageMetadata: IModelApiResponseViewObject | null;
    readonly fetchMarinas: typeof fetchMarinas;
    readonly changeMarinasFilters: typeof changeMarinasFilters;
    readonly applyMarinasFilters: typeof applyMarinasFilters;
    readonly changeMarinasPagePagination: typeof changeMarinasPagePagination;
    readonly resetToInitialMarinasPageState: typeof resetToInitialMarinasPageState;
}

interface IMarinasListProps extends IConnectedMarinasListProps {
    readonly connectedMarinas: IArticleConnectedMarina[] | null;
    readonly bindMarina: (marinaId: string[]) => void;
}

const MarinasList: React.FC<IMarinasListProps> = ({
    isLoading,
    marinas,
    connectedMarinas,
    bindMarina,
    marinasPageMetadata,
    changeMarinasFilters,
    applyMarinasFilters,
    fetchMarinas,
    changeMarinasPagePagination,
    resetToInitialMarinasPageState,
}) => {
    const {t} = useTranslation();
    const [searchValue, setSearchValue] = useState<string | null>(null),
        [onValueStateChange$] = useState(() => new BehaviorSubject<any>(null)),
        [onValueStateChange$$] = useState(() => onValueStateChange$.pipe(share())),
        subscriptions: Subscription[] = [];
    const debouncedTimeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null);

    useEffect(() => {
        subscriptions.push(
            onValueStateChange$$
                .pipe(filter((data: {value: string}) => data && isNotNullOrUndefined(data.value)))
                .subscribe((data) => setSearchValue(data.value))
        );
        return () => {
            subscriptions.forEach((subscription) => subscription.unsubscribe());
        };
    }, []);

    function onValueStateChange(value: any) {
        onValueStateChange$.next({value: value.target.value});
    }

    useEffect(() => {
        if (debouncedTimeoutRef.current) {
            clearTimeout(debouncedTimeoutRef.current);
        }
        debouncedTimeoutRef.current = setTimeout(() => {
            if (searchValue === null) {
                return;
            }
            const filters: IMarinasFilters = {
                name: searchValue,
            };

            changeMarinasFilters(filters);
            applyMarinasFilters();
        }, 300);
        return () => {
            if (debouncedTimeoutRef.current) {
                clearTimeout(debouncedTimeoutRef.current);
            }
        };
    }, [searchValue, resetToInitialMarinasPageState]);

    useEffect(() => {
        fetchMarinas();

        return () => {
            resetToInitialMarinasPageState();
        };
    }, [fetchMarinas]);

    return (
        <div>
            <Card className="marinas-list">
                <CardHeader>
                    <div className={styles.actions}>
                        <InputBasic
                            type={InputType.TEXT}
                            placeholder={t('marinas.search')}
                            inputStyles="offer-name-input"
                            value={searchValue}
                            name="marinaSearchInput"
                            handleChange={(e: any) => onValueStateChange(e)}
                            // autoComplete="off"
                        />
                    </div>
                </CardHeader>
                <CardBody>
                    <Table responsive className="marinas-list-table">
                        <thead>
                            <tr>
                                <th>
                                    <Translation text="marinas.table.name" />
                                </th>
                                <th />
                            </tr>
                        </thead>
                        <tbody>{renderMarinasTable()}</tbody>
                    </Table>

                    <div className={styles.paginationContainer}>
                        <Pagination listMetadata={marinasPageMetadata} changePage={changeMarinasPagePagination} itemsPerPage={10} />
                    </div>
                    <Loader type={LoaderType.Local} showLoader={isLoading} />
                </CardBody>
            </Card>
        </div>
    );

    function renderMarinasTable() {
        if (null === marinas || !marinas.length) {
            return (
                <tr>
                    <td>
                        <Translation text="operators.table.noData" />
                    </td>
                </tr>
            );
        }

        return marinas.map((item: IAdminMarinaListItem) => {
            return (
                <tr key={item.id}>
                    <td>{item.name}</td>
                    <td>
                        <button
                            className="btn btn-underline connect-marina-btn"
                            disabled={connectedMarinas ? connectedMarinas.some((marina) => marina.id === item.id) : false}
                            onClick={() => connectMarina(item.id)}>
                            <Translation text="marinas.table.actions.connect" />
                        </button>
                    </td>
                </tr>
            );
        });
    }

    function connectMarina(marinaId: string) {
        let ids = [];
        ids.push(marinaId);
        bindMarina(ids);
    }
};

export default connect(
    (state: RootState) => ({
        marinas: marinasSelector(state),
        isLoading: isMarinasPageLoadingSelector(state),
        marinasPageMetadata: marinasPageMetadataSelector(state),
    }),
    {
        fetchMarinas,
        resetToInitialMarinasPageState,
        changeMarinasPagePagination,
        changeMarinasFilters,
        applyMarinasFilters,
    }
)(MarinasList);
