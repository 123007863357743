import React from 'react';
import {Translation} from '../../index';
import {fixInjectedProperties, lazyInject} from '../../ioc';
import FormStateEmitter from '../../service/formStateEmitter';
import {IFormControlConfig, IFormGroupConfig, IFormProps, IFormState} from '../../types';
import BaseGroupedFormControl from '../BaseGroupedFormControl';
import FormControl from '../FormControl';
import {FormButtonType} from '../FormControl/FormButton';
import styles from '../FormControl/styles.module.scss';
import FormControlsGroup from '../FormControlsGroup';
import FormErrorMessages from '../FormErrorMessages';
import {createFormClass, createFormPath} from '../../utils/formUtils';

const uuid = require('uuid/v4');

class Form extends BaseGroupedFormControl<IFormProps, IFormState> {
    @lazyInject('FormStateEmitter')
    private stateEmitter: FormStateEmitter;

    constructor(props: IFormProps) {
        super(props);

        this.state = {
            value: this.inputDataMapper(this.props.value, this.props.config),
            valid: true,
            errorMessages: [],
            touched: false,
            childValidationState: {},
            mappedOutputValue: {},
            formId: uuid(),
        } as IFormState;

        fixInjectedProperties(this);
    }

    componentDidUpdate(prevProps: Readonly<IFormProps>, prevState: Readonly<IFormState>, snapshot?: any) {
        super.componentDidUpdate(prevProps, prevState, snapshot);

        const currentValue = this.state.mappedOutputValue,
            previousValue = prevState.mappedOutputValue,
            currentValid = this.state.valid && this.isValid(this.state.childValidationState),
            previousValid = prevState.valid && this.isValid(prevState.childValidationState),
            currentTouched = this.state.touched,
            previousTouched = prevState.touched;

        if (currentValue !== previousValue || currentValid !== previousValid || currentTouched !== previousTouched) {
            this.stateEmitter.emit(this.state.formId, currentValue, currentValid, currentTouched);
        }
    }

    render() {
        if (!this.props.config) {
            return null;
        }

        return (
            <React.Fragment>
                <form className={`form-wrapper-${this.props.config.class} ${createFormClass(this.props.controlName)}`}>
                    <div className={this.props.config.class}>
                        {this.hasError && (
                            <div className={styles.errorBlock}>
                                <FormErrorMessages errorMessages={this.state.errorMessages} />
                            </div>
                        )}

                        {this.renderControls()}
                    </div>
                </form>
            </React.Fragment>
        );
    }

    // DO NOT move this method to BaseGroupedFormControl, it will cause the app to break
    // probably because there will be a circular reference between FormControlsGroup and BaseGroupedFormControl
    protected renderControls() {
        const path = createFormPath(this.state.formId);

        return this.mapControls(this.props.config.controls, (control: IFormGroupConfig | IFormControlConfig, controlName: string) => {
            if (control.controlType === 'fieldset') {
                return (
                    <fieldset className={`fieldset`} key={`fieldset_${control.key}`}>
                        <legend className="fieldset-legend">
                            <Translation text={control.fieldsetTitle} />
                        </legend>
                        <FormControlsGroup
                            config={control}
                            controlName={control.key}
                            formControlName={this.props.controlName}
                            value={this.dataAccessor(this.state.value, control.key)}
                            handleChange={this.dummyChangeHandler}
                            handleMultiselectChange={this.dummyChangeHandler}
                            onValidationStateChange={this.onChildValidationStateChanged}
                            onTouchedStateChange={this.onTouchedStateChanged}
                            onValueStateChange={this.onValueStateChanged}
                            onButtonClicked={this.onButtonClicked}
                            submitTouched={this.state.submitted}
                            formId={this.state.formId}
                            controlPath={path}
                        />
                    </fieldset>
                );
            }
            if (control.controlType === 'group') {
                return (
                    <FormControlsGroup
                        config={control}
                        controlName={control.key}
                        formControlName={this.props.controlName}
                        value={this.dataAccessor(this.state.value, control.key)}
                        handleChange={this.dummyChangeHandler}
                        handleMultiselectChange={this.dummyChangeHandler}
                        onValidationStateChange={this.onChildValidationStateChanged}
                        onTouchedStateChange={this.onTouchedStateChanged}
                        onValueStateChange={this.onValueStateChanged}
                        onButtonClicked={this.onButtonClicked}
                        submitTouched={this.state.submitted}
                        formId={this.state.formId}
                        key={control.key}
                        controlPath={path}
                    />
                );
            }

            return (
                <FormControl
                    config={control as IFormControlConfig}
                    controlName={controlName}
                    formControlName={this.props.controlName}
                    handleChange={this.dummyChangeHandler}
                    handleMultiselectChange={this.dummyChangeHandler}
                    value={this.dataAccessor(this.props.value, controlName)}
                    onValidationStateChange={this.onChildValidationStateChanged}
                    onTouchedStateChange={this.onTouchedStateChanged}
                    onValueStateChange={this.onValueStateChanged}
                    onButtonClicked={this.onButtonClicked}
                    submitTouched={this.state.submitted}
                    formId={this.state.formId}
                    key={controlName}
                    controlPath={path}
                />
            );
        });
    }

    protected onButtonClicked = (key: string, type: FormButtonType, event: any): void => {
        if (type === FormButtonType.SUBMIT) {
            this.doSubmit(event);
        }
        if (this.props.onButtonClicked) {
            this.props.onButtonClicked(key, type, event);
        }
    };

    protected doSubmit = (event: any): void => {
        this.setState({
            submitted: true,
        });

        if (this.props.submitForm) {
            this.props.submitForm(
                event,
                this.state.mappedOutputValue,
                this.state.valid && this.isValid(this.state.childValidationState),
                this.state.touched
            );
        }
    };

    protected dummyChangeHandler(): void {
        // noop
    }
}

export default Form;
