import {ajax} from 'rxjs/ajax';

const API_URL = 'https://api.marinehub.solvee.pl';

export function addPhotoAPI(authToken: string, formData: FormData) {
    return ajax({
        url: `${API_URL}/api/media_objects`,
        method: 'POST',
        body: formData,
        headers: {
            Authorization: 'Bearer ' + authToken,
        },
    });
}
