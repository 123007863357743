import {IRawRestQueryParams} from '../types';
import {isNotNullOrUndefined} from './runtimeUtils';

export const flattenObj = (object: {[key: string]: any}): IRawRestQueryParams | [] => {
    const result: IRawRestQueryParams = [];
    doFlattenObj(object, [], result);

    return result;
};

const doFlattenObj = (object: {[key: string]: any}, path: string[] = [], result: IRawRestQueryParams = []) => {
    if (object !== null) {
        Object.keys(object).forEach((key) => {
            if (!object.hasOwnProperty(key)) return;

            const newPath = Array.from(path);
            newPath.push(key);
            const pathUrl: string = newPath.join('.');

            if (typeof object[key] === 'object' && !Array.isArray(object[key])) {
                doFlattenObj(object[key], newPath, result);
            } else {
                if (Array.isArray(object[key])) {
                    object[key].forEach((item: any) => {
                        if (isNotNullOrUndefined(item)) {
                            const val = isNotNullOrUndefined(item.value) ? item.value : item;
                            result.push({path: `${pathUrl}[]`, val});
                        }
                    });
                } else {
                    if (isNotNullOrUndefined(object[key])) {
                        result.push({path: pathUrl, val: object[key]});
                    }
                }
            }
        });
    }
};

export function deepCloneObject<T>(object: T): T {
    return JSON.parse(JSON.stringify(object));
}

export function isObjectEmpty(obj: any) {
    return isNotNullOrUndefined(obj) && Object.keys(obj).length === 0 && Object.getPrototypeOf(obj) === Object.prototype;
}
