import {marineHubAPI, RestQueryParams} from 'marine-panel-common-web';
import {Observable} from 'rxjs';

export interface IVesselType {
    name: string;
    boatCertId: number;
}

export interface IAddVesselTypePayload {
    vesselTypes: IVesselType[];
}

export function addVesselTypeAPI(authToken: string | null, payload: IAddVesselTypePayload): Observable<any> {
    const headers = {
        Authorization: `Bearer ${authToken}`,
    };

    return marineHubAPI.post(`api/dictionary/vessel_types`, payload, new RestQueryParams(), headers);
}
