import React from 'react';
import Chart from 'react-apexcharts';
import {ApexRadialbarType, IApexRadialbarProps} from '../../types';

type Props = IApexRadialbarProps;
type State = {};

class ApexRadialbar extends React.Component<Props, State> {
    render() {
        return (
            <div className="d-flex align-items-center me-3">
                <div className="fw-bold text-body-heading me-1">{this.props.item.value} %</div>
                <Chart
                    options={this.getOptions(this.props.item.value)}
                    series={this.props.item.series}
                    type={this.props?.chart?.type ? this.props.chart.type : ApexRadialbarType.RADIAL_BAR}
                    height={this.props?.chart?.height ? this.props.chart.height : 30}
                    width={this.props?.chart?.width ? this.props.chart.width : 30}
                />
            </div>
        );
    }

    private getOptions = (value: number) => {
        return {
            grid: {
                show: false,
                padding: {
                    left: -15,
                    right: -15,
                    top: -12,
                    bottom: -15,
                },
            },
            colors: value >= 50 ? ['#28C76F'] : ['#EA5455'],
            plotOptions: {
                radialBar: {
                    hollow: {
                        size: '22%',
                    },
                    track: {
                        background: '#e9ecef',
                    },
                    dataLabels: {
                        showOn: 'always',
                        name: {
                            show: false,
                        },
                        value: {
                            show: false,
                        },
                    },
                },
            },
            stroke: {
                lineCap: 'round' as any,
            },
        };
    };
}

export default ApexRadialbar;
