import {ServerChatContact, serverChatContactIntoChatContactMapper} from './mappers/serverChatContactIntoChatContactMapper';
import {ApiResponseBase, ChatContact} from '../../types';
import {createAPIHandler} from '../base/apiFactory';
import {API_URL} from '../../config/entrypoint';

export const marineHubChatContactsAPI = (accountId: string) =>
    createAPIHandler({
        url: API_URL,
        defaultHeaders: {
            Accept: 'application/ld+json',
            'Content-type': 'application/json',
        },
        mapper: (data: ApiResponseBase<ServerChatContact[]>): ChatContact[] =>
            serverChatContactIntoChatContactMapper(data['hydra:member'], accountId),
        isList: false,
    });
