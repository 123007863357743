import {RestQueryParams, marineHubAPI} from 'marine-panel-common-web';
import {Observable} from 'rxjs';

export function getMarinaAPI(marinaId: string, authToken: string | null, params?: any): Observable<any> {
    const queryParams = params ? params : new RestQueryParams(),
        headers = {
            Authorization: `Bearer ${authToken}`,
        };

    return marineHubAPI.get(`api/marinas/${marinaId}`, queryParams, headers);
}
