import React, {useEffect} from 'react';
import {Card, CardBody, CardHeader, Table} from 'reactstrap';
import {Loader, LoaderType, Translation, VesselType} from 'marine-panel-common-web';
import {connect} from 'react-redux';
import {isVesselTypesPagesLoadingSelector, vesselTypesSelector} from '../../../store/selectors/vesselTypesPageSelectors';
import {RootState} from '../../../store/reducers';
import {fetchVesselTypes, resetToInitialVesselTypesPageState} from '../../../store/reducers/vesselTypesPageSlice';
import useModal from '../../../utils/modalHook';
import AddVesselTypeModal from './AddVesselType';

interface IConnectedVesselTypesProps {
    readonly isLoading: boolean;
    readonly vesselTypes: VesselType[] | null;
    readonly fetchVesselTypes: typeof fetchVesselTypes;
    readonly resetToInitialVesselTypesPageState: typeof resetToInitialVesselTypesPageState;
}

interface IVesselTypesProps extends IConnectedVesselTypesProps {}

const VesselTypes: React.FC<IVesselTypesProps> = ({vesselTypes, isLoading, fetchVesselTypes, resetToInitialVesselTypesPageState}) => {
    const [isModalShown, toggleModal] = useModal();

    useEffect(() => {
        fetchVesselTypes();

        return () => {
            resetToInitialVesselTypesPageState();
        };
    }, [fetchVesselTypes]);

    return (
        <>
            <Card className="vessel-types-card">
                <CardHeader>
                    <h2 className="section-subtitle">
                        <Translation text="administration.vesselTypes.title" />
                    </h2>

                    <button className="btn btn-create add-vessel-model-button" onClick={() => toggleModal()}>
                        <Translation text="administration.vesselModels.addModel" />
                    </button>
                </CardHeader>
                <CardBody>
                    <Table responsive className="vesselTypes-table">
                        <thead>
                            <tr>
                                <th>
                                    <Translation text="administration.vesselTypes.table.name" />
                                </th>
                                <th>
                                    <Translation text="administration.vesselTypes.table.class" />
                                </th>
                            </tr>
                        </thead>
                        <tbody>{renderVesselTypesTable()}</tbody>
                    </Table>

                    <Loader type={LoaderType.Local} showLoader={isLoading} />
                </CardBody>
            </Card>
            {isModalShown ? <AddVesselTypeModal isModalShown={isModalShown} toggleModal={toggleModal} /> : null}
        </>
    );

    function renderVesselTypesTable() {
        if (null === vesselTypes || !vesselTypes.length) {
            return (
                <tr>
                    <td colSpan={2}>
                        <Translation text="operators.table.noData" />
                    </td>
                </tr>
            );
        }

        return vesselTypes.map((item: VesselType) => {
            return (
                <tr key={item.id}>
                    <td>{item.name}</td>
                    <td>{item.boatCert.className}</td>
                </tr>
            );
        });
    }
};

export default connect(
    (state: RootState) => ({
        isLoading: isVesselTypesPagesLoadingSelector(state),
        vesselTypes: vesselTypesSelector(state),
    }),
    {
        fetchVesselTypes,
        resetToInitialVesselTypesPageState,
    }
)(VesselTypes);
