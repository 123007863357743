import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {ChatContact} from '../../types';

export interface IContactsState {
    chatContacts: ChatContact[];
}
export interface ISetContacts {
    readonly chatContacts: ChatContact[];
}

export interface IGetContacts {}

const initialState: IContactsState = {
    chatContacts: [],
};

const chatContactsSlice = createSlice({
    name: 'contacts',
    initialState: initialState,
    reducers: {
        setChatContacts: {
            reducer: (state: IContactsState, action: PayloadAction<ISetContacts>) => {
                return {
                    chatContacts: action.payload.chatContacts,
                };
            },
            prepare(chatContacts: ChatContact[]) {
                return {
                    payload: {
                        chatContacts,
                    },
                };
            },
        },
        getChatContacts: {
            reducer: (state: IContactsState) => {
                return {
                    chatContacts: state.chatContacts,
                };
            },
            prepare() {
                return {
                    payload: {},
                };
            },
        },
    },
});

export const {setChatContacts, getChatContacts} = chatContactsSlice.actions;

export default chatContactsSlice.reducer;
