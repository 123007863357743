import React, { useEffect } from 'react'
import {NavLink, useLocation} from 'react-router-dom'
import classnames from 'classnames'
import { useTranslation } from 'react-i18next'
import { Badge } from 'reactstrap'
import { IVerticalNavMenuLinkProps } from '../../../types'


type Props = IVerticalNavMenuLinkProps;

const VerticalNavMenuLink = (props: Props) => {
    const LinkTag = props.item.externalLink ? 'a' : NavLink;

    const { t } = useTranslation();
    const location = useLocation();

    useEffect(() => {
        if (props.currentActiveItem !== null) {
            props.setActiveItem(props.currentActiveItem)
        }
    }, [location]);

    return (
        <li
            className={classnames({
                'nav-item': true, // !props.item.children,
                disabled: props.item.disabled,
                active: props.item.navLink === location.pathname
            })}>
            {props.item.onClick ?
                <button className={`${props.item.navLinkClassname} d-flex align-items-center`} onClick={props.item.onClick}>
                    {props.item.icon}
                    <span className='menu-item text-truncate'>{t(props.item.title)}</span>

                    {props.item.badge && props.item.badgeText ? (
                        <Badge className='ms-auto me-1' color={props.item.badge} pill>
                            {props.item.badgeText}
                        </Badge>
                    ) : null}
                </button> :
                <LinkTag
                    className={`${props.item.navLinkClassname} nav-link`}
                    to={props.item.navLink}
                    target={props.item.newTab ? '_blank' : undefined}
                    {...(props.item.externalLink === true
                        ? {
                            href: props.item.navLink || '/'
                        }
                        : {
                            to: props.item.navLink
                        })}
                    onClick={e => {
                        if (props.item.navLink.length === 0 || props.item.navLink === '#' || props.item.disabled === true) {
                            e.preventDefault()
                        }
                    }}>
                    {props.item.icon}
                    <span className='menu-item text-truncate'>{t(props.item.title)}</span>

                    {props.item.badge && props.item.badgeText ? (
                        <Badge className='ms-auto me-1' color={props.item.badge} pill>
                            {props.item.badgeText}
                        </Badge>
                    ) : null}
                </LinkTag>
            }
        </li>
    )
};

export default VerticalNavMenuLink
