import {
    addAlert,
    AlertType,
    authTokenSelector,
    flattenObj,
    getMetadataDetails,
    handleApiError,
    IModelApiResponseViewObject,
    IRawRestQueryParams,
} from 'marine-panel-common-web';
import {combineEpics, Epic, ofType, StateObservable} from 'redux-observable';
import {Observable, of} from 'rxjs';
import {catchError, switchMap} from 'rxjs/operators';
import {getArticlesAPI} from '../../api/getArticles';
import {RootState} from '../reducers';
import {
    changeIsArticlesPageLoading,
    changeArticles,
    changeArticlesPagePagination,
    fetchArticles,
    setArticlesPageError,
    setArticlesPageMetadata,
    addArticle,
    IAddArticle,
    changeIsArticlesActionProcessing,
    changeIsArticlesActionComplete,
    updateArticle,
    deleteArticle,
    IDeleteArticle,
    bindArticleToMarinas,
    IBindArticle,
    unbindArticleFromMarinas,
    IUpdateArticle,
    fetchArticleDetails,
    IFetchArticleDetails,
    changeArticleDetails,
    changeArticleStatus,
    IChangeArticleStatus,
} from '../reducers/articlesPageSlice';
import {articlesPagePaginationSelector} from '../selectors/articlesPageSelectors';
import {addArticleAPI} from '../../api/addArticle';
import {PayloadAction} from '@reduxjs/toolkit';
import {updateArticleAPI} from '../../api/updateArticle';
import {deleteArticleAPI} from '../../api/deleteArticle';
import {bindArticleToMarinasAPI} from '../../api/bindArticleToMarinas';
import {unbindArticleFromMarinasAPI} from '../../api/unbindArticleFromMarinas';
import {getArticleDetailsAPI} from '../../api/getArticleDetails';
import {changeArticleStatusAPI} from '../../api/changeArticleStatus';

const fetchArticlesEpic: Epic = (action$, state$: StateObservable<RootState>) => {
    return getArticles(action$, state$, fetchArticles);
};

const changeArticlesPaginationEpic: Epic = (action$, state$: StateObservable<RootState>) => {
    return getArticles(action$, state$, changeArticlesPagePagination);
};

const fetchArticleDetailsEpic: Epic = (action$: Observable<any>, state$: StateObservable<RootState>) => {
    return action$.pipe(
        ofType(fetchArticleDetails.type),
        switchMap((action: PayloadAction<IFetchArticleDetails>): any => {
            const authToken = authTokenSelector(state$.value),
                articleId = action.payload.articleId;

            return getArticleDetailsAPI(authToken, articleId).pipe(
                switchMap((resp: any) => {
                    return of(changeArticleDetails(resp), changeIsArticlesActionProcessing(false));
                }),
                catchError((error) => of(...updateErrorActions(error)))
            );
        }),
        catchError((error) => of(...updateErrorActions(error)))
    );
};

const addArticleEpic: Epic = (action$: Observable<any>, state$: StateObservable<RootState>) => {
    return action$.pipe(
        ofType(addArticle.type),
        switchMap((action: PayloadAction<IAddArticle>): any => {
            const authToken = authTokenSelector(state$.value),
                payload = action.payload.articlePayload;

            return addArticleAPI(authToken, payload).pipe(
                switchMap(() => {
                    const message = 'articles.addArticleModal.articleAdded';
                    return of(...updateSuccessActions(message));
                }),
                catchError((error) => of(...updateErrorActions(error)))
            );
        }),
        catchError((error) => of(...updateErrorActions(error)))
    );
};

const updateArticleEpic: Epic = (action$: Observable<any>, state$: StateObservable<RootState>) => {
    return action$.pipe(
        ofType(updateArticle.type),
        switchMap((action: PayloadAction<IUpdateArticle>): any => {
            const authToken = authTokenSelector(state$.value),
                articleId = action.payload.articleId,
                payload = action.payload.articlePayload;

            return updateArticleAPI(authToken, articleId, payload).pipe(
                switchMap(() => {
                    const message = 'articles.editArticleModal.articleUpdated';
                    return of(...updateSuccessActions(message));
                }),
                catchError((error) => of(...updateErrorActions(error)))
            );
        }),
        catchError((error) => of(...updateErrorActions(error)))
    );
};

const deleteArticleEpic: Epic = (action$: Observable<any>, state$: StateObservable<RootState>) => {
    return action$.pipe(
        ofType(deleteArticle.type),
        switchMap((action: PayloadAction<IDeleteArticle>): any => {
            const authToken = authTokenSelector(state$.value),
                articleId = action.payload.articleId;

            return deleteArticleAPI(authToken, articleId).pipe(
                switchMap(() => {
                    const message = 'articles.deleteArticleModal.articleRemoved';
                    return of(...updateSuccessActions(message));
                }),
                catchError((error) => of(...updateErrorActions(error)))
            );
        }),
        catchError((error) => of(...updateErrorActions(error)))
    );
};

const bindArticleToMarinasEpic: Epic = (action$: Observable<any>, state$: StateObservable<RootState>) => {
    return action$.pipe(
        ofType(bindArticleToMarinas.type),
        switchMap((action: PayloadAction<IBindArticle>): any => {
            const authToken = authTokenSelector(state$.value),
                articleId = action.payload.articleId,
                marinaIds = action.payload.marinaIds;

            return bindArticleToMarinasAPI(authToken, articleId, marinaIds).pipe(
                switchMap(() => {
                    const message = 'articles.connectedMarinasModal.marinaConnected';
                    return of(...updateSuccessActions(message));
                }),
                catchError((error) => of(...updateErrorActions(error)))
            );
        }),
        catchError((error) => of(...updateErrorActions(error)))
    );
};

const unbindArticleFromMarinasEpic: Epic = (action$: Observable<any>, state$: StateObservable<RootState>) => {
    return action$.pipe(
        ofType(unbindArticleFromMarinas.type),
        switchMap((action: PayloadAction<IBindArticle>): any => {
            const authToken = authTokenSelector(state$.value),
                articleId = action.payload.articleId,
                marinaIds = action.payload.marinaIds;

            return unbindArticleFromMarinasAPI(authToken, articleId, marinaIds).pipe(
                switchMap(() => {
                    const message = 'articles.connectedMarinasModal.marinaDisconnected';
                    return of(...updateSuccessActions(message));
                }),
                catchError((error) => of(...updateErrorActions(error)))
            );
        }),
        catchError((error) => of(...updateErrorActions(error)))
    );
};

const changeArticleStatusEpic: Epic = (action$: Observable<any>, state$: StateObservable<RootState>) => {
    return action$.pipe(
        ofType(changeArticleStatus.type),
        switchMap((action: PayloadAction<IChangeArticleStatus>): any => {
            const authToken = authTokenSelector(state$.value),
                articleId = action.payload.articleId,
                isActive = action.payload.isActive;

            return changeArticleStatusAPI(authToken, articleId, isActive).pipe(
                switchMap(() => {
                    const message = 'articles.articleStatusChanged';
                    return of(changeIsArticlesPageLoading(false), addAlert({message: message}));
                }),
                catchError((error) => of(...errorActions(error)))
            );
        }),
        catchError((error) => of(...errorActions(error)))
    );
};

const getArticles = (action$: Observable<any>, state$: StateObservable<RootState>, actionType: any) => {
    return action$.pipe(
        ofType(actionType.type),
        switchMap((): any => {
            const authToken = authTokenSelector(state$.value),
                paginationParams = articlesPagePaginationSelector(state$.value),
                params = paginationParams ? flattenObj(paginationParams) : null;

            return getArticlesList(authToken, params);
        }),
        catchError((error) => of(...errorActions(error)))
    );
};

export const getArticlesList = (authToken: string, params?: IRawRestQueryParams | null) => {
    return getArticlesAPI(authToken, params).pipe(
        switchMap((resp: any) => {
            const articleList = resp['hydra:member'],
                metadata = getMetadataDetails(resp['hydra:view']),
                actions = successActions(articleList, metadata);
            return of(...actions);
        }),
        catchError((error: any) => of(...errorActions(error)))
    );
};

const successActions = (articles: any[], metadata: IModelApiResponseViewObject | null): any[] => {
    return [changeArticles(articles), setArticlesPageMetadata(metadata), changeIsArticlesPageLoading(false)];
};

const updateSuccessActions = (successMessage: string): any[] => {
    return [
        changeIsArticlesActionProcessing(false),
        changeIsArticlesActionComplete(true),
        addAlert({message: successMessage}),
        fetchArticles(),
    ];
};

const updateErrorActions = (error: any): any[] => {
    const errorObj = handleApiError(error);
    errorObj.type = AlertType.WARNING;
    return [
        changeIsArticlesActionProcessing(false),
        changeIsArticlesActionComplete(true),
        setArticlesPageError(errorObj.message),
        addAlert(errorObj),
    ];
};

const errorActions = (error: any): any[] => {
    const errorObj = handleApiError(error);
    errorObj.type = AlertType.WARNING;
    return [changeIsArticlesPageLoading(false), setArticlesPageError(errorObj.message), addAlert(errorObj)];
};

const articlesPageEpic = combineEpics(
    fetchArticlesEpic,
    changeArticlesPaginationEpic,
    addArticleEpic,
    updateArticleEpic,
    deleteArticleEpic,
    bindArticleToMarinasEpic,
    unbindArticleFromMarinasEpic,
    changeArticleStatusEpic,
    fetchArticleDetailsEpic
);

export default articlesPageEpic;
