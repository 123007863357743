import {Translation} from 'marine-panel-common-web';
import {NavLink, useNavigate} from 'react-router-dom';
import {scrollSmoothTo} from '../../../../../service/helperFunctions';
import {IWizardEditSideMenuItem} from '../edit-menu-items';

type Props = {
    sideMenuConfig: IWizardEditSideMenuItem[];
    activeSublink: string;
};

export default function SideNavigation(props: Props) {
    const navigation = useNavigate();
    if (props.sideMenuConfig.length === 0) {
        return null;
    }

    return (
        <nav className="wizard-edit-nav-wrapper col-xl-3">
            <ul className="nav nav-tabs">{renderSideMenuItems(props.sideMenuConfig, props.activeSublink, navigation)}</ul>
        </nav>
    );
}
function renderSideMenuItems(sideMenuConfig: IWizardEditSideMenuItem[], activeSublink: string, navigation: any) {
    return sideMenuConfig.map((item) => {
        const navLinkWithSubmenu = item.sublinks && item.sublinks.length > 0 ? `${item.navLink}#${item.sublinks[0]}` : item.navLink;
        return (
            <li key={item.id}>
                <NavLink
                    key={item.id}
                    to={navLinkWithSubmenu}
                    className={({isActive}) => `${isActive ? 'active-edited-item-nav' : ''} nav-link title main`}>
                    <Translation text={item.title} />
                </NavLink>
                {renderSubmenu(item.sublinks, activeSublink, navigation)}
            </li>
        );
    });
}

function renderSubmenu(sublinks: string[], activeSublink: string, navigation: any) {
    if (!sublinks) {
        return null;
    }

    return (
        <div className="nav-sublinks-wrapper">
            {sublinks.map((sublink: string) => (
                <span
                    key={sublink}
                    onClick={() => scrollSmoothTo(sublink, navigation)}
                    className={`nav-sublink secondary-title ${activeSublink === sublink ? 'active-sublink' : ''}`}
                    data-link>
                    <Translation text={`editMenuItems.submenu.${sublink}`} />
                </span>
            ))}
        </div>
    );
}
