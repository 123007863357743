import React from 'react';
import {Eye, EyeOff, MinusCircle, PlusCircle} from 'react-feather';
import {WithTranslation, withTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {Card, CardBody, CardHeader} from 'reactstrap';
import {changeContrastMode, changeFontSize} from '../../store/reducers/sagaSlice';
import {contrastModeSelector, fontSizeSelector} from '../../store/selectors/sagaSelectors';
import Translation from '../Translation';
import Switch from '../FormControl/Switch';

interface IAccessibilityMenuProps extends WithTranslation {}

function AccessibilityMenu(props: React.PropsWithChildren<IAccessibilityMenuProps>) {
    const dispatch = useDispatch(),
    fontSize = useSelector(fontSizeSelector),
    contrastMode = useSelector(contrastModeSelector);

    return (
            <Card className="accessibility-menu">
               <CardHeader className="accessibility-menu-header">
                <h2 className="accessibility-menu-title">
                    <Translation text="accessibilityMenu.title" />
                </h2>
               </CardHeader>
               <CardBody className="accessibility-menu-content-wrapper">
               <div className="button-wrapper">
                    <span className="accessiblity-button-label">{props.t('accessibilityMenu.fontSize.title')}</span>
                    <div className="font-size-controller">
                        <button
                            title={props.t('accessibilityMenu.fontSize.increase')}
                            className="accessibility-button font-controller"
                            onClick={() => changeFontSizeHandle(true)}>
                            <PlusCircle size={24} id={'file_size_up'} />
                            <span className="sr-only">{props.t('accessibilityMenu.fontSize.increase')}</span>
                        </button>

                        <button
                            title={props.t('accessibilityMenu.fontSize.decrease')}
                            className="accessibility-button font-controller"
                            onClick={() => changeFontSizeHandle(false)}>
                            <MinusCircle size={24} id={'file_size_down'} />
                            <span className="sr-only">{props.t('accessibilityMenu.fontSize.decrease')}</span>
                        </button>
                    </div>
                </div>
                <div className="button-wrapper">
                    <span className="accessiblity-button-label">
                        {props.t('accessibilityMenu.contrastMode.title')}
                    </span>
                    <Switch name="contrastMode" inputStyles="contrast-mode-switch" checked={contrastMode} handleChange={() => toggleContrastMode()} />
                </div>


               </CardBody>
            </Card>
    );

    function toggleContrastMode() {
        dispatch(changeContrastMode(!contrastMode));
        document.body.classList.toggle('contrast-mode');
    }

    function changeFontSizeHandle(increase: boolean) {
        if ((fontSize === 20 && increase) || (fontSize === 6 && !increase)) {
            return;
        }
        if (increase) {
            dispatch(changeFontSize(fontSize + 2));
            document.documentElement.style.fontSize = (fontSize + 2) * 6.25 + '%';
        } else {
            dispatch(changeFontSize(fontSize - 2));
            document.documentElement.style.fontSize = (fontSize - 2) * 6.25 + '%';
        }
    }
}

export default withTranslation()(AccessibilityMenu);
