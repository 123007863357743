import React from 'react';
import Loader from '../Loader';
import {IDetailsProps, LoaderType} from '../../types';

type Props = IDetailsProps;

const Details = (props: Props) => {
    return (
        <React.Fragment>
            <details className={`custom-details`}>
                {props.children}
                <Loader showLoader={props.showLocalLoader} type={LoaderType.Local} />
            </details>
        </React.Fragment>
    );
};

Details.Title = (props: React.PropsWithChildren<{}>) => <summary className="custom-details-title">{props.children}</summary>;

Details.Body = (props: React.PropsWithChildren<{}>) => <div className="custom-details-body">{props.children}</div>;

export default Details;
