const en = {
    app: {
        title: 'Admin Panel',
    },
    accessibilityMenu: {
        title: 'Accessibility',
        contrastMode: {
            title: 'High contrast',
            toggleButton: 'Toggle contrast mode',
        },
        fontSize: {
            title: 'Font Size',
            increase: 'Increase Font Size',
            decrease: 'Decrease Font Size',
        },
    },
    administration: {
        title: 'Administration',
        metersMeasure: 'm',
        tabs: {
            vessels: 'Vessel Types',
            vesselModels: 'Vessel models',
        },
        vesselTypes: {
            title: 'Vessel Types',
            table: {
                name: 'Name',
                class: 'Class',
            },
            addType: '+ Add type',
            addVesselType: {
                title: 'Add yacht type',
                form: {
                    labels: {
                        name: 'Name',
                        class: 'Class',
                    },
                    placeholders: {
                        name: 'Type name',
                        class: 'Start typing to select vessel class',
                    },
                },
                vesselTypeAdded: 'Vessel type has been added',
            },
        },
        vesselModels: {
            title: 'Vessel Models',
            table: {
                name: 'Name',
                length: 'Length',
                width: 'Width',
                draft: 'Draft',
                height: 'Height',
                type: 'Yacht type',
            },
            addModel: '+ Add model',
            addVesselModel: {
                title: 'Add Yacht Model',
                form: {
                    labels: {
                        name: 'Name',
                        length: 'Length',
                        width: 'Width',
                        draft: 'Draft',
                        height: 'Height',
                        type: 'Yacht type',
                    },
                    placeholders: {
                        name: 'Type name',
                        length: 'Type length',
                        width: 'Type width',
                        draft: 'Type draft',
                        height: 'Type height',
                        type: 'Select yacht type',
                    },
                },
                vesselModelAdded: 'Vessel model has been added',
            },
            editVesselModel: {
                title: 'Edit Yacht Model',
            },
        },
    },
    operators: {
        title: 'Marina Operators',
        inviteOperator: '+ Invite New Operator',
        table: {
            name: 'Name',
            contact: 'Contact',
            connectedMarinas: 'Connected Marinas',
            active: 'Active',
            subscriptionHistory: 'Subscription history',
            noData: 'There is no data that meets your requirements.',
        },
        operatorStatusUpdated: 'Operator status has been changed',
        inviteOperatorModal: {
            title: 'Invite new Marina Operator',
            form: {
                labels: {
                    email: 'Email',
                },
                placeholders: {
                    email: 'Email',
                },
            },
        },
        confirmInvitation: {
            title: 'Confirm invitation',
            subtitle: 'Are you sure you want to invite this Marina Operator to work with you?',
            description: 'If you confirm, operator will get an invitation to cooperate.',
            invitationSent: 'Invitation has been successfully sent.',
        },
        addMarinaModal: {
            title: 'Marinas',
            table: {
                name: 'Name',
            },
            addMarina: '+ Add Marina',
            marinaConnected: 'Operator was assigned to selected marina',
            marinaDisconnected: 'Operator was unassigned from selected marina',
        },
        detachMarinaModal: {
            title: 'Confirm',
            subtitle: 'Are you sure you wish to delete this Marina?',
            description: 'This operation is irreversible.',
        },
        subscriptionsModal: {
            title: 'Subscription history',
            table: {
                name: 'Plan name',
                grossAmount: 'Gross amount',
                invoiceNumber: 'Invoice number',
                startDate: 'Start date',
                endDate: 'End date',
                status: 'Status',
            },
            activeSubscription: 'Active',
        },
    },
    skippers: {
        title: 'Skippers',
        search: 'Search skipper',
        table: {
            name: 'Name',
            phone: 'Mobile number',
            email: 'Email',
            unknownSkipper: 'Unknown skipper',
        },
    },
    marinas: {
        title: 'Marinas',
        search: 'Marina search',
        createMarina: '+ Create marina',
        table: {
            name: 'Name',
            status: 'Active',
            connectedOperators: 'Connected operators',
            country: 'Country',
            actions: {
                details: 'Details',
                edit: 'Edit Marina',
                connect: 'Connect',
            },
        },
        statusChanged: 'Marina status has been changed',
        connectedOperators: {
            title: 'Operators',
            addOperator: '+ Add operator',
            table: {
                name: 'Name',
                disconnect: 'Disconnect',
                unknownOperator: 'Unknown Operator',
            },
            operatorAssigned: 'Selected operator has been assigned to marina',
            operatorUnassigned: 'Selected operator has been unassigned from marina',
        },
    },
    articles: {
        title: 'Articles',
        addArticle: '+ Add article',
        table: {
            title: 'Title',
            connectedMarinas: 'Connected marinas',
            status: 'Active',
            actions: {
                remove: 'Remove',
                edit: 'Edit',
            },
        },
        deleteArticleModal: {
            title: 'Confirm',
            subtitle: 'Are you sure you wish to delete this article?',
            description: 'This operation is irreversible.',
            articleRemoved: 'Article has been removed',
        },
        addArticleModal: {
            title: 'Add Article',
            form: {
                labels: {
                    title: 'Title',
                    subtitle: 'Subtitle',
                    description: 'Description',
                    content: 'Content',
                },
                placeholders: {
                    title: 'Title',
                    subtitle: 'Subtitle',
                    description: 'Description',
                    content: 'Content',
                },
            },
            articleAdded: 'Article has been added',
        },
        editArticleModal: {
            title: 'Edit Article',
            articleUpdated: 'Article has been updated.',
        },
        connectedMarinasModal: {
            marinaConnected: 'Marina has been connected to selected article',
            marinaDisconnected: 'Marina has been removed from selected article',
        },
        articleStatusChanged: 'Article status has been changed',
    },
    auth: {
        header: {
            skipper: 'Skipper',
            operator: 'Marina operator',
            admin: 'Admin',
            boatsman: 'Boatsman',
        },
        authFooter: {
            srTitle: 'Stopka strony zawierająca dane kontaktowe, nawigację po stronie i zapisy do newslettera',
            addressColumn: {
                title: 'MarineHub',
                address1: 'ul. Żurawia 71',
                address2: '5-540 Białystok, Polska',
                nip: 'NIP 123445677',
                regon: 'REGON  12',
            },
            contactColumn: {
                title: 'Kontakt',
                correspondenceAddressTitle: 'Adres korespondencyjny:',
                address1: 'Leonida Teligi 5/8 ',
                address2: '02-777 Warszawa',
            },
            linksColumn: {
                title: 'MarineHub',
                aboutUs: 'O nas',
                career: 'Kariera',
                cooperation: 'Współpraca',
                contact: 'Kontakt',
                policies: 'Regulamin i polityka prywatności',
                safePayments: 'Bezpieczne płatności',
            },
            newsletterColumn: {
                title: 'Bądź na bieżąco',
                subscribeInfo: 'Subskrybuj nasz newsletter',
                newsletterLabelSR: 'Wpisz swój adres email aby otrzymac newsletter',
                newsletterSubmitButtonSR: 'Przycisk zatwierdzania formularza subskrypcji Newslettera',
            },
            copyright: 'MarineHub {{date}} © Wszystkie prawa zastrzeżone.',
            socialIcons: {
                facebook: 'Link prowadzący do profilu na Facebooku',
                instagram: 'Link prowadzący do profilu na Instagramie',
                linkedin: 'Link prowadzący do profilu na Linkedin',
                youtube: 'Link prowadzący do kanalu Youtube',
            },
        },
        formControls: {
            placeholder: 'Type',
            email: 'Email',
            password: 'Password',
            newPassword: 'New password',
            confirmPassword: 'Confirm Password',
            repeatPassword: 'Repeat password',
            placeholderConfirmPassword: 'Confirm Password',
            registrationTermsAgreement: ``,
            phone: 'Phone',
            firstName: 'First name',
            lastName: 'Last name',
            registrationTerms: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempr. Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempr.`,
            placeholders: {
                email: 'Email',
                password: 'Password',
                repeatPassword: 'Repeat password',
            },
        },
        login: {
            title: 'Login',
            subtitle: '',
            haveAccount: 'New on our platform? ',
            register: 'Create an account.',
            resetPassword: 'Forgot password?',
            loginWith: {
                title: 'lub zaloguj się bezpośrednio za pomocą swojego konta na:',
                facebook: 'Zaloguj się przez Facebook',
                google: 'Zaloguj się przez Google',
            },
        },
        resetPassword: {
            title: 'Reset Password',
            subtitle: 'Please provide an email used during registration process. Further instructions will be sent to your email.',
        },
        newPassword: {
            title: 'Change Password',
            subtitle: 'Your new password must be different from previously used passwords',
        },
        alert: {
            loginSuccess: 'Login successful.',
            loginFailed: 'Incorrect login or password. Please try again.',
            logout: 'You have been loged out.',
            registrationSuccess:
                'Registration data was successfully processed. Confirm registration by clicking on the link provided in the email.',
            confirmRegistrationSuccess: 'Your email has been confirmed. You can now login to the application.',
            sendResetPasswordMailSuccess: 'Please check your email for password reset link.',
            confirmResetPasswordSuccess: 'Your password has been changed. You can log in to the panel.',
            authenticationError: 'You must be logged in to access the data.',
        },
    },
    settings: {
        title: 'Settings',
        changePassword: {
            title: 'Profile Details',
            form: {
                labels: {
                    currentPassword: 'Current password',
                    newPassword: 'New password',
                    confirmPassword: 'Confirm new password',
                },
                placeholders: {
                    currentPassword: 'Enter current password',
                    newPassword: 'Enter new password',
                    confirmPassword: 'Confirm your new password',
                },
            },
            passwordRequirements: {
                title: 'Password requirements:',
                length: 'Minimum 8 characters long - the more, the better',
                uppercase: 'At least one lowercase character',
                characters: 'At least one number, symbol, or whitespace character',
            },
        },
    },
    modal: {
        createMarina: {
            title: 'Create New Marina',
            description: 'New Marina requires admin approval.',
            inputs: {
                name: 'Name your Marina',
                phone: 'Contact phone',
                email: 'Contact email',
                description: 'Description',
                attractions: 'Attractions near Marina',
                restaurants: 'Food & Restaurants close to Marina',
                location: 'Location',
                tags: 'Tags',
            },
            placeholders: {
                marinaName: 'Marina name',
                description: 'Describe your Marina',
                attractions: 'Describe attractions near Marina',
                restaurants: 'Describe food & restaurants close to Marina',
                phone: 'Marina contact phone',
                email: 'Marina email',
                tags: 'Select marina tags',
            },
        },
        createExtra: {
            title: 'Create New Extra',
            inputs: {
                name: 'Name your Extra',
                price: 'Price',
                description: 'Description',
            },
            placeholders: {
                name: 'Name',
                price: 'Price',
                description: 'Description',
            },
        },
        changeExtra: {
            title: 'Change extra details',
        },
        addPhoto: {
            title: 'Add photo',
        },
        changePhoto: {
            title: 'Change photo',
        },
        deletePhoto: {
            title: 'Delete photo',
            description: 'Are you sure you want to delete selected photo?',
        },
        setLocation: {
            titleMarina: 'Marina location',
            subtitleMarina: 'Mark your Marina',
            instructionsMarina: 'Click on the map to place your Marina',
        },
    },
    editMenuItems: {
        marinaDetails: 'Marina details',
        marinaTags: 'Tags',
        photoGallery: 'Photos',
        marinaPricingAvailability: 'Pricing and Availability',
        marinaExtras: 'Extras',
        marinaPolicies: 'Policies and rules',
        badges: {
            active: 'Active',
            inactive: 'Inactive',
            app_booking: 'App booking',
        },
        submenu: {
            marina_basics: 'Marina basics',
            marina_sizes: 'Sizes',
            marina_contact: 'Marina contact & address',
            marina_status: 'Status',
            marina_location: 'Location',
            cover_photo: 'Cover photo',
            all_photos: 'All photos',
        },
        sections: {
            marina_basics: {
                title: 'Marina basics',
                formControls: {
                    marina_name: 'Marina name',
                    marina_name_description: 'Give name to your Marina.',
                    marina_description: 'Marina description',
                    marina_description_description: 'Write few words about your Marina.',
                    marina_attractions: 'Nearby attractions',
                    marina_attractions_description: 'Write few words about nearby attractions.',
                    marina_restaurants: 'Foods & restaurants',
                    marina_restaurants_description: 'Write few words about nearby restaurants.',
                },
            },
            marina_contact: {
                title: 'Marina contact & address',
                formControls: {
                    marina_email: 'Email',
                    marina_email_description: 'Email address of your Marina.',
                    marina_phone: 'Phone',
                    marina_phone_description: 'Phone number of your Marina.',
                    marina_address: 'Address',
                    marina_address_description: 'Address of your Marina.',
                },
            },
            marina_status: {
                title: 'Status',
                formControls: {
                    activeStatus: 'Enable or disable Marina from usage.',
                },
            },
            marina_location: {
                title: 'Location',
            },
            marina_gallery: {
                title: 'Photo',
                sectionTitleCoverPhoto: 'Cover photo',
                coverSubtitle: 'First impression for your Marina',
                mediaTitle: 'All photos',
                mediaSubtitle: 'Drag and drop your media to change the order',
                noData: 'There are no photos added yet.',
                noCoverPhoto: 'There is no cover photo added yet.',
            },
            marina_tags: {
                title: 'Marina tags',
                noTags: 'Marina does not have any tags added yet.',
            },
            marina_policies: {
                title: 'Policies and rules',
                formControls: {
                    gateOpensAt: "Marina's gate opens at:",
                    gateClosesAt: "Marina's gate closes at:",
                    additionalRules: 'Additional rules:',
                },
                noPolicySet: 'That policy is not set',
                updatePolicySuccess: 'Rules and policies for selected marina have been successfully updated.',
            },
            marina_extras: {
                title: 'Extras',
                description: 'Add extra service that can be purchased at your Marina',
                table: {
                    headers: {
                        name: 'Name',
                        currentPrice: 'Current Price',
                        actions: 'Actions',
                    },
                },
                buttons: {
                    create: 'Create Extras',
                    edit: 'Edit Extras',
                    delete: 'Delete Extras',
                },
            },
        },
        marina: {
            stopAdministration: 'Stop administration',
            warning: 'This operation is irreversible',
            stopAdministrationDescription: 'Stop administration of this marina',
        },
        alerts: {
            archiveBerthSuccess: 'Berth removed successfully',
            berthNameChangeSuccess: 'Berth name changed successfully',
            berthNameChangeError: 'Berth name change failed',
            berthNoteChangeSuccess: 'Berth note changed successfully',
            berthNoteChangeError: 'Berth note change failed',
            berthDescriptionChangeSuccess: 'Berth description changed successfully',
            berthDescriptionChangeError: 'Berth description change failed',
            berthStatusChangeSuccess: 'Berth status changed successfully',
            berthStatusChangeError: 'Berth status change failed',
            berthMaxSizesChangeSuccess: 'Berth max sizes changed successfully',
            berthMaxSizesChangeError: 'Berth max sizes change failed',
            berthLocationChangeSuccess: 'Berth location changed successfully',
            marinaLocationChangeSuccess: 'Marina location changed successfully',
            berthLocationChangeError: 'Berth location change failed',
            marinaLocationChangeError: 'Marina location change failed',
            berthTagStatusChangeSuccess: 'Berth tag status changed successfully',
            berthAllowedAppBooking: 'Booking in App enabled',
            berthDisallowedAppBooking: 'Booking in App disabled',
            berthActivated: 'Berth activated',
            berthDeactivated: 'Berth deactivated',
            berthCreateSuccess: 'Berth created successfully',
            marinaCreateSuccess: 'Marina created successfully',
            removeTagSuccess: 'Berth tag removed successfully',
            removeTagError: 'Berth tag remove failed',
            addTagSuccess: 'Berth tag added successfully',
            addTagError: 'Berth tag add failed',
            tagDescriptionUpdateSuccess: 'Berth tag description updated successfully',
            tagDescriptionUpdateError: 'Berth tag description update failed',
            marinaRemoveTagSuccess: 'Marina tag removed successfully',
            marinaRemoveTagError: 'Marina tag remove failed',
            marinaAddTagSuccess: 'Marina tag added successfully',
            marinaAddTagError: 'Marina tag add failed',
            marinaTagDescriptionUpdateSuccess: 'Marina tag description updated successfully',
            marinaExtraCreateSuccess: 'Marina extra service has been added',
            marinaExtraRemoveSuccess: 'Marina extra service has been removed',
            marinaTagDescriptionUpdateError: 'Marina tag description update failed',
            marinaPhoneEditSuccess: 'Marina phone number has been updated',
            marinaEmailEditSuccess: 'Marina email has been updated',
            pricingStrategyCreateSuccess: 'Pricing strategy created successfully',
            pricingStrategyUpdateSuccess: 'Pricing strategy updated successfully',
            pricingStrategyDefinitionCreateSuccess: 'Pricing strategy definition created successfully',
            marinaExtraEditSuccess: 'Marina extra service have been updated',
            marinaExtraEditOrderSuccess: 'Order of marina extra services has been changed',
            pricingStrategyActiveStatusChangeSuccess: 'Pricing strategy active status changed successfully',
            pricingStrategyDeleteSuccess: 'Pricing strategy deleted successfully',
            marinaNameChangeSuccess: 'Marina name has been successfully updated',
            marinaRestaurantsEditSuccess: 'Food and restaurants details have been updated',
            marinaAttractionsEditSuccess: 'Attractions details have been updated',
            marinaAddressEditSuccess: 'Address details have been updated',
        },
    },
    buttons: {
        add: 'Add',
        addNew: 'Add new',
        upload: 'Upload',
        more: 'More',
        login: 'Login',
        edit: 'Edit',
        saveChanges: 'Save changes',
        discard: 'Discard',
        apply: 'Apply',
        buy: 'Buy',
        readLess: 'Read less',
        readMore: 'Read more',
        close: 'Close',
        join: 'Join',
        showAll: 'Show all',
        next: 'Next',
        previous: 'Previous',
        submit: 'Submit',
        change: 'Change',
        remove: 'Remove',
        save: 'Save',
        resetPassword: 'Reset password',
        setNewPassword: 'Set new password',
        register: 'Register',
        backToLogin: 'Back to login',
        createBerth: '+ Create Berth',
        createReservation: '+ Reservation',
        updateReservation: 'Update Reservation',
        cancel: 'Cancel',
        create: 'Create',
        showBerthInfo: 'Show Berth info',
        showLastPosition: 'Show last position',
        confirm: 'Confirm',
        sendInvitation: 'Send invitation',
        view: 'View',
        detach: 'Detach',
        delete: 'Delete',
        update: 'Update',
        addPhoto: 'Add photo',
        changePhoto: 'Change photo',
        changeDescription: 'Change description',
    },
    dateRangePicker: {
        config: {
            previousMonth: 'Previous month',
            lastWeek: 'Last week',
            today: 'Today',
            currentMonth: 'Current month',
        },
    },
    formValidation: {
        errors: {
            minLength: 'The value is too short',
            isEmailValid: 'Email is not valid',
            isRequired: 'Field is required',
            isCheckedTrue: 'Field should be selected',
            isTrue: 'Field should be selected',
            isPasswordSame: 'Passwords cannot be different',
            isStartDateValid: 'Please select future date',
            isStartDateGreaterOrEvenValid: 'Please select future date',
            isEndDateValid: 'Data powrotu musi być po dacie wylotu',
            isNumber: 'Provided value should be a number',
            isValidDecimalNumber: 'Provided value should be a number and contain no more than 2 digits after decimal separator',
            isHigherThanMaxValue: 'Provided value should be lower',
        },
    },
    footer: {
        copyright: `MarineHunter {{date}} © All right reserved`,
    },
    maintenance: {
        pageNotFound: 'Błąd - nie znaleziono strony',
    },
    menuItems: {
        operators: 'Operators',
        marinas: 'Marinas',
        skippers: 'Skippers',
        articles: 'Articles',
        reports: 'Reports',
        administration: 'Administration',
        settings: 'Settings',
        logout: 'Logout',
    },
    alerts: {
        warning: 'Warning!',
        error: 'Error!',
        success: 'Success!',
        info: 'Info',
        authError: 'The username or password you entered is incorrect. Please try again',
        noAccessError: 'You are not authorized to login to panel.',
        baseError: 'Something went wrong. Please try again later.',
        loginError: 'An error occurred while logging in',
        tryAgain: 'Please try again',
        tokenRefreshed: 'Your session has been renewed',
        unknown: 'Unknown error occurred.',
    },
    account: {
        changeAccountDataSuccess: 'Data has been successfully changed.',
        changeLocaleSuccess: 'Language has been successfully changed.',
        changePasswordSuccess: 'Password has been successfully changed.',
        imageAddSuccess: 'Image has been successfully added.',
    },
};

export default en;
