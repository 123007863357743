import {marineHubAPI, RestQueryParams} from 'marine-panel-common-web';
import {Observable} from 'rxjs';

export interface IArticleTag {
    name: string;
    code: string;
}

export interface IAddArticlePayload {
    title: string;
    shortContent: string;
    subtitle: string;
    content: string;
    tags?: string[];
    newTags?: IArticleTag[];
}

export function addArticleAPI(authToken: string | null, payload: IAddArticlePayload): Observable<any> {
    const headers = {
        Authorization: `Bearer ${authToken}`,
    };

    return marineHubAPI.post(`api/articles`, payload, new RestQueryParams(), headers);
}
