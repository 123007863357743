import React, {useEffect, useState} from 'react';
import {SortableContainer, SortableElement, SortableContainerProps, SortableElementProps, SortableHandle} from 'react-sortable-hoc';
import {arrayMoveImmutable} from 'array-move';
import {Edit3, MoreVertical, Trash2} from 'react-feather';
import {Extra, IconSize, Price, Translation} from 'marine-panel-common-web';
import {IChangeExtraOrderItem} from '../../../../../../../store/reducers/marinaEditWizardSlice';

interface ISortableHandleElement {
    children: React.ReactNode;
    className?: string;
}

interface ISortableItem extends SortableElementProps {
    children: React.ReactNode;
    className?: string;
}

interface ISortableContainer extends SortableContainerProps {
    children: React.ReactNode;
    className?: string;
}

interface IDragDropTable {
    extras: Extra[] | [] | undefined;
    openEditExtraModal: (id: string) => void;
    removeExtraAction: (id: string) => void;
    changeExtraServiceOrder: (extrasList: IChangeExtraOrderItem[]) => void;
}

const DndTrigger: React.ComponentClass<ISortableHandleElement, any> = SortableHandle(
    ({children, className}: {children: React.ReactNode; className: string}) => <>{children}</>
);

const DndItem: React.ComponentClass<ISortableItem, any> = SortableElement(
    ({children, className}: {children: React.ReactNode; className: string}) => <>{children}</>
);

const DndList: React.ComponentClass<ISortableContainer, any> = SortableContainer(
    ({children, className}: {children: React.ReactNode; className: string}) => {
        return <tbody>{children}</tbody>;
    }
);

const DragDropTable: React.FC<IDragDropTable> = ({openEditExtraModal, removeExtraAction, changeExtraServiceOrder, extras}) => {
    const [state, setState] = useState<Extra[]>([]);

    const onSortEnd = ({oldIndex, newIndex}: {oldIndex: number; newIndex: number}): void => {
        setState(arrayMoveImmutable(state, oldIndex, newIndex));
    };

    function checkOrderChanged(arr1: Extra[], arr2: Extra[]) {
        if (arr1.length !== arr2.length) {
            return true;
        }

        for (let i = 0; i < arr1.length; i++) {
            if (arr1[i] !== arr2[i]) {
                return true;
            }
        }

        return false;
    }

    useEffect(() => {
        if (extras !== undefined) {
            setState(extras);
        }
    }, [extras]);

    useEffect(() => {
        if (extras && state.length && checkOrderChanged(extras, state)) {
            const payloadList = state.map((item, index: number) => {
                return {
                    extraId: item.id,
                    price: item.price
                        ? {
                              amount: item?.price?.amount,
                              currency: item?.price?.currency.name,
                          }
                        : null,
                    description: item.description,
                    order: index,
                };
            });
            changeExtraServiceOrder(payloadList);
        }
    }, [state]);

    return (
        <DndList lockAxis="y" lockToContainerEdges={true} useDragHandle onSortEnd={onSortEnd} className="itemsContainer">
            {state.map((value: any, index: number) => (
                <DndItem key={`item-${value.id}`} index={index} className="item">
                    <tr>
                        <td>{value.name}</td>
                        <td>
                            <Price price={value.price} />
                        </td>
                        <td>
                            <button className="btn btn-icon edit-marina-extra-button me-2" onClick={() => openEditExtraModal(value.id)}>
                                <span className="sr-only">
                                    <Translation text="buttons.edit" />
                                </span>
                                <Edit3 color={'#1E78C1'} size={IconSize.Large} />
                            </button>
                            <button className="btn btn-icon delete-marina-extra-button me-2" onClick={() => removeExtraAction(value.id)}>
                                <Trash2 size={IconSize.Large} color={'#EA5455'} />
                                <span className="sr-only">
                                    <Translation text={'buttons.delete'} />
                                </span>
                            </button>
                            <DndTrigger className="itemTrigger">
                                <button className="btn btn-icon">
                                    <MoreVertical size={IconSize.Large} color={'#E5E5E5'} />
                                </button>
                            </DndTrigger>
                        </td>
                    </tr>
                </DndItem>
            ))}
        </DndList>
    );
};

export default DragDropTable;
