import React from "react";
import styles from "./styles.module.scss";
import { Picker } from "emoji-mart";
import { WithTranslation, withTranslation } from "react-i18next";
import { FilePicker } from "react-file-picker";
import { IBaseChatProps } from "../index";
import {FileStreamType, PeerConnection} from "../../../../../types";
import Translation from "../../../../Translation";
import Loader from "../../../../Loader";
import {BYTES_PER_CHUNK} from "../../../config";
import {FilePlus, XCircle, File as IconFile, Smile, Send} from "react-feather";

interface IChatActionsProps extends WithTranslation, IBaseChatProps {
    addFile: (data: FileStreamType) => void;
    error: string;
    connection: PeerConnection | null;
    errorModuleHandle: (errorMessage: string) => void;
}

interface IChatActionsState {
    isEmojiShown: boolean;
    messageInput: string;
    imgUrl: string | null;
    isDeleteModalOpen: boolean;
    file: File | null;
    currentChunk: number;
    sendingFile: boolean;
}

class ChatActions extends React.Component<
    IChatActionsProps,
    IChatActionsState
    > {
    private emojiPicker: any;
    private divElement: any;
    private fileReader = new FileReader();

    constructor(props: IChatActionsProps) {
        super(props);

        this.state = {
            isEmojiShown: false,
            messageInput: "",
            imgUrl: null,
            isDeleteModalOpen: false,
            file: null,
            currentChunk: 0,
            sendingFile: false
        };

        this.emojiPicker = React.createRef();
        this.divElement = React.createRef();
    }

    componentDidMount(): void {
        this.divElement.current.addEventListener("input", this.onChange, false);

        this.fileReader.onload = () => {
            const chunk =  (this.fileReader.result as string).split('data:application/octet-stream;base64,').pop()
            this.sendChunk({file: chunk})
            if( BYTES_PER_CHUNK * this.state.currentChunk < this.state.file.size ) {
                this.readNextChunk();
            } else {
                this.setState(() => ({currentChunk: 0}))
                this.sendChunk({ done: true, fileName: this.state.file.name, fullFile: this.state.file });
            }
        }
    }

    componentWillUnmount() {
        document.removeEventListener("input", this.onChange, false);
    }

    render() {
        return (
            <React.Fragment>
                <div className={`${styles.interactionContainer} "interaction-container chat-app-form"`}>
                    <button type="button"
                            className={styles.toggleEmoji}
                            onClick={() => this.showEmojis()}>
                        <Smile/>
                    </button>
                    {this.state.isEmojiShown ? (
                        <span className={styles.emojiPicker}
                              ref={el => (this.emojiPicker = el)}>
                            <Picker onSelect={this.addEmoji} style={{ width: "100%" }} />
                        </span>) : null}
                    <div contentEditable={true}
                         onKeyPress={ev => {
                            if (ev.key === "Enter") {
                                ev.preventDefault();
                                return this.messageAction();
                            }
                            return;
                        }}
                        className={styles.editableDiv}
                        ref={this.divElement}
                        data-text={this.props.t('videoChat.chat.placeholder')}/>
                    <FilePicker
                        extensions={["doc", "docx", "xls", "xlsx", "pdf", "jpg", "jpeg", "png"]}
                        onChange={(FileObject: File) => this.setState({ file: FileObject })}
                        onError={this.props.errorModuleHandle}>
                        <button disabled={!!this.state.file} className={`${styles.addMediaBtn} ${styles.btnAddImage}`}>
                            <FilePlus/>
                        </button>
                    </FilePicker>
                    <div className={styles.actionBtnContainer}>
                        <button className={`${styles.btnSend} bg-primary`}
                                onClick={() => this.messageAction()}>
                            <Send/>
                        </button>
                        {this.renderFilePreview()}
                        {this.renderError()}
                        {this.renderConnectionError()}
                    </div>
                </div>
            </React.Fragment>
        );
    }

    private renderFilePreview() {
        if (!this.state.file) {
            return;
        }

        return (
            <div className={styles.imagePreview}>
                <div>
                    <p><Translation text={'chat.fileIsReadyToSent'}/>: {this.state.file.name} </p>
                    <p><Translation text={'chat.fileSize'}/>: {this.state.file.size}</p>
                </div>
                <button
                    className={styles.cancelImgUpload}
                    disabled={this.state.sendingFile}
                    onClick={() => this.setState({ file: null })}
                >
                    <XCircle/>
                </button>
                <button
                    className={styles.sendImg}
                    disabled={this.state.sendingFile}
                    onClick={() => this.handleSendFile()}
                >
                    <IconFile/>
                </button>
                {this.renderLoader()}
            </div>
        );
    }

    private renderLoader() {
        if(!this.state.sendingFile) {
            return;
        }
        return (
            <Loader showLoader={this.state.sendingFile} showLocalLoader />
        )
    }

    private handleSendFile() {
        if(this.state.sendingFile) {
            return
        }
        this.setState({sendingFile: true})
        this.readNextChunk()
    }

    private renderError() {
        if(!this.props.error) {
            return
        }
        setTimeout(this.props.errorModuleHandle, 5000)
        return (
            <div className={styles.errorMessage}>
                {this.props.error}
            </div>
        )
    }

    private renderConnectionError() {
        if(this.props.connection?.connection?.connectionState !== 'failed' || this.props.connection?.connectionAttemptNumber < 1) {
            return null
        }
        return (
            <div className={styles.errorConnectionMessage}>
               <Translation text={'chat.connectionFailed'}/>
            </div>
        )
    }

    private readNextChunk() {
        if(!this.state.file) {
            return
        }

        const start = BYTES_PER_CHUNK * this.state.currentChunk;
        this.setState(state => ({currentChunk: state.currentChunk + 1}))
        const end = Math.min( this.state.file.size, start + BYTES_PER_CHUNK );
        this.fileReader.readAsDataURL( this.state.file.slice( start, end ) );
    }


    private sendChunk = (data: FileStreamType) => {
        if(data.done) {
            this.setState({file: null, sendingFile: false})
        }
        this.props.addFile(data)
    };

    private showEmojis = () => {
        this.setState(
            {
                isEmojiShown: true
            },
            () => document.addEventListener("click", this.closeEmojiMenu)
        );
    };

    private closeEmojiMenu = (e: any) => {
        if (this.emojiPicker !== null && !this.emojiPicker.contains(e.target)) {
            this.setState(
                {
                    isEmojiShown: false
                },
                () => document.removeEventListener("click", this.closeEmojiMenu)
            );
        }
    };

    private addEmoji = (e: any) => {
        const emoji = e.native;
        this.setState({
            messageInput: this.state.messageInput + emoji
        });

        this.divElement.current.innerText = this.state.messageInput + emoji;
    };

    private messageAction = () => {
        this.props.addMessage(this.state.messageInput);
        this.setState({
            messageInput: ""
        });
        this.divElement.current.dataset.text = this.props.t('videoChat.chat.placeholder');
        this.divElement.current.innerText = "";
    };

    private onChange = () => {
        const { t } = this.props;

        this.setState({
            isDeleteModalOpen: true,
            messageInput: this.divElement.current.innerText
        });

        this.divElement.current.dataset.text = t('videoChat.chat.placeholder')

        if (this.divElement.current.innerText.length > 0) {
            this.setState({
                isDeleteModalOpen: false
            });
        }

        this.setState({
            messageInput: this.divElement.current.innerText
        });
    };
}

export default withTranslation()(ChatActions);
