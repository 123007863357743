import React from 'react';
import {ICheckboxProps, ICheckboxState} from "../../../types";
import {InputType} from "../index";
import Translation from "../../Translation";
import {createFormInputClass} from "../../../utils/formUtils";

type Props = ICheckboxProps;
type State = ICheckboxState;

class Checkbox extends React.Component<Props, State> {
    labelRef: React.RefObject<HTMLInputElement>;

    constructor(props: any) {
        super(props);

        this.labelRef = React.createRef();
    }

    render() {
        return (
            <React.Fragment>
                <div className="checkbox-container">
                    <div className={`checkbox-wrapper ${this.props.additionalStyles}`}>
                        {this.props.isCheckboxReversed ? ((!!this.props.isLabelRaw) ?
                            <span className={`checkbox-label ${this.props.labelStyles ? this.props.labelStyles : ''}`}
                                  ref={this.labelRef}
                                  dangerouslySetInnerHTML={{__html: this.props.label}}/> :
                            <span className={`checkbox-label ${this.props.labelStyles ? this.props.labelStyles : ''}`}>
                                {this.props.isLabelTranslated === false ? this.props.label :
                                    <Translation text={this.props.label}/>}
                            </span>
                        ) : null}
                        <input className={`checkbox-input ${createFormInputClass(this.props.name)}`}
                               type={InputType.CHECKBOX}
                               onChange={this.props.handleChange}
                               checked={!!this.props.checked}
                               id={this.props.name}
                               name={this.props.name}
                               value={this.props.value}
                               disabled={this.props.disabled}/>
                        {this.props.isCheckboxReversed ? null :
                            ((!!this.props.isLabelRaw) ?
                                    <span className={`checkbox-label ${this.props.labelStyles ? this.props.labelStyles : ''}`}
                                          ref={this.labelRef}
                                          dangerouslySetInnerHTML={{__html: this.props.label}}/> :
                                    <span className={`checkbox-label ${this.props.labelStyles ? this.props.labelStyles : ''}`}>
                    {this.props.isLabelTranslated === false ? this.props.label :
                        <Translation text={this.props.label}/>}
                  </span>
                            )
                        }
                        <span
                            className={`checkbox-checkmark ${this.props.isCheckboxReversed ? "checkbox-checkmark-reversed" : ""}`}/>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default Checkbox;
