import {RestQueryParams, marineHubAPI} from 'marine-panel-common-web';
import {Observable} from 'rxjs';
import {ITagPayload} from './addTag';

export function removeMarinaTagAPI(id: string | null, authToken: string | null, payload: ITagPayload | null): Observable<any> {
    const headers = {
        Authorization: `Bearer ${authToken}`,
    };

    return marineHubAPI.put(`api/marinas/${id}/remove_tag`, payload, new RestQueryParams(), headers);
}
