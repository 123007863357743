import React, {useEffect} from 'react';
import {Modal, ModalBody, ModalHeader} from 'reactstrap';
import {connect} from 'react-redux';
import ConnectedOperatorsList from './ConnectedOperatorsList';
import {RootState} from '../../../store/reducers';
import {isMarinaActionCompleteSelector, marinaConnectedOperatorsSelector} from '../../../store/selectors/marinaSelectors';
import {
    assignOperatorToMarina,
    changeIsMarinaActionComplete,
    fetchConnectedOperators,
    unassignOperatorFromMarina,
} from '../../../store/reducers/marinasSlice';
import {IOperatorFullInfo} from '../../../model/outputItems';
import {Translation} from 'marine-panel-common-web';

interface IConnectedRecommendationOfferModalProps {
    readonly connectedOperators: IOperatorFullInfo[] | null;
    readonly fetchConnectedOperators: typeof fetchConnectedOperators;
}

interface IExternalMarinaOperatorsModalProps {
    readonly isModalOpen: boolean;
    readonly toggleModal: (operatorId: string | null) => void;
    readonly selectedMarinaId: string | null;
    readonly isMarinaActionComplete: boolean;
    readonly assignOperatorToMarina: typeof assignOperatorToMarina;
    readonly unassignOperatorFromMarina: typeof unassignOperatorFromMarina;
    readonly changeIsMarinaActionComplete: typeof changeIsMarinaActionComplete;
}

interface IMarinaOperatorsModalProps extends IConnectedRecommendationOfferModalProps, IExternalMarinaOperatorsModalProps {}

const MarinaOperatorsModal: React.FC<IMarinaOperatorsModalProps> = ({
    isModalOpen,
    toggleModal,
    connectedOperators,
    isMarinaActionComplete,
    selectedMarinaId,
    fetchConnectedOperators,
    assignOperatorToMarina,
    unassignOperatorFromMarina,
    changeIsMarinaActionComplete,
}) => {
    useEffect(() => {
        if (null !== selectedMarinaId) {
            fetchConnectedOperators(selectedMarinaId);
        }
    }, [selectedMarinaId]);

    return (
        <Modal isOpen={isModalOpen} toggle={() => toggleModal(null)}>
            <ModalHeader toggle={() => toggleModal(null)}>
                <Translation text="marinas.connectedOperators.title" />
            </ModalHeader>
            <ModalBody>
                <ConnectedOperatorsList
                    operators={connectedOperators}
                    bindOperatorToMarina={bindOperator}
                    unbindOperatorFromMarina={unbindOperator}
                    isMarinaActionComplete={isMarinaActionComplete}
                    changeIsMarinaActionComplete={changeIsMarinaActionComplete}
                />
            </ModalBody>
        </Modal>
    );

    function bindOperator(operatorId: string) {
        if (null === selectedMarinaId) {
            return;
        }
        assignOperatorToMarina(selectedMarinaId, operatorId);
    }

    function unbindOperator(operatorId: string) {
        if (null === selectedMarinaId) {
            return;
        }
        unassignOperatorFromMarina(selectedMarinaId, operatorId);
    }
};

export default connect(
    (state: RootState) => ({
        connectedOperators: marinaConnectedOperatorsSelector(state),
        isMarinaActionComplete: isMarinaActionCompleteSelector(state),
    }),
    {
        fetchConnectedOperators,
        assignOperatorToMarina,
        unassignOperatorFromMarina,
        changeIsMarinaActionComplete,
    }
)(MarinaOperatorsModal);
