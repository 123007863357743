import {RestQueryParams, marineHubAPI} from 'marine-panel-common-web';
import {Observable} from 'rxjs';

export interface ISetPhotoPayload {
    marinaId: string | null;
    id: string | null;
}

export function setPhotoAsCoverAPI(payload: ISetPhotoPayload, authToken: string | null): Observable<any> {
    const headers = {
        Authorization: `Bearer ${authToken}`,
    };

    return marineHubAPI.put(`api/marinas/${payload.marinaId}/set_photo_as_cover`, {id: payload?.id}, new RestQueryParams(), headers);
}
