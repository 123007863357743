import classnames from 'classnames';
import {
    changeMenuHidden,
    initLogout,
    menuCollapsedSelector,
    menuHiddenSelector,
    NotFound,
    Sidebar,
    Toast,
    Translation,
} from 'marine-panel-common-web';
import React from 'react';
import {connect} from 'react-redux';
import {Navigate, Route, Routes} from 'react-router-dom';
import {fixInjectedProperties, lazyInject} from '../../ioc';
import {IAlertManagerService} from '../../service/alertManagerService';
import {RootState} from '../../store/reducers';
import {changeIsModalOpen, ModalNames, setModalName} from '../../store/reducers/modalSlice';
import {nameSelector} from '../../store/selectors/modalSelectors';
import Settings from '../Settings';
import menu from './menu-items';
import subMenu from './sub-menu-items';
import themeConfig from './themeConfig';
import ModalComponent from '../SharedComponents/ModalComponent';
import Operators from '../Operators';
import Articles from '../Articles';
import Marinas from '../Marinas';
import Skippers from '../Skippers';
import Administration from '../Administration';
import {NavItem, NavLink} from 'reactstrap';
import {Menu} from 'react-feather';
import WizardEdit, {WizardEditHostType} from '../Marinas/WizardEdit';

interface IConnectedPanelHostProps {
    readonly initLogout: typeof initLogout;
    readonly menuHidden: boolean;
    readonly menuCollapsed: boolean;
    readonly modalName: ModalNames | null | undefined;
    readonly changeIsModalOpen: typeof changeIsModalOpen;
    readonly changeMenuHidden: typeof changeMenuHidden;
    readonly setModalName: typeof setModalName;
}

interface IExternalPanelHostProps {
    envData: any;
    fullWidthLayout?: any;
}

interface IPanelHostProps extends IConnectedPanelHostProps, IExternalPanelHostProps {}

interface IPanelHostState {
    width: number;
}

class PanelHost extends React.Component<IPanelHostProps, IPanelHostState> {
    @lazyInject('AlertManagerService') private alertManager: IAlertManagerService | undefined;

    constructor(props: IPanelHostProps) {
        super(props);

        this.state = {
            width: window.innerWidth,
        };

        fixInjectedProperties(this);
    }

    componentDidMount(): void {
        if (this.state.width <= 768) {
            this.props.changeMenuHidden(true);
        } else {
            this.props.changeMenuHidden(false);
        }

        window.addEventListener('resize', this.updateDimensions);
    }

    componentDidUpdate(prevProps: Readonly<IPanelHostProps>, prevState: Readonly<IPanelHostState>, snapshot?: any) {
        if (this.state.width !== prevState.width && this.state.width <= 768) {
            this.props.changeMenuHidden(true);
        }

        if (this.state.width !== prevState.width && this.state.width > 768) {
            this.props.changeMenuHidden(false);
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDimensions);
    }

    render() {
        const isHidden = this.props.menuHidden;
        const menuCollapsed = this.props.menuCollapsed;
        const navbarWrapperClasses = {
            floating: 'navbar-floating',
            sticky: 'navbar-sticky',
            static: 'navbar-static',
            hidden: 'navbar-hidden',
        };
        const navbarType = 'floating';
        const windowWidth = this.state.width;

        return (
            <div className={`${themeConfig.layout.skin}-layout`}>
                <h1 className="sr-only">
                    <Translation text="app.title" />
                </h1>
                <div
                    className={classnames(`wrapper vertical-layout ${navbarWrapperClasses[navbarType] || 'navbar-floating'}`, {
                        'vertical-menu-modern': windowWidth >= 768,
                        'menu-collapsed': (menuCollapsed && !isHidden) || (windowWidth <= 768 && !isHidden),
                        'menu-expanded': !menuCollapsed && windowWidth > 768,
                        'menu-hide': isHidden || windowWidth <= 768,
                        // 'vertical-overlay-menu': windowWidth < 768,
                    })}
                    {...(isHidden || windowWidth <= 768 ? {'data-col': '1-column'} : {})}>
                    <nav className="header-navbar d-md-none d-sm-flex">
                        <div className="navbar-container d-flex justify-content-end align-items-center">
                            <ul className="bookmark-wrapper justify-content-end">
                                <NavItem className="mobile-menu me-auto">
                                    <NavLink
                                        className="nav-menu-main menu-toggle is-active"
                                        onClick={() => this.props.changeMenuHidden(!isHidden)}>
                                        <Menu className="ficon" color="#000" />
                                    </NavLink>
                                </NavItem>
                            </ul>
                        </div>
                    </nav>

                    {!isHidden ? (
                        <Sidebar
                            skin={themeConfig.layout.skin}
                            logoImage={themeConfig.app.appLogoImage}
                            menuData={[menu(), subMenu(this.onLogout)]}
                            menuCollapsed={menuCollapsed}
                            toggleMobileMenu={() => this.props.changeMenuHidden(!isHidden)}
                            currentActiveItem={null}
                        />
                    ) : null}
                    <main className="panel-wrapper">
                        <div
                            className={classnames('sidenav-overlay d-md-none d-sm-flex', {
                                show: !isHidden,
                            })}
                            onClick={() => this.props.changeMenuHidden(!isHidden)}
                        />

                        <Routes>
                            <Route path="/operators" element={<Operators />} key="operators" />
                            <Route path="/marinas" element={<Marinas />} key="marinas" />
                            <Route
                                path="/marinas/:id?/*"
                                element={<WizardEdit editedItemType={WizardEditHostType.MARINA} />}
                                key="edit-berth"
                            />
                            <Route path="/skippers" element={<Skippers />} key="skippers" />
                            <Route path="/articles" element={<Articles />} key="articles" />
                            <Route path="/administration" element={<Administration />} key="administration" />
                            <Route path="/settings" element={<Settings />} key="settings" />
                            <Route path="*" element={<NotFound />} key="not-found" />
                        </Routes>
                    </main>
                    {this.props.modalName !== null || undefined ? <ModalComponent toggleModalOpen={this.toggleModalOpen} /> : null}
                </div>
                <Toast />
            </div>
        );
    }

    private onLogout = () => {
        this.props.initLogout();
        this.alertManager?.logoutSuccess();
        return <Navigate to="/" />;
    };

    private toggleModalOpen = () => {
        this.props.setModalName(null);
        this.props.changeIsModalOpen(false);
    };

    private updateDimensions = () => {
        this.setState({width: window.innerWidth});
    };
}

export default connect(
    (state: RootState) => ({
        menuCollapsed: menuCollapsedSelector(state),
        menuHidden: menuHiddenSelector(state),
        modalName: nameSelector(state),
    }),
    {
        initLogout,
        changeIsModalOpen,
        setModalName,
        changeMenuHidden,
    }
)(PanelHost);
