import moment, {Moment} from 'moment';
import {getCurrencySymbol} from './currencyHelpers';
import {MoneyOutput} from '../types';
import {translatePathToValue} from './TranslationTools';
import {BOOKING_DAY_START_HOUR} from '../config/config';
import {DateFormat} from '../constants/miscellaneous';

export const rangeDatesString = (dates?: string[]): string => {
    if (!dates || dates.length === 0) {
        return translatePathToValue('general.noDataInfo');
    }

    if (dates.length === 1 || dates[0] === dates[1]) {
        return `${moment(dates[0]).format(DateFormat.DateDefaultDisplay)}`;
    }

    return `${moment(dates[0]).format(DateFormat.DateDefaultDisplay)} - ${moment(dates[dates.length - 1]).format(
        DateFormat.DateDefaultDisplay
    )}`;
};

export const moneyString = (value?: MoneyOutput | null, noCurrency = false): string => {
    if (noCurrency) {
        return value?.amount ? `${(+value.amount / 100).toFixed(2)}` : '0';
    }
    return value?.amount ? `${(+value.amount / 100).toFixed(2)} ${getCurrencySymbol(value.currency?.name)}` : '0';
};

export const meterString = (value: string | number | undefined): string => (value ? `${+value / 100}` : '');
export const meterNumber = (value: string | number | undefined): number => (value ? +value / 100 : 0);
export const centimeterString = (value: string | number | undefined): string => (value ? `${+value * 100}` : '');
export const meterNumberOrNull = (value: number | null): number | null => (value ? +value / 100 : null);
export const centimeterNumberOrNull = (value: number | null | undefined): number | null => (value ? +value * 100 : null);

export const serverFormattedDate = (startingDay?: Date | Moment | string, numberOfDays?: number, hour?: number) => {
    if (!numberOfDays) {
        return moment(startingDay)
            .utc(false)
            .hours(hour || BOOKING_DAY_START_HOUR)
            .minutes(0)
            .seconds(0)
            .format();
    }
    return moment(startingDay)
        .add(numberOfDays, 'd')
        .utc(false)
        .hours(hour || BOOKING_DAY_START_HOUR)
        .minutes(0)
        .seconds(0)
        .format();
};

export const serverFormattedMoment = (startingDay?: Date | Moment | string, numberOfDays?: number, hour?: number) => {
    if (!numberOfDays) {
        return moment(startingDay)
            .utc(false)
            .hours(hour || BOOKING_DAY_START_HOUR)
            .minutes(0)
            .seconds(0);
    }
    return moment(startingDay)
        .add(numberOfDays, 'd')
        .utc(false)
        .hours(hour || BOOKING_DAY_START_HOUR)
        .minutes(0)
        .seconds(0);
};
