import {Marina, Switch, Translation} from 'marine-panel-common-web';
import {FC, useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {useParams} from 'react-router-dom';
import {changeIsMarinaActive} from '../../../../../../../store/reducers/marinaEditWizardSlice';

interface IMarinaStatusProps {
    readonly marina: Marina | null;
    readonly changeIsMarinaActive: typeof changeIsMarinaActive;
}

interface IMarinaStatusValues {
    value: {
        active?: boolean;
    };
}

const MarinaStatus: FC<IMarinaStatusProps> = ({marina, changeIsMarinaActive}) => {
    const params = useParams(),
        marinaId = params.id;
    const [values, setValue] = useState<IMarinaStatusValues>();

    function changeActive() {
        setValue({
            value: {
                active: !values?.value.active,
            },
        });
        changeIsMarinaActive(marinaId, !values?.value.active);
    }
    useEffect(() => {
        if (marina !== null) {
            setValue({
                value: {
                    active: marina?.active,
                },
            });
        }
    }, []);

    return (
        <section id="marina_status" className="details-edit-section col-xl-6" data-section="marina_status">
            <div className="details-edit-form-control">
                <div className="edit-form-control-body-wrapper status-switch">
                    <h3 className="title main">
                        <Translation text="editMenuItems.sections.marina_status.title" />
                    </h3>

                    <div className="d-flex justify-content-between">
                        <span className="secondary-title description">
                            <Translation text="editMenuItems.sections.marina_status.formControls.activeStatus" />
                        </span>
                        {marina && <Switch name="active" checked={marina.active} handleChange={() => changeActive()} />}
                    </div>
                </div>
            </div>
        </section>
    );
};
export default connect(() => ({}), {
    changeIsMarinaActive,
})(MarinaStatus);
