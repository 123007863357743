import React, {useEffect, useState} from 'react';
import LayoutWrapper from '../LayoutWrapper';
import {Card, CardBody, CardHeader, Table} from 'reactstrap';
import styles from './styles.module.scss';
import {
    IArticle,
    IArticleFullDetails,
    IModelApiResponseViewObject,
    Loader,
    LoaderType,
    Pagination,
    Translation,
    Switch,
} from 'marine-panel-common-web';
import {
    addArticle,
    bindArticleToMarinas,
    changeArticleDetails,
    changeArticlesPagePagination,
    changeArticleStatus,
    changeIsArticlesActionComplete,
    deleteArticle,
    fetchArticleDetails,
    fetchArticles,
    resetToInitialArticlesPageState,
    unbindArticleFromMarinas,
    updateArticle,
} from '../../store/reducers/articlesPageSlice';
import {connect} from 'react-redux';
import {RootState} from '../../store/reducers';
import {
    articleDetailsSelector,
    articlesPageMetadataSelector,
    articlesSelector,
    isArticleActionCompleteSelector,
    isArticleActionProcessingSelector,
    isArticlesPageLoadingSelector,
} from '../../store/selectors/articlesPageSelectors';
import useModal from '../../utils/modalHook';
import DeleteArticleModal from './DeleteArticleModal';
import AddArticleModal from './AddArticleModal';
import ArticleMarinasModal from './ArticleMarinasModal';

interface IConnectedArticlesProps {
    readonly isLoading: boolean;
    readonly articles: IArticle[] | null;
    readonly articleDetails: IArticleFullDetails | null;
    readonly articlesPageMetadata: IModelApiResponseViewObject | null;
    readonly isArticleActionComplete: boolean;
    readonly isArticleActionProcessing: boolean;
    readonly fetchArticles: typeof fetchArticles;
    readonly changeArticlesPagePagination: typeof changeArticlesPagePagination;
    readonly resetToInitialArticlesPageState: typeof resetToInitialArticlesPageState;
    readonly addArticle: typeof addArticle;
    readonly deleteArticle: typeof deleteArticle;
    readonly updateArticle: typeof updateArticle;
    readonly fetchArticleDetails: typeof fetchArticleDetails;
    readonly changeArticleDetails: typeof changeArticleDetails;
    readonly changeIsArticlesActionComplete: typeof changeIsArticlesActionComplete;
    readonly bindArticleToMarinas: typeof bindArticleToMarinas;
    readonly unbindArticleFromMarinas: typeof unbindArticleFromMarinas;
    readonly changeArticleStatus: typeof changeArticleStatus;
}

interface IArticlesProps extends IConnectedArticlesProps {}

const Articles: React.FC<IArticlesProps> = ({
    isLoading,
    articles,
    articleDetails,
    articlesPageMetadata,
    isArticleActionComplete,
    isArticleActionProcessing,
    fetchArticles,
    changeArticlesPagePagination,
    resetToInitialArticlesPageState,
    addArticle,
    updateArticle,
    deleteArticle,
    fetchArticleDetails,
    changeArticleDetails,
    changeIsArticlesActionComplete,
    bindArticleToMarinas,
    unbindArticleFromMarinas,
    changeArticleStatus,
}) => {
    const [selectedArticle, setSelectedArticle] = useState<IArticle | null>(null),
        [isMarinasModalShown, toggleMarinasModal] = useModal(),
        [isDeleteArticleModalShown, toggleDeleteArticleModal] = useModal(),
        [isAddArticleModalShown, toggleAddArticleModal] = useModal();

    useEffect(() => {
        fetchArticles();

        return () => {
            resetToInitialArticlesPageState();
        };
    }, [fetchArticles]);

    useEffect(() => {
        if (articles && articles.length && selectedArticle) {
            fetchArticleDetails(selectedArticle.id);
        }
    }, [selectedArticle, articles, fetchArticleDetails]);

    return (
        <LayoutWrapper>
            <Card className="transactions-history">
                <CardHeader>
                    <h2 className="section-subtitle">
                        <Translation text="articles.title" />
                    </h2>

                    <button className="btn btn-create add-article-button" onClick={() => toggleEditArticle(null)}>
                        <Translation text="articles.addArticle" />
                    </button>
                </CardHeader>
                <CardBody>
                    <Table responsive className="operators-table">
                        <thead>
                            <tr>
                                <th>
                                    <Translation text="articles.table.title" />
                                </th>
                                <th>
                                    <Translation text="articles.table.connectedMarinas" />
                                </th>
                                <th>
                                    <Translation text="articles.table.status" />
                                </th>
                                <th />
                            </tr>
                        </thead>
                        <tbody>{renderArticlesTable()}</tbody>
                    </Table>

                    <div className={styles.paginationContainer}>
                        <Pagination listMetadata={articlesPageMetadata} changePage={changeArticlesPagePagination} itemsPerPage={10} />
                    </div>
                    <Loader type={LoaderType.Local} showLoader={isLoading} />
                </CardBody>
            </Card>

            <ArticleMarinasModal
                isModalOpen={isMarinasModalShown}
                toggleModal={toggleConnectedMarinasModal}
                fetchArticleDetails={fetchArticleDetails}
                selectedArticle={selectedArticle}
                bindArticleToMarinas={bindArticleToMarinas}
                unbindArticleFromMarinas={unbindArticleFromMarinas}
                articleDetails={articleDetails}
            />
            {isDeleteArticleModalShown ? (
                <DeleteArticleModal
                    isModalShown={isDeleteArticleModalShown}
                    toggleModal={toggleRemoveArticleModal}
                    selectedArticle={selectedArticle}
                    deleteArticle={deleteArticle}
                    isArticleActionProcessing={isArticleActionProcessing}
                    isArticleActionComplete={isArticleActionComplete}
                />
            ) : null}
            {isAddArticleModalShown ? (
                <AddArticleModal
                    isModalShown={isAddArticleModalShown}
                    toggleModal={toggleEditArticle}
                    selectedArticle={selectedArticle}
                    articleDetails={articleDetails}
                    addArticle={addArticle}
                    updateArticle={updateArticle}
                    isArticleActionComplete={isArticleActionComplete}
                    isArticleActionProcessing={isArticleActionProcessing}
                    fetchArticleDetails={fetchArticleDetails}
                />
            ) : null}
        </LayoutWrapper>
    );

    function renderArticlesTable() {
        if (null === articles || !articles.length) {
            return (
                <tr>
                    <td>
                        <Translation text="operators.table.noData" />
                    </td>
                </tr>
            );
        }

        return articles.map((item: IArticle, index: number) => {
            return (
                <tr key={`article_${index}`}>
                    <td>{item.title}</td>
                    <td>
                        <div className="badge-wrapper">
                            <div className="badge">{item.marinaCount}</div>
                            <button
                                className="btn btn-underline connected-marinas-button"
                                onClick={() => toggleConnectedMarinasModal(item)}>
                                <Translation text="buttons.view" />
                            </button>
                        </div>
                    </td>
                    <td>
                        <Switch
                            name="marina-status"
                            checked={item.active}
                            handleChange={(e: any) => changeArticleActiveStatus(item.id, e)}
                        />
                    </td>
                    <td>
                        <button className="btn btn-underline remove-article-btn" onClick={() => toggleRemoveArticleModal(item)}>
                            <Translation text="articles.table.actions.remove" />
                        </button>
                        <button className="btn btn-underline edit-article-btn" onClick={() => toggleEditArticle(item)}>
                            <Translation text="articles.table.actions.edit" />
                        </button>
                    </td>
                </tr>
            );
        });
    }

    function toggleConnectedMarinasModal(article: IArticle | null) {
        setSelectedArticle(article);
        if (article === null) {
            changeArticleDetails(article);
        }
        toggleMarinasModal();
    }

    function toggleRemoveArticleModal(article: IArticle | null) {
        setSelectedArticle(article);
        toggleDeleteArticleModal();
    }

    function toggleEditArticle(article: IArticle | null) {
        setSelectedArticle(article);
        if (article === null) {
            changeArticleDetails(article);
        }

        if (isArticleActionComplete) {
            changeIsArticlesActionComplete(false);
        }
        toggleAddArticleModal();
    }

    function changeArticleActiveStatus(articleId: string, e: any) {
        const isChecked = e.target.checked;
        changeArticleStatus(articleId, isChecked);
    }
};

export default connect(
    (state: RootState) => ({
        isLoading: isArticlesPageLoadingSelector(state),
        articles: articlesSelector(state),
        articleDetails: articleDetailsSelector(state),
        articlesPageMetadata: articlesPageMetadataSelector(state),
        isArticleActionComplete: isArticleActionCompleteSelector(state),
        isArticleActionProcessing: isArticleActionProcessingSelector(state),
    }),
    {
        fetchArticles,
        changeArticlesPagePagination,
        resetToInitialArticlesPageState,
        addArticle,
        deleteArticle,
        updateArticle,
        fetchArticleDetails,
        changeArticleDetails,
        changeIsArticlesActionComplete,
        bindArticleToMarinas,
        unbindArticleFromMarinas,
        changeArticleStatus,
    }
)(Articles);
