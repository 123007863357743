import Cleave from 'cleave.js/react';
import React from 'react';
import {withTranslation} from 'react-i18next';
import TimezoneSelect from 'react-timezone-select';
import {i18nTimezones} from '../../assets/metadata/timezoneList';
import {FileType, FormButtonType, isSameValue, Translation} from '../../index';
import {
    ChangeHandler,
    CurrencyType,
    FormControlChangeType,
    IFormControlConfig,
    IFormControlProps,
    IFormControlState,
    IMultiselectOption,
    InputDataMapper,
    MultiselectChangeHandler,
    OutputDataMapper,
    TooltipPlacement,
} from '../../types';
import {isSameCollection, isSameSelection} from '../../utils/comparatorUtils';
import {appendFormPath, createFormButtonClass, createFormInputClass, defaultDataMapper} from '../../utils/formUtils';
import {runValidators} from '../../utils/formValidators';
import FormErrorMessages from '../FormErrorMessages';
import CalendarFormControl from './Calendar';
import CardSection from './CardSection';
import Checkbox from './Checkbox';
import DatePickerInput from './Datepicker';
import EventCalendarFormControl from './EventCalendar';
import FormButton from './FormButton';
import InputBasic from './InputBasic';
import MultiSelect from './MultiSelect';
import QuantityInput from './QuantityInput';
import Radio from './Radio';
import RangeControl from './RangeControl';
import Select from './Select';
// import styles from './styles.module.scss';
import Switch from './Switch';
import Textarea from './Textarea';
import WeekCalendar from './WeekCalendar';
import Tooltip from '../Tooltip';
import DateRangePickerInput from './DateRangePicker';
import parse from 'html-react-parser';
import InputFileUploader from './FileUpload';
import RangePicker from './RangePicker';
import RichTextComponent from './RichTextEditor';
import {MultiValue, SingleValue} from 'react-select';

export enum MultiSelectType {
    DEFAULT = 'default',
    SEARCHABLE = 'searchable',
    CUSTOM = 'custom',
    SINGLE = 'single',
    GROUPED = 'grouped',
}

export enum MultiselectMenuPlacement {
    AUTO = 'auto',
    BOTTOM = 'bottom',
    TOP = 'top',
}

export enum FormControlType {
    INPUT = 'input',
    RADIO = 'radio',
    CHECKBOX = 'checkbox',
    FILE_UPLOAD = 'file-upload',
    SWITCH = 'switch',
    DROPDOWN = 'dropdown',
    TEXTAREA = 'textarea',
    SELECT = 'select',
    CREDITCARD = 'credit-card',
    DATE = 'date',
    CARDEXPIRYDATE = 'card-expiry',
    AUTOCOMPLETE = 'autocomplete',
    STRIPECARD = 'stripecard',
    CALENDAR = 'calendar',
    EVENT_CALENDAR = 'event-calendar',
    CURRENCY = 'currency',
    BUTTON = 'button',
    TIMEZONE = 'timezone',
    WEEK_CALENDAR = 'week-calendar',
    RANGE = 'range',
    RANGE_PICKER = 'range-picker',
    QUANTITY_INPUT = 'quantity-input',
    DATE_RANGE_PICKER = 'date-range-picker',
    RICHTEXT = 'richtext',
}

export enum InputType {
    TEL = 'tel',
    TEXT = 'text',
    EMAIL = 'email',
    NUMBER = 'number',
    PASSWORD = 'password',
    DATE = 'date',
    RADIO = 'radio',
    CHECKBOX = 'checkbox',
    FILE = 'file',
}

class FormControl extends React.Component<IFormControlProps, IFormControlState> {
    constructor(props: IFormControlProps) {
        super(props);

        this.state = {
            value: this.inputDataMapper(null === this.props.value ? this.props.config.defaultValue : this.props.value, this.props.config),
            touched: false,
            valid: true,
            errorMessages: [],
        };
    }

    private get inputDataMapper(): InputDataMapper {
        if (!this.props.config) {
            return defaultDataMapper;
        }

        return this.props.config.inputDataMapper || defaultDataMapper;
    }

    private get outputDataMapper(): OutputDataMapper {
        // using defaultDataMapper instead of defaultOutputDataMapper, because the later works sets keys on objects
        if (!this.props.config) {
            return defaultDataMapper;
        }

        return this.props.config.outputDataMapper || defaultDataMapper;
    }

    private get value(): any {
        if (!this.state.touched && (null === this.state.value || undefined === this.state.value || this.state.value.length < 1)) {
            return this.props.config.defaultValue;
        }
        return this.state.value;
    }

    private get isTouched(): boolean {
        return this.state.touched || this.props.submitTouched;
    }

    private get hasError(): boolean {
        return this.isTouched && !this.state.valid;
    }

    componentDidMount(): void {
        const mapped = this.outputDataMapper(this.state.value, null, this.props.controlName, FormControlChangeType.Init);
        this.checkIsValid(this.state.value);
        this.props.onTouchedStateChange(this.props.controlName, this.state.touched);
        this.props.onValueStateChange(this.props.controlName, mapped, FormControlChangeType.Init);
    }

    componentDidUpdate(prevProps: Readonly<any>): void {
        let shouldComputeNewValue = this.props.value !== prevProps.value;

        if (!shouldComputeNewValue && FormControlType.AUTOCOMPLETE === this.props.config.formControlType) {
            const currentOptions =
                typeof this.props.config.multiselectOptions === 'function'
                    ? this.props.config.multiselectOptions()
                    : this.props.config.multiselectOptions;

            const previousOptions =
                typeof prevProps.config.multiselectOptions === 'function'
                    ? prevProps.config.multiselectOptions()
                    : prevProps.config.multiselectOptions;

            shouldComputeNewValue = !isSameCollection(currentOptions, previousOptions);
        }
        if (!shouldComputeNewValue && FormControlType.SELECT === this.props.config.formControlType) {
            shouldComputeNewValue = !isSameCollection(this.props.config.options, prevProps.config.options);
        }

        if (shouldComputeNewValue) {
            const mappedValue = this.inputDataMapper(this.props.value, this.props.config);
            const previousValue = this.state.value;
            if (previousValue !== mappedValue) {
                this.setState({
                    value: mappedValue,
                });
                const mappedOutput = this.outputDataMapper(this.props.value, null, this.props.controlName, FormControlChangeType.Internal);
                this.props.onValueStateChange(this.props.controlName, mappedOutput, FormControlChangeType.Internal);
                this.checkIsValid(mappedValue);
            }
        }
    }

    render() {
        if (!this.props.config) {
            return null;
        }

        return (
            <div
                id={this.props.controlName}
                className={`form-control-wrapper
                           ${this.hasError ? 'has-error invalid' : ''}
                           ${this.props.config.hostClass}
                           ${!!this.props.config.isLabelHidden ? 'label-hidden' : ''}
                           ${this.props.config.additionalStyles ? this.props.config.additionalStyles : ''}`}>
                <div className="label-container">
                    <label htmlFor={this.props.controlName} className={!!this.props.config.isLabelHidden ? 'label-hidden' : 'form-label'}>
                        {this.props.config.richTextLabel ? (
                            parse(this.props.config.richTextLabel, this.addTranslationHtmlParserOption())
                        ) : (
                            <Translation text={this.props.config.label} />
                        )}
                        {this.renderTooltip(this.props.controlName)}
                    </label>
                </div>
                {this.renderFormControl(this.props.config.formControlType, this.props.controlName, this.props.config)}

                {this.hasError && (
                    <div className={`error-block error-block`}>
                        <FormErrorMessages errorMessages={this.state.errorMessages} />
                    </div>
                )}
            </div>
        );
    }

    private renderTooltip = (target: string) => {
        if (this.props.config.tooltipText) {
            return (
                <Tooltip tooltipText={this.props.config.tooltipText} target={target} placement={TooltipPlacement.TOP}>
                    <span aria-hidden="true" className="tooltip">
                        !
                    </span>
                </Tooltip>
            );
        }
        return null;
    };

    private renderFormControl = (formControlType: FormControlType, name: any, option: IFormControlConfig) => {
        const isInputValid = !this.hasError;
        const {t} = this.props;

        if (true === option.useWholePathAsName) {
            name = appendFormPath(this.props.controlPath, name);
        }

        switch (formControlType) {
            case FormControlType.INPUT:
                return (
                    <div className="input-container">
                        <InputBasic
                            value={this.value}
                            name={name}
                            type={option.type}
                            placeholder={t(option.placeholder)}
                            handleChange={this.createDefaultChangeHandler()}
                            inputStyles={`
                            form-control
                            ${isInputValid ? '' : 'has-error'}
                            ${option.customIncrementButtons ? 'custom-increment-button' : ''}
                            ${createFormInputClass(name)}`}
                            disabled={option.disabled}
                            maxDate={option.maxDate}
                            minDate={option.minDate}
                            minLength={option.minLength}
                            maxLength={option.maxLength}
                            pattern={option.pattern}
                            step={option.step}
                            isCurrencyInput={option.isCurrencyInput}
                            autocomplete={option.autocomplete}
                            customIncrementButtons={option.customIncrementButtons}
                            renderPreIcon={option.renderPreIcon}
                            customNumberIndicator={option.customNumberIndicator}
                        />
                    </div>
                );

            case FormControlType.CHECKBOX:
                return (
                    <Checkbox
                        name={name}
                        handleChange={this.createDefaultChangeHandler()}
                        checked={!!this.value}
                        disabled={option.disabled}
                        label={option.checkboxLabel}
                        isLabelRaw={option.isCheckboxLabelRaw}
                        labelStyles={option.labelStyles}
                        additionalStyles={option.additionalStyles}
                        isCheckboxReversed={option.isCheckboxReversed}
                    />
                );

            case FormControlType.SELECT:
                return (
                    <Select
                        value={this.value}
                        name={name}
                        placeholder={option.placeholder}
                        options={option.options}
                        handleChange={this.createDefaultChangeHandler()}
                        inputStyles="form-control"
                        disabled={option.disabled}
                        firstOptionValue={option.firstOptionValue}
                    />
                );

            case FormControlType.RADIO:
                return (
                    <Radio
                        value={this.value}
                        name={name}
                        placeholder={t(option.placeholder)}
                        options={option.options}
                        handleChange={this.createDefaultChangeHandler()}
                        inputStyles="form-control"
                        disabled={option.disabled}
                        wrapperStyles={option.wrapperStyles}
                    />
                );

            case FormControlType.SWITCH:
                return (
                    <Switch
                        name={name}
                        handleChange={this.createDefaultChangeHandler()}
                        checked={!!this.value}
                        disabled={option.disabled}
                    />
                );

            case FormControlType.AUTOCOMPLETE:
                return (
                    <MultiSelect
                        multiselectType={option.multiselectType}
                        handleChange={this.createMultiselectChangeHandler(option.handleChange)}
                        handleInputChange={option.handleMultiselectInputChange}
                        options={option.multiselectOptions}
                        name={name}
                        placeholder={t(option.placeholder)}
                        value={this.value}
                        control={option}
                        isDisabled={option.disabled}
                        menuPlacement={option.menuPlacement}
                        isCurrencySelect={option.isCurrencySelect}
                        isComponentCustom={option.isComponentCustom}
                        isCustomLogoOption={option.isCustomLogoOption}
                        isCustomMultiValueContainer={option.isCustomMultiValueContainer}
                        isGroupedComponentCustom={option.isGroupedComponentCustom}
                        isGroupedLabelTranslated={option.isGroupedLabelTranslated}
                        openMenuOnClick={option.openMenuOnClick}
                        hideValueOnFocus={option.hideValueOnFocus}
                        isSearchable={option.isSearchable}
                    />
                );

            case FormControlType.TIMEZONE:
                return (
                    <TimezoneSelect
                        value={this.value}
                        onChange={this.createDefaultChangeHandler()}
                        // handleChange={this.createDefaultChangeHandler()}
                        labelStyle="abbrev"
                        className="basic-single"
                        classNamePrefix="select"
                        timezones={i18nTimezones}
                        isDisabled={option.disabled}
                    />
                );

            case FormControlType.TEXTAREA:
                return (
                    <div className="textarea-container">
                        <Textarea
                            name={name}
                            value={this.value}
                            placeholder={t(option.placeholder)}
                            handleChange={this.createDefaultChangeHandler()}
                            inputStyles={`form-control ${isInputValid ? '' : 'has-error'}  ${createFormInputClass(name)}`}
                            cols={option.cols}
                            rows={option.rows}
                            minLength={option.minLength}
                            maxLength={option.maxLength}
                            disabled={option.disabled}
                        />
                    </div>
                );

            case FormControlType.DATE:
                return (
                    <DatePickerInput
                        onChange={() => null}
                        value={this.value}
                        id={name}
                        placeholderText={t(option.placeholder)}
                        name={name}
                        inputStyles={`form-control ${option.type} ${isInputValid ? '' : 'has-error'}`}
                        disabledKeyboardNavigation={true}
                        handleChange={this.createDefaultChangeHandler()}
                        openToDate={option.openToDate}
                        disabled={option.disabled}
                        maxDate={option.maxDate}
                        minDate={option.minDate}
                        onChangeRaw={this.createDefaultChangeHandler()}
                        showMonthDropdown={option.showMonthDropdown}
                        showYearDropdown={option.showYearDropdown}
                        inline={option.inline}
                        onSelect={() => null}
                        dateFormat={option.dateFormat}
                        popperPlacement={option.popperPlacement}
                    />
                );

            case FormControlType.STRIPECARD:
                return (
                    <CardSection
                        name={name}
                        onChange={this.createDefaultChangeHandler()}
                        stripePublicKey={option.stripePublicKey}
                        stripeAccount={option.stripeAccount}
                    />
                );

            case FormControlType.CREDITCARD:
                return (
                    <Cleave
                        name={name}
                        options={{
                            creditCard: true,
                            delimiter: '-',
                        }}
                        onChange={this.createDefaultChangeHandler()}
                        checked={option.checked}
                        className="form-control"
                        id={name}
                        disabled={option.disabled}
                        value={option.defaultValue}
                        placeholder={t(option.placeholder)}
                    />
                );

            case FormControlType.CARDEXPIRYDATE:
                return (
                    <Cleave
                        name={name}
                        options={{date: true, datePattern: ['m', 'y']}}
                        onChange={this.createDefaultChangeHandler()}
                        checked={option.checked}
                        className="form-control"
                        id={name}
                        disabled={option.disabled}
                        value={option.defaultValue}
                        placeholder={t(option.placeholder)}
                    />
                );

            case FormControlType.CALENDAR:
                return (
                    <CalendarFormControl
                        onChange={this.createDefaultChangeHandler()}
                        selectedDate={this.value}
                        availableDates={option.availableDates}
                        availableTimeSlots={option.availableTimeSlots}
                        placeholder={option.placeholder}
                        checkboxSlots={option.checkboxSlots}
                        isDateFiltered={option.isDateFiltered}
                        isDayDisabled={option.isDayDisabled}
                        maxDate={option.maxDate}
                        isLabelHidden={option.isLabelHidden}
                    />
                );

            case FormControlType.EVENT_CALENDAR:
                return (
                    <EventCalendarFormControl
                        onChange={this.createDefaultChangeHandler()}
                        selectedDate={option.selectedDate}
                        eventDates={option.eventDates}
                        placeholder={option.placeholder}
                        minDate={option.minDate}
                        maxDate={option.maxDate}
                    />
                );

            case FormControlType.WEEK_CALENDAR:
                return (
                    <WeekCalendar
                        daysNumberDisplay={option.daysNumberDisplay}
                        startDate={option.startDate}
                        onChange={this.createDefaultChangeHandler()}
                        availableConsultationSlots={option.availableConsultationSlots}
                        isLabelHidden={option.isLabelHidden}
                        timeSlotsType={option.timeSlotsType}
                        slotsNumberDisplayed={option.slotsNumberDisplayed}
                        selectedDate={this.value}
                        checkboxSlots={option.checkboxSlots}
                    />
                );

            case FormControlType.CURRENCY:
                return (
                    <div className="currency-input-wrapper">
                        <InputBasic
                            value={this.value}
                            name={name}
                            type={option.type}
                            placeholder={t(option.placeholder)}
                            handleChange={this.createDefaultChangeHandler()}
                            inputStyles={`form-control currency-input ${isInputValid ? '' : 'has-error'} ${createFormInputClass(name)}`}
                            disabled={option.disabled}
                            pattern={option.pattern}
                            currency={option.currency}
                            readonly={option.readonly}
                        />
                        {this.renderCurrencyMarker(option.currency)}
                    </div>
                );

            case FormControlType.RANGE:
                return (
                    <RangeControl
                        step={option.rangeStep}
                        minValue={option.rangeMinValue}
                        maxValue={option.rangeMaxValue}
                        valueUnit={option.rangeValueUnit}
                        defaultValue={option.defaultValue}
                        label={option.label}
                        value={this.value}
                        onChange={this.createDefaultChangeHandler()}
                    />
                );

            case FormControlType.RANGE_PICKER:
                return (
                    <RangePicker
                        defaultValue={option.defaultValue}
                        labels={option.rangeLabels}
                        labelFormat={option.labelFormat}
                        onChange={this.createDefaultChangeHandler()}
                        disabled={option.disabled}
                    />
                );

            case FormControlType.QUANTITY_INPUT:
                return (
                    <QuantityInput
                        name={name}
                        disabled={option.disabled}
                        handleChange={this.createDefaultChangeHandler()}
                        value={this.value}
                        maxValue={option.maxValue}
                        minValue={option.minValue}
                        stepValue={option.stepValue}
                        inputStyles={`
                        form-control
                        ${isInputValid ? '' : 'has-error'}
                        ${option.customIncrementButtons ? 'custom-increment-button' : ''}
                        ${createFormInputClass(name)}`}
                        measurementUnit={option.measurementUnit}
                    />
                );

            case FormControlType.DATE_RANGE_PICKER:
                return (
                    <DateRangePickerInput
                        name={name}
                        onChange={this.createDefaultChangeHandler()}
                        placeholder={option.placeholder}
                        placement={option.dateRangePlacement}
                        ranges={option.dateRangesConfig}
                        value={this.value}
                        defaultStartValue={option.defaultStartValue}
                        defaultEndValue={option.defaultEndValue}
                    />
                );

            case FormControlType.RICHTEXT:
                return (
                    <RichTextComponent
                        name={name}
                        onChange={this.createDefaultChangeHandler()}
                        placeholder={option.placeholder}
                        label={option.label}
                        value={this.value}
                        defaultValue={option.defaultValue}
                    />
                );

            case FormControlType.BUTTON:
                return (
                    <FormButton
                        name={option.buttonName}
                        btnText={t(option.btnText)}
                        buttonType={t(option.buttonType)}
                        defaultInputStyles={option.defaultStyles}
                        inputStyles={`${option.inputStyles} ${
                            option.buttonType === FormButtonType.SUBMIT ? createFormButtonClass(this.props.formControlName) : ''
                        }`}
                        defaultContainerStyles={option.defaultContainerStyles}
                        containerStyles={option.containerStyles}
                        disabled={option.buttonDisabled}
                        disabledStyles={option.disabledStyles}
                        enabledStyles={option.enabledStyles}
                        onButtonClicked={this.props.onButtonClicked}
                        customClickHandler={option.customClickHandler}
                        preIconStyles={option.btnPreIconStyles}
                        postIconStyles={option.btnPostIconStyles}
                        innerStyles={option.btnInnerStyles}
                        formId={this.props.formId}
                        btnHasTooltip={option.btnHasTooltip}
                        btnTooltipText={option.btnTooltipText}
                    />
                );

            case FormControlType.FILE_UPLOAD:
                return (
                    <InputFileUploader
                        label={option.label}
                        onImageChange={option.onFileChange}
                        onFileChange={this.createFileUploadChangeHandler()}
                        isFileRemovable={option.isFileRemovable}
                        acceptedFileExtension={option.acceptedFileExtension}
                    />
                );

            default:
                return;
        }
    };

    private renderCurrencyMarker = (currency: CurrencyType) => {
        if (currency) {
            return <span className="currency-marker">{this.renderCurrencySymbol(currency)}</span>;
        }
        return null;
    };

    private renderCurrencySymbol = (currency: CurrencyType) => {
        if (currency === CurrencyType.EURO) {
            return '€';
        }
        if (currency === CurrencyType.DOLLAR) {
            return '$';
        }
        if (currency === CurrencyType.PLN) {
            return 'Zł';
        }
        return null;
    };

    private touch(): void {
        const wasTouched = this.state.touched;
        this.setState({
            touched: true,
        });
        if (!wasTouched) {
            this.props.onTouchedStateChange(this.props.controlName, true);
        }
    }

    private createDefaultChangeHandler(): ChangeHandler {
        return (...args) => {
            const formControlType = this.props.config.formControlType;

            const event = args[0];
            if (undefined !== event.persist) {
                event.persist();
            }
            let newValue = '';
            if (formControlType === FormControlType.CHECKBOX || formControlType === FormControlType.SWITCH) {
                newValue = event.target.checked;
            } else if (
                formControlType === FormControlType.TIMEZONE ||
                formControlType === FormControlType.AUTOCOMPLETE ||
                formControlType === FormControlType.RANGE ||
                formControlType === FormControlType.RANGE_PICKER ||
                formControlType === FormControlType.QUANTITY_INPUT
            ) {
                newValue = event;
            } else if (formControlType === FormControlType.RICHTEXT) {
                newValue = event.getCurrentContent().getPlainText();
            } else {
                newValue = event.target.value;
            }

            this.setState((state: IFormControlState, props: IFormControlProps) => {
                if (state.value !== newValue) {
                    const mapped = this.outputDataMapper(newValue, state.value, props.controlName, FormControlChangeType.User);
                    props.onValueStateChange(props.controlName, mapped, FormControlChangeType.User);
                    args[0] = mapped;
                    props.handleChange(...(args as [any]));
                }

                return {
                    value: newValue,
                };
            });
            this.touch();
            this.checkIsValid(newValue);
        };
    }

    private createMultiselectChangeHandler(handler: MultiselectChangeHandler): MultiselectChangeHandler {
        return (values: SingleValue<IMultiselectOption> | MultiValue<IMultiselectOption[]>, action: any) => {
            this.setState((state: IFormControlState, props: IFormControlProps) => {
                if (!isSameSelection(state.value, values)) {
                    const mapped = this.outputDataMapper(values, state.value, props.controlName, FormControlChangeType.User);
                    if (props.handleMultiselectChange) {
                        props.handleMultiselectChange(mapped, action);
                    }
                    props.onValueStateChange(props.controlName, mapped, FormControlChangeType.User);
                    if (handler) {
                        handler(mapped, action);
                    }
                }

                return {
                    value: values,
                };
            });
            this.touch();
            this.checkIsValid(values);
        };
    }

    private createFileUploadChangeHandler(): ChangeHandler {
        return (value: FileType[]) => {
            this.setState((state: IFormControlState, props: IFormControlProps) => {
                if (!isSameValue(state.value, value)) {
                    const mapped = this.outputDataMapper(value, state.value, props.controlName, FormControlChangeType.User);
                    props.onValueStateChange(props.controlName, mapped, FormControlChangeType.User);
                    value = mapped;
                    props.handleChange(...(value as [any]));
                }

                return {
                    value: value,
                };
            });
            this.touch();
            this.checkIsValid(value);
        };
    }

    private checkIsValid(value: any): void {
        const result = runValidators(value, this.props.config.validationRules);

        this.setState({
            valid: result.valid,
            errorMessages: result.errorMessages,
        });

        if (this.props.onValidationStateChange) {
            this.props.onValidationStateChange(this.props.controlName, result.valid, result.errorMessages);
        }
    }

    private addTranslationHtmlParserOption = () => {
        return {
            replace: (domNode) => {
                if (domNode.attribs && domNode.attribs.class === 'replace-translation') {
                    return <Translation text={domNode.children[0].data} />;
                }
            },
        };
    };
}

export default withTranslation()(FormControl);
