import {createSelector} from '@reduxjs/toolkit';
import {IVesselModelState} from '../reducers/vesselModelSlice';

export const selectVesselModel = (state: any): IVesselModelState => {
    return state.vesselModel;
};

export const vesselModelsSelector = createSelector([selectVesselModel], (state: IVesselModelState) => state.vesselModels);

export const vesselModelsLoadingSelector = createSelector([selectVesselModel], (state: IVesselModelState) => state.isLoading);

export const vesselModelsInitializedSelector = createSelector([selectVesselModel], (state: IVesselModelState) => state.isInitialized);

export const vesselModelsErrorSelector = createSelector([selectVesselModel], (state: IVesselModelState) => state.error);
