import {createSelector} from '@reduxjs/toolkit';
import {IMarinasState} from '../reducers/marinasSlice';
import {RootState} from '../reducers';

export const selectMarinasSlice = (state: RootState): IMarinasState => {
    return state.marinas;
};

export const marinasSelector = createSelector([selectMarinasSlice], (state: IMarinasState) => state.marinas);

export const marinaConnectedOperatorsSelector = createSelector([selectMarinasSlice], (state: IMarinasState) => state.connectedOperators);

export const isMarinasPageLoadingSelector = createSelector([selectMarinasSlice], (state: IMarinasState) => state.isLoading);

export const marinasPagePaginationSelector = createSelector([selectMarinasSlice], (state: IMarinasState) => state.pagination);

export const marinasPageMetadataSelector = createSelector([selectMarinasSlice], (state: IMarinasState) => state.marinasMetadata);

export const isMarinasPageInitializedSelector = createSelector([selectMarinasSlice], (state: IMarinasState) => state.isInitialized);

export const marinasPageErrorSelector = createSelector([selectMarinasSlice], (state: IMarinasState) => state.error);

export const marinasPageFiltersSelector = createSelector([selectMarinasSlice], (state: IMarinasState) => state.filters);

export const isMarinaActionCompleteSelector = createSelector([selectMarinasSlice], (state: IMarinasState) => state.isMarinaActionComplete);

export const isMarinaActionProcessingSelector = createSelector(
    [selectMarinasSlice],
    (state: IMarinasState) => state.isMarinaActionProcessing
);
