import {Observable} from 'rxjs';
import {marineHubAPI} from '../provider/marineHubAPI';
import {RestQueryParams} from '../base/queryParams';
import {NotificationMessage} from '../../types';

export function postDelayedMessageAPI(accessToken: string, message: NotificationMessage): Observable<any> {
    return marineHubAPI.post(`notifications/chat`, message, new RestQueryParams(), {
        Authorization: `Bearer ${accessToken}`,
    });
}
