import classnames from 'classnames';
import React, {Component} from 'react';
import {Check, Minus} from 'react-feather';
import {NavLink} from 'react-router-dom';
import {ITimelineItem, ITimelineProps} from '../../types';
import {withTranslation} from 'react-i18next';
import {IconSize} from '../../constants/miscellaneous';

type Props = ITimelineProps;
type State = {};

class Timeline extends Component<Props, State> {
    render() {
        const {t} = this.props;

        return (
            <ul
                className={classnames(
                    'timeline',
                    this.props.className
                        ? {
                              [this.props.className]: this.props.className,
                          }
                        : null
                )}>
                {this.props.data.map((item, i) => {
                    return (
                        <li
                            key={`timeline-item-${i}`}
                            className={classnames(
                                'timeline-item',
                                {'item-disabled': !item.isTimelineComplete},
                                item.className
                                    ? {
                                          [item.className]: this.props.className,
                                      }
                                    : null
                            )}>
                            <span
                                className={classnames('timeline-point', {
                                    [`timeline-point-${item.color}`]: item.color,
                                    'timeline-point-indicator': !item.icon,
                                })}>
                                {item.icon ? item.icon : null}
                            </span>
                            <div className="timeline-event">
                                <div
                                    className={classnames('d-flex justify-content-between flex-sm-row flex-column', {
                                        'mb-sm-0 mb-1': item.meta,
                                    })}>
                                    {item.timelineLink ? (
                                        <NavLink to={item.timelineLink} state={{activeTab: item?.activeTab}} className={item.linkClassName}>
                                            {t(item.title)}
                                        </NavLink>
                                    ) : (
                                        <h6>{t(item.title)}</h6>
                                    )}
                                    {item.meta ? (
                                        <span
                                            className={classnames(
                                                'timeline-event-time',
                                                item.metaClassName
                                                    ? {
                                                          [item.metaClassName]: item.metaClassName,
                                                      }
                                                    : null
                                            )}>
                                            {item.meta}
                                        </span>
                                    ) : null}

                                    {this.renderTimelineIcon(item)}
                                </div>
                                <p
                                    className={classnames({
                                        'mb-0': i === this.props.data.length - 1 && !item.customContent,
                                    })}>
                                    {t(item.content)}
                                </p>
                                {item.customContent ? item.customContent : null}
                            </div>
                        </li>
                    );
                })}
            </ul>
        );
    }

    private renderTimelineIcon = (item: ITimelineItem) => {
        return (
            <>
                {item.metaIcon ? (
                    item.isTimelineComplete ? (
                        <Check color={'#28c76f'} size={IconSize.Timeline} />
                    ) : (
                        <Minus color={'#ea5455'} size={IconSize.Timeline} />
                    )
                ) : null}
            </>
        );
    };
}

export default withTranslation()(Timeline);
