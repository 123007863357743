import {RestQueryParams, marineHubAPI} from 'marine-panel-common-web';
import {Observable} from 'rxjs';
import {IRemoveExtra} from '../../store/reducers/marinaEditWizardSlice';

export function removeMarinaExtraAPI(payload: IRemoveExtra, authToken: string | null): Observable<any> {
    const headers = {
        Authorization: `Bearer ${authToken}`,
    };

    return marineHubAPI.put(`api/marinas/${payload.marinaId}/remove_extra`, payload.payload, new RestQueryParams(), headers);
}
