import React, {Component} from 'react';
import moment from 'moment';
import {DateRangePicker} from 'rsuite';
import {DateRangePickerPlacement, IDateRangePickerProps, IDateRangePickerState} from '../../../types';
import {isNotNullOrUndefined, isNullOrUndefined} from '../../../utils/runtimeUtils';
import {withTranslation} from "react-i18next";
import {isSameValue} from "../../..";

const { allowedMaxDays } = DateRangePicker;


const datepickerRanges = (t: any): any[] => ([
    {
        label: t('dateRangePicker.config.previousMonth'),
        value: [
            new Date(moment().subtract(1, 'months').startOf('month').format()),
            new Date(moment().subtract(1, 'months').endOf('month').format())
        ]
    },
    {
        label: t('dateRangePicker.config.lastWeek'),
        value: [
            new Date(moment().subtract(7, 'days').format()),
            new Date(moment().endOf('day').format())]
    },
    {
        label: t('dateRangePicker.config.today'),
        value: [
            new Date(moment().startOf('day').format()),
            new Date(moment().endOf('day').format())
        ]
    },
    {
        label: t('dateRangePicker.config.currentMonth'),
        value: [
            new Date(moment().startOf('month').format()),
            new Date(moment().endOf('month').format())
        ]
    },
]);

type Props = IDateRangePickerProps;
type State = IDateRangePickerState;


class DateRangePickerInput extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        const defaultStartValue = this.props.defaultStartValue ? this.props.defaultStartValue : null,
            defaultEndValue = this.props.defaultEndValue ? this.props.defaultEndValue : null;

        this.state = {
            defaultDateValue: [defaultStartValue, defaultEndValue]
        }
    }

    componentDidUpdate(prevProps: Readonly<Props>): void {
        if (!isSameValue(this.props.value, prevProps.value) &&
            isNullOrUndefined(this.props.value) &&
            (Array.isArray(this.props.value) && 0 === this.props.value.length)) {
            this.changeInputValue(this.props.value);
        }
    }

    render() {
        const {t} = this.props,
            startValue = this.props.defaultStartValue ? this.props.defaultStartValue : new Date(),
            endValue = this.props.defaultEndValue ? this.props.defaultEndValue : new Date(),
            value = isNotNullOrUndefined(this.props.value) && this.props.value !== '' ?
                this.props.value.map((date: string | null) => isNotNullOrUndefined(date) ? new Date(date) : new Date()) : [startValue, endValue];

        return (
            <DateRangePicker placeholder={t(this.props.placeholder)}
                             onChange={(e: any) => this.changeInputValue(e)}
                             placement={this.props.placement ? this.props.placement : DateRangePickerPlacement.AUTO_VERTICAL_START}
                             ranges={this.props.ranges ? this.props.ranges : datepickerRanges(t)}
                             defaultValue={this.state.defaultDateValue}
                             value={value}
                             disabledDate={allowedMaxDays(this.props.allowedMaxDays ? this.props.allowedMaxDays : 30)}
                             renderValue={(value) => {
                                 return `${this.formatDateToString(value[0])} - ${this.formatDateToString(value[1])}`;
                             }}
            />
        );
    }


    private formatDateToString(date: Date | undefined): string | null {
        if (!date) {
            return null;
        }

        return date.toLocaleDateString('pl-PL', {
            day: '2-digit', month: '2-digit', year: '2-digit'
        }).replace(/ /g, '.');
    }

    private changeInputValue = (e: any) => {
        const eventData = {target: {name: this.props.name, value: e}};
        this.props.onChange(eventData);
    }
}

export default withTranslation()(DateRangePickerInput);
