import {Observable} from 'rxjs';
import {RestQueryParams} from '../base/queryParams';
import {marineHubChatContactsAPI} from '../provider/marineHubChatContactsAPI';
import {ChatContact} from '../../types';

export function getChatContactsListAPI(accessToken: string, accountId: string): Observable<ChatContact[]> {
    return marineHubChatContactsAPI(accountId).get(`api/accounts/chat_contacts`, new RestQueryParams(), {
        Authorization: `Bearer ${accessToken}`,
    });
}
