import React, {useEffect} from 'react';
import {Modal, ModalBody, ModalHeader} from 'reactstrap';
import {connect} from 'react-redux';
import ConnectedMarinasList from './ConnectedMarinasList';
import {
    bindMarinaToOperator,
    changeIsMarinaActionComplete,
    fetchConnectedMarinas,
    unbindMarinaFromOperator,
} from '../../../store/reducers/operatorsPageSlice';
import {
    isMarinaActionCompleteSelector,
    isMarinaActionProcessingSelector,
    operatorConnectedMarinasSelector,
} from '../../../store/selectors/operatorsPageSelectors';
import {RootState} from '../../../store/reducers';
import {IAdminMarinaListItem, Translation} from 'marine-panel-common-web';

interface IConnectedRecommendationOfferModalProps {
    readonly connectedMarinas: IAdminMarinaListItem[] | null;
    readonly fetchConnectedMarinas: typeof fetchConnectedMarinas;
}

interface IExternalOperatorMarinasModalProps {
    readonly isModalOpen: boolean;
    readonly toggleModal: (operatorId: string | null) => void;
    readonly selectedOperatorId: string | null;
    readonly isMarinaActionComplete: boolean;
    readonly isMarinaActionProcessing: boolean;
    readonly bindMarinaToOperator: typeof bindMarinaToOperator;
    readonly unbindMarinaFromOperator: typeof unbindMarinaFromOperator;
    readonly changeIsMarinaActionComplete: typeof changeIsMarinaActionComplete;
}

interface IOperatorMarinasModalProps extends IConnectedRecommendationOfferModalProps, IExternalOperatorMarinasModalProps {}

const OperatorMarinasModal: React.FC<IOperatorMarinasModalProps> = ({
    isModalOpen,
    toggleModal,
    connectedMarinas,
    selectedOperatorId,
    isMarinaActionProcessing,
    isMarinaActionComplete,
    fetchConnectedMarinas,
    bindMarinaToOperator,
    unbindMarinaFromOperator,
    changeIsMarinaActionComplete,
}) => {
    useEffect(() => {
        if (null !== selectedOperatorId) {
            fetchConnectedMarinas(selectedOperatorId);
        }
    }, [selectedOperatorId]);

    return (
        <Modal isOpen={isModalOpen} toggle={() => toggleModal(null)}>
            <ModalHeader toggle={() => toggleModal(null)}>
                <Translation text="operators.addMarinaModal.title" />
            </ModalHeader>
            <ModalBody>
                <ConnectedMarinasList
                    marinas={connectedMarinas}
                    bindMarinaToArticle={bindMarina}
                    unbindMarinaFromArticle={unbindMarina}
                    isMarinaActionComplete={isMarinaActionComplete}
                    isMarinaActionProcessing={isMarinaActionProcessing}
                    changeIsMarinaActionComplete={changeIsMarinaActionComplete}
                />
            </ModalBody>
        </Modal>
    );

    function bindMarina(marinaId: string[]) {
        if (null === selectedOperatorId) {
            return;
        }

        bindMarinaToOperator(selectedOperatorId, marinaId[0]);
    }

    function unbindMarina(marinaId: string[]) {
        if (null === selectedOperatorId) {
            return;
        }

        unbindMarinaFromOperator(selectedOperatorId, marinaId[0]);
    }
};

export default connect(
    (state: RootState) => ({
        connectedMarinas: operatorConnectedMarinasSelector(state),
        isMarinaActionComplete: isMarinaActionCompleteSelector(state),
        isMarinaActionProcessing: isMarinaActionProcessingSelector(state),
    }),
    {
        fetchConnectedMarinas,
        bindMarinaToOperator,
        unbindMarinaFromOperator,
        changeIsMarinaActionComplete,
    }
)(OperatorMarinasModal);
