import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {
    IBaseDictionaryState,
    IChangeIsInitialized,
    IChangeIsLoading,
    IChangePagePagination,
    IPaginationParams,
    ISetError,
    ISetPageMetadata,
} from './marinasSlice';
import {initApiCall} from '../../utils/reduxHelpers';
import {IModelApiResponseViewObject, SkipperFullInfo} from 'marine-panel-common-web';

export interface ISkippersState extends IBaseDictionaryState {
    skippers: SkipperFullInfo[] | null;
    skippersMetadata: IModelApiResponseViewObject | null;
    pagination: IPaginationParams | null;
}

export interface ISetSkippers {
    readonly skippers: SkipperFullInfo[] | null;
}

const initialState: ISkippersState = {
    skippers: null,
    skippersMetadata: null,
    pagination: {
        page: 1,
        itemsPerPage: 10,
    },
    isLoading: false,
    isInitialized: false,
    error: null,
};

const skipperSlice = createSlice({
    name: 'skippers',
    initialState: initialState,
    reducers: {
        changeSkippers: {
            reducer: (state: ISkippersState, action: PayloadAction<ISetSkippers>) => {
                return {
                    ...state,
                    skippers: action.payload.skippers,
                };
            },
            prepare(skippers: SkipperFullInfo[] | null) {
                return {
                    payload: {skippers: skippers},
                };
            },
        },
        changeIsSkippersPageLoading: {
            reducer: (state: ISkippersState, action: PayloadAction<IChangeIsLoading>) => {
                return {
                    ...state,
                    isLoading: action.payload.isLoading,
                };
            },
            prepare(isLoading: boolean) {
                return {
                    payload: {isLoading: isLoading},
                };
            },
        },
        changeIsSkippersPageInitialized: {
            reducer: (state: ISkippersState, action: PayloadAction<IChangeIsInitialized>) => {
                return {
                    ...state,
                    isInitialized: action.payload.isInitialized,
                };
            },
            prepare(isInitialized: boolean) {
                return {
                    payload: {isInitialized: isInitialized},
                };
            },
        },
        setSkippersPageError: {
            reducer: (state: ISkippersState, action: PayloadAction<ISetError>) => {
                return {
                    ...state,
                    error: action.payload.error,
                };
            },
            prepare(error: string | null) {
                return {
                    payload: {error: error},
                };
            },
        },
        setSkippersPageMetadata: {
            reducer: (state: ISkippersState, action: PayloadAction<ISetPageMetadata>) => {
                return {
                    ...state,
                    skippersMetadata: action.payload.metadata,
                };
            },
            prepare(metadata: IModelApiResponseViewObject | null) {
                return {
                    payload: {
                        metadata: metadata,
                    },
                };
            },
        },
        changeSkippersPagePagination: {
            reducer: (state: ISkippersState, action: PayloadAction<IChangePagePagination>) => {
                return {
                    ...state,
                    isLoading: true,
                    pagination: action.payload.pagination,
                };
            },
            prepare(pagination: IPaginationParams) {
                return {
                    payload: {pagination: pagination},
                };
            },
        },
        fetchSkippers: (state: ISkippersState) => initApiCall(state),
        resetToInitialSkippersPageState: () => {
            return {
                ...initialState,
            };
        },
    },
});

export const {
    changeSkippers,
    changeIsSkippersPageLoading,
    changeIsSkippersPageInitialized,
    setSkippersPageError,
    fetchSkippers,
    changeSkippersPagePagination,
    setSkippersPageMetadata,
    resetToInitialSkippersPageState,
} = skipperSlice.actions;

export default skipperSlice.reducer;
