import {
    IAdminMarinaListItem,
    IModelApiResponseViewObject,
    InputBasic,
    InputType,
    Loader,
    LoaderType,
    Pagination,
    Switch,
    Translation,
    isNotNullOrUndefined,
} from 'marine-panel-common-web';
import React, {useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import {Card, CardBody, CardHeader, Table} from 'reactstrap';
import {BehaviorSubject, Subscription} from 'rxjs';
import {filter, share} from 'rxjs/operators';
import {RootState} from '../../store/reducers';
import {
    IMarinasFilters,
    applyMarinasFilters,
    changeMarinaStatus,
    changeMarinasFilters,
    changeMarinasPagePagination,
    fetchMarinas,
    resetToInitialMarinasPageState,
} from '../../store/reducers/marinasSlice';
import {ModalNames, changeIsModalOpen, setModalName} from '../../store/reducers/modalSlice';
import {isMarinasPageLoadingSelector, marinasPageMetadataSelector, marinasSelector} from '../../store/selectors/marinaSelectors';
import useModal from '../../utils/modalHook';
import LayoutWrapper from '../LayoutWrapper';
import MarinaOperatorsModal from './MarinaOperatorsModal';
import styles from './styles.module.scss';

interface IConnectedMarinasProps {
    readonly isLoading: boolean;
    readonly marinas: IAdminMarinaListItem[] | null;
    readonly marinasPageMetadata: IModelApiResponseViewObject | null;
    readonly fetchMarinas: typeof fetchMarinas;
    readonly changeMarinasFilters: typeof changeMarinasFilters;
    readonly applyMarinasFilters: typeof applyMarinasFilters;
    readonly changeMarinaStatus: typeof changeMarinaStatus;
    readonly changeMarinasPagePagination: typeof changeMarinasPagePagination;
    readonly resetToInitialMarinasPageState: typeof resetToInitialMarinasPageState;
    readonly setModalName: typeof setModalName;
    readonly changeIsModalOpen: typeof changeIsModalOpen;
}

interface IMarinasProps extends IConnectedMarinasProps {}

const Marinas: React.FC<IMarinasProps> = ({
    isLoading,
    marinas,
    marinasPageMetadata,
    fetchMarinas,
    changeMarinasPagePagination,
    resetToInitialMarinasPageState,
    changeMarinasFilters,
    applyMarinasFilters,
    changeMarinaStatus,
    setModalName,
    changeIsModalOpen,
}) => {
    const {t} = useTranslation();
    const [searchValue, setSearchValue] = useState<string | null>(null),
        [selectedMarinaId, setSelectedMarinaId] = useState<string | null>(null),
        [onValueStateChange$] = useState(() => new BehaviorSubject<any>(null)),
        [onValueStateChange$$] = useState(() => onValueStateChange$.pipe(share())),
        subscriptions: Subscription[] = [],
        [isMarinaOperatorsModalShown, toggleOperatorsModal] = useModal();
    const debouncedTimeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null);

    useEffect(() => {
        subscriptions.push(
            onValueStateChange$$
                .pipe(filter((data: {value: string}) => data && isNotNullOrUndefined(data.value)))
                .subscribe((data) => setSearchValue(data.value))
        );
        return () => {
            subscriptions.forEach((subscription) => subscription.unsubscribe());
        };
    }, []);

    function onValueStateChange(value: any) {
        onValueStateChange$.next({value: value.target.value});
    }

    useEffect(() => {
        if (debouncedTimeoutRef.current) {
            clearTimeout(debouncedTimeoutRef.current);
        }
        debouncedTimeoutRef.current = setTimeout(() => {
            if (searchValue === null) {
                return;
            }
            const filters: IMarinasFilters = {
                name: searchValue,
            };

            changeMarinasFilters(filters);
            applyMarinasFilters();
        }, 300);
        return () => {
            if (debouncedTimeoutRef.current) {
                clearTimeout(debouncedTimeoutRef.current);
            }
        };
    }, [searchValue, changeMarinasFilters, applyMarinasFilters, resetToInitialMarinasPageState]);

    useEffect(() => {
        fetchMarinas();

        return () => {
            resetToInitialMarinasPageState();
        };
    }, [fetchMarinas]);

    return (
        <LayoutWrapper>
            <Card className="marinas">
                <CardHeader>
                    <h2 className="section-subtitle">
                        <Translation text="marinas.title" />
                    </h2>

                    <div className={styles.actions}>
                        <div className={styles.inputContainer}>
                            <InputBasic
                                type={InputType.TEXT}
                                placeholder={t('marinas.search')}
                                inputStyles="offer-name-input"
                                value={searchValue}
                                name="marinaSearchInput"
                                handleChange={(e: any) => onValueStateChange(e)}
                            />
                        </div>
                        <button className="btn btn-create invite-operator-button" onClick={() => openCreateMarinaModal()}>
                            <Translation text="marinas.createMarina" />
                        </button>
                    </div>
                </CardHeader>
                <CardBody>
                    <Table responsive className="marinas-table">
                        <thead>
                            <tr>
                                <th>
                                    <Translation text="marinas.table.name" />
                                </th>
                                <th>
                                    <Translation text="marinas.table.status" />
                                </th>
                                <th>
                                    <Translation text="marinas.table.connectedOperators" />
                                </th>
                                <th />
                            </tr>
                        </thead>
                        <tbody>{renderMarinasTable()}</tbody>
                    </Table>

                    <div className={styles.paginationContainer}>
                        <Pagination listMetadata={marinasPageMetadata} changePage={changeMarinasPagePagination} itemsPerPage={10} />
                    </div>
                    <Loader type={LoaderType.Local} showLoader={isLoading} />
                </CardBody>
            </Card>

            <MarinaOperatorsModal
                isModalOpen={isMarinaOperatorsModalShown}
                toggleModal={toggleMarinaOperatorsModal}
                selectedMarinaId={selectedMarinaId}
            />
        </LayoutWrapper>
    );

    function renderMarinasTable() {
        if (null === marinas || !marinas.length) {
            return (
                <tr>
                    <td>
                        <Translation text="operators.table.noData" />
                    </td>
                </tr>
            );
        }

        return marinas.map((item: IAdminMarinaListItem) => {
            return (
                <tr key={item.id}>
                    <td>{renderMarinaDetails(item)}</td>
                    <td>
                        <Switch
                            name="marina-status"
                            checked={item.active}
                            handleChange={(e: any) => changeMarinaActiveStatus(item.id, e)}
                        />
                    </td>
                    <td>
                        <div className="badge-wrapper">
                            <div className="badge">{item.operatorCount}</div>
                            <button
                                className="btn btn-underline connected-operators-button"
                                onClick={() => toggleMarinaOperatorsModal(item.id)}>
                                <Translation text="buttons.view" />
                            </button>
                        </div>
                    </td>
                    <td>
                        <Link className="btn btn-underline edit-marina-btn" to={`/panel/marinas/${item.id}/details`}>
                            <Translation text="marinas.table.actions.edit" />
                        </Link>
                    </td>
                </tr>
            );
        });
    }

    function renderMarinaDetails(item: IAdminMarinaListItem) {
        const hasCoverPhoto = item && item.cover && item.cover.fileUrls && item.cover.fileUrls.mediumThumb;

        return (
            <div className={styles.marinaDetails}>
                {hasCoverPhoto ? <img className={styles.marinaCover} src={item?.cover?.fileUrls?.mediumThumb} alt="Marina photo" /> : null}
                <p className={styles.title}>{item.name}</p>
            </div>
        );
    }

    function changeMarinaActiveStatus(marinaId: string, e: any) {
        const isChecked = e.target.checked;
        changeMarinaStatus(marinaId, isChecked);
    }

    function toggleMarinaOperatorsModal(marinaId: string | null) {
        setSelectedMarinaId(marinaId);
        toggleOperatorsModal();
    }

    function openCreateMarinaModal() {
        setModalName(ModalNames.CREATE_MARINA);
        changeIsModalOpen(true);
    }
};

export default connect(
    (state: RootState) => ({
        marinas: marinasSelector(state),
        isLoading: isMarinasPageLoadingSelector(state),
        marinasPageMetadata: marinasPageMetadataSelector(state),
    }),
    {
        fetchMarinas,
        resetToInitialMarinasPageState,
        changeMarinasPagePagination,
        changeMarinasFilters,
        applyMarinasFilters,
        changeMarinaStatus,
        setModalName,
        changeIsModalOpen,
    }
)(Marinas);
