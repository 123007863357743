import {Observable} from "rxjs";
import {marineHubAPI} from "../provider/marineHubAPI";
import {RestQueryParams} from "../base/queryParams";

export function postMessageReadConfirmationAPI(
    accessToken: string,
    messages: string[]
): Observable<any> {
    return marineHubAPI.post(
        `api/messages/mark_chat_messages_as_read`,
        {ids: messages},
        new RestQueryParams(),
        {
            Authorization: `Bearer ${accessToken}`
        }
    );
}
