import { Action, PayloadAction } from "@reduxjs/toolkit";

export function initApiCall<S, T>(state: S, action?: PayloadAction<T>) {
    return {
        ...state,
        isLoading: true,
    };
}

export function initCleanApiCall<S, T>(state: S, action?: Action<T>) {
    return {
        ...state,
        isLoading: true,
    };
}
