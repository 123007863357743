import {FormControlChangeType, IconSize, Loader, Marina, Translation, UserRole, userRolesSelector} from 'marine-panel-common-web';
import {FC, useEffect, useState} from 'react';
import {Anchor, Coffee, CreditCard, Disc, DollarSign, Droplet, Filter, Pocket, ShoppingCart, Speaker, Wifi, Zap} from 'react-feather';
import {connect, useDispatch} from 'react-redux';
import {useParams} from 'react-router-dom';
import {BehaviorSubject, Subscription} from 'rxjs';
import {filter, share} from 'rxjs/operators';
import {RootState} from '../../../../../../store/reducers';
import {fetchMarinaDetails} from '../../../../../../store/reducers/marinaEditWizardSlice';
import {addTag, changeTagDescription, fetchMarinaTags, removeTag} from '../../../../../../store/reducers/marinasTagsSlice';
import {currentlyEditedMarina} from '../../../../../../store/selectors/marinaEditWizardSelectors';
import {isMarinaTagsLoadingSelector, marinaTagsSelector} from '../../../../../../store/selectors/marinaTagsSelectors';
import MarinaTagEditItem from './MarinaTagItem';

interface IMarinaTagsProps {
    readonly marina: Marina | null;
    readonly tags: any;
    readonly isLoading: boolean;
    readonly fetchMarinaDetails: typeof fetchMarinaDetails;
    readonly fetchMarinaTags: typeof fetchMarinaTags;
    readonly addTag: typeof addTag;
    readonly removeTag: typeof removeTag;
    readonly changeTagDescription: typeof changeTagDescription;
    readonly userRole: UserRole[] | null;
}
export enum MarinaTagNames {
    ELECTRICITY = 'electricity',
    WIFI = 'wifi',
    WATERTANK = 'water_tank_refill',
    BUOY = 'buoy',
    LAUNDRY = 'laundry',
    LPG = 'lpg',
    ATM = 'atm',
    GAS_STATION = 'gas_station',
    SHOP = 'shop',
    CREDIT_CARD = 'credit_card',
    RESTAURANT = 'restaurant',
    ANCHOR = 'anchor',
}
export const icon = (name: string) => {
    let icon = null;
    switch (name) {
        case (name = MarinaTagNames.ELECTRICITY):
            icon = <Zap size={IconSize.XLarge} />;
            break;
        case (name = MarinaTagNames.WATERTANK):
            icon = <Droplet size={IconSize.XLarge} />;
            break;
        case (name = MarinaTagNames.WIFI):
            icon = <Wifi size={IconSize.XLarge} />;
            break;
        case (name = MarinaTagNames.BUOY):
            icon = <Disc size={IconSize.XLarge} />;
            break;
        case (name = MarinaTagNames.LAUNDRY):
            icon = <Speaker size={IconSize.XLarge} />;
            break;
        case (name = MarinaTagNames.LPG):
            icon = <Pocket size={IconSize.XLarge} />;
            break;
        case (name = MarinaTagNames.ATM):
            icon = <DollarSign size={IconSize.XLarge} />;
            break;
        case (name = MarinaTagNames.GAS_STATION):
            icon = <Filter size={IconSize.XLarge} />;
            break;
        case (name = MarinaTagNames.SHOP):
            icon = <ShoppingCart size={IconSize.XLarge} />;
            break;
        case (name = MarinaTagNames.CREDIT_CARD):
            icon = <CreditCard size={IconSize.XLarge} />;
            break;
        case (name = MarinaTagNames.RESTAURANT):
            icon = <Coffee size={IconSize.XLarge} />;
            break;
        case (name = MarinaTagNames.ANCHOR):
            icon = <Anchor size={IconSize.XLarge} />;
            break;
    }
    return icon;
};
const MarinaTags: FC<IMarinaTagsProps> = ({
    marina,
    fetchMarinaDetails,
    fetchMarinaTags,
    tags,
    isLoading,
    changeTagDescription,
    userRole,
}) => {
    const params = useParams(),
        marinaId = params.id,
        [onValueStateChange$] = useState(() => new BehaviorSubject<any>(null)),
        [onValueStateChange$$] = useState(() => onValueStateChange$.pipe(share())),
        [currentlyEditedItem, setCurrentlyEditedItem] = useState<any>(null),
        subscriptions: Subscription[] = [],
        dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchMarinaDetails(marinaId ? marinaId : ''));
        dispatch(fetchMarinaTags());

        return () => {
            subscriptions.forEach((subscription) => subscription.unsubscribe());
        };
    }, []);

    const tagsArray = marina?.publicTags.concat(tags);
    const unique = tagsArray?.reduce((acc: {[key: string]: any}, current: {[key: string]: any}) => {
        const x = acc.find((item: any) => item?.tag?.code === current?.code);
        return !x ? acc.concat([current]) : acc;
    }, []);

    const [value, setValue] = useState<any>({
        description: '',
    });

    useEffect(() => {
        subscriptions.push(
            onValueStateChange$$
                .pipe(
                    filter((data: {controlName?: string; value?: {[name: string]: string}; changeType?: FormControlChangeType}) => {
                        return data && data?.changeType === FormControlChangeType.User;
                    })
                )
                .subscribe((data) => {
                    if (data.controlName) {
                        setValue({
                            description: data?.value?.description,
                        });
                    }
                })
        );
    }, []);
    function onValueStateChange(controlName: string, value: any, changeType: FormControlChangeType) {
        onValueStateChange$.next({controlName: controlName, value: value, changeType: changeType});
    }
    function switchEditing(item: any, description?: string) {
        setCurrentlyEditedItem(item);
        setValue({
            description: description ? description : '',
        });
    }
    function changeTag(value: string, method: any) {
        dispatch(method(marinaId ? marinaId : null, value));
        dispatch(fetchMarinaDetails(marinaId));
        dispatch(fetchMarinaTags());
    }
    function changeDescription(id: string, value: string) {
        dispatch(changeTagDescription(marinaId ? marinaId : null, id, value));
        dispatch(fetchMarinaDetails(marinaId));
        dispatch(fetchMarinaTags());
        setCurrentlyEditedItem(null);
    }
    const isAdminOrOperatorAdminOrOwner = (userRole: UserRole[] | null) => {
        if (!userRole) return false;
        const allowedRoles = [UserRole.ADMIN, UserRole.OPERATOR_ADMIN, UserRole.OWNER];
        return userRole && allowedRoles.some((role) => userRole.includes(role));
    };

    const renderTagList = (tagsArray: any) => {
        if (!tagsArray || tagsArray?.length === 0 || tagsArray === null) {
            return (
                <p>
                    <Translation text="editMenuItems.sections.marina_tags.noTags" />
                </p>
            );
        } else {
            return (
                <div className="details-edit-section-wrapper">
                    {tagsArray.map((item: {[key: string]: any}) => {
                        return (
                            <MarinaTagEditItem
                                key={item?.id}
                                switchEditing={switchEditing}
                                changeTag={changeTag}
                                changeDescription={changeDescription}
                                currentItem={item}
                                editedItemId={currentlyEditedItem}
                                value={value}
                                onValueStateChange={onValueStateChange}
                                isProperUserRole={isAdminOrOperatorAdminOrOwner(userRole)}
                            />
                        );
                    })}
                </div>
            );
        }
    };
    return (
        <div>
            <h2 className="title main">
                <Translation text="editMenuItems.sections.marina_tags.title" />
            </h2>
            {renderTagList(unique)}
            <Loader showLoader={isLoading} />
        </div>
    );
};

export default connect(
    (state: RootState) => ({
        marina: currentlyEditedMarina(state),
        tags: marinaTagsSelector(state),
        isLoading: isMarinaTagsLoadingSelector(state),
        userRole: userRolesSelector(state),
    }),
    {fetchMarinaDetails, fetchMarinaTags, addTag, removeTag, changeTagDescription}
)(MarinaTags);
