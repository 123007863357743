import MarinaDetailsComponent from '../WizardContent/Marina/Details';
import GalleryMarinaComponent from '../WizardContent/Marina/Gallery';
import MarinaPoliciesAndRules from '../WizardContent/Marina/PoliciesAndRules';
import MarinaTags from '../WizardContent/Marina/Tags';
import MarinaExtras from '../WizardContent/Marina/Extras';

export type IWizardEditSideMenuItem = {
    id: string;
    title: string;
    navLink: string;
    sublinks: string[];
    component: any;
    icon?: any;
    onClick?: () => void;
    badge?: string;
    badgeText?: string;
    navLinkClassname?: string;
};

export const marinaMenu = (): IWizardEditSideMenuItem[] => [
    {
        id: 'marina_details',
        title: 'editMenuItems.marinaDetails',
        navLink: 'details',
        sublinks: ['marina_basics', 'marina_contact', 'marina_status', 'marina_location'],
        component: <MarinaDetailsComponent />,
    },
    {
        id: 'photo_gallery',
        title: 'editMenuItems.photoGallery',
        navLink: 'photo-gallery',
        sublinks: ['cover_photo', 'all_photos'],
        component: <GalleryMarinaComponent />,
    },
    {
        id: 'marina_tags',
        title: 'editMenuItems.marinaTags',
        navLink: 'tags',
        sublinks: [],
        component: <MarinaTags />,
    },
    {
        id: 'extras',
        title: 'editMenuItems.marinaExtras',
        navLink: 'extras',
        sublinks: [],
        component: <MarinaExtras />,
    },
    {
        id: 'marina_policies',
        title: 'editMenuItems.marinaPolicies',
        navLink: 'policies',
        sublinks: [],
        component: <MarinaPoliciesAndRules />,
    },
];
export default marinaMenu;
