import {createSelector} from '@reduxjs/toolkit';
import {IOperatorsState} from '../reducers/operatorsPageSlice';
import {RootState} from '../reducers';

export const selectOperatorsPageSlice = (state: RootState): IOperatorsState => {
    return state.operators;
};

export const operatorsSelector = createSelector([selectOperatorsPageSlice], (state: IOperatorsState) => state.operators);

export const operatorsPageMetadataSelector = createSelector(
    [selectOperatorsPageSlice],
    (state: IOperatorsState) => state.operatorsMetadata
);

export const operatorsPaginationSelector = createSelector([selectOperatorsPageSlice], (state: IOperatorsState) => state.pagination);

export const operatorConnectedMarinasSelector = createSelector(
    [selectOperatorsPageSlice],
    (state: IOperatorsState) => state.operatorConnectedMarinas
);

export const operatorSubscriptionsSelector = createSelector(
    [selectOperatorsPageSlice],
    (state: IOperatorsState) => state.operatorSubscriptions
);

export const isOperatorsPageLoadingSelector = createSelector([selectOperatorsPageSlice], (state: IOperatorsState) => state.isLoading);

export const isOperatorsPagesInitializedSelector = createSelector(
    [selectOperatorsPageSlice],
    (state: IOperatorsState) => state.isInitialized
);

export const isInvitationCompleteSelector = createSelector(
    [selectOperatorsPageSlice],
    (state: IOperatorsState) => state.isInvitationComplete
);

export const isInvitationProcessingSelector = createSelector(
    [selectOperatorsPageSlice],
    (state: IOperatorsState) => state.isInvitationProcessing
);

export const isMarinaActionCompleteSelector = createSelector(
    [selectOperatorsPageSlice],
    (state: IOperatorsState) => state.isMarinaActionComplete
);

export const isMarinaActionProcessingSelector = createSelector(
    [selectOperatorsPageSlice],
    (state: IOperatorsState) => state.isMarinaActionProcessing
);

export const operatorsPagesErrorSelector = createSelector([selectOperatorsPageSlice], (state: IOperatorsState) => state.error);
