import {Observable} from 'rxjs';
import {marineHubAPI} from '../provider/marineHubAPI';
import {RestQueryParams} from '../base/queryParams';
import {ChatServerMessagePayload} from '../../types';

export function sendMessageToOfflineAPI(accessToken: string, message: ChatServerMessagePayload): Observable<any> {
    return marineHubAPI.post(`api/messages`, message, new RestQueryParams(), {
        Authorization: `Bearer ${accessToken}`,
    });
}
