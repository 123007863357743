import React, {useEffect, useState} from 'react';
import {Modal, ModalBody, ModalHeader} from 'reactstrap';
import {
    Form,
    FormControlChangeType,
    IFormConfig,
    Loader,
    LoaderType,
    Translation,
    vesselTypesSelector,
    IModelDictionaryDatum,
    convertToMultiselectLabels,
    centimeterString,
} from 'marine-panel-common-web';
import {BehaviorSubject, Subscription} from 'rxjs';
import {filter, share} from 'rxjs/operators';
import {addVesselModelFormConfig} from './addVesselModelFormConfig';
import {IVesselModel} from '../../../../api/addVesselModel';
import {addVesselModel, changeIsActionComplete} from '../../../../store/reducers/vesselModelsPageSlice';
import {connect} from 'react-redux';
import {RootState} from '../../../../store/reducers';
import {
    isVesselModelActionCompleteSelector,
    isVesselModelActionProcessingSelector,
} from '../../../../store/selectors/vesselModelsPageSelectors';

interface IConnectedAddVesselModelModalProps {
    readonly dictionaryVesselType: IModelDictionaryDatum[] | null;
    readonly isVesselModelActionComplete: boolean;
    readonly isVesselModelActionProcessing: boolean;
    readonly addVesselModel: typeof addVesselModel;
    readonly changeIsActionComplete: typeof changeIsActionComplete;
}

interface IExternalAddVesselModelModalProps {
    readonly isModalShown: boolean;
    readonly toggleModal: () => void;
}

interface IAddVesselModelModalProps extends IConnectedAddVesselModelModalProps, IExternalAddVesselModelModalProps {}

const AddVesselModelModal: React.FC<IAddVesselModelModalProps> = ({
    isModalShown,
    toggleModal,
    addVesselModel,
    dictionaryVesselType,
    isVesselModelActionComplete,
    isVesselModelActionProcessing,
}) => {
    const [formValues, setFormValues] = useState<{[key: string]: any} | null>(null),
        [formConfig, setFormConfig] = useState<IFormConfig | null>(null),
        [onValueStateChange$] = useState(() => new BehaviorSubject<any>(null)),
        [onValueStateChange$$] = useState(() => onValueStateChange$.pipe(share())),
        subscriptions: Subscription[] = [];

    useEffect(() => {
        if (isVesselModelActionComplete) {
            toggleModal();
        }
    }, [isVesselModelActionComplete]);

    useEffect(() => {
        setForm();
        subscriptions.push(
            onValueStateChange$$
                .pipe(
                    filter((data: {controlName?: string; value?: {[name: string]: string}; changeType?: FormControlChangeType}) => {
                        return data && data?.changeType === FormControlChangeType.User;
                    })
                )
                .subscribe((data) => {
                    if (data.controlName && data.value) {
                        setFormValues({
                            name: data.value.name,
                            length: data.value.length,
                            width: data.value.width,
                            draft: data.value.draft,
                            height: data.value.height,
                            type: data.value.type,
                        });
                    }
                })
        );
        return () => {
            subscriptions.forEach((subscription) => subscription.unsubscribe());
            changeIsActionComplete(false);
            setFormValues(null);
        };
    }, []);

    function onValueStateChange(controlName: string, value: any, changeType: FormControlChangeType) {
        onValueStateChange$.next({controlName: controlName, value: value, changeType: changeType});
    }

    return (
        <Modal isOpen={isModalShown} toggle={() => toggleModal()}>
            <ModalHeader toggle={() => toggleModal()}>
                <Translation text="administration.vesselModels.addVesselModel.title" />
            </ModalHeader>
            <ModalBody>
                {formConfig && (
                    <Form
                        config={formConfig}
                        submitForm={vesselModelAction}
                        onValueStateChange={onValueStateChange}
                        value={formValues}
                        controlName={'vesselModelForm'}
                    />
                )}
            </ModalBody>
            <Loader type={LoaderType.Local} showLoader={isVesselModelActionProcessing} />
        </Modal>
    );

    function vesselModelAction() {
        if (null !== formValues) {
            const vesselModel: IVesselModel = {
                name: formValues.name,
                length: Number(centimeterString(formValues.length)),
                width: Number(centimeterString(formValues.width)),
                draft: Number(centimeterString(formValues.draft)),
                height: Number(centimeterString(formValues.height)),
                vesselTypeId: formValues.type,
            };

            return addVesselModel({vesselModels: [vesselModel]});
        }
    }

    function setForm() {
        const vesselTypes = dictionaryVesselType ? convertToMultiselectLabels(dictionaryVesselType) : [];
        setFormConfig(addVesselModelFormConfig(vesselTypes, () => toggleModal()));
    }
};

export default connect(
    (state: RootState) => ({
        dictionaryVesselType: vesselTypesSelector(state),
        isVesselModelActionComplete: isVesselModelActionCompleteSelector(state),
        isVesselModelActionProcessing: isVesselModelActionProcessingSelector(state),
    }),
    {
        addVesselModel,
        changeIsActionComplete,
    }
)(AddVesselModelModal);
