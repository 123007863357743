import {PayloadAction} from '@reduxjs/toolkit';
import {combineEpics, Epic, ofType, StateObservable} from 'redux-observable';
import {of} from 'rxjs';
import {catchError, switchMap} from 'rxjs/operators';
import {RootState} from '../reducers';
import {addAlert, AlertType, authTokenSelector, handleApiError} from 'marine-panel-common-web';
import {
    addTag,
    changeMarinaTagsError,
    changeIsActionSuccessful,
    changeIsMarinaTagsLoading,
    changeTagDescription,
    fetchMarinaTags,
    IAddMarinaTagPayload,
    IChangeTagDescription,
    removeTag,
    setMarinaTags,
} from '../reducers/marinasTagsSlice';
import {getMarinaTagsAPI} from '../../api/marina/getMarinaTags';
import {changeMarinaTagDescriptionAPI} from '../../api/marina/changeTagDescription';
import {removeMarinaTagAPI} from '../../api/marina/removeTag';
import {addMarinaTagAPI} from '../../api/marina/addTag';

const removeTagEpic: Epic = (action$, state$: StateObservable<RootState>) => {
    return changeTag(
        action$,
        state$,
        removeMarinaTagAPI,
        removeTag,
        'editMenuItems.alerts.marinaRemoveTagSuccess',
        'editMenuItems.alerts.marinaRemoveTagError'
    );
};

const addTagEpic: Epic = (action$, state$: StateObservable<RootState>) => {
    return changeTag(
        action$,
        state$,
        addMarinaTagAPI,
        addTag,
        'editMenuItems.alerts.marinaAddTagSuccess',
        'editMenuItems.alerts.marinaAddTagError'
    );
};
const changeTag = (
    action$: any,
    state$: StateObservable<any>,
    api: any,
    type: any,
    successTranslationKey: string,
    errorTranslationKey: string
) => {
    return action$.pipe(
        ofType(type.type),
        switchMap((action: PayloadAction<IAddMarinaTagPayload>) => {
            const authToken = authTokenSelector(state$.value);
            return api(action.payload.marinaId, authToken, action.payload.marinaTagId).pipe(
                switchMap(() => {
                    const actions = successActions([
                        changeIsActionSuccessful(true),
                        addAlert({message: successTranslationKey ? successTranslationKey : '', type: AlertType.SUCCESS}),
                    ]);
                    return of(...actions);
                }),
                catchError((error) => {
                    return of(...fetchListErrorActions(error, changeMarinaTagsError(errorTranslationKey)));
                })
            );
        }),
        catchError((error) => {
            return of(...fetchListErrorActions(error, changeMarinaTagsError(errorTranslationKey)));
        })
    );
};
const changeMarinaTagDescriptionEpic: Epic = (action$, state$: StateObservable<RootState>) => {
    return action$.pipe(
        ofType(changeTagDescription.type),
        switchMap((action: PayloadAction<IChangeTagDescription>) => {
            const authToken = authTokenSelector(state$.value);
            return changeMarinaTagDescriptionAPI(authToken, action.payload).pipe(
                switchMap(() => {
                    const actions = successActions([
                        changeIsActionSuccessful(true),
                        addAlert({message: 'editMenuItems.alerts.marinaTagDescriptionUpdateSuccess', type: AlertType.SUCCESS}),
                    ]);
                    return of(...actions);
                }),
                catchError((error) => {
                    return of(
                        ...fetchListErrorActions(error, changeMarinaTagsError('editMenuItems.alerts.marinaTagDescriptionUpdateError'))
                    );
                })
            );
        }),
        catchError((error) => {
            return of(...fetchListErrorActions(error, changeMarinaTagsError('editMenuItems.alerts.marinaTagDescriptionUpdateError')));
        })
    );
};
const fetchMarinaTagsEpic: Epic = (action$, state$: StateObservable<RootState>) => {
    return action$.pipe(
        ofType(fetchMarinaTags.type),
        switchMap(() => {
            const authToken = authTokenSelector(state$.value);
            return getMarinaTagsAPI(authToken).pipe(
                switchMap((resp): any => {
                    const actions = successActions([setMarinaTags(resp['hydra:member'])]);
                    return of(...actions);
                }),
                catchError((error) => {
                    return of(...fetchListErrorActions(error, changeMarinaTagsError(error)));
                })
            );
        }),
        catchError((error) => {
            return of(...fetchListErrorActions(error, changeMarinaTagsError(error)));
        })
    );
};

const successActions = (changeSliceList: any[]): any[] => {
    const actions = [changeIsMarinaTagsLoading(false)];

    if (changeSliceList) {
        return actions.concat(changeSliceList);
    }
    return actions;
};

export const fetchListErrorActions = (error: any, setSliceError: any): any[] => {
    const errorObj = handleApiError(error);
    errorObj.type = AlertType.WARNING;
    return [addAlert(errorObj), changeIsMarinaTagsLoading(false), setSliceError(errorObj.message)];
};

const marinaTagsEpic = combineEpics(fetchMarinaTagsEpic, addTagEpic, removeTagEpic, changeMarinaTagDescriptionEpic);

export default marinaTagsEpic;
