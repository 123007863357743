import {
    addAlert,
    AlertType,
    ApiError,
    authTokenSelector,
    getMetadataDetails,
    handleApiError,
    IModelApiResponseViewObject,
    IRawRestQueryParams,
} from 'marine-panel-common-web';
import {combineEpics, Epic, ofType, StateObservable} from 'redux-observable';
import {Observable, of} from 'rxjs';
import {catchError, switchMap} from 'rxjs/operators';
import {RootState} from '../reducers';
import {
    changeIsBoatCertificatesLoading,
    changeBoatCertificates,
    changeBoatCertificatesPagination,
    fetchBoatCertificates,
    setBoatCertificatesError,
    setBoatCertificatesMetadata,
    IFetchBoatCertificates,
} from '../reducers/boatCertificatesSlice';
import {getBoatCertificatesAPI} from '../../api/getBoatCertificates';
import {PayloadAction} from '@reduxjs/toolkit';

const fetchBoatCertificatesEpic: Epic = (action$, state$: StateObservable<RootState>) => {
    return getBoatCertificates(action$, state$, fetchBoatCertificates);
};

const changeBoatCertificatesPaginationEpic: Epic = (action$, state$: StateObservable<RootState>) => {
    return getBoatCertificates(action$, state$, changeBoatCertificatesPagination);
};

const getBoatCertificates = (action$: Observable<any>, state$: StateObservable<RootState>, actionType: any) => {
    return action$.pipe(
        ofType(actionType.type),
        switchMap((action: PayloadAction<IFetchBoatCertificates>): any => {
            const authToken = authTokenSelector(state$.value),
                filters: any = [];
            if (action.payload.params) {
                filters.push(...action.payload.params);
            }
            return getBoatCertificatesList(authToken, filters);
        }),
        catchError((error) => of(...errorActions(error)))
    );
};

export const getBoatCertificatesList = (authToken: string, params?: IRawRestQueryParams | null) => {
    return getBoatCertificatesAPI(authToken, params).pipe(
        switchMap((resp: any) => {
            const boatCertificateList = resp['hydra:member'],
                metadata = getMetadataDetails(resp['hydra:view']),
                actions = successActions(boatCertificateList, metadata);
            return of(...actions);
        }),
        catchError((error: any) => of(...errorActions(error)))
    );
};

const errorActions = (error: ApiError) => {
    const errorObj = handleApiError(error);
    errorObj.type = AlertType.WARNING;
    return [addAlert(errorObj), changeIsBoatCertificatesLoading(false), setBoatCertificatesError(errorObj.message)];
};

const successActions = (boatCertificates: any[], metadata: IModelApiResponseViewObject | null): any[] => {
    return [changeBoatCertificates(boatCertificates), setBoatCertificatesMetadata(metadata), changeIsBoatCertificatesLoading(false)];
};

const boatCertificatesEpic = combineEpics(fetchBoatCertificatesEpic, changeBoatCertificatesPaginationEpic);

export default boatCertificatesEpic;
