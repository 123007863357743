export const convertPriceToDisplayValue = (price: string): string => {
    const convertedValue = Math.floor(Number(price) / 100);
    return String(convertedValue);
};

export const convertPriceToInputValue = (price: string): number => {
    return Math.floor(Number(price) / 100);
};

export const convertPriceToPayloadValue = (price: string): string => {
    const convertedValue = Number(price) * 100;
    return String(convertedValue);
};
