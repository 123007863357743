import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {UserRole} from '../../types';
import {IRegisterPayload} from '../../api/auth/register';
import {IResetPasswordPayload} from '../../api/auth/sendResetPasswordEmail';

export interface IAuthEntry {
    username: string | null;
    authToken: string | null;
    refreshToken: string | null;
}

export interface IAuthTokens {
    authToken: string;
    refreshToken: string;
}

export interface IAuthState {
    username: string | null;
    authToken: string | null;
    refreshToken: string | null;
    error: string | null;
    isAuthenticated: boolean;
    userRoles: UserRole[] | null;
    isAuthPageLoading: boolean;
}

export interface ISetAuthTokens {
    readonly authToken: string | null;
    readonly refreshToken: string | null;
}

export interface ISetAuthState {
    readonly username: string | null;
    readonly authToken: string | null;
    readonly refreshToken: string | null;
    readonly isAuthenticated: boolean;
    readonly userRoles: UserRole[] | null;
}

export interface ISetAuthError {
    readonly error: string;
}

export interface IChangeInitialLoading {
    readonly initialLoading: boolean;
}

export interface IChangeIsAuthenticated {
    readonly isAuthenticated: boolean;
}

export interface IRenewAuthToken {
    readonly authToken: string;
    readonly refreshToken: string;
}

export interface ISetAuthToken {
    readonly authToken: string;
    readonly userRole: UserRole;
}

export interface IRegisterUser {
    readonly registrationPayload: IRegisterPayload;
}

export interface IRequestNewPassword {
    readonly requestNewPasswordPayload: IResetPasswordPayload;
}

export interface IConfirmRegistration {
    readonly registrationToken: string;
}

export interface ISetNewPassword {
    readonly authToken: string;
    readonly password: string;
}

export interface IChangeIsAuthPageLoading {
    readonly isLoading: boolean;
}

const initialState: IAuthState = {
    username: null,
    authToken: null,
    refreshToken: null,
    error: null,
    isAuthenticated: false,
    userRoles: null,
    isAuthPageLoading: false,
};

const authSlice = createSlice({
    name: 'auth',
    initialState: initialState,
    reducers: {
        setAuthState: {
            reducer: (state: IAuthState, action: PayloadAction<ISetAuthState>) => {
                return {
                    ...state,
                    username: action.payload.username,
                    authToken: action.payload.authToken,
                    refreshToken: action.payload.refreshToken,
                    isAuthenticated: action.payload.isAuthenticated,
                    userRoles: action.payload.userRoles,
                };
            },
            prepare(
                username: string | null,
                authToken: string | null,
                refreshToken: string | null,
                isAuthenticated: boolean,
                userRoles: UserRole[]
            ) {
                return {
                    payload: {
                        username: username,
                        authToken: authToken,
                        refreshToken: refreshToken,
                        isAuthenticated: isAuthenticated,
                        userRoles: userRoles,
                    },
                };
            },
        },
        setAuthStateFailure: {
            reducer: (state: IAuthState, action: PayloadAction<ISetAuthError>) => {
                return {
                    ...state,
                    error: action.payload.error,
                };
            },
            prepare(error: string) {
                return {
                    payload: {
                        error: error,
                    },
                };
            },
        },
        setAuthTokens: {
            reducer: (state: IAuthState, action: PayloadAction<ISetAuthTokens>) => {
                return {
                    ...state,
                    authToken: action.payload.authToken,
                    refreshToken: action.payload.refreshToken,
                };
            },
            prepare(authToken: string, refreshToken: string) {
                return {
                    payload: {
                        authToken: authToken,
                        refreshToken: refreshToken,
                    },
                };
            },
        },
        initAuthTokenChange: {
            reducer: (state: IAuthState) => {
                return {
                    ...state,
                };
            },
            prepare(authToken: string, userRole: UserRole) {
                return {
                    payload: {
                        authToken: authToken,
                        userRole: userRole,
                    },
                };
            },
        },
        changeIsAuthenticated: {
            reducer: (state: IAuthState, action: PayloadAction<IChangeIsAuthenticated>) => {
                return {
                    ...state,
                    isAuthenticated: action.payload.isAuthenticated,
                };
            },
            prepare(isAuthenticated: boolean) {
                return {
                    payload: {
                        isAuthenticated: isAuthenticated,
                    },
                };
            },
        },
        logout: {
            reducer: () => {
                return initialState;
            },
            prepare(logout: true) {
                return {
                    payload: {logout},
                };
            },
        },
        renewAuthToken: {
            reducer: (state: IAuthState) => {
                return {
                    ...state,
                };
            },
            prepare(authToken: string, refreshToken: string) {
                return {
                    payload: {
                        authToken: authToken,
                        refreshToken: refreshToken,
                    },
                };
            },
        },
        registerUser: {
            reducer: (state: IAuthState) => {
                return {
                    ...state,
                    isAuthPageLoading: true,
                };
            },
            prepare(registrationPayload: IRegisterPayload) {
                return {
                    payload: {
                        registrationPayload: registrationPayload,
                    },
                };
            },
        },
        confirmRegistration: {
            reducer: (state: IAuthState) => {
                return {
                    ...state,
                    isAuthPageLoading: true,
                };
            },
            prepare(registrationToken: string) {
                return {
                    payload: {
                        registrationToken: registrationToken,
                    },
                };
            },
        },
        requestNewPassword: {
            reducer: (state: IAuthState) => {
                return {
                    ...state,
                    isAuthPageLoading: true,
                };
            },
            prepare(requestNewPasswordPayload: IResetPasswordPayload) {
                return {
                    payload: {
                        requestNewPasswordPayload: requestNewPasswordPayload,
                    },
                };
            },
        },
        setNewPassword: {
            reducer: (state: IAuthState) => {
                return {
                    ...state,
                    isAuthPageLoading: true,
                };
            },
            prepare(authToken: string, password: string) {
                return {
                    payload: {
                        authToken: authToken,
                        password: password,
                    },
                };
            },
        },
        changeIsAuthPageLoading: {
            reducer: (state: IAuthState, action: PayloadAction<IChangeIsAuthPageLoading>) => {
                return {
                    ...state,
                    isAuthPageLoading: action.payload.isLoading,
                };
            },
            prepare(isLoading: boolean) {
                return {
                    payload: {
                        isLoading: isLoading,
                    },
                };
            },
        },
    },
});

export const {
    setAuthState,
    setAuthTokens,
    initAuthTokenChange,
    setAuthStateFailure,
    changeIsAuthenticated,
    renewAuthToken,
    logout,
    registerUser,
    confirmRegistration,
    requestNewPassword,
    setNewPassword,
    changeIsAuthPageLoading,
} = authSlice.actions;

export default authSlice.reducer;
