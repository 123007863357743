import {Marina, Translation} from 'marine-panel-common-web';
import {FC, useEffect, useState} from 'react';
import {ModalBody, ModalFooter, ModalHeader} from 'reactstrap';
import {Uploader} from 'rsuite';
import {of, Subscription} from 'rxjs';
import styles from '../style.module.scss';
import {catchError, switchMap} from 'rxjs/operators';
import {addPhotoAPI} from '../../../../api/addPhoto';
import {changeIsModalOpen} from '../../../../store/reducers/modalSlice';
import {setIsActionSuccessful} from '../../../../store/reducers/marinaEditWizardSlice';
import {addPhotoToGalleryAPI} from '../../../../api/marina/addPhotoToGalery';

interface IAddPhotoModalProps {
    readonly setIsActionSuccessful: typeof setIsActionSuccessful;
    readonly changeIsModalOpen: typeof changeIsModalOpen;
    readonly authToken: string;
    readonly toggleModalOpen: () => void;
    readonly marina?: Marina | null;
}

interface IImageHostState {
    image?: string | Blob | null;
    value?: any;
    isLoading?: boolean;
}

const AddPhotoModal: FC<IAddPhotoModalProps> = ({marina, changeIsModalOpen, setIsActionSuccessful, authToken, toggleModalOpen}) => {
    const subscriptions: Subscription[] = [];

    useEffect(() => {
        return () => {
            subscriptions.forEach((subscription) => subscription.unsubscribe());
        };
    }, []);

    const [uploadImg, setUploadImg] = useState<IImageHostState>({
        image: null,
        value: null,
        isLoading: false,
    });

    function addFile(file: any) {
        return setUploadImg({
            value: file.slice(file.length - 1),
            image: file.length > 1 ? file[1].blobFile : file[0].blobFile,
        });
    }

    function removeFile() {
        setUploadImg({
            value: null,
            image: null,
            isLoading: false,
        });
        return toggleModalOpen();
    }

    function addImage() {
        const verificationFile = uploadImg.value,
            verificationFileTypeId = uploadImg.value;

        if (verificationFile && verificationFile.length && verificationFileTypeId && authToken) {
            const formData = new FormData();
            formData.append('file', verificationFile[0].blobFile);
            formData.append('typeId', verificationFileTypeId);

            setUploadImg({isLoading: true});
            subscriptions.push(
                addPhotoAPI(authToken, formData)
                    .pipe(
                        switchMap((resp: any) => {
                            changeIsModalOpen(false);
                            const payload = {
                                marinaId: marina?.id ? marina.id : null,
                                id: resp.response.id,
                            };
                            return addPhotoToGalleryAPI(payload, authToken).pipe(
                                switchMap(() => {
                                    const actions = [setIsActionSuccessful(true)];
                                    return of(...actions);
                                }),
                                catchError((error) => {
                                    return of(...error);
                                })
                            );
                        }),
                        catchError((error) => {
                            return of(...error);
                        })
                    )
                    .subscribe()
            );
        }
    }

    return (
        <>
            <ModalHeader toggle={() => toggleModalOpen()}>
                <p className={styles.title}>
                    <Translation text="modal.addPhoto.title" />
                </p>
            </ModalHeader>
            <ModalBody className={styles.container}>
                <div className={styles.content}>
                    <Uploader
                        className={styles.uploader}
                        draggable
                        action={''}
                        removable={false}
                        multiple={false}
                        accept="image/png, image/jpeg"
                        listType="picture"
                        autoUpload={false}
                        onChange={addFile}></Uploader>
                </div>
            </ModalBody>
            <ModalFooter>
                <button className="btn btn-underline btn-underline-primary close-modal-button" onClick={() => removeFile()}>
                    <Translation text="buttons.cancel" />
                </button>
                <button className="btn btn-create add-photo-button" onClick={() => addImage()}>
                    <Translation text="buttons.add" />
                </button>
            </ModalFooter>
        </>
    );
};

export default AddPhotoModal;
