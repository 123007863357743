import {
    addAlert,
    AlertType,
    authTokenSelector,
    deepCloneObject,
    flattenObj,
    getMetadataDetails,
    handleApiError,
    IModelApiResponseViewObject,
    IRawRestQueryParams,
    isNullOrUndefined,
} from 'marine-panel-common-web';
import {combineEpics, Epic, ofType, StateObservable} from 'redux-observable';
import {BehaviorSubject, Observable, ObservableInput, of} from 'rxjs';
import {catchError, concatMap, debounceTime, filter, map, switchMap, tap} from 'rxjs/operators';
import {getMarinasAPI} from '../../api/marina/getMarinas';
import {RootState} from '../reducers';
import {
    applyMarinasFilters,
    assignOperatorToMarina,
    changeIsMarinasPageLoading,
    changeMarinas,
    changeMarinasPagePagination,
    changeMarinaStatus,
    fetchConnectedOperators,
    fetchMarinas,
    IChangeMarinaStatus,
    IFetchConnectedMarinaOperators,
    setMarinaConnectedOperators,
    setMarinasPageError,
    setMarinasPageMetadata,
    unassignOperatorFromMarina,
    changeIsMarinaActionComplete,
    changeIsMarinaActionProcessing,
} from '../reducers/marinasSlice';
import {marinasPageFiltersSelector, marinasPagePaginationSelector} from '../selectors/marinaSelectors';
import {PayloadAction} from '@reduxjs/toolkit';
import {changeMarinaActiveStatusAPI} from '../../api/marina/changeMarinaActiveStatus';
import {getOperatorsAPI} from '../../api/getOperators';
import {IBindMarinaToOperator} from '../reducers/operatorsPageSlice';
import {assignOperatorToMarinaAPI} from '../../api/assignOperatorToMarinas';
import {unassignOperatorFromMarinaAPI} from '../../api/unassignOperatorFromMarinas';

const fetchMarinasEpic: Epic = (action$, state$: StateObservable<RootState>) => {
    return getMarinas(action$, state$, fetchMarinas);
};

const applyMarinasFiltersEpic: Epic = (action$, state$: StateObservable<RootState>) =>
    getAction(action$, state$, applyMarinasFilters, doFetch);

const changeMarinasPaginationEpic: Epic = (action$, state$: StateObservable<RootState>) => {
    return getMarinas(action$, state$, changeMarinasPagePagination);
};

const changeMarinaStatusEpic: Epic = (action$: Observable<any>, state$: StateObservable<RootState>) => {
    return action$.pipe(
        ofType(changeMarinaStatus.type),
        switchMap((action: PayloadAction<IChangeMarinaStatus>): any => {
            const authToken = authTokenSelector(state$.value),
                marinaId = action.payload.marinaId,
                isActive = action.payload.isActive;

            return changeMarinaActiveStatusAPI({marinaId: marinaId, active: isActive}, authToken).pipe(
                switchMap(() => {
                    const message = 'marinas.statusChanged';
                    return of(changeIsMarinasPageLoading(false), addAlert({message: message}));
                }),
                catchError((error) => of(...errorActions(error)))
            );
        }),
        catchError((error) => of(...errorActions(error)))
    );
};

const fetchConnectedOperatorsEpic: Epic = (action$: Observable<any>, state$: StateObservable<RootState>) =>
    action$.pipe(
        ofType(fetchConnectedOperators.type),
        switchMap((action: PayloadAction<IFetchConnectedMarinaOperators>) => {
            const authToken = authTokenSelector(state$.value),
                marinaId = action.payload.marinaId,
                params = [{path: 'marinas.id', val: marinaId}];

            return getOperatorsAPI(authToken, params).pipe(
                switchMap((resp: any) => {
                    return of(setMarinaConnectedOperators(resp['hydra:member']));
                }),
                catchError((error: any) => of(...errorActions(error)))
            );
        }),
        catchError((error: any) => of(...errorActions(error)))
    );

const assignMarinaToOperatorEpic: Epic = (action$: Observable<any>, state$: StateObservable<RootState>) => {
    return action$.pipe(
        ofType(assignOperatorToMarina.type),
        switchMap((action: PayloadAction<IBindMarinaToOperator>): any => {
            const authToken = authTokenSelector(state$.value),
                operatorId = action.payload.operatorId,
                marinaId = action.payload.marinaId;
            return assignOperatorToMarinaAPI(authToken, marinaId, operatorId).pipe(
                switchMap(() => {
                    const message = 'marinas.connectedOperators.operatorAssigned';
                    return of(...updateSuccessActions(message, marinaId));
                }),
                catchError((error) => of(...updateErrorActions(error)))
            );
        }),
        catchError((error) => of(...updateErrorActions(error)))
    );
};

const unassignMarinafromOperatorEpic: Epic = (action$: Observable<any>, state$: StateObservable<RootState>) => {
    return action$.pipe(
        ofType(unassignOperatorFromMarina.type),
        switchMap((action: PayloadAction<IBindMarinaToOperator>): any => {
            const authToken = authTokenSelector(state$.value),
                operatorId = action.payload.operatorId,
                marinaId = action.payload.marinaId;
            return unassignOperatorFromMarinaAPI(authToken, marinaId, operatorId).pipe(
                switchMap(() => {
                    const message = 'marinas.connectedOperators.operatorUnassigned';
                    return of(...updateSuccessActions(message, marinaId));
                }),
                catchError((error) => of(...updateErrorActions(error)))
            );
        }),
        catchError((error) => of(...updateErrorActions(error)))
    );
};

export type FetchAction = {token: string | null; flattenedParams: any};
const fetchSubject = new BehaviorSubject<FetchAction>({token: null, flattenedParams: null});
const resultsSubject = new BehaviorSubject<any>(null);
fetchSubject
    .asObservable()
    .pipe(
        debounceTime(250),
        switchMap((fetch) => {
            if (isNullOrUndefined(fetch.token)) {
                return of(null);
            }

            return getMarinasList(fetch.token as string, fetch.flattenedParams);
        }),
        tap((action) => resultsSubject.next(action))
    )
    .subscribe(); // subscription with same lifetime as the application, no need to unsubscribe

const doFetch = (state: RootState) => {
    const authToken = authTokenSelector(state),
        paginationParams = marinasPagePaginationSelector(state),
        filters = deepCloneObject(marinasPageFiltersSelector(state));
    const filterObj = {
            ...filters,
            ...paginationParams,
        },
        flattened = flattenObj(filterObj);

    fetchSubject.next({token: authToken, flattenedParams: flattened});

    return resultsSubject.asObservable().pipe(
        filter((action: any) => null !== action),
        concatMap((action) => of(action, changeIsMarinasPageLoading(false)))
    );
};

const getMarinas = (action$: Observable<any>, state$: StateObservable<any>, actionType: any) => {
    return action$.pipe(
        ofType(actionType.type),
        switchMap((): any => {
            const authToken = authTokenSelector(state$.value),
                paginationParams = marinasPagePaginationSelector(state$.value),
                filters = deepCloneObject(marinasPageFiltersSelector(state$.value));
            const filterObj = {
                    ...filters,
                    ...paginationParams,
                },
                params = filterObj ? flattenObj(filterObj) : null;

            return getMarinasList(authToken, params);
        }),
        catchError((error) => of(...errorActions(error)))
    );
};

export const getAction = (
    action$: Observable<any>,
    state$: StateObservable<any>,
    actionType: any,
    doFetch: (state: RootState) => ObservableInput<any>
) => {
    return action$.pipe(
        ofType(actionType.type),
        map(() => state$.value),
        switchMap(doFetch)
    );
};

export const getMarinasList = (authToken: string, params?: IRawRestQueryParams | null) => {
    return getMarinasAPI(authToken, params).pipe(
        switchMap((resp: any) => {
            const marinaList = resp['hydra:member'],
                metadata = getMetadataDetails(resp['hydra:view']),
                actions = successActions(marinaList, metadata);
            return of(...actions);
        }),
        catchError((error: any) => of(...errorActions(error)))
    );
};

const successActions = (marinas: any[], metadata: IModelApiResponseViewObject | null): any[] => {
    return [changeMarinas(marinas), setMarinasPageMetadata(metadata), changeIsMarinasPageLoading(false)];
};

const errorActions = (error: any): any[] => {
    const errorObj = handleApiError(error);
    errorObj.type = AlertType.WARNING;
    return [changeIsMarinasPageLoading(false), setMarinasPageError(errorObj.message), addAlert(errorObj)];
};

const updateSuccessActions = (successMessage: string, marinaId: string): any[] => {
    return [
        changeIsMarinaActionProcessing(false),
        changeIsMarinaActionComplete(true),
        addAlert({message: successMessage}),
        fetchMarinas(),
        fetchConnectedOperators(marinaId),
    ];
};

const updateErrorActions = (error: any): any[] => {
    const errorObj = handleApiError(error);
    errorObj.type = AlertType.WARNING;
    return [
        changeIsMarinaActionProcessing(false),
        changeIsMarinaActionComplete(true),
        setMarinasPageError(errorObj.message),
        addAlert(errorObj),
    ];
};

const marinasEpic = combineEpics(
    fetchMarinasEpic,
    changeMarinasPaginationEpic,
    applyMarinasFiltersEpic,
    changeMarinaStatusEpic,
    fetchConnectedOperatorsEpic,
    assignMarinaToOperatorEpic,
    unassignMarinafromOperatorEpic
);

export default marinasEpic;
