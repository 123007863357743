import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {IBaseDictionaryState, IChangeIsInitialized, IChangeIsLoading, ISetError} from './marinasSlice';
import {initApiCall} from '../../utils/reduxHelpers';
import {IChangeIsActionComplete, IChangeIsActionProcessing} from './vesselModelsPageSlice';
import {IAddVesselTypePayload} from '../../api/addVesselType';
import {VesselType} from 'marine-panel-common-web';

export interface IVesselTypesState extends IBaseDictionaryState {
    vesselTypes: VesselType[] | null;
    isActionComplete: boolean;
    isActionProcessing: boolean;
}

export interface ISetVesselTypes {
    readonly vesselTypes: VesselType[] | null;
}

export interface IAddVesselType {
    vesselTypePayload: IAddVesselTypePayload;
}

const initialState: IVesselTypesState = {
    vesselTypes: null,
    isActionComplete: false,
    isActionProcessing: false,
    isLoading: false,
    isInitialized: false,
    error: null,
};

const vesselTypesSlice = createSlice({
    name: 'vesselTypes',
    initialState: initialState,
    reducers: {
        changeVesselTypes: {
            reducer: (state: IVesselTypesState, action: PayloadAction<ISetVesselTypes>) => {
                return {
                    ...state,
                    vesselTypes: action.payload.vesselTypes,
                };
            },
            prepare(vesselTypes: VesselType[] | null) {
                return {
                    payload: {vesselTypes: vesselTypes},
                };
            },
        },
        changeIsVesselTypesPageLoading: {
            reducer: (state: IVesselTypesState, action: PayloadAction<IChangeIsLoading>) => {
                return {
                    ...state,
                    isLoading: action.payload.isLoading,
                };
            },
            prepare(isLoading: boolean) {
                return {
                    payload: {isLoading: isLoading},
                };
            },
        },
        changeIsVesselTypesPageInitialized: {
            reducer: (state: IVesselTypesState, action: PayloadAction<IChangeIsInitialized>) => {
                return {
                    ...state,
                    isInitialized: action.payload.isInitialized,
                };
            },
            prepare(isInitialized: boolean) {
                return {
                    payload: {isInitialized: isInitialized},
                };
            },
        },
        setVesselTypesPageError: {
            reducer: (state: IVesselTypesState, action: PayloadAction<ISetError>) => {
                return {
                    ...state,
                    error: action.payload.error,
                };
            },
            prepare(error: string | null) {
                return {
                    payload: {error: error},
                };
            },
        },
        changeIsActionComplete: {
            reducer: (state: IVesselTypesState, action: PayloadAction<IChangeIsActionComplete>) => {
                return {
                    ...state,
                    isActionComplete: action.payload.isComplete,
                };
            },
            prepare(isComplete: boolean) {
                return {
                    payload: {isComplete: isComplete},
                };
            },
        },
        changeIsActionProcessing: {
            reducer: (state: IVesselTypesState, action: PayloadAction<IChangeIsActionProcessing>) => {
                return {
                    ...state,
                    isActionProcessing: action.payload.isProcessing,
                };
            },
            prepare(isProcessing: boolean) {
                return {
                    payload: {isProcessing: isProcessing},
                };
            },
        },
        addVesselType: {
            reducer: (state: IVesselTypesState) => {
                return {
                    ...state,
                    isActionProcessing: true,
                };
            },
            prepare(vesselTypePayload: IAddVesselTypePayload) {
                return {
                    payload: {vesselTypePayload: vesselTypePayload},
                };
            },
        },
        fetchVesselTypes: (state: IVesselTypesState) => initApiCall(state),
        resetToInitialVesselTypesPageState: () => {
            return {
                ...initialState,
            };
        },
    },
});

export const {
    changeVesselTypes,
    changeIsVesselTypesPageLoading,
    changeIsVesselTypesPageInitialized,
    setVesselTypesPageError,
    changeIsActionProcessing,
    changeIsActionComplete,
    addVesselType,
    fetchVesselTypes,
    resetToInitialVesselTypesPageState,
} = vesselTypesSlice.actions;

export default vesselTypesSlice.reducer;
