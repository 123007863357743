import {ChatMessage, MessageContentType} from "../types";
import {ChatServerMessage} from "../components/hoc/chat.types";

export function convertServerMessageIntoChatMessage(message: ChatServerMessage, accountId: string): [ChatMessage, ChatServerMessage | null] {
        return ([{
            from: message.fromAccountId,
            to: message.toAccountId,
            date: new Date(message.sentAt),
            messageType: message.mediaObject ? MessageContentType.FILE : MessageContentType.TEXT,
            messageContent: message.content,
            messageId: message.id,
            messageMedia: message?.mediaObject?.contentUrl || message?.mediaObject?.fileUrls?.original || '',
        }, (!message.receivedAt && message.fromAccountId === accountId) ? message : null])
}


