import {PeerConnection} from "../../../types";

const NULL_PEER_CONNECTION: PeerConnection = {
	biggestPageNumber: 0,
	peerIsOnline: false,
	connection: null,
	channelId: '',
	messagesBuffer: [],
	messages: [],
	unseenMessages: 0,
	channel: null,
	peerIsPolite: false,
	makingOffer: false,
	connectionAttemptNumber: 0,
	totalMessagesNumber: 0,
};

export default NULL_PEER_CONNECTION;
