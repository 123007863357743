import {createSelector} from '@reduxjs/toolkit';
import {RootState} from '../reducers';
import {IVesselTypesState} from '../reducers/vesselTypesPageSlice';

export const selectVesselTypesPageSlice = (state: RootState): IVesselTypesState => {
    return state.vesselTypes;
};

export const vesselTypesSelector = createSelector([selectVesselTypesPageSlice], (state: IVesselTypesState) => state.vesselTypes);

export const isVesselTypesPagesLoadingSelector = createSelector(
    [selectVesselTypesPageSlice],
    (state: IVesselTypesState) => state.isLoading
);

export const isVesselTypesPagesInitializedSelector = createSelector(
    [selectVesselTypesPageSlice],
    (state: IVesselTypesState) => state.isInitialized
);

export const vesselTypesPagesErrorSelector = createSelector([selectVesselTypesPageSlice], (state: IVesselTypesState) => state.error);

export const isVesselTypeActionCompleteSelector = createSelector(
    [selectVesselTypesPageSlice],
    (state: IVesselTypesState) => state.isActionComplete
);

export const isVesselTypeActionProcessingSelector = createSelector(
    [selectVesselTypesPageSlice],
    (state: IVesselTypesState) => state.isActionProcessing
);
