import {translatePathToValue} from './TranslationTools';
import {AlertType, ApiError} from '../types';

export const handleErrorMessage = (error: ApiError | string): string => {
    if (typeof error === 'string') {
        return translatePathToValue(error);
    }
    return error?.response?.['hydra:description'] || translatePathToValue('alerts.errorMessage');
};

export const handleApiError = (error: ApiError | string): ApiError => ({message: handleErrorMessage(error), type: AlertType.WARNING});
