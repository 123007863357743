import {addAlert} from '../reducers/alertSlice';
import {combineEpics, Epic, ofType, StateObservable} from 'redux-observable';
import {RootState} from '../reducers';
import {catchError, concatMap, mergeMap, switchMap} from 'rxjs/operators';
import {authTokenSelector} from '../selectors/authSelectors';
import {of} from 'rxjs';
import {AccountBasicInfo, AlertType, ApiError, ApiSingleResponseBase, FullAccount, MediaFile, PaymentAccountOutput} from '../../types';
import {
    changeAvatar,
    changeLocale,
    changeMisc,
    changePassword,
    changePasswordSuccess,
    getAccount,
    getFullAccount,
    IChangeUserPasswordInput,
    IGetFullAccount,
    IRemovePaymentMethod,
    IUpdateAccount,
    IUpdateLocale,
    IUpdateMisc,
    IUpdateOperatorFullAccount,
    IUpdateSkipperFullAccount,
    removePaymentMethod,
    setAccountState,
    setPaymentAccountState,
    updateAccount,
    updateAccountFailure,
    updateAccountSuccess,
    updateOperatorFullAccount,
    updateSkipperFullAccount,
} from '../reducers/accountSlice';

import {PayloadAction} from '@reduxjs/toolkit';

import {logout} from '../reducers/authSlice';
import {AjaxResponse} from 'rxjs/ajax';
import {changePasswordAPI} from '../../api/auth/changePassword';
import {changeLocaleAPI} from '../../api/account/changeLocale';
import {getAccountDataAPI} from '../../api/account/getAccountData';
import {deletePaymentMethodAPI} from '../../api/payments/deletePaymentMethod';
import {getAccountBasicInfoAPI} from '../../api/account/getAccountBasicInfo';
import {setIsLoading} from '../reducers/loaderSlice';
import {handleApiError} from '../../utils/errorHandlingUtils';
import {updateAccountDataAPI} from '../../api/account/updateAccount';
import {setAvatarAPI} from '../../api/account/setAvatar';
import {
    accountIdSelector,
    skipperAccountIdSelector,
    accountUserIdSelector,
} from '../selectors/accountSelectors';
import {updateSkipperFullAccountAPI} from '../../api/account/updateSkipperAccount';
import {changeMiscAPI} from '../../api/account/changeMisc';
import {updateOperatorFullAccountAPI} from "../../api/account/updateOperatorAccount";

const errorActions = (error: ApiError) => {
    if ((error?.response?.['hydra:description'] as string)?.includes("oldPassword: This value should be the user's current password.")) {
        return [logout(true), setIsLoading(false), updateAccountFailure(error.message), addAlert(handleApiError('alerts.badPassword'))];
    }

    return [addAlert(handleApiError(error)), setIsLoading(false), updateAccountFailure(error.message)];
};

const updateErrorActions = (error: ApiError) => {
    const errorObj = handleApiError(error);
    errorObj.type = AlertType.WARNING;
    return [addAlert(errorObj), updateAccountFailure(errorObj.message)];
};

const getProfileEpic: Epic = (action$, state$: StateObservable<RootState>) =>
    action$.pipe(
        ofType(getAccount.type),
        switchMap(() => {
            const authToken = authTokenSelector(state$.value) || '';
            return getAccountBasicInfoAPI(authToken).pipe(
                mergeMap((res: ApiSingleResponseBase<AccountBasicInfo>) => {
                    return of(setAccountState(res), setIsLoading(false));
                }),
                catchError((e) => {
                    //todo something with shit
                    return errorActions(e);
                })
            );
        })
    );

const removePaymentMethodEpic: Epic = (action$) =>
    action$.pipe(
        ofType(removePaymentMethod.type),
        switchMap((action: PayloadAction<IRemovePaymentMethod>) => {
            return deletePaymentMethodAPI(action.payload.paymentAccountId, action.payload.paymentMethodInput).pipe(
                mergeMap((res: ApiSingleResponseBase<PaymentAccountOutput>) => {
                    return of(setPaymentAccountState(res), setIsLoading(false));
                }),
                catchError((e) => {
                    //todo something with shit
                    return errorActions(e);
                })
            );
        })
    );

const getFullProfileEpic: Epic = (action$, state$: StateObservable<RootState>) =>
    action$.pipe(
        ofType(getFullAccount.type),
        switchMap((action: PayloadAction<IGetFullAccount>) => {
            const authToken = authTokenSelector(state$.value) || '';

            return getAccountDataAPI(authToken, action.payload.accountId).pipe(
                mergeMap((res: ApiSingleResponseBase<FullAccount>) => {
                    return of(setAccountState(res.operator), setIsLoading(false));
                }),
                catchError(errorActions)
            );
        })
    );

const updateProfileEpic: Epic = (action$, state$: StateObservable<RootState>) =>
    action$.pipe(
        ofType(updateAccount.type),
        switchMap((action: PayloadAction<IUpdateAccount>) => {
            const authToken = authTokenSelector(state$.value) || '';
            return updateAccountDataAPI(authToken, action.payload.accountId, action.payload.account).pipe(
                switchMap((res: ApiSingleResponseBase<AccountBasicInfo>) => {
                    return of(
                        addAlert({message: 'account.changeAccountDataSuccess', type: AlertType.SUCCESS}),
                        updateAccountSuccess(res),
                        setIsLoading(false)
                    );
                }),
                catchError(updateErrorActions)
            );
        }),
        catchError(updateErrorActions)
    );

const updateSkipperFullAccountEpic: Epic = (action$, state$: StateObservable<RootState>) =>
    action$.pipe(
        ofType(updateSkipperFullAccount.type),
        switchMap((action: PayloadAction<IUpdateSkipperFullAccount>) => {
            const authToken = authTokenSelector(state$.value) || '',
                accountId = skipperAccountIdSelector(state$.value);
            return updateSkipperFullAccountAPI(authToken, accountId, action.payload.accountPayload).pipe(
                switchMap((res: ApiSingleResponseBase<AccountBasicInfo>) => {
                    return of(
                        addAlert({message: 'account.changeAccountDataSuccess', type: AlertType.SUCCESS}),
                        updateAccountSuccess(res),
                        setIsLoading(false)
                    );
                }),
                catchError(updateErrorActions)
            );
        }),
        catchError(updateErrorActions)
    );

const updateOperatorFullAccountEpic: Epic = (action$, state$: StateObservable<RootState>) =>
    action$.pipe(
        ofType(updateOperatorFullAccount.type),
        switchMap((action: PayloadAction<IUpdateOperatorFullAccount>) => {
            const authToken = authTokenSelector(state$.value) || '',
                accountId = accountUserIdSelector(state$.value);

            return updateOperatorFullAccountAPI(authToken, accountId, action.payload.accountPayload).pipe(
                switchMap(() => {
                    const accountUserId = accountUserIdSelector(state$.value) || '';
                    return of(
                        addAlert({message: 'account.changeAccountDataSuccess', type: AlertType.SUCCESS}),
                        getFullAccount({accountId: accountUserId}),
                        setIsLoading(false)
                    );
                }),
                catchError(updateErrorActions)
            );
        }),
        catchError(updateErrorActions)
    );

const updateLocaleEpic: Epic = (action$, state$: StateObservable<RootState>) =>
    action$.pipe(
        ofType(changeLocale.type),
        switchMap((action: PayloadAction<IUpdateLocale>) => {
            const accountId = accountIdSelector(state$.value) || '';
            return changeLocaleAPI(accountId, action.payload.locale).pipe(
                mergeMap((res: ApiSingleResponseBase<AccountBasicInfo>) => {
                    return of(
                        addAlert({message: 'account.changeLocaleSuccess', type: AlertType.SUCCESS}),
                        updateAccountSuccess(res),
                        setIsLoading(false)
                    );
                }),
                catchError(errorActions)
            );
        })
    );

const updateMiscEpic: Epic = (action$, state$: StateObservable<RootState>) =>
    action$.pipe(
        ofType(changeMisc.type),
        switchMap((action: PayloadAction<IUpdateMisc>) => {
            const accountUserId = accountUserIdSelector(state$.value) || '',
                authToken = authTokenSelector(state$.value) || '';
            return changeMiscAPI(accountUserId, action.payload.misc, authToken).pipe(
                mergeMap((res: ApiSingleResponseBase<AccountBasicInfo>) => {
                    return of(
                        addAlert({message: 'account.changeAccountDataSuccess', type: AlertType.SUCCESS}),
                        updateAccountSuccess(res),
                        setIsLoading(false)
                    );
                }),
                catchError(errorActions)
            );
        })
    );

const updatePasswordEpic: Epic = (action$, state$: StateObservable<RootState>) =>
    action$.pipe(
        ofType(changePassword.type),
        switchMap((action: PayloadAction<IChangeUserPasswordInput>) => {
            const authToken = authTokenSelector(state$.value) || '';
            return changePasswordAPI(action.payload.passwordForm.oldPassword, action.payload.passwordForm.newPassword, authToken).pipe(
                mergeMap(() => {
                    return of(
                        addAlert({message: 'account.changePasswordSuccess', type: AlertType.SUCCESS}),
                        changePasswordSuccess(),
                        setIsLoading(false)
                    );
                }),
                catchError(errorActions)
            );
        })
    );

const updateAvatarEpic: Epic = (action$, state$: StateObservable<RootState>) =>
    action$.pipe(
        ofType(changeAvatar.type),
        // switchMap((action: PayloadAction<IUpdateAvatar>) => {
        //     const authToken = authTokenSelector(state$.value) || '';
        //     const formData = new FormData();
        //     formData.append('file', {name: action.payload.avatar.fileName, uri: action.payload.avatar.uri, type: 'image/jpeg'});
        //     // formData.append('public', {data: true});
        //     return saveFile(authToken, formData);
        // }),
        concatMap((res: AjaxResponse<MediaFile>) => {
            const accountId = accountIdSelector(state$.value) || '',
                mediaObjectId = res.response.id;
            return setAvatarAPI(accountId, {mediaObjectId}).pipe(
                mergeMap((response: ApiSingleResponseBase<AccountBasicInfo>) => {
                    return of(
                        addAlert({message: 'account.imageAddSuccess', type: AlertType.SUCCESS}),
                        updateAccountSuccess(response),
                        setIsLoading(false)
                    );
                })
            );
        }),
        catchError(errorActions)
    );

const accountEpic = combineEpics(
    getProfileEpic,
    getFullProfileEpic,
    updateProfileEpic,
    updateAvatarEpic,
    updatePasswordEpic,
    updateLocaleEpic,
    updateMiscEpic,
    removePaymentMethodEpic,
    updateSkipperFullAccountEpic,
    updateOperatorFullAccountEpic
);

export default accountEpic;
