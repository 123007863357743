import React from 'react';
import {connect} from 'react-redux';
import {AlertType, IFormConfig, withRouterWrapper, WithRouterWrapperProps} from '../../..';
import {addAlert} from '../../../store/reducers/alertSlice';
import Form from '../../Form';
import Loader from '../../Loader';
import Translation from '../../Translation';
import {setNewPassword} from '../../../store/reducers/authSlice';

interface IConnectedNewPasswordProps {
    readonly addAlert: typeof addAlert;
    readonly setNewPassword: typeof setNewPassword;
}

interface IExternalNewPasswordProps {
    readonly formConfig: IFormConfig;
    readonly isLoading: boolean;
}

interface INewPasswordProps extends IConnectedNewPasswordProps, IExternalNewPasswordProps, WithRouterWrapperProps {}

interface INewPasswordState {
    formConfig: IFormConfig;
    value: any;
}

class NewPassword extends React.Component<INewPasswordProps, INewPasswordState> {
    constructor(props: INewPasswordProps) {
        super(props);

        this.state = {
            formConfig: this.props.formConfig,
            value: null,
        };
    }

    render() {
        return (
            <article className="auth-article">
                <header className="auth-form-header">
                    <h1 className="auth-title">
                        <Translation text="auth.newPassword.title" />
                    </h1>
                    <div className="auth-subtitle-container">
                        <p className="auth-subtitle">
                            <Translation text="auth.newPassword.subtitle" />
                        </p>
                    </div>
                </header>
                <Loader showLoader={this.props.isLoading} />
                <Form config={this.state.formConfig} submitForm={this.resetPassword} controlName={'newPasswordForm'} value={{}} />
            </article>
        );
    }
    private resetPassword = (event: any, value: any, valid: boolean, touched: boolean) => {
        event.preventDefault();
        if (!valid || !touched) {
            return;
        }
        const authToken = this.props.router?.params?.id;
        if (!authToken) {
            this.props.addAlert({message: 'auth.alert.invalidResetPasswordURL', type: AlertType.WARNING});
            return;
        }

        this.props.setNewPassword(authToken, value.password);
    };
}

export default connect(() => ({}), {
    addAlert,
    setNewPassword,
})(withRouterWrapper(NewPassword));
