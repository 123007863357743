import {marineHubAPI, RestQueryParams} from 'marine-panel-common-web';
import {Observable} from 'rxjs';

export interface IVesselModel {
    name: string;
    length: number;
    width: number;
    draft: number;
    height: number;
    vesselTypeId: string;
}

export interface IAddVesselModelPayload {
    vesselModels: IVesselModel[];
}

export function addVesselModelAPI(authToken: string | null, payload: IAddVesselModelPayload): Observable<any> {
    const headers = {
        Authorization: `Bearer ${authToken}`,
    };

    return marineHubAPI.post(`api/dictionary/vessel_models`, payload, new RestQueryParams(), headers);
}
