import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {IModelDictionaryDatum} from '../../model/dictionaryDatum';

export interface IBaseDictionaryState {
    isLoading: boolean;
    isInitialized: boolean;
    error: string | null;
}

export interface IChangeIsLoading {
    isLoading: boolean;
}

export interface IChangeIsInitialized {
    isInitialized: boolean;
}

export interface ISetError {
    error: string | null;
}

export interface IVesselModelState extends IBaseDictionaryState {
    vesselModels: IModelDictionaryDatum[] | null;
}

export interface IChangeVesselModel {
    vesselModels: IModelDictionaryDatum[] | null;
}

const initialState: IVesselModelState = {
    vesselModels: null,
    isLoading: false,
    isInitialized: false,
    error: null,
};

const vesselModelSlice = createSlice({
    name: 'vesselModel',
    initialState: initialState,
    reducers: {
        changeVesselModel: {
            reducer: (state: IVesselModelState, action: PayloadAction<IChangeVesselModel>) => {
                return {
                    vesselModels: action.payload.vesselModels,
                    isLoading: state.isLoading,
                    isInitialized: state.isInitialized,
                    error: state.error,
                };
            },
            prepare(vesselModels: IModelDictionaryDatum[] | null) {
                return {
                    payload: {vesselModels: vesselModels},
                };
            },
        },
        changeIsVesselModelLoading: {
            reducer: (state: IVesselModelState, action: PayloadAction<IChangeIsLoading>) => {
                return {
                    vesselModels: state.vesselModels,
                    isLoading: action.payload.isLoading,
                    isInitialized: state.isInitialized,
                    error: state.error,
                };
            },
            prepare(isLoading: boolean) {
                return {
                    payload: {isLoading: isLoading},
                };
            },
        },
        changeIsVesselModelInitialized: {
            reducer: (state: IVesselModelState, action: PayloadAction<IChangeIsInitialized>) => {
                return {
                    vesselModels: state.vesselModels,
                    isLoading: state.isLoading,
                    isInitialized: action.payload.isInitialized,
                    error: state.error,
                };
            },
            prepare(isInitialized: boolean) {
                return {
                    payload: {isInitialized: isInitialized},
                };
            },
        },
        setVesselModelError: {
            reducer: (state: IVesselModelState, action: PayloadAction<ISetError>) => {
                return {
                    vesselModels: state.vesselModels,
                    isLoading: state.isLoading,
                    isInitialized: state.isInitialized,
                    error: action.payload.error,
                };
            },
            prepare(error: string | null) {
                return {
                    payload: {error: error},
                };
            },
        },
        fetchVesselModels: (state: IVesselModelState) => {
            return {
                ...state,
                isLoading: true,
            };
        },
        fetchAllDictionaryData: (state: IVesselModelState) => {
            return {
                ...state,
            };
        },
    },
});

export const {
    changeVesselModel,
    changeIsVesselModelLoading,
    changeIsVesselModelInitialized,
    setVesselModelError,
    fetchVesselModels,
    fetchAllDictionaryData,
} = vesselModelSlice.actions;

export default vesselModelSlice.reducer;
