import {marineHubAPI, RestQueryParams} from 'marine-panel-common-web';
import {Observable} from 'rxjs';

export function assignOperatorToMarinaAPI(authToken: string | null, marinaId: string, operatorId: string): Observable<any> {
    const headers = {
            Authorization: `Bearer ${authToken}`,
        },
        payload = {
            operatorId: operatorId,
        };

    return marineHubAPI.put(`api/marinas/${marinaId}/assign_operator`, payload, new RestQueryParams(), headers);
}
