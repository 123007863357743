import React, {Component} from 'react';
import {useLocation, useNavigate, useParams} from 'react-router-dom';

export const withNavigation = (Component: typeof React.Component) => {
    return (props) => <Component {...props} navigate={useNavigate()} />;
};

export const withParams = (Component: typeof React.Component) => {
    return (props) => <Component {...props} params={useParams()} />;
};

export const withLocation = (Component: typeof React.Component) => {
    return (props) => <Component {...props} location={useLocation()} />;
};
