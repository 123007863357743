import {Observable} from 'rxjs';
import {RestQueryParams} from '../base/queryParams';
import {marineHubAPI} from '../provider/marineHubAPI';

export interface IResetPasswordPayload {
    username: string;
    returnUrl: string;
}

export function sendResetPasswordEmailAPI(payload: IResetPasswordPayload): Observable<any> {
    return marineHubAPI.post(
        `api/users/request_set_new_password`,
        payload,
        new RestQueryParams(),
    );
}
