import {PayloadAction} from '@reduxjs/toolkit';
import {addAlert, AlertType, authTokenSelector, handleApiError, IMultiselectOption} from 'marine-panel-common-web';
import {combineEpics, Epic, ofType, StateObservable} from 'redux-observable';
import {forkJoin, of} from 'rxjs';
import {catchError, switchMap} from 'rxjs/operators';
import {addMarinaExtraAPI} from '../../api/marina/addMarinaExtra';
import {changeMarinaActiveStatusAPI} from '../../api/marina/changeMarinaActiveStatus';
import {changeMarinaAddressAPI} from '../../api/marina/changeMarinaAddress';
import {changeMarinaAttractionsAPI} from '../../api/marina/changeMarinaAttractions';
import {changeMarinaDescriptionAPI} from '../../api/marina/changeMarinaDescription';
import {changeMarinaEmailAPI} from '../../api/marina/changeMarinaEmail';
import {changeMarinaExtraAPI} from '../../api/marina/changeMarinaExtra';
import {changeMarinaLocationAPI} from '../../api/marina/changeMarinaLocation';
import {changeMarinaNameAPI} from '../../api/marina/changeMarinaName';
import {changeMarinaPhoneAPI} from '../../api/marina/changeMarinaPhone';
import {changeMarinaPoliciesAndRulesAPI} from '../../api/marina/changeMarinaPoliciesAndRules';
import {changeMarinaRestaurantsAPI} from '../../api/marina/changeMarinaRestaurants';
import {createMarinaAPI} from '../../api/marina/createMarina';
import {getMarinaAPI} from '../../api/marina/getMarina';
import {getMarinaTagsAPI} from '../../api/marina/getMarinaTags';
import {removeMarinaExtraAPI} from '../../api/marina/removeMarinaExtra';
import {RootState} from '../reducers';
import {
    changeExtra,
    changeExtraOrder,
    changeGalleryOrder,
    changeIsMarinaActive,
    changeIsMarinaEditWizardInitialized,
    changeIsMarinaEditWizardLoading,
    changeMarinaAddress,
    changeMarinaAttractions,
    changeMarinaDescription,
    changeMarinaEditWizardError,
    changeMarinaEmail,
    changeMarinaLocation,
    changeMarinaName,
    changeMarinaPhone,
    changeMarinaPolicyAndRule,
    changeMarinaRestaurants,
    createExtra,
    createMarina,
    deletePhoto,
    fetchMarinaDetails,
    fetchMarinaTags,
    IChangeExtraOrder,
    removeExtra,
    setIsActionSuccessful,
    setMarinaDetails,
    setMarinaTags,
    setPhotoAsCover,
} from '../reducers/marinaEditWizardSlice';
import {fetchMarinas} from '../reducers/marinasSlice';
import {changeIsModalOpen, setCurrentlyEditedItemId} from '../reducers/modalSlice';
import {setPhotoAsCoverAPI} from '../../api/marina/setPhotoAsCover';
import {removePhotoAPI} from '../../api/marina/removePhoto';
import {changeGalleryOrderAPI} from '../../api/marina/changeGalleryOrder';

const createEpic =
    (actionType: any, apiCall: any, successMessage: string, extraSuccessActions: any[] = []) =>
    (action$: any, state$: StateObservable<RootState>) => {
        return action$.pipe(
            ofType(actionType),
            switchMap((action: any) => {
                const authToken = authTokenSelector(state$.value);
                return apiCall(action.payload, authToken).pipe(
                    switchMap((resp: any) => {
                        const actions = successActions([
                            setIsActionSuccessful(true),
                            addAlert({message: successMessage, type: AlertType.SUCCESS}),
                            setMarinaDetails(resp),
                            changeIsModalOpen(false),
                            ...extraSuccessActions,
                        ]);
                        return of(...actions);
                    }),
                    catchError((error) => {
                        return of(...fetchListErrorActions(error, changeMarinaEditWizardError));
                    })
                );
            }),
            catchError((error) => {
                return of(...fetchListErrorActions(error, changeMarinaEditWizardError));
            })
        );
    };

const createExtraEpic: Epic = createEpic(createExtra.type, addMarinaExtraAPI, 'editMenuItems.alerts.marinaExtraCreateSuccess');
const changeExtraEpic: Epic = createEpic(changeExtra.type, changeMarinaExtraAPI, 'editMenuItems.alerts.marinaExtraEditSuccess', [
    setCurrentlyEditedItemId(null),
]);
const changePhoneEpic: Epic = createEpic(changeMarinaPhone.type, changeMarinaPhoneAPI, 'editMenuItems.alerts.marinaPhoneEditSuccess', [
    setCurrentlyEditedItemId(null),
]);
const changeEmailEpic: Epic = createEpic(changeMarinaEmail.type, changeMarinaEmailAPI, 'editMenuItems.alerts.marinaEmailEditSuccess', [
    setCurrentlyEditedItemId(null),
]);
const changeRestaurantsEpic: Epic = createEpic(
    changeMarinaRestaurants.type,
    changeMarinaRestaurantsAPI,
    'editMenuItems.alerts.marinaRestaurantsEditSuccess',
    [setCurrentlyEditedItemId(null)]
);
const changeAttractionsEpic: Epic = createEpic(
    changeMarinaAttractions.type,
    changeMarinaAttractionsAPI,
    'editMenuItems.alerts.marinaAttractionsEditSuccess',
    [setCurrentlyEditedItemId(null)]
);
const changeAddressEpic: Epic = createEpic(
    changeMarinaAddress.type,
    changeMarinaAddressAPI,
    'editMenuItems.alerts.marinaAddressEditSuccess',
    [setCurrentlyEditedItemId(null)]
);
const setMarinaCoverPhotoEpic: Epic = createEpic(
    setPhotoAsCover.type,
    setPhotoAsCoverAPI,
    'editMenuItems.alerts.marinaChangeCoverPhotoSuccess'
);

const removeMarinaPhotoEpic: Epic = createEpic(deletePhoto.type, removePhotoAPI, 'editMenuItems.alerts.marinaPhotoRemoved', [
    setCurrentlyEditedItemId(null),
]);

const changeGalleryOrderEpic: Epic = createEpic(
    changeGalleryOrder.type,
    changeGalleryOrderAPI,
    'editMenuItems.alerts.marinaGalleryOrderChanged'
);
const changeIsMarinaActiveEpic: Epic = createEpic(changeIsMarinaActive.type, changeMarinaActiveStatusAPI, 'marinas.statusChanged', []);

const createMarinaEpic: Epic = createEpic(createMarina.type, createMarinaAPI, 'editMenuItems.alerts.marinaCreateSuccess', [
    fetchMarinas(),
    changeIsModalOpen(false),
]);

export const fetchMarinaDetailsEpic: Epic = (action$, state$: StateObservable<RootState>) => {
    return action$.pipe(
        ofType(fetchMarinaDetails.type),
        switchMap((action: PayloadAction<any>) => {
            const authToken = authTokenSelector(state$.value);
            if (!action.payload.id) return of();
            return getMarinaAPI(action.payload.id, authToken).pipe(
                switchMap((resp: any) => {
                    const actions = successActions([setMarinaDetails(resp)]);
                    return of(...actions);
                }),
                catchError((error) => of(...fetchListErrorActions(error, changeMarinaEditWizardError)))
            );
        }),
        catchError((error) => of(...fetchListErrorActions(error, changeMarinaEditWizardError)))
    );
};

const changeExtraOrderEpic: Epic = (action$, state$: StateObservable<RootState>) => {
    return action$.pipe(
        ofType(changeExtraOrder.type),
        switchMap((action: PayloadAction<IChangeExtraOrder>) => {
            const authToken = authTokenSelector(state$.value),
                requests = action.payload.payload.map((item) => {
                    if (item) {
                        return changeMarinaExtraAPI(
                            {
                                marinaId: action.payload.marinaId,
                                payload: item,
                            },
                            authToken
                        );
                    }
                });

            const allRequests = forkJoin(requests);

            return allRequests.pipe(
                switchMap(() => {
                    const actions = successActions([
                        addAlert({message: 'editMenuItems.alerts.marinaExtraEditSuccess', type: AlertType.SUCCESS}),
                    ]);
                    return of(...actions);
                }),
                catchError((error) => {
                    return of(...fetchListErrorActions(error, changeMarinaEditWizardError));
                })
            );
        }),
        catchError((error) => {
            return of(...fetchListErrorActions(error, changeMarinaEditWizardError));
        })
    );
};

const removeExtraEpic: Epic = createEpic(removeExtra.type, removeMarinaExtraAPI, 'editMenuItems.alerts.marinaExtraRemoveSuccess', []);
const changeMarinaNameEpic: Epic = createEpic(
    changeMarinaName.type,
    changeMarinaNameAPI,
    'editMenuItems.alerts.marinaNameChangeSuccess',
    []
);

const changeMarinaLocationEpic: Epic = createEpic(
    changeMarinaLocation.type,
    changeMarinaLocationAPI,
    'editMenuItems.alerts.marinaLocationChangeSuccess',
    []
);

const changeMarinaDescriptionEpic: Epic = createEpic(
    changeMarinaDescription.type,
    changeMarinaDescriptionAPI,
    'editMenuItems.alerts.descriptionUpdateSuccess',
    []
);

const changeMarinaPolicyAndRuleEpic: Epic = createEpic(
    changeMarinaPolicyAndRule.type,
    changeMarinaPoliciesAndRulesAPI,
    'editMenuItems.sections.marina_policies.updatePolicySuccess',
    []
);

const fetchMarinaTagsEpic: Epic = (action$, state$: StateObservable<RootState>) => {
    return action$.pipe(
        ofType(fetchMarinaTags.type),
        switchMap(() => {
            const authToken = authTokenSelector(state$.value);
            return getMarinaTagsAPI(authToken).pipe(
                switchMap((resp: any) => {
                    const uniqueMarinaTags: IMultiselectOption[] = resp['hydra:member'].reduce((acc: any, item: any) => {
                        const foundItem = acc.find((i: any) => i.value === item.id);
                        if (!foundItem) {
                            acc.push({
                                value: item.id,
                                label: item.name,
                            });
                        }
                        return acc;
                    }, []);
                    const actions = successActions([setMarinaTags(uniqueMarinaTags)]);
                    return of(...actions);
                }),
                catchError((error) => {
                    return of(...fetchListErrorActions(error, changeMarinaEditWizardError));
                })
            );
        }),
        catchError((error) => {
            return of(...fetchListErrorActions(error, changeMarinaEditWizardError));
        })
    );
};

const successActions = (changeSliceList: any[]): any[] => {
    const actions = [changeIsMarinaEditWizardLoading(false), changeIsMarinaEditWizardInitialized(true)];

    if (changeSliceList) {
        return actions.concat(changeSliceList);
    }
    return actions;
};

export const fetchListErrorActions = (error: any, setSliceError: any): any[] => {
    const errorObj = handleApiError(error);
    errorObj.type = AlertType.WARNING;

    return [addAlert(errorObj), changeIsMarinaEditWizardLoading(false), setSliceError(errorObj.message)];
};

const marinaEditWizardEpic = combineEpics(
    fetchMarinaDetailsEpic,
    changeMarinaNameEpic,
    changeMarinaDescriptionEpic,
    createExtraEpic,
    changePhoneEpic,
    changeIsMarinaActiveEpic,
    changeMarinaLocationEpic,
    changeEmailEpic,
    changeAddressEpic,
    fetchMarinaTagsEpic,
    createMarinaEpic,
    removeExtraEpic,
    changeExtraEpic,
    changeMarinaPolicyAndRuleEpic,
    changeExtraOrderEpic,
    changeRestaurantsEpic,
    changeAttractionsEpic,
    setMarinaCoverPhotoEpic,
    removeMarinaPhotoEpic,
    changeGalleryOrderEpic
);
export default marinaEditWizardEpic;
