import {PayloadAction} from '@reduxjs/toolkit';
import {combineEpics, Epic, ofType} from 'redux-observable';
import {of} from 'rxjs';
import {catchError, switchMap} from 'rxjs/operators';
import {fetchAllDictionaryData, getAccountDataAPI, setAccountState, setAuthState, setLoginFailure} from '../..';
import {AlertType} from '../../types';
import {addAlert} from '../reducers/alertSlice';
import jwt_decode from 'jwt-decode';
import {
    changeIsAuthPageLoading,
    confirmRegistration,
    IConfirmRegistration,
    initAuthTokenChange,
    IRegisterUser,
    IRequestNewPassword,
    ISetAuthToken,
    ISetNewPassword,
    registerUser,
    requestNewPassword,
    setNewPassword,
} from '../reducers/authSlice';
import {mapResponseAccountToOperatorFullInfo, setAccountStateFailure} from '../reducers/accountSlice';
import {isRoleMatched} from './loginEpic';
import {setLoadingState} from '../reducers/loginSlice';
import {getErrorMessage} from '../../utils/epicUtils';
import {registerAPI} from '../../api/auth/register';
import {push} from 'react-router-redux';
import {confirmRegistrationAPI} from '../../api/auth/confirmRegistration';
import {sendResetPasswordEmailAPI} from '../../api/auth/sendResetPasswordEmail';
import {resetPasswordAPI} from '../../api/auth/resetPassword';

const setAuthTokenEpic: Epic = (action$) =>
    action$.pipe(
        ofType(initAuthTokenChange.type),
        switchMap((action: PayloadAction<ISetAuthToken>) => {
            const authToken = action.payload.authToken,
                decoded = jwt_decode(authToken),
                tokenExpiration = (decoded as any).exp,
                refresh_token = (decoded as any).refresh_token,
                userRoles = (decoded as any).roles,
                accountId = (decoded as any).account_id,
                username = (decoded as any).username,
                hasExpired = Math.floor(new Date().getTime() / 1000) > tokenExpiration;

            if (hasExpired) {
                return of(addAlert({message: 'Provided token has expired or is not valid', type: AlertType.WARNING}));
            } else {
                if (isRoleMatched(userRoles, action.payload.userRole) !== undefined) {
                    return getAccountDataAPI(authToken, accountId).pipe(
                        switchMap((resp: any) => {
                            const actions: any[] = [
                                    setLoadingState(false),
                                    setAuthState(username, authToken, refresh_token, true, userRoles),
                                ],
                                account = {
                                    skipper: null,
                                    operator: mapResponseAccountToOperatorFullInfo(resp),
                                    misc: resp.misc,
                                };
                            actions.push(setAccountState(account.operator));
                            actions.push(fetchAllDictionaryData());

                            return of(...actions);
                        }),
                        catchError((error: any) => {
                            return of(
                                setAccountStateFailure(getErrorMessage(error)),
                                setLoginFailure('alerts.noAccessError'),
                                setAuthState(null, null, null, false, null),
                                addAlert({
                                    message: error.response ? error.response.message : 'alerts.baseError',
                                    type: AlertType.WARNING,
                                })
                            );
                        })
                    );
                } else {
                    return of(
                        setLoginFailure('alerts.noAccessError'),
                        addAlert({
                            message: 'alerts.noAccessError',
                            type: AlertType.WARNING,
                        })
                    );
                }
            }
        }),
        catchError((error: any) => of(addAlert({message: getErrorMessage(error), type: AlertType.WARNING})))
    );

const registerUserEpic: Epic = (action$) =>
    action$.pipe(
        ofType(registerUser.type),
        switchMap((action: PayloadAction<IRegisterUser>) => {
            return registerAPI(action.payload.registrationPayload).pipe(
                switchMap(() => {
                    const actions = successActions('auth.alert.registrationSuccess');
                    return of(...actions);
                }),
                catchError((error: any) => {
                    const message = getAuthErrorMessage(error);
                    return of(...errorActions(message));
                })
            );
        }),
        catchError((error: any) => of(...errorActions(getErrorMessage(error))))
    );

const confirmRegistrationEpic: Epic = (action$) =>
    action$.pipe(
        ofType(confirmRegistration.type),
        switchMap((action: PayloadAction<IConfirmRegistration>) => {
            return confirmRegistrationAPI(action.payload.registrationToken).pipe(
                switchMap(() => {
                    const actions = successActions('auth.alert.confirmRegistrationSuccess');
                    return of(...actions);
                }),
                catchError((error: any) => {
                    const message = getAuthErrorMessage(error);

                    return of(...errorActions(message));
                })
            );
        }),
        catchError((error: any) => of(...errorActions(getErrorMessage(error))))
    );

const requestNewPasswordEpic: Epic = (action$) =>
    action$.pipe(
        ofType(requestNewPassword.type),
        switchMap((action: PayloadAction<IRequestNewPassword>) => {
            return sendResetPasswordEmailAPI(action.payload.requestNewPasswordPayload).pipe(
                switchMap(() => {
                    const actions = successActions('auth.alert.sendResetPasswordMailSuccess');
                    return of(...actions);
                }),
                catchError((error: any) => {
                    const message = getAuthErrorMessage(error);

                    return of(...errorActions(message));
                })
            );
        }),
        catchError((error: any) => of(...errorActions(getErrorMessage(error))))
    );

const setNewPasswordEpic: Epic = (action$) =>
    action$.pipe(
        ofType(setNewPassword.type),
        switchMap((action: PayloadAction<ISetNewPassword>) => {
            return resetPasswordAPI(action.payload.authToken, action.payload.password).pipe(
                switchMap(() => {
                    const actions = successActions('auth.alert.confirmResetPasswordSuccess');
                    return of(...actions);
                }),
                catchError((error: any) => {
                    const message = getAuthErrorMessage(error);

                    return of(...errorActions(message));
                })
            );
        }),
        catchError((error: any) => of(...errorActions(getErrorMessage(error))))
    );

const errorActions = (errorMessage: string) => {
    return [addAlert({message: errorMessage, type: AlertType.WARNING}), changeIsAuthPageLoading(false)];
};

const successActions = (successMessage: string) => {
    return [changeIsAuthPageLoading(false), addAlert({message: successMessage, type: AlertType.SUCCESS, displayFor: 20 * 1000}), push('/')];
};

export const getAuthErrorMessage = (error: any): string => {
    let errorMessage = '';
    const response = error.response;

    if (response['hydra:description']) {
        errorMessage =
            response['hydra:description'] && response['hydra:description'].includes('Token not found')
                ? 'auth.alert.invalidConfirmRegistrationURL'
                : response['hydra:description'];
    } else if (response.message) {
        if (response.message === 'JWT Token not found') {
            errorMessage = 'auth.alert.authenticationError';
        } else {
            errorMessage = response.message;
        }
    }

    return errorMessage;
};

const authEpic = combineEpics(setAuthTokenEpic, registerUserEpic, confirmRegistrationEpic, requestNewPasswordEpic, setNewPasswordEpic);

export default authEpic;
