import {combineEpics, Epic, ofType, StateObservable} from 'redux-observable';
import {RootState} from '../reducers';
import {catchError, concatMap, filter, map, switchMap, tap} from 'rxjs/operators';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {
    changeIsVesselTypeInitialized,
    changeIsVesselTypeLoading,
    changeVesselType,
    fetchVesselTypes,
    setVesselTypeError,
} from '../reducers/vesselTypeSlice';
import {
    changeIsVesselModelInitialized,
    changeIsVesselModelLoading,
    changeVesselModel,
    fetchAllDictionaryData,
    fetchVesselModels,
    setVesselModelError,
} from '../reducers/vesselModelSlice';
import {DictionaryName} from '../../model/dictionaryDatum';
import {changeCountry, changeIsCountryInitialized, changeIsCountryLoading, fetchCountries, setCountryError} from '../reducers/countrySlice';
import {isNullOrUndefined} from '../../utils/runtimeUtils';
import {authTokenSelector} from '../selectors/authSelectors';
import {addAlert} from '../reducers/alertSlice';
import {AlertType} from '../../types';
import {getDictionaryDataAPI} from '../../api/dictionaryData/getDictionaryData';

const fetchVesselTypesEpic: Epic = (action$, state$: StateObservable<RootState>) =>
    getAction(
        action$,
        state$,
        fetchVesselTypes,
        DictionaryName.VESSEL_TYPES,
        changeVesselType,
        changeIsVesselTypeLoading,
        changeIsVesselTypeInitialized,
        setVesselTypeError
    );

const fetchVesselModelsEpic: Epic = (action$, state$: StateObservable<RootState>) =>
    getAction(
        action$,
        state$,
        fetchVesselModels,
        DictionaryName.VESSEL_MODELS,
        changeVesselModel,
        changeIsVesselModelLoading,
        changeIsVesselModelInitialized,
        setVesselModelError
    );

const fetchCountriesEpic: Epic = (action$, state$: StateObservable<RootState>) =>
    getAction(
        action$,
        state$,
        fetchCountries,
        DictionaryName.COUNTRY,
        changeCountry,
        changeIsCountryLoading,
        changeIsCountryInitialized,
        setCountryError
    );

const fetchAllDictionaryDataEpic: Epic = (action$) =>
    action$.pipe(
        ofType(fetchAllDictionaryData.type),
        concatMap(() => {
            const actions = [fetchCountries(), fetchVesselTypes(), fetchVesselModels()];
            return of(...actions);
        }),
        catchError((error: any) => of(addAlert({message: getErrorMessage(error), type: AlertType.WARNING})))
    );

export type FetchAction = {
    token: string | null;
    dictionaryName: string | null;
    changeSliceList: any;
    changeIsSliceLoading: any;
    changeIsSliceInitialized: any;
    setSliceError: any;
};

const fetchSubject = new BehaviorSubject<FetchAction>({
    token: null,
    dictionaryName: null,
    changeSliceList: null,
    changeIsSliceLoading: null,
    changeIsSliceInitialized: null,
    setSliceError: null,
});

const resultsSubject = new BehaviorSubject<any>(null);
fetchSubject
    .asObservable()
    .pipe(
        concatMap((fetch: any) => {
            if (
                // isNullOrUndefined(fetch.token) ||
                isNullOrUndefined(fetch.dictionaryName)
            ) {
                return of(null);
            }

            return getDictionaryData(
                fetch.token as string,
                fetch.dictionaryName as string,
                fetch.changeSliceList,
                fetch.changeIsSliceLoading,
                fetch.changeIsSliceInitialized,
                fetch.setSliceError
            );
        }),
        tap((action) => resultsSubject.next(action))
    )
    .subscribe(); // subscription with same lifetime as the application, no need to unsubscribe

const doFetch = (
    state: RootState,
    dictionaryName: string,
    changeSliceList: null,
    changeIsSliceLoading: null,
    changeIsSliceInitialized: null,
    setSliceError: null
) => {
    const authToken = authTokenSelector(state);

    fetchSubject.next({
        token: authToken,
        dictionaryName: dictionaryName,
        changeSliceList: changeSliceList,
        changeIsSliceLoading: changeIsSliceLoading,
        changeIsSliceInitialized: changeIsSliceInitialized,
        setSliceError: setSliceError,
    });

    return resultsSubject.asObservable().pipe(
        filter((action: any) => null !== action),
        concatMap((action) => of(action))
    );
};

const getDictionaryData = (
    authToken: string,
    dictionaryName: string,
    changeSliceList: any,
    changeIsSliceLoading: any,
    changeIsSliceInitialized: any,
    setSliceError: any
) => {
    return getList(
        getDictionaryDataAPI(dictionaryName, authToken),
        (resp: any) => fetchListSuccessActions(resp['hydra:member'], changeSliceList, changeIsSliceLoading, changeIsSliceInitialized),
        (error: any) => fetchListErrorActions(error, setSliceError, changeIsSliceLoading)
    );
};

export const getList = (api: Observable<any>, successActions: (resp: any) => any[], errorActions: (error: any) => any[]) => {
    return api.pipe(
        switchMap((resp: any) => of(...successActions(resp))),
        catchError((error: any) => of(...errorActions(error)))
    );
};

export const getAction = (
    action$: Observable<any>,
    state$: StateObservable<any>,
    actionType: any,
    dictionaryName: string,
    changeSliceList: any,
    changeIsSliceLoading: any,
    changeIsSliceInitialized: any,
    setSliceError: any
) => {
    return action$.pipe(
        ofType(actionType.type),
        map(() => state$.value),
        switchMap((state: RootState) => {
            return doFetch(state, dictionaryName, changeSliceList, changeIsSliceLoading, changeIsSliceInitialized, setSliceError);
        })
    );
};

export const fetchListSuccessActions = (
    resp: any,
    changeSliceList: any,
    changeIsSliceLoading: any,
    changeIsSliceInitialized: any
): any[] => {
    return [changeSliceList(resp), changeIsSliceLoading(false), changeIsSliceInitialized(true)];
};

export const fetchListErrorActions = (error: any, setSliceError: any, setSliceIsLoading: any): any[] => {
    return [
        addAlert({message: getErrorMessage(error), type: AlertType.WARNING}),
        setSliceError(getErrorMessage(error)),
        setSliceIsLoading(false),
    ];
};

export const getErrorMessage = (error: any) => {
    let errorMessage;
    if (error.response && error.response.message) {
        errorMessage = error.response.message;
    } else if (error.response && error.response['hydra:description']) {
        errorMessage = error.response['hydra:description'];
    } else {
        errorMessage = 'Something went wrong. Please try again later.';
    }

    return errorMessage;
};

const dictionaryDataEpic = combineEpics(fetchVesselTypesEpic, fetchVesselModelsEpic, fetchCountriesEpic, fetchAllDictionaryDataEpic);

export default dictionaryDataEpic;
