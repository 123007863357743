import {RestQueryParams, marineHubAPI} from 'marine-panel-common-web';
import {Observable} from 'rxjs';
import {IChangeMarinaAddress} from '../../store/reducers/marinaEditWizardSlice';

export interface IChangeMarinaAddressInput {
    address: string;
}

export function changeMarinaAddressAPI(payload: IChangeMarinaAddress, authToken: string): Observable<any> {
    const headers = {
        Authorization: `Bearer ${authToken}`,
    };
    return marineHubAPI.put(`api/marinas/${payload.marinaId}/change_address`, payload.address, new RestQueryParams(), headers);
}
