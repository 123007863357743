import React, {Component} from 'react';
import classnames from 'classnames';
import 'animate.css/animate.css';
import {connect} from 'react-redux';
import {ContentWidth, RouterTransition} from '../../store/reducers/sagaSlice';
import {contentWidthSelector, routerTransitionSelector} from '../..';

interface IConnectedLayoutWrapperProps {
    routerTransition: RouterTransition;
    contentWidth: ContentWidth;
}

interface IExternalLayoutWrapperProps {
    children: any;
    layout?: string;
    appLayout?: string;
    wrapperClass?: string;
}

interface ILayoutWrapperProps extends IConnectedLayoutWrapperProps, IExternalLayoutWrapperProps {}

class LayoutWrapper extends Component<ILayoutWrapperProps, any> {
    render() {
        const defaultLayout = this.props.layout ? this.props.layout : 'VerticalLayout';

        return (
            <div
                className={classnames(
                    'app-content content overflow-hidden',
                    this.props.wrapperClass ? {[this.props.wrapperClass]: this.props.wrapperClass} : {}
                )}>
                <div className="content-overlay" />
                <div
                    className={classnames({
                        'content-wrapper': !this.props.appLayout,
                        'content-area-wrapper': this.props.appLayout,
                        'container-xxl p-0': this.props.contentWidth === 'boxed',
                        [`animate__animated animate__${this.props.routerTransition}`]:
                            this.props.routerTransition !== 'none' && this.props.routerTransition.length,
                    })}>
                    <div
                        {...(defaultLayout === 'HorizontalLayout' && !this.props.appLayout
                            ? {className: classnames({'content-body': !this.props.appLayout})}
                            : {})}>
                        {this.props.children}
                    </div>
                </div>
            </div>
        );
    }
}

export default connect(
    (state: any) => ({
        contentWidth: contentWidthSelector(state),
        routerTransition: routerTransitionSelector(state),
    }),
    {}
)(LayoutWrapper);
