import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {IBaseDictionaryState, IChangeIsInitialized, IChangeIsLoading, ISetError} from './marinasSlice';
import {initApiCall} from '../../utils/reduxHelpers';
import {IAddVesselModelPayload} from '../../api/addVesselModel';
import {VesselModel} from 'marine-panel-common-web';

export interface IVesselModelsState extends IBaseDictionaryState {
    vesselModels: VesselModel[] | null;
    isActionComplete: boolean;
    isActionProcessing: boolean;
}

export interface ISetVesselModels {
    readonly vesselModels: VesselModel[] | null;
}

export interface IChangeIsActionComplete {
    readonly isComplete: boolean;
}

export interface IChangeIsActionProcessing {
    readonly isProcessing: boolean;
}

export interface IAddVesselModel {
    vesselModelPayload: IAddVesselModelPayload;
}

const initialState: IVesselModelsState = {
    vesselModels: null,
    isLoading: false,
    isInitialized: false,
    error: null,
    isActionComplete: false,
    isActionProcessing: false,
};

const vesselModelSlice = createSlice({
    name: 'vesselModels',
    initialState: initialState,
    reducers: {
        changeVesselModels: {
            reducer: (state: IVesselModelsState, action: PayloadAction<ISetVesselModels>) => {
                return {
                    ...state,
                    vesselModels: action.payload.vesselModels,
                };
            },
            prepare(vesselModels: VesselModel[] | null) {
                return {
                    payload: {vesselModels: vesselModels},
                };
            },
        },
        changeIsVesselModelsPageLoading: {
            reducer: (state: IVesselModelsState, action: PayloadAction<IChangeIsLoading>) => {
                return {
                    ...state,
                    isLoading: action.payload.isLoading,
                };
            },
            prepare(isLoading: boolean) {
                return {
                    payload: {isLoading: isLoading},
                };
            },
        },
        changeIsVesselModelsPageInitialized: {
            reducer: (state: IVesselModelsState, action: PayloadAction<IChangeIsInitialized>) => {
                return {
                    ...state,
                    isInitialized: action.payload.isInitialized,
                };
            },
            prepare(isInitialized: boolean) {
                return {
                    payload: {isInitialized: isInitialized},
                };
            },
        },
        setVesselModelsPageError: {
            reducer: (state: IVesselModelsState, action: PayloadAction<ISetError>) => {
                return {
                    ...state,
                    error: action.payload.error,
                };
            },
            prepare(error: string | null) {
                return {
                    payload: {error: error},
                };
            },
        },
        changeIsActionComplete: {
            reducer: (state: IVesselModelsState, action: PayloadAction<IChangeIsActionComplete>) => {
                return {
                    ...state,
                    isActionComplete: action.payload.isComplete,
                };
            },
            prepare(isComplete: boolean) {
                return {
                    payload: {isComplete: isComplete},
                };
            },
        },
        changeIsActionProcessing: {
            reducer: (state: IVesselModelsState, action: PayloadAction<IChangeIsActionProcessing>) => {
                return {
                    ...state,
                    isActionProcessing: action.payload.isProcessing,
                };
            },
            prepare(isProcessing: boolean) {
                return {
                    payload: {isProcessing: isProcessing},
                };
            },
        },
        addVesselModel: {
            reducer: (state: IVesselModelsState) => {
                return {
                    ...state,
                    isActionProcessing: true,
                };
            },
            prepare(vesselModelPayload: IAddVesselModelPayload) {
                return {
                    payload: {vesselModelPayload: vesselModelPayload},
                };
            },
        },
        fetchVesselModels: (state: IVesselModelsState) => initApiCall(state),
        resetToInitialVesselModelsPageState: () => {
            return {
                ...initialState,
            };
        },
    },
});

export const {
    changeVesselModels,
    changeIsVesselModelsPageLoading,
    changeIsVesselModelsPageInitialized,
    setVesselModelsPageError,
    changeIsActionProcessing,
    changeIsActionComplete,
    addVesselModel,
    fetchVesselModels,
    resetToInitialVesselModelsPageState,
} = vesselModelSlice.actions;

export default vesselModelSlice.reducer;
