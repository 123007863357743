import {Observable} from 'rxjs';
import {marineHubAPI} from '../provider/marineHubAPI';
import {RestQueryParams} from '../base/queryParams';
import {ApiSingleResponseBase, FullAccount} from '../../types';

export function getAccountDataAPI(authToken: string, accountId: string): Observable<ApiSingleResponseBase<FullAccount>> {
    return marineHubAPI.get(`api/accounts/${accountId}`, new RestQueryParams(), {
        Authorization: `Bearer ${authToken}`,
    });
}
