import React from 'react';
import {Observable} from 'rxjs';
import styles from './styles.module.scss';
import ChatComponent from './ChatComponent';
import {of, Subject, Subscription} from 'rxjs';
import {map} from 'rxjs/operators';
import {AllUserConnections, ChatContact, ChatMessage, ChatServerMessagePayload, NodeErrorTypes} from '../../types';
import ChatUsersList from './ChatUsersList';
import Translation from '../Translation';

interface ChatProps {
    getMessagesFromUser: (accountId: string, page?: number) => Observable<boolean>;
    peerConnections: AllUserConnections;
    addMessage: (message: ChatMessage | string, room: string) => void;
    setSelectedChatRoomId: (roomId: string) => void;
    selectedChatRoomId: string;
    isConnected: boolean;
    userName: string;
    accountId: string;
    hasUnreadMessages: string[];
    showOnlineIndicator?: boolean;
    chatContactsList?: ChatContact[];
    errors: Subject<Error>;
    confirmMessageRead: (messages: string[]) => void;

    errorHandler?: (errorMessage: string) => any;
    saveMessage?: (message: ChatServerMessagePayload) => Observable<any>;
    saveFile?: (file: any) => Promise<any>;
}

interface ChatState {
    nodeErrors: typeof initialErrorsState;
    isLoading: boolean;
    error: string | null;
}

const initialErrorsState: {[key in NodeErrorTypes]: boolean} = {
    [NodeErrorTypes.ALREADY_ONLINE]: false,
    [NodeErrorTypes.EXPIRED_TOKEN]: false,
    [NodeErrorTypes.NOT_AUTHORIZED]: false,
    [NodeErrorTypes.NO_TOKEN]: false,
    [NodeErrorTypes.WEBSOCKET_ERROR]: false,
};

class Chat extends React.Component<ChatProps, ChatState> {
    subscription: Subscription;

    constructor(props: ChatProps) {
        super(props);
        this.state = {
            nodeErrors: initialErrorsState,
            isLoading: false,
            error: null,
        };
    }

    componentDidMount() {
        this.subscription = this.props.errors
            .pipe(
                map((error) => {
                    this.handleNodeErrors(error);
                    this.errorHandler(error);
                })
            )
            .subscribe();

        if (!this.props.selectedChatRoomId && this.props.hasUnreadMessages?.length > 0) {
            const selectedChat = this.props.hasUnreadMessages[0];
            this.props.setSelectedChatRoomId(selectedChat);
        }
    }

    componentDidUpdate(prevProps: ChatProps) {
        if (!this.props.selectedChatRoomId && this.props.chatContactsList?.length > 0) {
            const selectedChat = this.props.chatContactsList[0].roomId;
            this.props.setSelectedChatRoomId(selectedChat);
        }
        if (!prevProps.isConnected && this.props.isConnected) {
            this.setState({nodeErrors: initialErrorsState});
        }
    }

    componentWillUnmount() {
        this.props.setSelectedChatRoomId('');
        this.subscription.unsubscribe();
    }

    render() {
        return (
            <>
                <ChatUsersList
                    onChatSelection={this.props.setSelectedChatRoomId}
                    showOnlineIndicator={this.props.showOnlineIndicator}
                    connections={this.props.peerConnections}
                    isConnected={this.props.isConnected}
                    selectedChat={this.props.selectedChatRoomId}
                    chatContactsList={this.props.chatContactsList || []}
                />
                <div className="content-right">
                    <div className="content-wrapper">
                        <div className="content-body">
                            {this.props.isConnected ? (
                                <ChatComponent
                                    connection={
                                        this.props.peerConnections ? this.props.peerConnections?.[this.props?.selectedChatRoomId] : null
                                    }
                                    getMessagesFromUser={this.props.getMessagesFromUser}
                                    addMessage={this.props.addMessage}
                                    error={this.state.error}
                                    errorModuleHandle={this.setError}
                                    errorHandler={this.errorHandler}
                                    saveFile={this.props.saveFile}
                                    chatContact={this.chatPartnerAccount}
                                    userName={this.userName}
                                    confirmMessageRead={this.props.confirmMessageRead}
                                    accountId={this.props.accountId}
                                    saveMessage={this.props.saveMessage}
                                    showOnlineIndicator={
                                        this.props.showOnlineIndicator &&
                                        this.props.peerConnections?.[this.props?.selectedChatRoomId]?.peerIsOnline
                                    }
                                    selectedChat={this.props.selectedChatRoomId}
                                />
                            ) : (
                                <div className="chat-app-window">
                                    <div className="active-chat">
                                        <div className={`col-12 ${styles.noChatInfo}`}>
                                            <i className={'feather icon-alert-triangle'} />
                                            <p>
                                                <Translation text={'chat.noNodeConnection'} />
                                            </p>
                                            <p className={`${styles.activeError} mt-3`}>
                                                <Translation text={'chat.noConnectionReasons'} />
                                            </p>
                                            {Object.keys(this.state.nodeErrors).map((error) => (
                                                <p
                                                    key={error}
                                                    className={this.state.nodeErrors[error] ? styles.activeError : styles.inactiveError}>
                                                    <Translation text={`chat.${error}`} />
                                                </p>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </>
        );
    }

    private handleNodeErrors(error: any) {
        const message = error.message;
        if (message === NodeErrorTypes.NO_TOKEN) {
            this.setState((state) => ({
                nodeErrors: {...state.nodeErrors, [NodeErrorTypes.NO_TOKEN]: true},
            }));
        }

        if (message === NodeErrorTypes.EXPIRED_TOKEN) {
            this.setState((state) => ({
                nodeErrors: {...state.nodeErrors, [NodeErrorTypes.EXPIRED_TOKEN]: true},
            }));
        }

        if (message === NodeErrorTypes.ALREADY_ONLINE) {
            this.setState((state) => ({
                nodeErrors: {
                    ...state.nodeErrors,
                    [NodeErrorTypes.ALREADY_ONLINE]: true,
                },
            }));
        }

        if (message === NodeErrorTypes.NOT_AUTHORIZED) {
            this.setState((state) => ({
                nodeErrors: {
                    ...state.nodeErrors,
                    [NodeErrorTypes.NOT_AUTHORIZED]: true,
                },
            }));
        }

        if (message === NodeErrorTypes.WEBSOCKET_ERROR) {
            this.setState((state) => ({
                nodeErrors: {
                    ...state.nodeErrors,
                    [NodeErrorTypes.WEBSOCKET_ERROR]: true,
                },
            }));
        }
    }

    private errorHandler = (er): Observable<any> => {
        if (this.props.errorHandler) {
            this.props.errorHandler(er);
        } else {
            this.setError(er.message);
        }
        return of(er);
    };

    private setError = (error: string) => {
        console.log('SET ERROR in CHAT');
        this.setState((state) => ({
            error: error,
        }));
    };

    private get chatPartnerAccount(): ChatContact | undefined {
        if (this.props.chatContactsList && this.props.selectedChatRoomId) {
            //todo think if change to object
            return this.props.chatContactsList.find((room: ChatContact) => room.roomId === this.props.selectedChatRoomId);
        }
        return;
    }

    private get userName(): string {
        if (this.props.chatContactsList && this.props.selectedChatRoomId) {
            //todo think if change to object
            const currentRoom = this.props.chatContactsList.find((room) => room.roomId === this.props.selectedChatRoomId);
            return currentRoom ? `${currentRoom.firstName} ${currentRoom.lastName}` : 'Unknown User';
        }
        return '';
    }
}

export default Chat;
