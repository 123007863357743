import {createSelector} from '@reduxjs/toolkit';
import {RootState} from '../reducers';
import {IMarinaTagsState} from '../reducers/marinasTagsSlice';

export const selectMarinaTagsSlice = (state: RootState) => {
    return state.marinaTags;
};

export const isActionSuccessfulSelector = createSelector([selectMarinaTagsSlice], (state: IMarinaTagsState) => state.isActionSuccessful);

export const marinaTagsSelector = createSelector([selectMarinaTagsSlice], (state: IMarinaTagsState) => state.tags);

export const isMarinaTagsLoadingSelector = createSelector([selectMarinaTagsSlice], (state: IMarinaTagsState) => state.isMarinaTagsLoading);

export const isMarinaTagsInitializedSelector = createSelector(
    [selectMarinaTagsSlice],
    (state: IMarinaTagsState) => state.isMarinaTagsInitialized
);

export const marinaTagsErrorSelector = createSelector([selectMarinaTagsSlice], (state: IMarinaTagsState) => state.marinaTagsError);
