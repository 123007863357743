import {marineHubAPI, RestQueryParams} from 'marine-panel-common-web';
import {Observable} from 'rxjs';

export function changeArticleStatusAPI(authToken: string | null, marinaId: string, isActive: boolean): Observable<any> {
    const headers = {
            Authorization: `Bearer ${authToken}`,
        },
        payload = {
            active: isActive,
        };

    return marineHubAPI.put(`api/articles/${marinaId}/change_active`, payload, new RestQueryParams(), headers);
}
