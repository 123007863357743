import {createSelector} from '@reduxjs/toolkit';
import {ISkippersState} from '../reducers/skippersPageSlice';
import {RootState} from '../reducers';

export const selectSkippersPageSlice = (state: RootState): ISkippersState => {
    return state.skippers;
};

export const skippersSelector = createSelector([selectSkippersPageSlice], (state: ISkippersState) => state.skippers);

export const skippersPagePaginationSelector = createSelector([selectSkippersPageSlice], (state: ISkippersState) => state.pagination);

export const skippersPageMetadataSelector = createSelector([selectSkippersPageSlice], (state: ISkippersState) => state.skippersMetadata);

export const isSkippersPagesLoadingSelector = createSelector([selectSkippersPageSlice], (state: ISkippersState) => state.isLoading);

export const isSkippersPagesInitializedSelector = createSelector([selectSkippersPageSlice], (state: ISkippersState) => state.isInitialized);

export const skippersPagesErrorSelector = createSelector([selectSkippersPageSlice], (state: ISkippersState) => state.error);
