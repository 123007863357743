import {createSelector} from '@reduxjs/toolkit';
import {IVesselTypeState} from '../reducers/vesselTypeSlice';

export const selectVesselType = (state: any): IVesselTypeState => {
    return state.vesselType;
};

export const vesselTypesSelector = createSelector([selectVesselType], (state: IVesselTypeState) => state.vesselTypes);

export const vesselTypesLoadingSelector = createSelector([selectVesselType], (state: IVesselTypeState) => state.isLoading);

export const vesselTypesInitializedSelector = createSelector([selectVesselType], (state: IVesselTypeState) => state.isInitialized);

export const vesselTypesErrorSelector = createSelector([selectVesselType], (state: IVesselTypeState) => state.error);
