import React from 'react';
import {EditorState, ContentState, convertFromHTML} from 'draft-js';
import 'draft-js/dist/Draft.css';
import {Editor} from 'react-draft-wysiwyg';
import {isSameValue} from '../../..';

const toolbarConfig = {
    options: ['blockType', 'list'],
    blockType: {
        inDropdown: false,
        options: ['Normal'],
        className: undefined,
        component: () => <span className="richtext-paragraph-button">P</span>,
        dropdownClassName: undefined,
    },
    list: {
        inDropdown: false,
        className: 'richtext-list-controls',
        component: undefined,
        dropdownClassName: undefined,
        options: ['unordered', 'ordered'],
    },
};

interface IRichTextComponentProps {
    value: string | undefined | null;
    defaultValue?: string | null;
    label?: string;
    updateData?: (type: string, value: string) => void;
    type?: string;
    name?: string;
    onChange?: any;
    placeholder?: string;
}

interface IRichTextComponentState {
    editorState: any;
    isEdited: boolean;
}

export class RichTextComponent extends React.Component<IRichTextComponentProps, IRichTextComponentState> {
    private editor: React.RefObject<any>;

    constructor(props: IRichTextComponentProps) {
        super(props);
        this.state = {
            editorState: this.props.value
                ? EditorState.createWithContent(ContentState.createFromText(this.props.value))
                : EditorState.createEmpty(),
            isEdited: false,
        };
        this.editor = React.createRef();
    }

    componentDidMount(): void {
        if (this.props.value) {
            const blocks = convertFromHTML(this.props.value);
            this.setState({
                editorState: EditorState.createWithContent(ContentState.createFromBlockArray(blocks.contentBlocks, blocks.entityMap)),
            });
        }
    }

    componentDidUpdate(prevProps: Readonly<IRichTextComponentProps>): void {
        if (!isSameValue(this.props.value, prevProps.value) && this.props.value && !this.state.isEdited) {
            const blocks = convertFromHTML(this.props.value);
            this.setState({
                editorState: EditorState.createWithContent(ContentState.createFromBlockArray(blocks.contentBlocks, blocks.entityMap)),
            });
        }
    }

    render() {
        return (
            <>
                <Editor
                    editorState={this.state.editorState}
                    wrapperClassName="richtext-wrapper"
                    toolbarClassName="richtext-toolbar"
                    editorClassName="richtext-editor"
                    onEditorStateChange={this.onChange}
                    toolbar={toolbarConfig}
                    localization={{
                        locale: 'pl',
                    }}
                />
            </>
        );
    }

    private onChange = (editorState: any) => {
        this.setState({editorState: editorState, isEdited: true}, () => {
            this.props.onChange(editorState);
        });
    };
}

export default RichTextComponent;
