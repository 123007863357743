import {addAlert, AlertType, authTokenSelector, handleApiError, IRawRestQueryParams} from 'marine-panel-common-web';
import {combineEpics, Epic, ofType, StateObservable} from 'redux-observable';
import {Observable, of} from 'rxjs';
import {catchError, switchMap} from 'rxjs/operators';
import {getVesselTypesAPI} from '../../api/getVesselTypes';
import {RootState} from '../reducers';
import {
    changeIsVesselTypesPageLoading,
    changeVesselTypes,
    fetchVesselTypes,
    setVesselTypesPageError,
    addVesselType,
    IAddVesselType,
    changeIsActionProcessing,
    changeIsActionComplete,
} from '../reducers/vesselTypesPageSlice';
import {addVesselTypeAPI} from '../../api/addVesselType';
import {PayloadAction} from '@reduxjs/toolkit';

const fetchVesselTypesEpic: Epic = (action$, state$: StateObservable<RootState>) => {
    return getVesselTypes(action$, state$, fetchVesselTypes);
};

const addVesselTypeEpic: Epic = (action$: Observable<any>, state$: StateObservable<RootState>) => {
    return action$.pipe(
        ofType(addVesselType.type),
        switchMap((action: PayloadAction<IAddVesselType>): any => {
            const authToken = authTokenSelector(state$.value),
                payload = action.payload.vesselTypePayload;

            return addVesselTypeAPI(authToken, payload).pipe(
                switchMap(() => {
                    const message = 'administration.vesselTypes.addVesselType.vesselTypeAdded';
                    return of(...updateSuccessActions(message));
                }),
                catchError((error) => of(...updateErrorActions(error)))
            );
        }),
        catchError((error) => of(...updateErrorActions(error)))
    );
};

const getVesselTypes = (action$: Observable<any>, state$: StateObservable<RootState>, actionType: any) => {
    return action$.pipe(
        ofType(actionType.type),
        switchMap((): any => {
            const authToken = authTokenSelector(state$.value);

            return getVesselTypesList(authToken);
        }),
        catchError((error) => of(...errorActions(error)))
    );
};

export const getVesselTypesList = (authToken: string, params?: IRawRestQueryParams | null) => {
    return getVesselTypesAPI(authToken, params).pipe(
        switchMap((resp: any) => {
            const vesselTypeList = resp['hydra:member'],
                actions = successActions(vesselTypeList);
            return of(...actions);
        }),
        catchError((error: any) => of(...errorActions(error)))
    );
};

const successActions = (vesselTypes: any[]): any[] => {
    return [changeVesselTypes(vesselTypes), changeIsVesselTypesPageLoading(false)];
};

const updateSuccessActions = (successMessage: string): any[] => {
    return [changeIsActionProcessing(false), changeIsActionComplete(true), addAlert({message: successMessage}), fetchVesselTypes()];
};

const updateErrorActions = (error: any): any[] => {
    const errorObj = handleApiError(error);
    errorObj.type = AlertType.WARNING;
    return [changeIsActionProcessing(false), changeIsActionComplete(true), setVesselTypesPageError(errorObj.message), addAlert(errorObj)];
};

const errorActions = (error: any): any[] => {
    const errorObj = handleApiError(error);
    errorObj.type = AlertType.WARNING;
    return [changeIsVesselTypesPageLoading(false), setVesselTypesPageError(errorObj.message), addAlert(errorObj)];
};

const vesselTypesPageEpic = combineEpics(fetchVesselTypesEpic, addVesselTypeEpic);

export default vesselTypesPageEpic;
