import React from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import {
    Form,
    IFormConfig,
    isAuthenticatedSelector,
    Loader,
    loginErrorSelector,
    loginLoadingSelector,
    Translation,
    UserRole,
    WithNavigate,
    withNavigation,
} from '../../..';
import {RootState} from '../../../store/reducers';
import {getLoginCredentials} from '../../../store/reducers/loginSlice';

interface IConnectedLoginProps {
    isAuthenticated: boolean;
    isLoading: boolean;
    loginError: string;
    getLoginCredentials: typeof getLoginCredentials;
}

interface IExternalLoginProps {
    userRole: UserRole;
    formConfig: () => IFormConfig;
}

interface ILoginProps extends IConnectedLoginProps, IExternalLoginProps, WithNavigate {}

interface ILoginState {
    formConfig: IFormConfig;
    value: any;
}

class Login extends React.Component<ILoginProps, ILoginState> {
    constructor(props: ILoginProps) {
        super(props);

        this.state = {
            formConfig: this.props.formConfig(),
            value: null,
        };
    }

    componentDidMount(): void {
        if (this.props.isAuthenticated) {
            this.props.navigate('/');
        }
        // const formConfig = this.props.formConfig();
        // this.setState({formConfig});
    }

    componentDidUpdate(): void {
        if (this.props.isAuthenticated) {
            this.props.navigate('/');
        }
    }

    render() {
        const isUserBoastman = this.props.userRole === UserRole.BOATSMAN,
            registerUrl = isUserBoastman ? '/boatsman/auth/register' : '/auth/register',
            resetPasswordUrl = isUserBoastman ? '/boatsman/auth/reset-password' : '/auth/reset-password';

        return (
            <article className="auth-article">
                <Loader showLoader={this.props.isLoading} />
                <header className="auth-form-header">
                    <h1 className="auth-title">
                        <Translation text="auth.login.title" />
                    </h1>
                </header>
                <Form config={this.state.formConfig} submitForm={this.login} controlName={'loginForm'} value={{}} />
                {(this.props.userRole !== UserRole.ADMIN && this.props.userRole !== UserRole.OWNER)? (
                    <div className="auth-subtitle login-subtitle">
                        <p>
                            <Translation text="auth.login.haveAccount" />
                        </p>
                        <Link className="auth-redirect-link" to={registerUrl}>
                            <Translation text="auth.login.register" />
                        </Link>
                    </div>
                ) : null}
                <div
                    className={`auth-subtitle login-subtitle forgot-password-subtitle ${
                        this.props.userRole === UserRole.ADMIN ? 'admin-auth-subtitle' : ''
                    }`}>
                    <Link className="auth-redirect-link" to={resetPasswordUrl}>
                        <Translation text="auth.login.resetPassword" />
                    </Link>
                </div>
            </article>
        );
    }

    private login = (event: any, value: any, valid: boolean, touched: boolean): any => {
        event.preventDefault();
        if (!valid || !touched) {
            return;
        }

        return this.props.getLoginCredentials(value.email, value.password, this.props.userRole);
    };
}

export default connect(
    (state: RootState) => ({
        isAuthenticated: isAuthenticatedSelector(state),
        isLoading: loginLoadingSelector(state),
        loginError: loginErrorSelector(state),
    }),
    {
        getLoginCredentials,
    }
)(withNavigation(Login));
