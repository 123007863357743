import React from 'react';
import {WithTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {contrastModeSelector, fontSizeSelector} from '../../store/selectors/sagaSelectors';

interface IExternalAccessiblityWrapperProps {
    readonly children?: any;
}

function AccessibilityWrapper(props: React.PropsWithChildren<IExternalAccessiblityWrapperProps>) {
    const fontSize = useSelector(fontSizeSelector);

    return (
        <div className={`accessibility-wrapper font-size-${fontSize}`}>
            {props.children}
        </div>
    );
}

export default AccessibilityWrapper;
