import {AccountBasicInfo, ApiSingleResponseBase} from '../../types';
import {Observable} from 'rxjs';
import {marineHubAPI} from '../provider/marineHubAPI';
import {RestQueryParams} from '../base/queryParams';

export function changeMiscAPI(accountId: string, misc: any, authToken: string): Observable<ApiSingleResponseBase<AccountBasicInfo>> {
    return marineHubAPI.put(`api/accounts/${accountId}/change_misc`, {misc}, new RestQueryParams(), {
        Authorization: `Bearer ${authToken}`,
    });
}
