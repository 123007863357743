import {Observable} from 'rxjs';
import {marineHubAPI} from '../provider/marineHubAPI';
import {RestQueryParams} from '../base/queryParams';
import {ToggleNotificationPayload} from '../../types';

export function toggleNotificationAPI(accessToken: string, payload: ToggleNotificationPayload): Observable<any> {
    return marineHubAPI.put(`notifications/toggle`, payload, new RestQueryParams(), {
        Authorization: `Bearer ${accessToken}`,
    });
}
