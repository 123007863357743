import {AccountBasicInfo, ApiSingleResponseBase, AvatarInput} from '../../types';
import {Observable} from 'rxjs';
import {marineHubAPI} from '../provider/marineHubAPI';
import {RestQueryParams} from '../base/queryParams';

export function setAvatarAPI(
    accountId: string,
    avatar: AvatarInput
): Observable<ApiSingleResponseBase<AccountBasicInfo>> {
    return marineHubAPI.put(`api/accounts/${accountId}/change_avatar`, avatar, new RestQueryParams());
}
