import React, {useEffect} from 'react';
import {Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';
import {IArticle, Loader, LoaderType, Translation} from 'marine-panel-common-web';
import {AlertCircle} from 'react-feather';
import {deleteArticle} from '../../../store/reducers/articlesPageSlice';

interface IConnectedDeleteArticleModalProps {}

interface IExternalDeleteArticleModalProps {
    readonly isModalShown: boolean;
    readonly toggleModal: (article: IArticle | null) => void;
    readonly selectedArticle: IArticle | null;
    readonly deleteArticle: typeof deleteArticle;
    readonly isArticleActionComplete: boolean;
    readonly isArticleActionProcessing: boolean;
}

interface IDeleteArticleModalProps extends IConnectedDeleteArticleModalProps, IExternalDeleteArticleModalProps {}

const DeleteArticleModal: React.FC<IDeleteArticleModalProps> = ({
    isModalShown,
    toggleModal,
    selectedArticle,
    deleteArticle,
    isArticleActionComplete,
    isArticleActionProcessing,
}) => {
    useEffect(() => {
        if (isArticleActionComplete) {
            toggleModal(null);
        }
    }, [isArticleActionComplete]);

    return (
        <Modal isOpen={isModalShown} toggle={() => toggleModal(null)}>
            <ModalHeader toggle={() => toggleModal(null)}>
                <Translation text="articles.deleteArticleModal.title" />
            </ModalHeader>
            <ModalBody>
                <p className="modal-subtitle">
                    <Translation text="articles.deleteArticleModal.subtitle" />
                </p>
                <p className="modal-description warning">
                    <span>
                        <AlertCircle size={16} />
                    </span>
                    <Translation text="articles.deleteArticleModal.description" />
                </p>
            </ModalBody>
            <ModalFooter>
                <button className="btn btn-underline btn-underline-primary cancel-confirm" onClick={() => toggleModal(null)}>
                    <Translation text="buttons.cancel" />
                </button>
                <button className="btn btn-create" onClick={removeArticle}>
                    <Translation text="buttons.delete" />
                </button>
            </ModalFooter>

            <Loader type={LoaderType.Local} showLoader={isArticleActionProcessing} />
        </Modal>
    );

    function removeArticle() {
        if (null === selectedArticle) {
            return;
        }
        const articleId = selectedArticle.id;
        deleteArticle(articleId);
    }
};

export default DeleteArticleModal;
