import React, {useEffect, useState} from 'react';
import {Modal, ModalBody, ModalHeader} from 'reactstrap';
import {
    Form,
    FormControlChangeType,
    IArticle,
    IArticleFullDetails,
    IArticlePage,
    IFormConfig,
    Loader,
    LoaderType,
    Translation,
} from 'marine-panel-common-web';
import {BehaviorSubject, Subscription} from 'rxjs';
import {filter, share} from 'rxjs/operators';
import {addArticleFormConfig} from './addArticleFormConfig';
import {addArticle, fetchArticleDetails, updateArticle} from '../../../store/reducers/articlesPageSlice';
import {IAddArticlePayload} from '../../../api/addArticle';

interface IConnectedAddArticleModalProps {}

interface IExternalAddArticleModalProps {
    readonly isModalShown: boolean;
    readonly toggleModal: (article: IArticle | null) => void;
    readonly selectedArticle: IArticle | null;
    readonly articleDetails: IArticleFullDetails | null;
    readonly addArticle: typeof addArticle;
    readonly updateArticle: typeof updateArticle;
    readonly isArticleActionComplete: boolean;
    readonly isArticleActionProcessing: boolean;
    readonly fetchArticleDetails: typeof fetchArticleDetails;
}

interface IAddArticleModalProps extends IConnectedAddArticleModalProps, IExternalAddArticleModalProps {}

const AddArticleModal: React.FC<IAddArticleModalProps> = ({
    isModalShown,
    toggleModal,
    selectedArticle,
    articleDetails,
    addArticle,
    updateArticle,
    isArticleActionComplete,
    isArticleActionProcessing,
    fetchArticleDetails,
}) => {
    const [formValues, setFormValues] = useState<{[key: string]: any} | null>(null),
        [formConfig, setFormConfig] = useState<IFormConfig | null>(null),
        [onValueStateChange$] = useState(() => new BehaviorSubject<any>(null)),
        [onValueStateChange$$] = useState(() => onValueStateChange$.pipe(share())),
        subscriptions: Subscription[] = [];

    useEffect(() => {
        if (null !== selectedArticle) {
            fetchArticleDetails(selectedArticle.id);
        }
    }, [selectedArticle]);

    useEffect(() => {
        if (isArticleActionComplete) {
            toggleModal(null);
        }
    }, [isArticleActionComplete]);

    useEffect(() => {
        if (null !== articleDetails) {
            let content = '';
            articleDetails.pages.forEach((page: IArticlePage) => (content += `${page.content}`));
            setFormValues({
                title: articleDetails.title,
                subtitle: articleDetails.subtitle,
                description: articleDetails.shortContent,
                content: content,
            });
        } else {
            setFormValues(null);
        }
    }, [articleDetails]);

    useEffect(() => {
        setFormConfig(addArticleFormConfig(() => toggleModal(null)));
        subscriptions.push(
            onValueStateChange$$
                .pipe(
                    filter((data: {controlName?: string; value?: {[name: string]: string}; changeType?: FormControlChangeType}) => {
                        return data && data?.changeType === FormControlChangeType.User;
                    })
                )
                .subscribe((data) => {
                    if (data.controlName && data.value) {
                        setFormValues({
                            title: data.value.title,
                            subtitle: data.value.subtitle,
                            description: data.value.description,
                            content: data.value.content,
                        });
                    }
                })
        );
        return () => {
            subscriptions.forEach((subscription) => subscription.unsubscribe());
            setFormValues(null);
        };
    }, []);

    function onValueStateChange(controlName: string, value: any, changeType: FormControlChangeType) {
        onValueStateChange$.next({controlName: controlName, value: value, changeType: changeType});
    }

    return (
        <Modal isOpen={isModalShown} toggle={() => toggleModal(null)}>
            <ModalHeader toggle={() => toggleModal(null)}>
                <Translation text={`articles.${null === selectedArticle ? 'addArticleModal' : 'editArticleModal'}.title`} />
            </ModalHeader>
            <ModalBody>
                {formConfig && (
                    <Form
                        config={formConfig}
                        submitForm={articleAction}
                        onValueStateChange={onValueStateChange}
                        value={formValues}
                        controlName={'articleForm'}
                    />
                )}
            </ModalBody>
            <Loader type={LoaderType.Local} showLoader={isArticleActionProcessing} />
        </Modal>
    );

    function articleAction() {
        if (null !== formValues) {
            const payload: IAddArticlePayload = {
                title: formValues.title,
                shortContent: formValues.description,
                subtitle: formValues.subtitle,
                content: formValues.content,
            };

            if (null === selectedArticle) {
                return addArticle(payload);
            } else {
                updateArticle(selectedArticle.id, payload);
            }
        }
    }
};

export default AddArticleModal;
