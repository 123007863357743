// export default () => {
//     self.addEventListener("message", event => {
//         const file = new File(event.data.messageMedia, event.data.filename)
//         const message = Object.assign(event.data.message, { messageMedia: URL.createObjectURL(file)})
//          // @ts-ignore
//         postMessage({message,
//             room: event.data.room
//         });
//     })
// }

function decideType(fileName = "") {
    const fileExtension = fileName.split(".").pop();

    switch (fileExtension) {
        case "png":
        case "jpeg":
        case "jpg":
            return `image/${fileExtension}`;
        case "pdf":
            return "application/pdf";
        case "xls":
            return "application/vnd.ms-excel";
        case "xlsx":
            return "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
        case "doc":
            return "application/msword";
        case "docx":
            return "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
        default:
            return "";
    }
}

export default () => {
    self.addEventListener("message", event => {
        const linkSource = `data:${decideType(event.data.message.messageContent)};base64,` + event.data.messageMedia.join('');
        const message = Object.assign(event.data.message, {messageMedia: linkSource});
        // @ts-ignore
        postMessage({ message, room: event.data.room });
    });
};
