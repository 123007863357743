import {Loader, LoaderType, Translation, VesselModel, VesselType, meterString} from 'marine-panel-common-web';
import React, {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {connect} from 'react-redux';
import {Card, CardBody, CardHeader, Table} from 'reactstrap';
import {RootState} from '../../../store/reducers';
import {fetchVesselTypes} from '../../../store/reducers/vesselTypesPageSlice';
import {vesselTypesSelector} from '../../../store/selectors/vesselTypesPageSelectors';
import {fetchVesselModels, resetToInitialVesselModelsPageState} from '../../../store/reducers/vesselModelsPageSlice';
import {isVesselModelsPagesLoadingSelector, vesselModelsSelector} from '../../../store/selectors/vesselModelsPageSelectors';
import useModal from '../../../utils/modalHook';
import AddVesselModelModal from './AddVesselModel';

interface IConnectedVesselModelsProps {
    readonly isLoading: boolean;
    readonly vesselModels: VesselModel[] | null;
    readonly vesselTypes: VesselType[] | null;
    readonly fetchVesselModels: typeof fetchVesselModels;
    readonly fetchVesselTypes: typeof fetchVesselTypes;
    readonly resetToInitialVesselModelsPageState: typeof resetToInitialVesselModelsPageState;
}

interface IVesselModelsProps extends IConnectedVesselModelsProps {}

const VesselModels: React.FC<IVesselModelsProps> = ({
    vesselModels,
    vesselTypes,
    isLoading,
    fetchVesselModels,
    fetchVesselTypes,
    resetToInitialVesselModelsPageState,
}) => {
    const {t} = useTranslation(),
        [isModalShown, toggleModal] = useModal();

    useEffect(() => {
        fetchVesselModels();

        return () => {
            resetToInitialVesselModelsPageState();
        };
    }, [fetchVesselModels, resetToInitialVesselModelsPageState]);
    useEffect(() => {
        fetchVesselTypes();
    }, [fetchVesselTypes]);

    const vesselModelName = (vesselTypeId: string | null) => {
        let vesselName = '---';
        if (vesselTypeId && vesselTypes?.length) {
            const vesselType = vesselTypes.find((item: VesselType) => item.id === vesselTypeId);
            vesselName = vesselType ? vesselType.name : '---';
        }
        return vesselName;
    };

    return (
        <>
            <Card className="vessel-models-card">
                <CardHeader>
                    <h2 className="section-subtitle">
                        <Translation text="administration.vesselModels.title" />
                    </h2>
                    <button className="btn btn-create add-vessel-model-button" onClick={() => toggleModal()}>
                        <Translation text="administration.vesselModels.addModel" />
                    </button>
                </CardHeader>
                <CardBody>
                    <Table responsive className="vessel-models-table">
                        <thead>
                            <tr>
                                <th>
                                    <Translation text="administration.vesselModels.table.name" />
                                </th>
                                <th>
                                    <Translation text="administration.vesselModels.table.length" />
                                </th>
                                <th>
                                    <Translation text="administration.vesselModels.table.width" />
                                </th>
                                <th>
                                    <Translation text="administration.vesselModels.table.draft" />
                                </th>
                                <th>
                                    <Translation text="administration.vesselModels.table.height" />
                                </th>
                                <th>
                                    <Translation text="administration.vesselModels.table.type" />
                                </th>
                            </tr>
                        </thead>
                        <tbody>{renderVesselModelsTable()}</tbody>
                    </Table>

                    <Loader type={LoaderType.Local} showLoader={isLoading} />
                </CardBody>
            </Card>

            {isModalShown ? <AddVesselModelModal isModalShown={isModalShown} toggleModal={toggleModal} /> : null}
        </>
    );

    function renderVesselModelsTable() {
        if (null === vesselModels || !vesselModels.length) {
            return (
                <tr>
                    <td colSpan={7}>
                        <Translation text="operators.table.noData" />
                    </td>
                </tr>
            );
        }

        return vesselModels.map((item: VesselModel) => {
            return (
                <tr key={item.id}>
                    <td>{item.name}</td>
                    <td>
                        {meterString(item.length)} {t('administration.metersMeasure')}
                    </td>
                    <td>
                        {meterString(item.width)} {t('administration.metersMeasure')}
                    </td>
                    <td>
                        {meterString(item.draft)} {t('administration.metersMeasure')}
                    </td>
                    <td>
                        {meterString(item.height)} {t('administration.metersMeasure')}
                    </td>
                    <td>{vesselModelName(item.vesselTypeId)}</td>
                </tr>
            );
        });
    }
};

export default connect(
    (state: RootState) => ({
        isLoading: isVesselModelsPagesLoadingSelector(state),
        vesselModels: vesselModelsSelector(state),
        vesselTypes: vesselTypesSelector(state),
    }),
    {
        fetchVesselModels,
        fetchVesselTypes,
        resetToInitialVesselModelsPageState,
    }
)(VesselModels);
