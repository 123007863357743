import {Observable} from 'rxjs';
import {marineHubAPI} from "../provider/marineHubAPI";
import {RestQueryParams} from "../base/queryParams";
import {IUpdateSkipperFullAccountPayload} from "../../store/reducers/accountSlice";


export function updateSkipperFullAccountAPI(
    authToken: string | null,
    accountId: string,
    payload: IUpdateSkipperFullAccountPayload,
): Observable<any> {
    let headers = undefined;

    if (authToken) {
        headers = {
            Authorization: `Bearer ${authToken}`,
        };
    }

    return marineHubAPI.put(`api/accounts/${accountId}/change_skipper_full_info`, payload, new RestQueryParams(), headers);
}
