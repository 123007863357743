import React from "react";
import VerticalNavMenuLink from "../VerticalNavMenuLink";
import VerticalNavMenuGroup from "../VerticalNavMenuGroup";
import VerticalNavMenuSectionHeader from "../VerticalNavMenuSectionHeader";
import {resolveVerticalNavMenuItemComponent as resolveNavItemComponent} from "../utils/utils";
import {IVerticalNavMenuItemsProps} from "../../../types";


type Props = IVerticalNavMenuItemsProps;

const VerticalMenuNavItems = (props: Props) => {
    const Components = {
        VerticalNavMenuLink,
        VerticalNavMenuGroup,
        VerticalNavMenuSectionHeader
    };

    const RenderNavItems = props.items.map((
        item: any,
        // index: number
    ) => {
        const TagName = Components[resolveNavItemComponent(item)];

        // if (item.children) {
        //     return <TagName item={item} index={index} key={item.id} {...props} />
        // }
        return <TagName key={item.id || item.header} item={item} {...props} />
    });

    return RenderNavItems;
};

export default VerticalMenuNavItems
