import React, {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {Card, CardBody, Table} from 'reactstrap';
import {Loader, LoaderType, Translation, Pagination, IModelApiResponseViewObject} from 'marine-panel-common-web';
import {connect} from 'react-redux';
import {RootState} from '../../../../store/reducers';
import styles from './styles.module.scss';
import {
    changeOperatorsPagePagination,
    fetchOperators,
    resetToInitialOperatorsPageState,
} from '../../../../store/reducers/operatorsPageSlice';
import {
    isOperatorsPageLoadingSelector,
    operatorsPageMetadataSelector,
    operatorsSelector,
} from '../../../../store/selectors/operatorsPageSelectors';
import {IOperatorFullInfo} from '../../../../model/outputItems';

interface IConnectedOperatorsListProps {
    readonly isLoading: boolean;
    readonly operators: IOperatorFullInfo[] | null;
    readonly operatorsPageMetadata: IModelApiResponseViewObject | null;
    readonly fetchOperators: typeof fetchOperators;
    readonly changeOperatorsPagePagination: typeof changeOperatorsPagePagination;
    readonly resetToInitialOperatorsPageState: typeof resetToInitialOperatorsPageState;
}

interface IOperatorsListProps extends IConnectedOperatorsListProps {
    readonly connectedOperators: IOperatorFullInfo[] | null;
    readonly bindOperator: (operatorId: string) => void;
}

const OperatorsList: React.FC<IOperatorsListProps> = ({
    isLoading,
    operators,
    connectedOperators,
    bindOperator,
    operatorsPageMetadata,
    fetchOperators,
    changeOperatorsPagePagination,
    resetToInitialOperatorsPageState,
}) => {
    const {t} = useTranslation();

    useEffect(() => {
        fetchOperators();

        return () => {
            resetToInitialOperatorsPageState();
        };
    }, [fetchOperators]);

    return (
        <div>
            <Card className="operators-list">
                <CardBody>
                    <Table responsive className="operators-list-table">
                        <thead>
                            <tr>
                                <th>
                                    <Translation text="operators.table.name" />
                                </th>
                                <th />
                            </tr>
                        </thead>
                        <tbody>{renderOperatorsTable()}</tbody>
                    </Table>

                    <div className={styles.paginationContainer}>
                        <Pagination listMetadata={operatorsPageMetadata} changePage={changeOperatorsPagePagination} itemsPerPage={10} />
                    </div>
                    <Loader type={LoaderType.Local} showLoader={isLoading} />
                </CardBody>
            </Card>
        </div>
    );

    function renderOperatorsTable() {
        if (null === operators || !operators.length) {
            return (
                <tr>
                    <td>
                        <Translation text="operators.table.noData" />
                    </td>
                </tr>
            );
        }

        return operators.map((item: IOperatorFullInfo) => {
            const userName =
                    item.user && item.user.firstName && item.user.lastName
                        ? `${item.user.firstName} ${item.user.lastName}`
                        : t('marinas.connectedOperators.table.unknownOperator'),
                operatorName = item.operator.name ? item.operator.name : userName;
            return (
                <tr key={item.id}>
                    <td>{operatorName}</td>
                    <td>
                        <button
                            className="btn btn-underline connect-operator-btn"
                            disabled={connectedOperators ? connectedOperators.some((operator) => operator.id === item.id) : false}
                            onClick={() => connectOperator(item.operator.id)}>
                            <Translation text="marinas.table.actions.connect" />
                        </button>
                    </td>
                </tr>
            );
        });
    }

    function connectOperator(operatorId: string) {
        bindOperator(operatorId);
    }
};

export default connect(
    (state: RootState) => ({
        operators: operatorsSelector(state),
        isLoading: isOperatorsPageLoadingSelector(state),
        operatorsPageMetadata: operatorsPageMetadataSelector(state),
    }),
    {
        fetchOperators,
        resetToInitialOperatorsPageState,
        changeOperatorsPagePagination,
    }
)(OperatorsList);
