import {FormButtonDisabledStrategy} from './index';
import {IDisableStrategy} from './iDisableStrategy';
import {UntouchedOrInvalidDisableStrategy} from './untouchedOrInvalidDisableStrategy';

export class DisableStrategyFactory {
    create(type: FormButtonDisabledStrategy): IDisableStrategy {
        switch (type) {
            case FormButtonDisabledStrategy.UNTOUCHED_OR_INVALID:
            default:
                return new UntouchedOrInvalidDisableStrategy();
        }
    }
}
