import {isNullOrUndefined} from './runtimeUtils';

export const setPath = (target: object, path: string, value: any): void => {
    const parts = path.split('.');
    if (0 === parts.length) {
        return value;
    }

    const lastPart = parts.pop();
    const realTarget = access(target, parts.join('.'));
    if (isNullOrUndefined(realTarget)) {
        throw new Error(`Cannot set path ${path} on null.`);
    }

    realTarget[lastPart] = value;
};

export const access = (value: object, path: string): any => {
    let current = value;
    let finalValue = null;
    const parts = path.split('.');

    if (0 === parts.length) {
        return value;
    }

    const lastIndex = parts.length - 1;

    parts.forEach((part, index) => {
        if (isNullOrUndefined(current)) {
            finalValue = null;

            return;
        }

        if (typeof current !== 'object') {
            throw new Error(`Cannot access path "${part}" on value that is not an object "${current}", while processing path ${path}.`);
        }

        if (index === lastIndex) {
            finalValue = doAccess(current, part);
        } else {
            current = doAccess(current, part);
        }
    });

    return finalValue;
};

const doAccess = (valueHolder: object, key: string): any => {
    if (!Array.isArray(valueHolder)) {
        return valueHolder[key];
    }

    return valueHolder.find((entry) => {
        return typeof entry === 'object' && entry.key === key;
    });
};
