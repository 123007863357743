import {IAPIHandler} from './api';
import {QueryParams, RestQueryParams} from './queryParams';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

export class MappingAPIHandlerDecorator<ParamsType extends QueryParams<any> = RestQueryParams> implements IAPIHandler<ParamsType> {
    constructor(
        private readonly next: IAPIHandler<ParamsType>,
        private readonly mapper: (data: any, params?: ParamsType) => any,
        private readonly isList: boolean
    ) {}

    get<T>(path: string, params?: ParamsType, headers?: Object): Observable<T> {
        return this.next.get<T>(path, params, headers).pipe(
            map((data) => {
                return this.isList ? (data as any).map((datum: any) => this.mapper(datum, params)) : this.mapper(data, params);
            })
        );
    }

    post<T = any>(path: string, payload?: any, params?: ParamsType, headers?: Object): Observable<T> {
        return this.next.post<T>(path, payload, params, headers).pipe(
            map((data) => {
                return this.isList ? (data as any).map((datum: any) => this.mapper(datum, params)) : this.mapper(data, params);
            })
        );
    }

    put<T = any>(path: string, payload?: any, params?: ParamsType, headers?: Object): Observable<T> {
        return this.next.put<T>(path, payload, params, headers).pipe(
            map((data) => {
                return this.isList ? (data as any).map((datum: any) => this.mapper(datum, params)) : this.mapper(data, params);
            })
        );
    }

    delete<T = any>(path: string, params?: ParamsType, headers?: Object): Observable<T> {
        return this.next.delete<T>(path, params, headers).pipe(
            map((data) => {
                return this.isList ? (data as any).map((datum: any) => this.mapper(datum, params)) : this.mapper(data, params);
            })
        );
    }
}
