import React, {useState} from 'react';
import {Accordion, AccordionBody, AccordionHeader, AccordionItem} from 'reactstrap';
import * as Icon from 'react-feather';
import Translation from '../Translation';
import {IAccordionComponentProps, IAccordionItem} from '../../types';

type Props = IAccordionComponentProps;

const AccordionComponent = (props: Props) => {
    const [open, setOpen] = useState('1');

    const toggle = (id: string) => {
        open === id ? setOpen('') : setOpen(id);
    };

    return (
        <Accordion
            className="accordion-margin"
            open={open}
            {...{
                toggle: (accordionItem: string) => toggle(accordionItem),
            }}>
            {props.items.map((item: IAccordionItem, index: number) => {
                const IconTag = (Icon as any)[item.icon];
                return (
                    <AccordionItem key={`accordion_item_${index}`}>
                        <AccordionHeader targetId={`accordion_item_${index}`}>
                            <IconTag size={16} color={'#7367F0'} />
                            <Translation text={item.title} />
                        </AccordionHeader>
                        <AccordionBody accordionId={`accordion_item_${index}`}>
                            <Translation text={item.description} />
                        </AccordionBody>
                    </AccordionItem>
                );
            })}
        </Accordion>
    );
};

export default AccordionComponent;
