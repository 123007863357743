import {Currency} from '../types';

export function getCurrencySymbol(currencyCode: Currency | undefined) {
	switch (currencyCode) {
		case Currency.EURO:
			return '€';
		default:
			return '';
	}
}
