import {Badge} from 'reactstrap';
import {Berth, Marina, Translation, BerthStatus} from 'marine-panel-common-web';

export interface IWizardEditBadgesProps {
    berth?: Berth;
    marina?: Marina;
}

export default function WizardEditBadges(props: IWizardEditBadgesProps) {
    const renderMarinaBadge = (marina: Marina | undefined) => {
        if (!marina) return null;
        return marina.active ? (
            <Badge color="light-primary" className="wizard-edit-badge">
                <Translation text={`editMenuItems.badges.active`} />
            </Badge>
        ) : null;
    };
    const renderBerthBadges = (berth: Berth | undefined) => {
        if (!berth) return null;
        const berthBadges = [];
        berth.active === true ? berthBadges.push(BerthStatus.ACTIVE) : berthBadges.push(BerthStatus.INACTIVE);
        if (berth.appBookingEnabled === true) {
            berthBadges.push(BerthStatus.APP_BOOKING);
        }
        return berthBadges.map((badge: BerthStatus) => (
            <Badge color="light-primary" className="wizard-edit-badge" key={berth.id + badge}>
                <Translation text={`editMenuItems.badges.${badge}`} />
            </Badge>
        ));
    };
    return (
        <div className="wizard-edit-badges-wrapper">
            {renderMarinaBadge(props.marina)}
            {renderBerthBadges(props.berth)}
        </div>
    );
}
