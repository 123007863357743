import {createSelector} from '@reduxjs/toolkit';
import {ILoginState} from '../reducers/loginSlice';

export const selectLogin = (state: any): ILoginState => {
    return state.login;
};

export const loginUsernameSelector = createSelector([selectLogin], (state: ILoginState) => state.username);

export const loginErrorSelector = createSelector([selectLogin], (state: ILoginState) => state.error);

export const loginLoadingSelector = createSelector([selectLogin], (state: ILoginState) => state.isLoading);
