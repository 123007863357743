import {RestQueryParams, marineHubAPI, IRawRestQueryParams} from 'marine-panel-common-web';
import {Observable} from 'rxjs';

export function getBoatCertificatesAPI(authToken: string | null, params?: IRawRestQueryParams | null): Observable<any> {
    const queryParams = params ? new RestQueryParams(params) : new RestQueryParams(),
        headers = {
            Authorization: `Bearer ${authToken}`,
        };

    return marineHubAPI.get(`api/boat_certs`, queryParams, headers);
}
