import React, {useEffect, useState} from 'react';
import {Loader, LoaderType, Translation} from 'marine-panel-common-web';
import {Modal, ModalBody, ModalFooter, ModalHeader, Table} from 'reactstrap';
import {AlertCircle} from 'react-feather';
import useModal from '../../../../utils/modalHook';
import OperatorsList from '../OperatorsList';
import {IOperatorFullInfo} from '../../../../model/outputItems';
import {useTranslation} from 'react-i18next';
import {changeIsMarinaActionComplete} from '../../../../store/reducers/marinasSlice';

interface IExternalConnectedOperatorsListProps {
    readonly operators: IOperatorFullInfo[] | null;
    readonly bindOperatorToMarina: (marinaId: string) => void;
    readonly unbindOperatorFromMarina: (marinaId: string) => void;
    readonly isMarinaActionComplete: boolean;
    readonly changeIsMarinaActionComplete: typeof changeIsMarinaActionComplete;
}

interface IConnectedOperatorsListProps extends IExternalConnectedOperatorsListProps {}

const ConnectedOperatorsList: React.FC<IConnectedOperatorsListProps> = ({
    operators,
    isMarinaActionComplete,
    changeIsMarinaActionComplete,
    bindOperatorToMarina,
    unbindOperatorFromMarina,
}) => {
    const [isLoading, setIsLoading] = useState<boolean>(true),
        [selectedOperatorId, setSelectedOperatorId] = useState<string | null>(null),
        [isOperatorsListShown, setIsOperatorsListShown] = useState<boolean>(false),
        [isDetachOperatorsModalShown, toggleDetachOperatorsModal] = useModal(),
        {t} = useTranslation();

    useEffect(() => {
        if (null !== operators) {
            setIsLoading(false);
        }
    }, [operators]);

    useEffect(() => {
        if (isMarinaActionComplete) {
            setIsOperatorsListShown(false);

            if (isDetachOperatorsModalShown) {
                toggleDetachOperatorsModal();
            }
        }
        return () => {
            if (changeIsMarinaActionComplete) {
                changeIsMarinaActionComplete(false);
            }
        };
    }, [isMarinaActionComplete]);

    return (
        <>
            <div className="content-header justify-content-end mb-1">
                <button className="btn btn-create" onClick={() => setIsOperatorsListShown(!isOperatorsListShown)}>
                    <Translation text="marinas.connectedOperators.addOperator" />
                </button>
            </div>

            {isOperatorsListShown ? <OperatorsList connectedOperators={operators} bindOperator={bindOperatorToMarina} /> : null}
            {renderConnectedOperators()}
            {renderDetachOperatorsModal()}

            <Loader type={LoaderType.Local} showLoader={isLoading} />
        </>
    );

    function renderConnectedOperators() {
        return (
            <Table responsive className="operators-table">
                <thead>
                    <tr>
                        <th>
                            <Translation text="operators.table.name" />
                        </th>
                        <th />
                    </tr>
                </thead>
                <tbody>{renderTableRows()}</tbody>
            </Table>
        );
    }

    function renderTableRows() {
        if (null === operators || !operators.length) {
            return (
                <tr>
                    <td colSpan={2}>
                        <Translation text="operators.table.noData" />
                    </td>
                </tr>
            );
        }

        return operators.map((item: IOperatorFullInfo) => {
            const userName =
                    item.user && item.user.firstName && item.user.lastName
                        ? `${item.user.firstName} ${item.user.lastName}`
                        : t('marinas.connectedOperators.table.unknownOperator'),
                operatorName = item.operator.name ? item.operator.name : userName;
            return (
                <tr key={item.id}>
                    <td>{operatorName}</td>
                    <td>
                        <button className="btn btn-underline" onClick={() => toggleDetachModal(item.operator.id)}>
                            <Translation text="buttons.detach" />
                        </button>
                    </td>
                </tr>
            );
        });
    }

    function renderDetachOperatorsModal() {
        return (
            <Modal isOpen={isDetachOperatorsModalShown} toggle={() => toggleDetachModal(null)}>
                <ModalHeader toggle={() => toggleDetachModal(null)}>
                    <Translation text="operators.detachMarinaModal.title" />
                </ModalHeader>
                <ModalBody>
                    <p className="modal-subtitle">
                        <Translation text="operators.detachMarinaModal.subtitle" />
                    </p>
                    <p className="modal-description warning">
                        <span>
                            <AlertCircle size={16} />
                        </span>
                        <Translation text="operators.detachMarinaModal.description" />
                    </p>
                </ModalBody>
                <ModalFooter>
                    <button className="btn btn-primary-outline cancel-confirm" onClick={() => toggleDetachModal(null)}>
                        <Translation text="buttons.cancel" />
                    </button>
                    <button className="btn btn-primary" onClick={() => detachOperator(selectedOperatorId)}>
                        <Translation text="buttons.delete" />
                    </button>
                </ModalFooter>
            </Modal>
        );
    }

    function toggleDetachModal(operatorId: string | null) {
        toggleDetachOperatorsModal();
        setSelectedOperatorId(operatorId);
    }

    function detachOperator(operatorId: string | null) {
        if (operatorId === null) {
            return;
        }
        unbindOperatorFromMarina(operatorId);
    }
};

export default ConnectedOperatorsList;
