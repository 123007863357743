import {Observable} from 'rxjs';
import {AccountBasicInfo, ApiSingleResponseBase, ProfileSettingsModalForm} from '../../types';
import {RestQueryParams} from '../base/queryParams';
import {marineHubAPI} from '../provider/marineHubAPI';

export function updateAccountDataAPI(
    authToken: string | null,
    accountId: string,
    account: ProfileSettingsModalForm
): Observable<ApiSingleResponseBase<AccountBasicInfo>> {
    let headers = undefined;

    if (authToken) {
        headers = {
            Authorization: `Bearer ${authToken}`,
        };
    }

    return marineHubAPI.put(`api/accounts/${accountId}`, account, new RestQueryParams(), headers);
}
