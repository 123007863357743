import {Marina, FormControlChangeType, Loader, Translation, Form} from 'marine-panel-common-web';
import {FC, useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {useParams} from 'react-router-dom';
import {BehaviorSubject, Subscription} from 'rxjs';
import {filter, share} from 'rxjs/operators';
import {RootState} from '../../../../../../store/reducers';
import {changeMarinaPolicyAndRule, fetchMarinaDetails} from '../../../../../../store/reducers/marinaEditWizardSlice';
import {currentlyEditedMarina, isMarinaEditWizardLoadingSelector} from '../../../../../../store/selectors/marinaEditWizardSelectors';
import EditFormControl from '../../Common/EditFormControl';
import {policiesAndRulesDefaultFormConfig} from './policiesAndRulesDefaultFormConfig';
import {IPolicyAndRulePayloadMarinaInput} from '../../../../../../api/marina/changeMarinaPoliciesAndRules';

interface IMarinaPoliciesAndRulesProps {
    readonly marina: Marina | null;
    readonly isLoading: boolean;
    readonly fetchMarinaDetails: typeof fetchMarinaDetails;
    readonly changeMarinaPolicyAndRule: typeof changeMarinaPolicyAndRule;
}

export enum MarinaPolicyAndRules {
    GATE_OPENS_AT = 'gateOpensAt',
    GATE_CLOSES_AT = 'gateClosesAt',
    ADDITIONAL_RULES = 'additionalRules',
}

const MarinaPoliciesAndRules: FC<IMarinaPoliciesAndRulesProps> = ({marina, fetchMarinaDetails, changeMarinaPolicyAndRule, isLoading}) => {
    const params = useParams(),
        marinaId = params.id,
        [onValueStateChange$] = useState(() => new BehaviorSubject<any>(null)),
        [onValueStateChange$$] = useState(() => onValueStateChange$.pipe(share())),
        subscriptions: Subscription[] = [];

    useEffect(() => {
        fetchMarinaDetails(marinaId);

        return () => {
            subscriptions.forEach((subscription) => subscription.unsubscribe());
        };
    }, []);

    useEffect(() => {
        setPoliciesAndRulesValues();
    }, [marina]);

    const [editedItemId, setEditedItemId] = useState<string | null>(null);
    const marinaPolicyAndRulesArray = Object.values(MarinaPolicyAndRules);
    const arrayOfObjects = marinaPolicyAndRulesArray.map((key) => ({[key]: null}));
    const tupleArray = arrayOfObjects.map((obj) => Object.entries(obj)[0] as [string, null]);
    const result = Object.fromEntries(tupleArray),
        [value, setValue] = useState<any>(result);

    useEffect(() => {
        subscriptions.push(
            onValueStateChange$$
                .pipe(
                    filter((data: {controlName?: string; value?: {[name: string]: string}; changeType?: FormControlChangeType}) => {
                        return data && data?.changeType === FormControlChangeType.User;
                    })
                )
                .subscribe((data) => {
                    if (data.controlName) {
                        setValue((prevState: {[key: string]: any}) => {
                            return {
                                gateOpensAt: data.value && data.value.gateOpensAt ? data.value.gateOpensAt : prevState?.gateOpensAt,
                                gateClosesAt: data.value && data.value.gateClosesAt ? data.value.gateClosesAt : prevState?.gateClosesAt,
                                additionalRules:
                                    data.value && data.value.additionalRules ? data.value.additionalRules : prevState?.additionalRules,
                            };
                        });
                    }
                })
        );
    }, []);

    function onValueStateChange(controlName: string, value: any, changeType: FormControlChangeType) {
        onValueStateChange$.next({controlName: controlName, value: value, changeType: changeType});
    }

    if (!marinaId) return null;

    const onConfirmAction = (marinaId: string, policyKey: string, value: string | null) => {
        const payload: IPolicyAndRulePayloadMarinaInput = {
            field: policyKey,
            value: value,
        };
        changeMarinaPolicyAndRule(marinaId, payload);
        setEditedItemId(null);
    };

    function setPoliciesAndRulesValues() {
        if (marina && marina.policyAndRules) {
            const keysToKeep = ['gateOpensAt', 'gateClosesAt', 'additionalRules'];

            const defaultValues = Object.entries(marina.policyAndRules).reduce((obj: {[key: string]: any}, [key, value]) => {
                if (keysToKeep.includes(key)) {
                    obj[key] = value;
                }
                return obj;
            }, {});

            setValue(defaultValues);
        }
    }

    const renderPoliciesList = (policyKeys: string[]) => {
        if (policyKeys.length === 0) return null;
        return Object.entries(policyKeys).map(([policyKey, displayValue]) => {
            const policyValue = displayValue ? displayValue : <Translation text="editMenuItems.sections.marina_policies.noPolicySet" />;
            return (
                <EditFormControl
                    key={policyKey}
                    editedItemId={editedItemId}
                    setEditedItemId={(editedItemId: string | null) => setEditedItemId(editedItemId)}
                    currentItemId={policyKey}
                    titleTranslationKey={`editMenuItems.sections.marina_policies.formControls.${policyKey}`}
                    onConfirm={() => onConfirmAction(marinaId, policyKey, value?.[`${policyKey}`])}
                    confirmButtonsDisabled={true}
                    editedChildren={
                        <Form
                            controlName={`${policyKey}Form`}
                            config={policiesAndRulesDefaultFormConfig(policyKey, displayValue)}
                            value={value}
                            onValueStateChange={onValueStateChange}
                            submitForm={() => onConfirmAction(marinaId, policyKey, value?.[`${policyKey}`])}
                        />
                    }
                    displayChildren={<p className="secondary-title description">{policyValue}</p>}
                />
            );
        });
    };
    return (
        <div className="details-edit-section-wrapper">
            <div className="details-edit-section col-xl-6">
                <h2 className="title main">
                    <Translation text="editMenuItems.sections.marina_policies.title" />
                </h2>
                <div>{renderPoliciesList(value)}</div>
                <Loader showLoader={isLoading} />
            </div>
        </div>
    );
};

export default connect(
    (state: RootState) => ({
        marina: currentlyEditedMarina(state),
        isLoading: isMarinaEditWizardLoadingSelector(state),
    }),
    {fetchMarinaDetails, changeMarinaPolicyAndRule}
)(MarinaPoliciesAndRules);
