import {Marina, GeoPointOutput, Translation} from 'marine-panel-common-web';
import {FC} from 'react';
import {connect} from 'react-redux';
import {RootState} from '../../../../../../../store/reducers';
import {changeIsMarinaEditWizardLoading} from '../../../../../../../store/reducers/marinaEditWizardSlice';
import {changeIsModalOpen, ModalNames, setModalName} from '../../../../../../../store/reducers/modalSlice';
import {nameSelector} from '../../../../../../../store/selectors/modalSelectors';
import {isMarinaEditWizardInitializedSelector} from '../../../../../../../store/selectors/marinaEditWizardSelectors';

interface IMarinaLocationProps {
    readonly marina: Marina | null;
    readonly isMarinaEditWizardInitialized: boolean;
    readonly setModalName: typeof setModalName;
    readonly modalName: ModalNames | null | undefined;
    readonly changeIsModalOpen: typeof changeIsModalOpen;
}

const MarinaLocation: FC<IMarinaLocationProps> = ({marina, setModalName, changeIsModalOpen}) => {
    return (
        <div id="marina_location" className="details-edit-section col-xl-6" data-section="marina_location">
            <div className="details-edit-form-control">
                <div className="d-flex justify-content-between">
                    <h3 className="title main">
                        <Translation text="editMenuItems.sections.marina_location.title" />
                    </h3>
                    <button
                        className="header-button"
                        onClick={() => {
                            setModalName(ModalNames.SET_MARINA_LOCATION);
                            changeIsModalOpen(true);
                        }}>
                        <span className="secondary-title edit">
                            <Translation text="buttons.edit" />
                        </span>
                    </button>
                </div>

                <ul className="location-list">
                    {marina?.location ? (
                        <li className="secondary-title description" key={marina.id}>
                            {getLocationCoords(marina?.location)}
                        </li>
                    ) : null}
                </ul>
            </div>
        </div>
    );
};

function getLocationCoords(vertex: GeoPointOutput) {
    return `${vertex.latitude} ${vertex.latitude <= 0 ? 'S' : 'N'}  ${vertex.longitude} ${vertex.longitude >= 0 ? 'E' : 'W'}`;
}
export default connect(
    (state: RootState) => ({
        isMarinaEditWizardInitialized: isMarinaEditWizardInitializedSelector(state),
        modalName: nameSelector(state),
    }),
    {
        changeIsMarinaEditWizardLoading,
        setModalName,
        changeIsModalOpen,
    }
)(MarinaLocation);
