import {addAlert, AlertType, authTokenSelector, handleApiError, IRawRestQueryParams} from 'marine-panel-common-web';
import {combineEpics, Epic, ofType, StateObservable} from 'redux-observable';
import {Observable, of} from 'rxjs';
import {catchError, switchMap} from 'rxjs/operators';
import {getVesselModelsAPI} from '../../api/getVesselModels';
import {RootState} from '../reducers';
import {
    changeIsVesselModelsPageLoading,
    changeVesselModels,
    fetchVesselModels,
    setVesselModelsPageError,
    addVesselModel,
    IAddVesselModel,
    changeIsActionProcessing,
    changeIsActionComplete,
} from '../reducers/vesselModelsPageSlice';
import {addVesselModelAPI} from '../../api/addVesselModel';
import {PayloadAction} from '@reduxjs/toolkit';

const fetchVesselModelsEpic: Epic = (action$, state$: StateObservable<RootState>) => {
    return getVesselModels(action$, state$, fetchVesselModels);
};

const addVesselModelEpic: Epic = (action$: Observable<any>, state$: StateObservable<RootState>) => {
    return action$.pipe(
        ofType(addVesselModel.type),
        switchMap((action: PayloadAction<IAddVesselModel>): any => {
            const authToken = authTokenSelector(state$.value),
                payload = action.payload.vesselModelPayload;

            return addVesselModelAPI(authToken, payload).pipe(
                switchMap(() => {
                    const message = 'administration.vesselModels.addVesselModel.vesselModelAdded';
                    return of(...updateSuccessActions(message));
                }),
                catchError((error) => of(...updateErrorActions(error)))
            );
        }),
        catchError((error) => of(...updateErrorActions(error)))
    );
};

const getVesselModels = (action$: Observable<any>, state$: StateObservable<RootState>, actionType: any) => {
    return action$.pipe(
        ofType(actionType.type),
        switchMap((): any => {
            const authToken = authTokenSelector(state$.value);

            return getVesselModelsList(authToken);
        }),
        catchError((error) => of(...errorActions(error)))
    );
};

export const getVesselModelsList = (authToken: string, params?: IRawRestQueryParams | null) => {
    return getVesselModelsAPI(authToken, params).pipe(
        switchMap((resp: any) => {
            const vesselModelList = resp['hydra:member'],
                actions = successActions(vesselModelList);
            return of(...actions);
        }),
        catchError((error: any) => of(...errorActions(error)))
    );
};

const successActions = (vesselModels: any[]): any[] => {
    return [changeVesselModels(vesselModels), changeIsVesselModelsPageLoading(false)];
};

const updateSuccessActions = (successMessage: string): any[] => {
    return [changeIsActionProcessing(false), changeIsActionComplete(true), addAlert({message: successMessage}), fetchVesselModels()];
};

const updateErrorActions = (error: any): any[] => {
    const errorObj = handleApiError(error);
    errorObj.type = AlertType.WARNING;
    return [changeIsActionProcessing(false), changeIsActionComplete(true), setVesselModelsPageError(errorObj.message), addAlert(errorObj)];
};

const errorActions = (error: any): any[] => {
    const errorObj = handleApiError(error);
    errorObj.type = AlertType.WARNING;
    return [changeIsVesselModelsPageLoading(false), setVesselModelsPageError(errorObj.message), addAlert(errorObj)];
};

const vesselModelsPageEpic = combineEpics(fetchVesselModelsEpic, addVesselModelEpic);

export default vesselModelsPageEpic;
